export function createFamily(data, callback) {
	let { createFamilyRequest, createFamilyError, createFamilyResult } = this.props;

	delete data.creationDate;
	delete data.updateDate;

	this.doAction(
		this.WEB_REQUEST_URL + 'create_family',
		this.createBundle(
			'create_family',
			{ family: data },
			this.POST,
			createFamilyRequest,
			(result) => {
				createFamilyResult(result)
				callback(result)
			},
			createFamilyError
		)
	);
}
