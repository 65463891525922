export function mergeMold(data) {
    return new Promise((resolve, reject) => {
		let { mergeMoldRequest, mergeMoldError, mergeMoldResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'merge_mold',
			this.createBundle(
				'merge_mold',
				{ data: data },
				this.POST,
				mergeMoldRequest,
                (result) => {
                    mergeMoldResult(result)
                    resolve(result)
                },
                (error) => {
                    mergeMoldError(error)
                    reject(error)
                }
			)
		);
	})
}
