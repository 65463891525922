import { DELETELOT_TYPES } from '../actions/deleteLot'
import reducer, { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    let requestReducer = reducer(DELETELOT_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer