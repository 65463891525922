import { connectNetwork } from 'lib/NetworkProvider'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Image } from 'semantic-ui-react'
import defaultImage from '../assets/default.png'

class ImageDefault extends Component {
    render() {
        let { fromRoot, src, network, dispatch, staticContext, ...rest } = this.props

        let image = src && src !== '' ? src : defaultImage

        if (fromRoot && !image.startsWith('http')) {
            //console.log("Image from root network:", network);
            image = `${network.getUrl()}${image}`
        }

        return src && src !== '' ? (
            <Image
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = defaultImage
                }}
                src={image}
                {...rest}
            />
        ) : (
            <Image {...rest} src={defaultImage} />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

export default withRouter(connect(mapStateToProps)(connectNetwork(ImageDefault)))
