import { Box, VStack } from '@chakra-ui/layout'
import React, { Component } from 'react'
import { Button, Form, Select } from 'semantic-ui-react'

class Sort extends Component {
    constructor(props) {
        super(props)

        this.state = {
            localValue: '',
        }
    }

    render() {
        const { availableSorts, activeSort, toExclude } = this.props

        const options = []
        for (const key in availableSorts) {
            if (key in toExclude) {
                continue
            }

            const filter = availableSorts[key]
            options.push({
                key: filter.name,
                value: key,
                text: filter.label,
            })
        }

        const sortOptions = [
            {
                key: 'asc',
                value: 'asc',
                text: 'Crescente',
            },
            {
                key: 'desc',
                value: 'desc',
                text: 'Decrescente',
            },
        ]

        return (
            //backgroundColor: 'rgb(215, 255, 241)',
            <Box m="4">
                {!activeSort && (
                    <VStack w="200">
                        <Box textStyle="description" as="h6" style={{ marginBottom: 8 }}>
                            Seleziona un ordinamento
                        </Box>
                        <Select
                            options={options}
                            onChange={(e, data) => {
                                // this.setState({ activeSort: data.value })
                                if (this.props.onSortActivated) {
                                    this.props.onSortActivated(data.value, true)
                                }
                            }}
                        />
                    </VStack>
                )}
                {activeSort && (
                    <>
                        <VStack w="200">
                            <Box textStyle="description" as="h6" style={{ marginBottom: 4 }}>
                                {availableSorts[activeSort].label}
                            </Box>
                            <Select
                                options={sortOptions}
                                onChange={(e, data) => {
                                    availableSorts[activeSort].onChange(availableSorts[activeSort], data.value)
                                }}
                            />
                        </VStack>

                        <Button
                            size="mini"
                            basic
                            onClick={(e, data) => {
                                if (this.props.onSortActivated) {
                                    this.props.onSortActivated(activeSort, false)
                                }

                                if (availableSorts[activeSort].onReset) {
                                    availableSorts[activeSort].onReset(availableSorts[activeSort])
                                }

                                // this.setState({ activeSort: null })
                            }}
                            icon="trash alternate outline"
                            color="red"
                            style={{ position: 'absolute', top: 0, right: 0 }}
                        />
                    </>
                )}
            </Box>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

export default Sort
