import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import Article from 'market/Article'
import moment from 'moment-timezone'
import 'moment/locale/it'
import Papa from 'papaparse'
import Master from 'printing/Master'
import Material from 'printing/Material'
import Mold from 'printing/Mold'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dimmer, Icon, Loader, Step, Table } from 'semantic-ui-react'
import XLSX from 'xlsx'
import { PickFile } from '../components'
import { baseViewDispatch, goTo, initializePage } from '../lib/util'
import View from '../lib/View'
const { printjobs } = routes
let currentLine = 0
let header = []
let readData = []

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64)
    var len = binary_string.length
    var bytes = new Uint8Array(len)
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
}

const applyPreviewToDatabase = async (
    network,
    { materials, jobs, articleList, materialsPerArticle, masters, mastersPerArticle, molds, moldsPerArticle }
) => {
    return new Promise(async (resolve, reject) => {
        try {
            switch (process.env.REACT_APP_BRANDING) {
                default:
                    break
                case 'vfstampi':
                    /* eslint-disable */
                    console.log('Applying preview to database', {
                        materials,
                        jobs,
                        articleList,
                        materialsPerArticle,
                        masters,
                        mastersPerArticle,
                        molds,
                        moldsPerArticle,
                    })
                    // for (const key in masters) {
                    //     try {
                    //         const master = masters[key]
                    //         await network.createMaster({
                    //             masterCode: master.masterCode,
                    //             masterName: master.masterName,
                    //             masterPrice: master.masterPrice ? master.masterPrice : 0,
                    //             masterSupplier: master.masterSupplier,
                    //         })

                    //         // console.log(
                    //         //     `INSERT INTO master (masterCode, masterName, masterPrice, masterSupplier) VALUES ('${master.masterCode}', '${
                    //         //         master.masterName
                    //         //     }', ${master.masterPrice ? master.masterPrice : 0}, '${master.masterSupplier}')`
                    //         // )
                    //     } catch (e) {
                    //         console.error('Failed while creating master', e)
                    //     }
                    // }

                    // for (const key in materials) {
                    //     try {
                    //         const material = materials[key]
                    //         await network.createMaterial({
                    //             materialCode: material.materialCode,
                    //             pricePerUnit: material.pricePerUnit ? material.pricePerUnit : 0,
                    //             materialDescription: material.materialDescription,
                    //             unit: 'kg',
                    //         })
                    //         // console.log(
                    //         //     `INSERT INTO material (materialCode, materialDescription, pricePerUnit, unit) VALUES ('${material.materialCode}', '${
                    //         //         material.materialDescription
                    //         //     }', ${material.pricePerUnit ? material.pricePerUnit : 0}, 'kg')`
                    //         // )
                    //     } catch (e) {
                    //         console.error('Failed while creating material', e)
                    //     }
                    // }

                    // for (const key in molds) {
                    //     try {
                    //         const mold = molds[key]
                    //         await network.createMold({
                    //             moldCode: mold.moldCode,
                    //             moldDescription: mold.moldDescription,
                    //             moldMachine: mold.moldMachine,
                    //             moldCycleTime: mold.moldCycleTime,
                    //         })

                    //         // console.log(
                    //         //     `INSERT INTO mold (moldCode, moldDescription, moldMachine, moldCycleTime) VALUES ('${mold.moldCode}', '${
                    //         //         mold.moldDescription
                    //         //     }', '${mold.moldMachine ? mold.moldMachine : 'Normale'}', ${mold.moldCycleTime ? mold.moldCycleTime : 0})`
                    //         // )
                    //     } catch (e) {
                    //         console.error('Failed while creating mold', e)
                    //     }
                    // }

                    const articlesToSave = []
                    for (const key in articleList) {
                        for (const key2 in articleList[key]) {
                            try {
                                const article = articleList[key][key2]

                                const {
                                    articleName,
                                    articleCode,
                                    articleSize,
                                    articleWeight,
                                    articleDescription,
                                    articleImages,
                                    articleNote,
                                    molds,
                                    packages,
                                    materials,
                                    kits,
                                    masters,
                                } = article
                                console.log('Article to be created is', {
                                    articleName,
                                    articleCode,
                                    articleSize,
                                    articleWeight,
                                    articleDescription,
                                    articleImages,
                                    articleNote,
                                    molds: moldsPerArticle[articleCode],
                                    packages: [],
                                    materials: materialsPerArticle[articleCode],
                                    kits: [],
                                    masters: mastersPerArticle[articleCode],
                                })
                                articlesToSave.push({
                                    // articleName,
                                    articleCode,
                                    // articleSize,
                                    // articleWeight,
                                    // articleDescription,
                                    // articleImages,
                                    // articleNote,
                                    // molds: moldsPerArticle[articleCode],
                                    // packages: [],
                                    materials: materialsPerArticle[articleCode],
                                    // kits: [],
                                    masters: mastersPerArticle[articleCode],
                                })
                            } catch (e) {
                                console.error('Failed while creating article', e)
                            }
                        }
                    }

                    const articlecreated = await network.createStdArticle({
                        articlesWPictureData: articlesToSave,
                    })

                    resolve(true)
                    break
                case 'syncode':
                case 'krila': {
                    for (const key in materials) {
                        const material = materials[key]
                        try {
                            const result = await network.createMaterial(material)
                            // console.log('Found material', result)
                            materials[key].materialId = result.materialId
                        } catch (error) {
                            console.error('Error while creating material', error)
                        }
                    }

                    for (const key in materials) {
                        for (const j in materialsPerArticle) {
                            const array = materialsPerArticle[j]
                            for (const mat of array) {
                                if (mat.materialCode === materials[key].materialCode) {
                                    mat.materialId = materials[key].materialId
                                }
                            }
                        }
                    }

                    for (const code in jobs) {
                        const job = jobs[code]

                        if (job.jobCode) {
                            try {
                                const result = await network.createJob(job.jobCode, job)
                                // console.log('Create job done', result, code, articleList[code])
                            } catch (error) {
                                console.error('Error while creating job', error)
                            }
                        }

                        const articles = articleList[code]
                        for (const article of articles) {
                            try {
                                const addart = await network.createStdArticle({
                                    articleName: article.workCode,
                                    articleCode: article.workCode,
                                    // articleSize:0,
                                    // articleWeight:0,
                                    // articleState:0,
                                    // articleDescription:articleCode,
                                    // articleDescriptionEng:articleCode,
                                    // articleOrdering:0,
                                    // articleImageIndexToPick:0,
                                    // articleImages:[],
                                    // customerId,
                                    // showDropdown,
                                    // dropdownName,
                                    // dropdownNameEng,
                                    // parentArticleId: removeParent || !parentArticleId ? null : parentArticleId,
                                    // variants,
                                    // familyId,
                                    // articlePrice,
                                    // articleSetupPrice,
                                    // articleNote,
                                    molds: [],
                                    packages: [],
                                    materials: materialsPerArticle[article.workCode],
                                    kits: [],
                                    masters: [],
                                })

                                article.articleId = addart.articleId
                                delete article.material
                                try {
                                    const workorder = await network.createWorkOrder(article)
                                    // console.log('Added article', addart, workorder)
                                } catch (error) {
                                    console.error('error while creating work order', error)
                                }
                            } catch (error) {
                                console.error('error while creating article', error)
                            }
                        }
                    }
                    break
                }
            }
        } catch (e) {
            reject(e)
            return
        }
        resolve(true)
    })
}

function FileImport(props) {
    const { network, toolbarResult, filtersResult, sortsResult, isEmployee, history } = props
    const [step, setStep] = useState(0)
    const [preview, setPreview] = useState(null)
    const [fileToImport, setFileToImport] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        network.getStdArticleList()

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    let molds, masters, materials, jobs, articleList, materialsPerArticle, mastersPerArticle, moldsPerArticle
    if (preview) {
        molds = preview.molds
        masters = preview.masters
        materials = preview.materials
        jobs = preview.jobs
        articleList = preview.articleList
        materialsPerArticle = preview.materialsPerArticle
        mastersPerArticle = preview.mastersPerArticle
        moldsPerArticle = preview.moldsPerArticle
    }

    return (
        <Box p={6} style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
            <Dimmer style={{ height: '100%', position: 'fixed' }} active={loading}>
                <Loader />
            </Dimmer>
            <Step.Group style={{ width: '100%' }}>
                <Step active={step === 0}>
                    <Icon name="file alternate outline" />
                    <Step.Content>
                        <Step.Title>File</Step.Title>
                        <Step.Description>Seleziona il file da importare</Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 1}>
                    <Icon name="tablet alternate" />
                    <Step.Content>
                        <Step.Title>Anteprima</Step.Title>
                        <Step.Description></Step.Description>
                    </Step.Content>
                </Step>

                <Step active={step === 2}>
                    <Icon name="cube" />
                    <Step.Content>
                        <Step.Title>Fine</Step.Title>
                        <Step.Description></Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Table celled>
                {step === 0 && (
                    <>
                        <PickFile
                            type="archive"
                            buttonText="Scegli file da importare"
                            onChange={(fileToImport) => {
                                setFileToImport(fileToImport)
                            }}
                            value={fileToImport}
                            handleUpload={(event, name) => {
                                const file = event.target.files[0]
                                // console.log('Selected file', name, file, file.type)
                                // if (file.type && !file.type.startsWith('image/')) {
                                //     console.log('File is not an image.', file.type, file)
                                //     return
                                // }

                                let type = 'excel'
                                if (file.type && file.type.indexOf('excel') >= 0) {
                                    type = 'excel'
                                } else if (file.type.indexOf('text/csv') >= 0) {
                                    type = 'csv'
                                } else {
                                    type = 'csv'
                                }

                                if (process.env.REACT_APP_BRANDING === 'krila' || process.env.REACT_APP_BRANDING === 'syncode') {
                                    currentLine = 0
                                    header = []
                                    readData = []

                                    Papa.parse(file, {
                                        step: function (results, parser) {
                                            if (currentLine === 1) {
                                                header = results.data
                                            } else if (currentLine > 2) {
                                                readData.push(results.data)
                                            }
                                            // console.log('Row data:', results.data)
                                            // console.log('Row errors:', results.errors)
                                            currentLine++
                                        },
                                        complete: async () => {
                                            // console.log('Parsing complete', { header, readData })

                                            const jobs = {}
                                            const articleList = {}
                                            const materials = {}
                                            const materialsPerArticle = {}

                                            for (const line of readData) {
                                                const creationDate = line[1]
                                                if (!creationDate || creationDate === '') {
                                                    continue
                                                }

                                                if (!moment(creationDate, 'DD/MM/YY').isValid()) {
                                                    continue
                                                }

                                                // console.log('Line is', line)

                                                const customer = line[2]
                                                const jobCode = line[3].trim()
                                                const articleinfo = line[4].trim()
                                                const articleCode = articleinfo.substring(0, articleinfo.indexOf(' '))
                                                const articleDescr = articleinfo.substring(articleinfo.indexOf(' '), articleinfo.length)
                                                let ordered = parseInt(line[5].replace(',', ''))
                                                const deliveryDate = line[6]
                                                let remaining = parseInt(line[8].replace(',', ''))

                                                if (!jobCode) {
                                                    continue
                                                }

                                                if (isNaN(ordered)) {
                                                    ordered = 0
                                                    // console.error('IT IS NOT A NUMBER', line[5])
                                                }
                                                if (isNaN(remaining)) {
                                                    remaining = 0
                                                    // console.error('IT IS NOT A NUMBER', line[8])
                                                }

                                                const workCode = line[9].trim() + line[10].trim()

                                                if (!workCode || workCode === '' || !articleCode || articleCode === '') {
                                                    continue
                                                }

                                                let sent = parseInt(line[17].replace(',', ''))
                                                if (isNaN(sent)) {
                                                    sent = 0
                                                }
                                                const rifddt = line[16]
                                                const materiaprima = line[20].trim()
                                                const materiaprimaprice = line[21]

                                                if (materiaprima && materiaprima.indexOf('?') === -1) {
                                                    materials[materiaprima] = {
                                                        materialCode: materiaprima,
                                                        pricePerUnit: materiaprimaprice,
                                                        materialDescription: materiaprima,
                                                        unit: 'kg',
                                                    }
                                                }

                                                if (!materialsPerArticle[articleCode]) {
                                                    materialsPerArticle[articleCode] = []
                                                }

                                                let skipit = false
                                                for (const mat of materialsPerArticle[articleCode]) {
                                                    if (mat.materialCode === materiaprima) {
                                                        skipit = true
                                                    }
                                                }
                                                if (!skipit && materiaprima) {
                                                    materialsPerArticle[articleCode].push({
                                                        materialCode: materiaprima,
                                                        pricePerUnit: materiaprimaprice,
                                                        materialDescription: materiaprima,
                                                        unit: 'kg',
                                                    })
                                                }

                                                jobs[jobCode] = {
                                                    jobCode: jobCode,
                                                    creationDate: moment(creationDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                                    description: jobCode,
                                                    active: 1,
                                                    jobType: 3,
                                                    client: customer,
                                                }

                                                if (!articleList[jobCode]) {
                                                    articleList[jobCode] = []
                                                }
                                                articleList[jobCode].push({
                                                    material: materiaprima,
                                                    workCode: articleCode,
                                                    jobCode: jobCode,
                                                    workOrderInternalId: workCode,
                                                    clientOrderCode: articleCode,
                                                    workOrderDescription: articleDescr,
                                                    product: articleCode,
                                                    rifddt,
                                                    productQuantity: ordered,
                                                    producedQuantity: ordered - remaining,
                                                    deliveredQuantity: sent,
                                                    remainingQuantity: remaining,
                                                    articleId:
                                                        props.articles && props.articles[articleCode] ? props.articles[articleCode].articleId : null,
                                                    completed: remaining === 0,
                                                    deliveryDate: moment(deliveryDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                                })
                                            }

                                            setPreview({
                                                materials,
                                                jobs,
                                                articleList,
                                                materialsPerArticle,
                                            })
                                            setStep(1)
                                        },
                                    })
                                } else if (process.env.REACT_APP_BRANDING === 'vfstampi') {
                                    const reader = new FileReader()
                                    reader.addEventListener('load', (event) => {
                                        const result = []
                                        switch (type) {
                                            case 'excel':
                                            default:
                                                {
                                                    const workbook = XLSX.read(
                                                        event.target.result.replace('data:application/vnd.ms-excel;base64,', ''),
                                                        {
                                                            type: 'base64',
                                                        }
                                                    )

                                                    const csv = XLSX.utils.sheet_to_csv(workbook.Sheets['Sheet1'], { FS: ';', blankrows: false })

                                                    const lines = csv.split('\n')

                                                    let linecount = 0
                                                    let header = []
                                                    for (const line of lines) {
                                                        const row = line.split(';')
                                                        if (linecount === 0) {
                                                            header = row
                                                        } else {
                                                            const rowobj = {}
                                                            for (let i = 0; i < row.length; i++) {
                                                                rowobj[header[i]] = row[i]
                                                            }
                                                            result.push(rowobj)
                                                        }

                                                        linecount += 1
                                                    }
                                                }
                                                break
                                            case 'csv':
                                                {
                                                    const lines = event.target.result.split('\n')
                                                    let linecount = 0
                                                    let header = []
                                                    for (const line of lines) {
                                                        const row = line.split(',')
                                                        if (linecount === 0) {
                                                            header = row
                                                        } else {
                                                            const rowobj = {}
                                                            for (let i = 0; i < row.length; i++) {
                                                                rowobj[header[i]] = row[i]
                                                            }
                                                            result.push(rowobj)
                                                        }

                                                        linecount += 1
                                                    }
                                                }
                                                break
                                        }

                                        const jobs = {}
                                        const articleList = {}
                                        const materials = {}
                                        const masters = {}
                                        const materialsPerArticle = {}
                                        const mastersPerArticle = {}
                                        const moldsPerArticle = {}
                                        const molds = {}

                                        switch (process.env.REACT_APP_BRANDING) {
                                            case 'vfstampi': {
                                                let rowNumber = 0
                                                if (type === 'excel') {
                                                    const ORDINE = 'ORDINE'
                                                    const CODMAT = 'CODMAT'
                                                    const DATA_O = 'CODMAT'
                                                    const DESCMAT = 'CODMAT'
                                                    const TOTALE = 'CODMAT'
                                                    const QTA_PROD = 'CODMAT'
                                                    const QTA_BOLLE = 'CODMAT'
                                                    const RESIDUO = 'CODMAT'
                                                    const FLAG_CON = 'CODMAT'

                                                    const jobs = {}
                                                    const articleList = {}
                                                    for (const row of result) {
                                                        if (!row[ORDINE]) continue

                                                        jobs[row[ORDINE]] = {
                                                            jobCode: row[ORDINE],
                                                            creationDate: moment(row[DATA_O], 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                                                            description: row[DESCMAT],
                                                            active: 1,
                                                            jobType: 3,
                                                        }

                                                        if (!articleList[row[ORDINE]]) {
                                                            articleList[row[ORDINE]] = []
                                                        }
                                                        articleList[row[ORDINE]].push({
                                                            workCode: row[CODMAT],
                                                            jobCode: row[ORDINE],
                                                            clientOrderCode: row[CODMAT],
                                                            workOrderDescription: row[DESCMAT],
                                                            product: row[CODMAT],
                                                            productQuantity: row[TOTALE].replace(',', ''),
                                                            producedQuantity: row[QTA_PROD].replace(',', ''),
                                                            deliveredQuantity: row[QTA_BOLLE].replace(',', ''),
                                                            remainingQuantity: row[RESIDUO].replace(',', ''),
                                                            articleId:
                                                                props.articles && props.articles[row[CODMAT]]
                                                                    ? props.articles[row[CODMAT]].articleId
                                                                    : null,
                                                            completed: row[FLAG_CON] !== '',
                                                            deliveryDate: moment(row[DATA_O], 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                                                        })
                                                    }

                                                    // console.log('Imported jobs: ', jobs, articleList)
                                                    for (const code in jobs) {
                                                        const job = jobs[code]

                                                        network.createJob(job.jobCode, job).then((result) => {
                                                            // console.log('Create job done', result, code, articleList[code])
                                                            const articles = articleList[code]
                                                            for (const article of articles) {
                                                                network.createWorkOrder(article)
                                                            }
                                                        })
                                                    }
                                                } else {
                                                    for (const row of result) {
                                                        rowNumber += 1
                                                        /*
                                                            Master: ""
                                                            % Master: ""
                                                            Codice Master: ""
                                                            Costo Master: ""
                                                            Fornitore Master: ""

                                                            Materiale 1: "PA6 Nero (Cassone)"
                                                            % Materiale 1: "100"
                                                            Codice Materiale 1: ""
                                                            Fornitore Materiale 1: "Leonardi"
                                                            Costo Materiale 1: ""

                                                            Materiale 2: ""
                                                            % Materiale 2: ""
                                                            Fornitore Materiale 2: ""
                                                            Costo Materiale 2: ""
                                                            
                                                            Stampo: "C799"
                                                            Stampo 2: ""
                                                            Stampo 3: ""

                                                            Codice: "34909005"
                                                            Descrizione: "PFA173 Coppia tappo  Terminale profilo ALUKYS0200"
                                                            
                                                            Cliente: "FB Group"
                                                            "Nota ": "\r"
                                                            Peso: ""
                                                            Pressa: "Baby"
                                                            Tempo Ciclo: ""
                                                        */

                                                        const ordered = row['Ordinati'] ? row['Ordinati'] : 0
                                                        const remaining = row['Rimanenti'] ? row['Rimanenti'] : 0
                                                        const stamped = row['Bollati'] ? row['Bollati'] : 0
                                                        const sent = row['Spediti'] ? row['Spediti'] : 0
                                                        const deliveryDate = row['Data di consegna'] ? row['Data di consegna'] : moment()

                                                        const articleCode = row['Codice']
                                                        const articleName = row['Descrizione']
                                                        const tempmolds = []

                                                        if (row['Stampo']) {
                                                            tempmolds.push({
                                                                mcode: row['Stampo'],
                                                            })
                                                        }
                                                        if (row['Stampo 2']) {
                                                            tempmolds.push({
                                                                mcode: row['Stampo 2'],
                                                            })
                                                        }

                                                        if (row['Stampo 3']) {
                                                            tempmolds.push({
                                                                mcode: row['Stampo 3'],
                                                                pressa: pressa ? pressa : '',
                                                                tciclo: tciclo ? tciclo : 0,
                                                            })
                                                        }

                                                        const tempmats = []

                                                        if (row['Materiale 1']) {
                                                            tempmats.push({
                                                                mpcode: row['Materiale 1'],
                                                                mpprice: row['Costo Materiale 1'],
                                                                mppercent: row['% Materiale 1'],
                                                                mpdesc: row['Materiale 1'],
                                                            })
                                                        }

                                                        if (row['Materiale 2']) {
                                                            tempmats.push({
                                                                mpcode: row['Materiale 2'],
                                                                mpprice: row['Costo Materiale 2'],
                                                                mppercent: row['% Materiale 2'],
                                                                mpdesc: row['Materiale 2'],
                                                            })
                                                        }

                                                        const mscode = row['Master']
                                                        const msprice = row['Costo Master']
                                                        const mspercent = row['% Master']
                                                        const msdesc = row['Master']
                                                        const mssupp = row['Fornitore Master']

                                                        const customer = row['Cliente']
                                                        const notes = row['Nota ']
                                                        const peso = row['Peso']
                                                        const pressa = row['Pressa']
                                                        const tciclo = row['Tempo Ciclo']

                                                        // console.log('Material adding', tempmats)
                                                        for (const mtemp of tempmats) {
                                                            const { mpcode, mpprice, mppercent, mpdesc } = mtemp

                                                            if (mpcode && mpcode !== '') {
                                                                materials[mpcode] = {
                                                                    materialCode: mpcode,
                                                                    pricePerUnit: mpprice,
                                                                    materialDescription: mpdesc,
                                                                    unit: 'kg',
                                                                }
                                                            }

                                                            if (!materialsPerArticle[articleCode]) {
                                                                materialsPerArticle[articleCode] = []
                                                            }

                                                            let skipit = false
                                                            for (const mat of materialsPerArticle[articleCode]) {
                                                                if (mat.materialCode === mpcode) {
                                                                    skipit = true
                                                                }
                                                            }
                                                            if (!skipit && mpcode) {
                                                                materialsPerArticle[articleCode].push({
                                                                    materialCode: mpcode,
                                                                    pricePerUnit: mpprice,
                                                                    materialDescription: mpcode,
                                                                    unit: 'kg',
                                                                    materialPercent: mppercent,
                                                                })
                                                            }
                                                        }

                                                        // console.log('Master adding', row, mscode)
                                                        if (mscode && mscode !== '') {
                                                            masters[mscode] = {
                                                                masterCode: mscode,
                                                                masterName: msdesc,
                                                                masterPrice: msprice,
                                                                masterSupplier: mssupp,
                                                            }
                                                        }

                                                        if (!mastersPerArticle[articleCode]) {
                                                            mastersPerArticle[articleCode] = []
                                                        }

                                                        let skipit = false
                                                        for (const mat of mastersPerArticle[articleCode]) {
                                                            if (mat.masterCode === mscode) {
                                                                skipit = true
                                                            }
                                                        }
                                                        if (!skipit && mscode) {
                                                            mastersPerArticle[articleCode].push({
                                                                masterCode: mscode,
                                                                masterName: mscode,
                                                                masterPrice: msprice,
                                                                masterSupplier: mssupp,
                                                                masterPercent: mspercent,
                                                            })
                                                        }

                                                        if (!moldsPerArticle[articleCode]) {
                                                            moldsPerArticle[articleCode] = []
                                                        }
                                                        for (const mold of tempmolds) {
                                                            if (mold.mcode && !molds[mold.mcode]) {
                                                                molds[mold.mcode] = {
                                                                    moldCode: mold.mcode,
                                                                    moldDescription: mold.mcode,
                                                                    moldMachine: mold.pressa,
                                                                    moldCycleTime: mold.tciclo,
                                                                }
                                                            }

                                                            skipit = false
                                                            for (const mat of moldsPerArticle[articleCode]) {
                                                                if (mold.mcode === mat.moldCode) {
                                                                    skipit = true
                                                                }
                                                            }
                                                            if (!skipit && mold.mcode) {
                                                                moldsPerArticle[articleCode].push({
                                                                    moldCode: mold.mcode,
                                                                    moldDescription: mold.mcode,
                                                                    moldMachine: mold.pressa,
                                                                    moldCycleTime: mold.tciclo,
                                                                    piecesPerCycle: 1,
                                                                })
                                                            }
                                                        }

                                                        const jobCode = '#AUTO#'
                                                        jobs[jobCode] = {
                                                            jobCode,
                                                            creationDate: moment().format('YYYY-MM-DD'),
                                                            description: jobCode,
                                                            active: 1,
                                                            jobType: 3,
                                                            client: customer,
                                                        }

                                                        if (!articleList[rowNumber]) {
                                                            articleList[rowNumber] = []
                                                        }
                                                        articleList[rowNumber].push({
                                                            articleCode: articleCode,
                                                            articleName: articleName,
                                                            articleWeight: isNaN(parseFloat(peso)) ? 0 : parseFloat(peso),
                                                            articleNotes: notes,
                                                            master: mscode,
                                                            masterPercent: mspercent,
                                                            workCode: articleCode,
                                                            jobCode: rowNumber,
                                                            clientOrderCode: articleCode,
                                                            workOrderDescription: articleName,
                                                            product: articleCode,
                                                            // rifddt,
                                                            productQuantity: ordered ? ordered : 0,
                                                            producedQuantity: ordered ? ordered - remaining : 0,
                                                            deliveredQuantity: ordered ? sent : 0,
                                                            remainingQuantity: ordered ? remaining : 0,
                                                            stampedQuantity: ordered ? stamped : 0,
                                                            articleId:
                                                                props.articles && props.articles[articleCode]
                                                                    ? props.articles[articleCode].articleId
                                                                    : null,
                                                            completed: remaining === 0,
                                                            deliveryDate: moment(deliveryDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                                                        })
                                                    }
                                                }
                                                break
                                            }
                                            default:
                                                break
                                        }

                                        /* eslint-disable */
                                        console.log('Preview result it', {
                                            materials,
                                            masters,
                                            jobs,
                                            articleList,
                                            materialsPerArticle,
                                            mastersPerArticle,
                                            moldsPerArticle,
                                        })
                                        setPreview({
                                            materials,
                                            masters,
                                            molds,
                                            jobs,
                                            articleList,
                                            materialsPerArticle,
                                            mastersPerArticle,
                                            moldsPerArticle,
                                        })
                                        setStep(1)
                                    })
                                    if (type === 'excel') {
                                        reader.readAsDataURL(file)
                                    } else {
                                        reader.readAsText(file)
                                    }
                                }
                            }}
                            format={'.xlsx, .csv, .xls'}
                        />
                    </>
                )}
                {step === 1 && (
                    <>
                        {articleList && Object.keys(articleList).length > 0 && (
                            <>
                                {Object.keys(articleList).map((listforjob, lindex) => {
                                    return articleList[listforjob].map((value, index) => {
                                        if (!value || !value.articleCode) {
                                            return null
                                        }

                                        return (
                                            <Article
                                                key={`article_${index}`}
                                                article={{
                                                    ...value,
                                                    materials: materialsPerArticle[value.articleCode],
                                                    masters: mastersPerArticle[value.articleCode],
                                                    molds: moldsPerArticle[value.articleCode],
                                                }}
                                                noActions
                                            />
                                        )
                                    })
                                })}
                            </>
                        )}
                        {molds && Object.keys(molds).length > 0 && (
                            <>
                                {Object.keys(molds).map((value, index) => {
                                    return <Mold key={`mold_${index}`} mold={molds[value]} noActions />
                                })}
                            </>
                        )}
                        {masters && Object.keys(masters).length > 0 && (
                            <>
                                {Object.keys(masters).map((value, index) => {
                                    return <Master key={`master_${index}`} master={masters[value]} noActions />
                                })}
                            </>
                        )}
                        {materials && Object.keys(materials).length > 0 && (
                            <>
                                {Object.keys(materials).map((value, index) => {
                                    return <Material key={`material_${index}`} material={materials[value]} noActions />
                                })}
                            </>
                        )}
                        {jobs && Object.keys(jobs).length > 0 && (
                            <>
                                {Object.keys(jobs).map((value, index) => {
                                    const strings = []
                                    if (articleList[value]) {
                                        for (const element of articleList[value]) {
                                            strings.push(`Ordine ${element.jobCode}: Articolo ${element.workCode}x${element.productQuantity}`)
                                        }
                                    }
                                    return <div key={`file-import-preview-jobs-${index}`}>{strings}</div>
                                })}
                            </>
                        )}
                    </>
                )}

                {step === 2 && <>Completato!</>}
            </Table>
            <View fullw style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 10, justifyContent: 'flex-end' }}>
                {step > 0 && (
                    <Button
                        onClick={() => {
                            setStep(step - 1)
                        }}
                    >
                        Indietro
                    </Button>
                )}
                {step === 1 && (
                    <Button
                        color="green"
                        onClick={() => {
                            setLoading(true)
                            applyPreviewToDatabase(network, preview)
                                .then((result) => {
                                    // console.log('Imported succesfully')
                                    setLoading(false)
                                    setStep(2)
                                })
                                .catch((error) => {
                                    console.error('Error while importing', error)
                                    setLoading(false)
                                })
                        }}
                    >
                        Lancia importazione
                    </Button>
                )}
                {step === 3 && (
                    <Button
                        color="green"
                        onClick={() => {
                            goTo(history, printjobs)
                        }}
                    >
                        Fine
                    </Button>
                )}
            </View>
        </Box>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteMold, mergeMold, getStdArticleList } = state

    const articles = {}
    if (getStdArticleList && getStdArticleList.data) {
        for (const key in getStdArticleList.data.data) {
            const article = getStdArticleList.data.data[key]
            if (!article.articleCode) continue

            articles[article.articleCode] = article
        }
    }

    return {
        getMachineList,
        deleteMold,
        mergeMold,
        getStdArticleList,
        articles,
        fetching: getStdArticleList.fetching,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(FileImport)))
