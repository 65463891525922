export function createPackage(data) {
    return new Promise((resolve, reject) => {
        let { createPackageRequest, createPackageError, createPackageResult } = this.props

        delete data.creationDate;
        delete data.updateDate;

        this.doAction(
            this.WEB_REQUEST_URL + 'create_package',
            this.createBundle(
                'create_package',
                { pack: data },
                this.POST,
                createPackageRequest,
                (result) => {
                    createPackageResult(result)
                    resolve(result)
                },
                (error) => {
                    createPackageError(error)
                    reject(error)
                }
            )
        )
    })
}
