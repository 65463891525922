import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder, Table } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { BadgeScan, Empty } from '../components'
import moment from 'momentconfig'

const { app, badgescans, newEl, badgescanEdit } = routes

class BadgeScans extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteBadgeScan } = this.state
        let { organization } = this.props
        const startMom = moment().startOf('week')
        const endMom = moment().endOf('week')
        let start = startMom.format('YYYY-MM-DD HH:mm:ss')
        let end = endMom.format('YYYY-MM-DD HH:mm:ss')
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteBadgeScan.fetching !== prevState.deleteBadgeScan.fetching && !deleteBadgeScan.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getBadgeScanList(start, end)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getBadgeScanList(start, end)
            this.setState({ page: 0 })
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_badgescan) {
            extraTools.push({
                name: 'Crea nuova collezione',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, badgescans, newEl, badgescanEdit))
                },
            })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                // Not paginated yet
                // {
                // 	name: '',
                // 	icon: 'arrow left',
                // 	position: 'right',
                // 	action: () => {
                // 		this.prev()
                // 	}
                // },
                // {
                // 	name: '',
                // 	icon: 'arrow right',
                // 	position: 'none',
                // 	action: () => {
                // 		this.next()
                // 	}
                // }
            ])
        }

        const startMom = moment().startOf('week')
        const endMom = moment().endOf('week')
        let start = startMom.format('YYYY-MM-DD HH:mm:ss')
        let end = endMom.format('YYYY-MM-DD HH:mm:ss')
        this.state.network.getBadgeScanList(start, end)
    }

    renderBadgeScan(key, badgescan) {
        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (badgescan) => {
                if (onChange) {
                    onChange(badgescan)
                }
                this.setState({ selected: badgescan })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return (
            <BadgeScan
                {...extraProps}
                selected={badgescan.badgescanId === selected.badgescanId}
                type="table"
                key={`badgescan_${key}`}
                badgescan={badgescan}
            />
        )
    }

    render() {
        let { badgescans, fetching } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (badgescans && badgescans.data && badgescans.data.length > 0) {
            content = Object.keys(badgescans.data).map((key) => {
                let value = badgescans.data[key]
                return this.renderBadgeScan(key, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                <Table>
                    <BadgeScan type="header" />
                    {content}
                </Table>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { getBadgeScanList, deleteBadgeScan, search, organization, login } = state

    let badgescanList = getBadgeScanList

    return {
        badgescans: badgescanList.data,
        fetching: badgescanList.fetching,
        deleteBadgeScan,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(BadgeScans)))
