export function createClientOrder(id, client_order, isEmployee) {
    let { createClientOrderRequest, createClientOrderError, createClientOrderResult } = this.props

    delete client_order.creationDate;
    delete client_order.updateDate;
    
    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'create_client_order',
            this.createBundle(
                'create_client_order',
                { id, client_order },
                this.POST,
                createClientOrderRequest,
                (result)=>{
                    createClientOrderResult(result)
                    resolve(result)
                },
                (error)=>{
                    createClientOrderError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
