import { Box } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/switch'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dimmer, Form, Input, Loader, Message, Placeholder, TextArea } from 'semantic-ui-react'
import { baseViewDispatch, createAlert } from '../lib/util'
import ArticleDropdown from '../utility/ArticleDropdown'
import JobDropdown from '../utility/JobDropdown'

function WorkOrderEditor({ network, workOrderId, onSave, onCancel, defaultDescription, error, hide, info, removeAll, show, success, warning }) {
    const [workOrder, setWorkOrder] = useState(null)
    const [status, setStatus] = useState(null)
    const [dirty, setDirty] = useState(false)
    const [article, setArticle] = useState({})
    const [autoId, setAutoId] = useState(true)
    const [autoWorkOrderId, setAutoWorkOrderId] = useState(true)
    const [job, setJob] = useState({})
    const [deliveryWeek, setDeliveryWeek] = useState(moment().get('week'))

    useEffect(() => {
        if (workOrderId) {
            network.getWorkOrder(workOrderId).then((data) => {
                const momobj = moment(data.data.deliveryDate, 'YYYY-MM-DD').add(1, 'day')
                const parsed = momobj.toDate()
                setArticle({
                    articleId: data.data.articleId,
                })
                setWorkOrder({ ...data.data, deliveryDate: parsed })
            })
        } else {
            setWorkOrder({
                clientOrderCode: '',
                jobCode: '#AUTO#',
                workCode: '',
                articleId: 1,
                productQuantity: 0,
                producedQuantity: 0,
                deliveredQuantity: 0,
                workOrderDescription: defaultDescription ? defaultDescription : '',
                deliveryDate: new Date(),
                workOrderNotes: '',
            })
        }
    }, [workOrderId])

    const handleInput = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (name === 'deliveryWeek') {
            setDeliveryWeek(value)

            if (moment(workOrder.deliveryDate).get('week') !== value) {
                setWorkOrder({
                    ...workOrder,
                    deliveryDate: moment().set('week', value).toDate(),
                })
            }
        } else {
            setWorkOrder({
                ...workOrder,
                [name]: value,
            })
        }
    }

    const save = () => {
        network
            .createWorkOrder({
                // originalWorkOrderId: workOrderId ? workOrderId : undefined,
                ...workOrder,
                remainingQuantity: workOrder.productQuantity - workOrder.producedQuantity,
                deliveryDate: moment(workOrder.deliveryDate).format('YYYY-MM-DD'),
            })
            .then((data) => {
                // console.log('Got workOrder', data.data)
                setWorkOrder(data.data)
            })
            .then((result) => {
                success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                setStatus('success')
                if (onSave) {
                    onSave(result)
                }
            })
            .catch((error) => {
                error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                setStatus('error')
            })
    }

    if (!workOrder) {
        return (
            <Box p={6}>
                <Dimmer active>
                    <Loader />
                </Dimmer>

                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Paragraph>
                </Placeholder>
            </Box>
        )
    }

    const {
        clientOrderCode,
        jobCode,
        workCode,
        articleId,
        productQuantity,
        producedQuantity,
        deliveredQuantity,
        workOrderDescription,
        deliveryDate,
        workOrderNotes,
        workOrderId: localWorkOrderId,
        workOrderInternalId
    } = workOrder

    // let workOrderIdToShow = localWorkOrderId ? localWorkOrderId : workOrderId

    // console.log('Work order is', workOrder)
    return (
        <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Form success={status === 'success'} error={status === 'error'} warning={status === 'warning'}>
                <Box p={6}>
                    <Box textStyle="header" as="h1">
                        Anagrafica Ordine di Lavoro
                    </Box>
                    {/* <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workOrder-name"
                            control={TextArea}
                            label={<span className="label">Descrizione Articolo</span>}
                            name="workOrderDescription"
                            value={workOrderDescription}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group> */}
                    {/* <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workOrder-code"
                            control={Input}
                            label={<span className="label">Codice Ordine di Lavoro (opzionale)</span>}
                            name="workCode"
                            value={workCode}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group> */}
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workOrder-internal-id"
                            control={Input}
                            label={<span className="label">Codice ODL Interno (opzionale)</span>}
                            name="workOrderInternalId"
                            value={workOrderInternalId}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                        {/* <Form.Field
                            id="form-input-control-workOrder-id"
                            control={Input}
                            label={<span className="label">Codice ODL Interno</span>}
                            name="workOrderId"
                            value={workOrderIdToShow}
                            type="number"
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                            disabled={autoWorkOrderId}
                        />
                        <Form.Field
                            control={Switch}
                            label={<span className="label">Genera automaticamente codice ODL</span>}
                            defaultChecked={autoWorkOrderId}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    workOrder.workOrderId = 0
                                    setWorkOrder(workOrder)
                                    setAutoWorkOrderId(true)
                                } else {
                                    delete workOrder.workOrderId
                                    setWorkOrder(workOrder)
                                    setAutoWorkOrderId(false)
                                }
                            }}
                        /> */}
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workOrder-code"
                            control={JobDropdown}
                            label={<span className="label">Riferimento ordine cliente (opzionale)</span>}
                            name="jobCode"
                            placeholder=""
                            defaultSelectedJobs={jobCode}
                            onJobSelected={(ev, data, selected, selectedArticle) => {
                                workOrder.jobCode = selected
                                // console.log('Setting work order is', workOrder)
                                setWorkOrder(workOrder)
                                setDirty(!dirty)
                            }}
                            single
                            onlySelection={true}
                            disabled={autoId}
                        />
                        <Form.Field
                            id="form-input-control-workOrder-manual-job"
                            control={Input}
                            label={<span className="label">Imputazione manuale ordine cliente</span>}
                            name="jobCode"
                            value={jobCode}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                            disabled={autoId}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            control={Switch}
                            label={<span className="label">Genera automaticamente codice commessa</span>}
                            defaultChecked={autoId}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setWorkOrder({ ...workOrder, jobCode: '#AUTO#' })
                                    setAutoId(true)
                                } else {
                                    setWorkOrder({ ...workOrder, jobCode: '' })
                                    setAutoId(false)
                                }
                            }}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            id="form-input-control-job-delivery-week"
                            control={Input}
                            label={<span className="label">Settimana di consegna</span>}
                            placeholder="32"
                            name="deliveryWeek"
                            value={deliveryWeek}
                            onChange={(e) => handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-job-delivery-date"
                            control={DatePicker}
                            label={<span className="label">Data di consegna</span>}
                            name="deliveryDate"
                            value={workOrder.deliveryDate}
                            dateFormat="dd/MM/yyyy"
                            selected={workOrder.deliveryDate}
                            onChange={(data) => {
                                let deliveryWeek = moment(data).get('week')
                                workOrder.deliveryDate = data

                                setWorkOrder(workOrder)
                                setDeliveryWeek(deliveryWeek)
                                setDirty(!dirty)
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workOrder-code"
                            control={ArticleDropdown}
                            label={<span className="label">Articolo da produrre</span>}
                            name="workCode"
                            value={workCode}
                            placeholder=""
                            onArticleSelected={(ev, data, selected, selectedArticle) => {
                                setArticle(selectedArticle)
                                workOrder.workCode = selectedArticle.articleCode
                                workOrder.product = selectedArticle.articleCode
                                workOrder.articleId = selectedArticle.articleId
                                setWorkOrder(workOrder)
                                setDirty(!dirty)
                            }}
                            defaultSelectedArticles={article}
                            single
                            onlySelection={true}
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workOrder-qty"
                            control={Input}
                            label={<span className="label">Quantità ordinata</span>}
                            name="productQuantity"
                            value={productQuantity}
                            type="number"
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                            step="0.01"
                        />
                        <Form.Field
                            id="form-input-control-workOrder-prodqty"
                            control={Input}
                            label={<span className="label">Quantità prodotta</span>}
                            name="producedQuantity"
                            value={producedQuantity}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workOrder-delqty"
                            control={Input}
                            label={<span className="label">Quantità consegnata</span>}
                            name="deliveredQuantity"
                            value={deliveredQuantity}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-workorder-notes"
                            control={TextArea}
                            label={<span className="label">Note</span>}
                            name="workOrderNotes"
                            value={workOrderNotes}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                </Box>

                <Form.Group>
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            save()
                        }}
                        color="green"
                    />
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-cancel"
                        control={Button}
                        content="Annulla"
                        onClick={() => {
                            if (onCancel) {
                                onCancel()
                            }
                        }}
                        color="red"
                    />
                </Form.Group>
                <Message success header="Form completato con successo" content="Articolo inserito con successo!" />
                <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
            </Form>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { login } = state

    return {
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WorkOrderEditor)))
