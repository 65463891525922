import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { Button, Form, Icon, Input, Modal } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'

class DeliveryDateModal extends Component {
    constructor(props) {
        super(props)
        const deldate = props.job.deliveryDate ? new Date(props.job.deliveryDate) : new Date()
        this.state = {
            tasks: [],
            cloneSource: null,
            searchJob: '',
            deliveryDate: deldate,
            deliveryWeek: moment(deldate).get('week'),
        }
    }

    cancel = () => {
        let { onCancel } = this.props
        if (onCancel) {
            onCancel()
        }
    }

    confirm = () => {
        const { deliveryDate } = this.state
        let { onConfirm, job } = this.props
        if (onConfirm) {
            onConfirm(deliveryDate)
        }

        this.props.network.createJob(job.jobCode, {
            deliveryDate: moment(deliveryDate).add('hour', 2).format('YYYY-MM-DD'),
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })

        if (name === 'deliveryWeek') {
            this.setState({
                deliveryDate: moment().set('week', value).toDate(),
            })
        }
    }

    render() {
        let { deliveryWeek, deliveryDate } = this.state
        let { showModal, job } = this.props

        if (!job) return null

        return (
            <Modal style={{ overflow: 'hidden' }} onClose={this.closeModal} open={showModal}>
                <Modal.Header>Modifica data di consegna per {job.jobName ? job.jobName : job.jobCode}</Modal.Header>
                <Modal.Content image scrolling style={{ padding: 0, margin: 16, height: 320 }}>
                    <View around>
                        <Form>
                            <Form.Group>
                                <Form.Field
                                    id="form-input-control-job-delivery-week"
                                    control={Input}
                                    label={<span className="label">Settimana di consegna</span>}
                                    placeholder="32"
                                    name="deliveryWeek"
                                    value={deliveryWeek}
                                    onChange={(e) => this.handleInput(e)}
                                />
                                <Form.Field
                                    id="form-input-control-job-delivery-date"
                                    control={DatePicker}
                                    label={<span className="label">Data di consegna</span>}
                                    name="deliveryDate"
                                    value={deliveryDate}
                                    dateFormat="dd/MM/yyyy"
                                    selected={deliveryDate}
                                    onChange={(data) => {
                                        let deliveryWeek = moment(data).get('week')

                                        this.setState({
                                            deliveryDate: data,
                                            deliveryWeek,
                                        })
                                    }}
                                />
                            </Form.Group>
                        </Form>
                    </View>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={this.cancel}>
                        Annulla <Icon name="cancel" />
                    </Button>
                    <Button primary onClick={this.confirm}>
                        Salva <Icon name="check" />
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let { login } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, login }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DeliveryDateModal))
