import * as htmlToImage from 'html-to-image'
import { connectNetwork } from '../lib/NetworkProvider'
import View from '../lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { pdfjs } from 'react-pdf'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Select, Dimmer, Loader } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { GETSESSIONDATA_ACTIONS } from '../actions/getSessionData'
import { Label } from '../components'
import { Labels } from '../containers'
import { baseViewDispatch, padToDigits, alertGenerator } from '../lib/util'
import { Base64 } from 'js-base64'
import axios from 'axios'
import { documentToSVG, elementToSVG, inlineResources, formatXML } from 'dom-to-svg'
import { UserAgent, withUserAgent } from 'react-useragent'
import qs from 'qs'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

moment.locale('it')

class WarehouseLabels extends Component {
    constructor(props) {
        super(props)
        this.state = this.defaultState(props)
        this.printRef = React.createRef()
    }

    defaultState(props) {
        const { categocleId, packageId, customerId, jobCode } = this.props

        this.updateCategoList = debounce(1000, false, () => {
            const { network } = this.props

            network.getCategoList(this.state.search)
        })

        this.updateSeq = debounce(1000, false, () => {
            axios
                .get(`${this.props.network.WEB_REQUEST_URL}get_article_lot_seq`, {
                    params: {
                        codop: this.state.lotto,
                        codice: this.state.workCode,
                    },
                })
                .then((result) => {
                    this.setState({
                        seq: padToDigits(result.data.data.seq, 3),
                    })
                })
                .catch((error) => {
                    console.error('Error get_article_lot_seq for ', {
                        codop: this.state.lotto,
                        codice: this.state.workCode,
                    })
                })
        })

        if (this.props.location.search) {
            const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

            let arti = ''
            let codop = ''
            let qta = 0
            let seq = 1
            let data = ''
            let ordine = ''
            let riga = ''

            if (query.codlot) {
                arti = query.codlot.substring(0, 8)

                if (!query.data) {
                    codop = query.codlot.substring(8, 14)
                    qta = query.codlot.substring(14, 20)
                }

                seq = query.codlot.substring(20, 23)
            }

            if (query.data) {
                data = query.data
            }

            if (query.ordine) {
                ordine = query.ordine
            }

            if (query.riga) {
                riga = query.riga
            }

            if (query.seq) {
                seq = query.seq
            }

            if (query.qta) {
                qta = query.qta
            }

            if (query.article) {
                arti = query.article
            }

            if (query.codop) {
                codop = query.codop
            }

            if (query.qta) {
                qta = parseInt(query.qta)
                if (isNaN(qta)) {
                    qta = 0
                }
            }

            return {
                article: props.article ? props.article : {},
                label: {},
                pageNumber: 1,
                packageIndex: 0,
                categocleId,
                packageId,
                customerId,
                jobCode,
                showLabel: false,
                page: 0,
                items: 10,
                selected: this.props.selected ? this.props.selected : {},
                workCode: arti,
                description: '',
                amount: parseInt(qta, 10),
                lotto: codop,
                seq: parseInt(seq, 10),
                search: arti,
                printCount: 1,
                labelPreviewScale: 0.2,
                customerName: '',
                printing: false,
                unpacking: true,
                unpackcodop: `${padToDigits(arti, 8)}${padToDigits(codop ? codop : '000000', 6)}${padToDigits(qta, 6)}${padToDigits(seq, 3)}`,
                hasQueryString: !!this.props.location.search && this.props.location.search !== '',
                data,
                ordine,
                riga,
            }
        }

        return {
            article: props.article ? props.article : {},
            label: {},
            pageNumber: 1,
            packageIndex: 0,
            categocleId,
            packageId,
            customerId,
            jobCode,
            showLabel: false,
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
            workCode: '',
            description: '',
            amount: 0,
            lotto: '',
            seq: 1,
            search: '',
            printCount: 1,
            labelPreviewScale: 0.2,
            customerName: '',
            printing: false,
            unpacking: false,
            unpackcodop: ``,
            hasQueryString: !!this.props.location.search && this.props.location.search !== '',
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.search !== prevState.search) {
            this.updateCategoList()
        }

        if (this.state.workCode !== prevState.workCode || this.state.lotto !== prevState.lotto) {
            this.updateSeq()
        }

        if (
            this.props.categos &&
            this.props.categos.length === 1 &&
            this.state.hasQueryString &&
            !this.props.fetchCategos &&
            prevProps.fetchCategos
        ) {
            const article = this.props.categos[0]
            let { onChange } = this.props
            if (onChange) {
                onChange(article)
            }
            this.setState({ descrizio: article.descrizio, selected: article, workCode: article.codice, description: article.descrizio })
        }
    }

    componentDidMount() {
        const { network } = this.props

        network.getCategoList(this.state.search)
        network.getCustomerList()
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages })
    }

    renderCatego(key, article) {
        let { selected } = this.state
        let { onChange, noActions } = this.props

        let extraProps = {}
        extraProps.onClick = (article) => {
            if (onChange) {
                onChange(article)
            }
            this.setState({ descrizio: article.descrizio, selected: article, workCode: article.codice, description: article.descrizio })
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return null // <Article {...extraProps} selected={article.codice === selected.codice} type="full" key={`catego_${key}`} article={article} />
    }

    generateSeq() {
        const { selected, lotto, seq, amount, data, ordine, riga } = this.state

        switch (selected.tipo) {
            default:
            case 'PF':
                return `${selected.codice}${padToDigits(lotto, 6)}${padToDigits(amount, 6)}${padToDigits(seq, 3)}`
            case 'MP':
                return `${selected.codice}${padToDigits(data, 4)}${padToDigits(ordine, 4)}${padToDigits(riga, 2)}${padToDigits(seq, 3)}`
        }
    }

    handleInput(event) {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (typeof value === 'number') {
            value = value > 0 ? value : 0
        }

        this.setState({
            [name]: value,
        })
    }

    getContext() {
        return {
            warehouseSequential: this.generateSeq(),
            creationDate: moment().format('DD/MM/YYYY'),
            currentDate: moment().format('DD/MM/YYYY'),
            pcsPerPackage: this.state.amount,
            articleQuantity: this.state.amount,
            paddedArticleQuantity: padToDigits(this.state.amount, 6),
            codop: this.state.lotto,
            lotto: this.state.lotto,
            seq: padToDigits(this.state.seq, 3),
            workCode: this.state.workCode,
            description: this.state.descrizio,
            ...this.props.currentSession,
            customerName: this.state.customerName,
            data: this.state.data,
            ordine: this.state.ordine,
            riga: this.state.riga,
        }
    }

    nextSeq() {
        return new Promise((resolve, reject) => {
            this.setState({ seq: padToDigits(parseInt(this.state.seq) + 1, 3) }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    startPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 1 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    stopPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 0.2 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    render() {
        const {
            label,
            page,
            items,
            selected,
            workCode,
            descrizio,
            amount,
            seq,
            labelPreviewScale,
            customerName,
            lotto,
            data,
            ordine,
            riga,
        } = this.state
        const { worker, categos, fetchCategos, customerOptions } = this.props

        const article = selected

        const labelTransform = `scale(${labelPreviewScale}, ${labelPreviewScale})`

        return (
            <Dimmer.Dimmable as={View} column fullw fullh dimmed={this.state.printing}>
                <Dimmer active={this.state.printing} inverted>
                    <Loader>Stampa in corso, non chiudere la pagina...</Loader>
                </Dimmer>
                <View column fullw fullh>
                    <Input
                        icon={this.state.search.length > 0 ? <Icon name="delete" link onClick={() => this.clearSearch()} /> : <Icon name="search" />}
                        placeholder="Cerca..."
                        value={this.state.search}
                        onChange={(e, data) => {
                            let text = data.value
                            this.setState({ search: text })
                        }}
                    />

                    <View row fullw fullh>
                        <View column fullw fullh>
                            <View column style={{ height: '50%', overflowY: 'scroll' }}>
                                {categos &&
                                    categos.slice(page, items + page * items).map((value, index) => {
                                        return this.renderCatego(value.codice, value)
                                    })}
                            </View>
                            {article.codice && (
                                <>
                                    <View column style={{ height: '20%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                        <View fullw fullh row style={{ marginLeft: 16, overflowX: 'hidden' }}>
                                            <Form
                                                success={this.state.status === 'success'}
                                                error={this.state.status === 'error'}
                                                warning={this.state.status === 'warning'}
                                            >
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        id="form-input-control-arti-description"
                                                        control={Input}
                                                        label="Codice"
                                                        placeholder="Codice"
                                                        name="workCode"
                                                        value={workCode}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-arti-description"
                                                        control={Input}
                                                        label="Descrizione"
                                                        placeholder="Descrizione"
                                                        name="descrizio"
                                                        value={descrizio}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-job-type"
                                                        control={Select}
                                                        options={customerOptions}
                                                        label="Cliente"
                                                        placeholder="Cliente"
                                                        name="customerName"
                                                        value={customerName}
                                                        onChange={(e, data) => {
                                                            let name = data.value
                                                            this.setState({ customerName: name })
                                                        }}
                                                    />
                                                </Form.Group>

                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        id="form-input-control-cost-cost"
                                                        control={Input}
                                                        type="number"
                                                        label="Quantità"
                                                        placeholder="0"
                                                        step="1"
                                                        name="amount"
                                                        value={amount}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-cost-cost"
                                                        control={Input}
                                                        type="number"
                                                        label="Lotto"
                                                        placeholder="0"
                                                        step="1"
                                                        name="lotto"
                                                        value={lotto}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-cost-cost"
                                                        control={Input}
                                                        type="number"
                                                        label="Sequenziale"
                                                        placeholder="0"
                                                        step="1"
                                                        name="seq"
                                                        value={seq}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-print-count"
                                                        control={Input}
                                                        label="Numero stampe"
                                                        name="printCount"
                                                        type="number"
                                                        value={this.state.printCount}
                                                        onChange={(e) => this.handleInput(e)}
                                                        placeholder="1"
                                                    />
                                                </Form.Group>
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        id="form-input-control-data"
                                                        control={Input}
                                                        label="Data"
                                                        type="number"
                                                        placeholder=""
                                                        step="1"
                                                        name="data"
                                                        value={data}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-ordine"
                                                        control={Input}
                                                        type="number"
                                                        label="Ordine"
                                                        placeholder="0"
                                                        step="1"
                                                        name="ordine"
                                                        value={ordine}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-riga"
                                                        control={Input}
                                                        type="number"
                                                        label="Riga ordine"
                                                        placeholder="0"
                                                        step="1"
                                                        name="riga"
                                                        value={riga}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                </Form.Group>
                                            </Form>
                                        </View>
                                    </View>

                                    <View column fullw style={{ width: '50%', height: '30%', overflowY: 'scroll' }}>
                                        <Labels
                                            sub
                                            selectable
                                            selected={label}
                                            onChange={(label) => {
                                                this.setState({ label, showLabel: true })
                                            }}
                                            warehouse
                                            noActions
                                            autoSelectMaterial={!!this.state.ordine}
                                        />
                                    </View>
                                </>
                            )}
                        </View>
                    </View>

                    {label && label.layout && article && (
                        <>
                            <View
                                row
                                fullw
                                style={{
                                    position: 'absolute',
                                    bottom: 16,
                                    right: -2,
                                    width: Math.max(label.layout.layoutWidth * labelPreviewScale, 340),
                                    height: label.layout.layoutHeight * labelPreviewScale + 64,
                                    display: this.state.showLabel ? 'block' : 'none',
                                    backgroundColor: 'white',
                                    zIndex: 999,
                                    border: 'black solid 2px',
                                }}
                            >
                                <View row around>
                                    <Button
                                        color="green"
                                        onClick={async () => {
                                            const { network } = this.props
                                            var node = document.getElementById('print-preview')

                                            await this.startPrinting()
                                            this.setState({ printing: true })

                                            try {
                                                for (let i = 0; i < this.state.printCount; i++) {
                                                    if (this.props.ua.mobile || this.props.ua.tablet) {
                                                        const bounds = {
                                                            height: label.layout.layoutHeight,
                                                            width: label.layout.layoutWidth,
                                                            x: 0,
                                                            y: 0,
                                                        }
                                                        const svgDocument = elementToSVG(document.querySelector('#print-preview'), {
                                                            bounds,
                                                            avoidTextSelection: true,
                                                        })

                                                        await inlineResources(svgDocument.documentElement)

                                                        let svgString = new XMLSerializer().serializeToString(svgDocument)

                                                        const regex = /<style>.*?<\/style>/gis
                                                        const mregex = /<mask[^>]*?>.*?<\/mask>/gis
                                                        const amregex = /mask=".*?"/gis
                                                        svgString = svgString.replace(regex, '').replace(mregex, '').replace(amregex, '')

                                                        await network
                                                            .sendToPrinter(svgString, 'filename', this.state.printCount, this.getContext(), {
                                                                width: label.layout.layoutWidth,
                                                                height: label.layout.layoutHeight,
                                                                mobile: true,
                                                                unpacking: this.state.unpacking,
                                                                codlot: this.state.unpackcodop,
                                                                printer: this.props.printer,
                                                            })
                                                            .then((result) => {
                                                                // this.props.onPrintLabel(result)
                                                            })
                                                    } else {
                                                        await htmlToImage
                                                            .toPng(node, {
                                                                width: label.layout.layoutWidth,
                                                                height: label.layout.layoutHeight,
                                                            })
                                                            .then((dataUrl) => {
                                                                const { network } = this.props
                                                                const filename = this.state.workCode
                                                                var img = new Image()
                                                                img.src = dataUrl
                                                                network
                                                                    .sendToPrinter(dataUrl, filename, this.state.printCount, this.getContext(), {
                                                                        width: label.layout.layoutWidth,
                                                                        height: label.layout.layoutHeight,
                                                                        mobile: false,
                                                                        unpacking: this.state.unpacking,
                                                                        codlot: this.state.unpackcodop,
                                                                        printer: this.props.printer,
                                                                    })
                                                                    .then((result) => {
                                                                        // this.props.onPrintLabel(result)
                                                                    })
                                                                return true
                                                            })
                                                            .catch(function (error) {
                                                                console.error('oops, something went wrong!', error)
                                                                return false
                                                            })
                                                    }

                                                    await this.nextSeq()
                                                }
                                            } catch (e) {
                                                this.props.error(alertGenerator({ title: 'Errore durante la stampa', message: JSON.stringify(e) }))
                                            }

                                            this.setState({ printing: false })
                                            await this.stopPrinting()
                                        }}
                                    >
                                        Stampa etichetta
                                    </Button>
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.setState({ showLabel: false })
                                        }}
                                    >
                                        Chiudi anteprima di stampa
                                    </Button>
                                    <Button
                                        color="grey"
                                        onClick={() => {
                                            this.setState({ labelPreviewScale: labelPreviewScale >= 0.8 ? 0.2 : 0.8 })
                                        }}
                                    >
                                        {labelPreviewScale >= 0.8 ? 'Comprimi' : 'Espandi'}
                                    </Button>
                                </View>
                                <div
                                    style={{
                                        transformOrigin: 'top left',
                                        msTransformOrigin: 'top left',
                                        MozTransformOrigin: 'top left',
                                        WebkitTransformOrigin: 'top left',
                                        transform: labelTransform,
                                        MsTransform: labelTransform,
                                        WebkitTransform: labelTransform,
                                        OTransform: labelTransform,
                                        MozTransform: labelTransform,
                                    }}
                                >
                                    <div
                                        id="print-preview"
                                        style={{
                                            position: 'relative',
                                            width: label.layout.layoutWidth,
                                            height: label.layout.layoutHeight,
                                            backgroundColor: 'white',
                                        }}
                                        ref={this.printRef}
                                    >
                                        <Label
                                            key={label.labelId}
                                            label={label}
                                            type="visualization"
                                            selectedArti={{
                                                warehouseSequential: this.generateSeq(),
                                                creationDate: moment().format('DD/MM/YYYY'),
                                                currentDate: moment().format('DD/MM/YYYY'),
                                                pcsPerPackage: this.state.amount,
                                                articleQuantity: this.state.amount,
                                                paddedArticleQuantity: padToDigits(this.state.amount, 6),
                                                codop: this.state.lotto,
                                                lotto: this.state.lotto,
                                                seq: padToDigits(this.state.seq, 3),
                                                workCode: this.state.workCode,
                                                description: this.state.descrizio,
                                            }}
                                            currentSession={this.props.currentSession}
                                            selectedCustomer={{
                                                customerName: this.state.customerName,
                                            }}
                                        />
                                    </div>
                                </div>
                            </View>
                            {!this.state.showLabel && (
                                <Button
                                    color="green"
                                    style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 999 }}
                                    onClick={() => {
                                        this.setState({ showLabel: true })
                                    }}
                                >
                                    STAMPA
                                </Button>
                            )}
                        </>
                    )}
                </View>
            </Dimmer.Dimmable>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),

        getSessionDataRequest: (result) => GETSESSIONDATA_ACTIONS.request(result, dispatch),
        getSessionDataResult: (result) => GETSESSIONDATA_ACTIONS.result(result, dispatch),
        getSessionDataError: (result) => GETSESSIONDATA_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getCustomerList: {
            data: { data: customers },
            fetching: fetchCustomers,
        },
        getCategoList: {
            data: { data: categos },
            fetching: fetchCategos,
        },
        getSessionData: {
            data: { data: session },
        },
        getLabelList,
        loginEmployee: {
            data: { user: worker },
        },
        printer,
    } = state

    let customerOptions = []
    for (let i in customers) {
        let type = customers[i]
        customerOptions.push({
            key: type.customerId,
            text: type.customerName,
            value: type.customerName,
        })
    }

    return {
        fetchCustomers,
        customerOptions,
        categos,
        fetchCategos,
        session: session ? session : [],
        getLabelList,
        worker,
        printer,
    }
}
export default withUserAgent(withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WarehouseLabels))))
