export function createSchedule(data) {
    return new Promise((resolve, reject) => {
		let { createScheduleRequest, createScheduleError, createScheduleResult } = this.props;

        delete data.creationDate;
        delete data.updateDate;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_schedule',
			this.createBundle(
				'create_schedule',
				{ schedule: data },
				this.POST,
				createScheduleRequest,
                (result) => {
                    createScheduleResult(result)
                    resolve(result)
                },
                (error) => {
                    createScheduleError(error)
                    reject(error)
                }
			)
		);
	})
}
