export function deleteMaterial(data) {
	let { deleteMaterialRequest, deleteMaterialError, deleteMaterialResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_material',
		this.createBundle(
			'delete_material',
			{ material: data },
			this.POST,
			deleteMaterialRequest,
			deleteMaterialResult,
			deleteMaterialError
		)
	);
}
