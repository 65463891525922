export function deleteTimeCalendar(data) {
    return new Promise((resolve, reject) => {
		let { deleteTimeCalendarRequest, deleteTimeCalendarError, deleteTimeCalendarResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_timecalendar',
			this.createBundle(
				'delete_timecalendar',
				{ timecalendar: data },
				this.POST,
				deleteTimeCalendarRequest,
                (result) => {
                    deleteTimeCalendarResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteTimeCalendarError(error)
                    reject(error)
                }
			)
		);
	})
}
