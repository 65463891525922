import { connectNetwork } from '../lib/NetworkProvider'
import { routes, route_from } from '../lib/routes'
import View from '../lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Card, Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Label, Empty } from '../components'

const { app, labels, newEl, labelEdit } = routes

class Labels extends Component {
    constructor(props) {
        super(props)

        // console.log('Current session data customer Labels', this.props.customerId)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
            customerLabelMissing: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.articleId !== this.props.articleId ||
            prevProps.packageId !== this.props.packageId ||
            prevProps.customerId !== this.props.customerId ||
            prevProps.warehouse !== this.props.warehouse ||
            prevProps.production !== this.props.production ||
            prevProps.custom !== this.props.custom ||
            prevProps.largepackager !== this.props.largepackager ||
            prevProps.kitlabels !== this.props.kitlabels
        ) {
            // console.log('Current session data Labels', this.props.kitlabels)
            this.props.network.getLabelList(
                this.props.articleId,
                this.props.packageId,
                this.props.customerId,
                true,
                this.props.warehouse,
                this.props.production,
                this.props.custom,
                this.props.largepackager,
                this.props.kitlabels
            )
        }

        if (
            !this.props.fetching &&
            prevProps.fetching &&
            this.props.labels.data &&
            this.props.labels.data.length > 0 &&
            !this.state.customerLabelMissing
        ) {
            let customerLabelFound = false
            if (this.props.customerId) {
                for (const key in this.props.labels.data) {
                    if (this.props.labels.data[key].customerId === this.props.customerId) {
                        customerLabelFound = true
                    }
                }
            } else {
                customerLabelFound = true
            }

            if (
                !customerLabelFound &&
                !this.props.warehouse &&
                !this.props.production &&
                !this.props.custom &&
                !this.props.largepackager &&
                !this.props.kitlabels
            ) {
                this.props.network.getLabelList(
                    this.props.articleId,
                    this.props.packageId,
                    null,
                    true,
                    this.props.warehouse,
                    this.props.production,
                    this.props.custom,
                    this.props.largepackager,
                    this.props.kitlabels
                )
                this.setState({ customerLabelMissing: true })
            } else {
                this.setState({ customerLabelMissing: false })
            }
        }

        if (!this.props.fetching && prevProps.fetching && this.props.labels.data && this.props.labels.data.length > 0 && this.props.autoselect) {
            if (this.props.onChange) {
                this.props.onChange(this.props.labels.data[0], this.state.customerLabelMissing)
            }
            this.setState({
                selected: this.props.labels.data[0],
            })
        }

        if (
            !this.props.fetching &&
            prevProps.fetching &&
            this.props.labels.data &&
            this.props.labels.data.length > 0 &&
            this.props.autoSelectMaterial
        ) {
            if (this.props.onChange) {
                this.props.onChange(this.props.labels.data[1], this.state.customerLabelMissing)
            }
            this.setState({
                selected: this.props.labels.data[1],
            })
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub, network } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_label) {
            extraTools.push({
                name: 'Crea nuova collezione',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, labels, newEl, labelEdit))
                },
            })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                // Not paginated yet
                // {
                // 	name: '',
                // 	icon: 'arrow left',
                // 	position: 'right',
                // 	action: () => {
                // 		this.prev()
                // 	}
                // },
                // {
                // 	name: '',
                // 	icon: 'arrow right',
                // 	position: 'none',
                // 	action: () => {
                // 		this.next()
                // 	}
                // }
            ])
        }

        const { articleId, packageId, customerId } = this.props
        network.getLabelList(
            articleId,
            packageId,
            customerId,
            false,
            this.props.warehouse,
            this.props.production,
            this.props.custom,
            this.props.largepackager,
            this.props.kitlabels
        )
    }

    renderLabel(key, label) {
        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (label) => {
                if (onChange) {
                    onChange(label, this.state.customerLabelMissing)
                }
                this.setState({ selected: label })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return (
            <Label
                {...extraProps}
                selected={label.labelId === selected.labelId}
                type="full"
                key={`label_${key}`}
                label={label}
                selectedArti={this.props.arti}
                currentSession={this.props.currentSession}
            />
        )
    }

    render() {
        let { labels, fetching, onChange, selectable, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Card key={i} style={{ width: '100%', margin: 8 }}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                    </Card>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (labels && labels.data && labels.data.length > 0) {
            content = Object.keys(labels.data).map((key) => {
                let value = labels.data[key]
                return this.renderLabel(key, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { getLabelList, deleteLabel, search, organization, login } = state

    let labelList = getLabelList

    return {
        labels: labelList ? labelList.data : [],
        fetching: labelList ? labelList.fetching : false,
        deleteLabel,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Labels)))
