import { connectNetwork } from 'lib/NetworkProvider'
import React from 'react'
import ReactExport from 'react-data-export'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Loader } from 'semantic-ui-react'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ProductRecap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            productDone: false,
        }
    }

    componentDidMount() {
        let { wait, network } = this.props

        if (!wait) {
            network.getProductRecap(null, null, '')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { getProductRecap, wait, network, onDone } = this.props
        let { productDone } = this.state

        if (!getProductRecap.fetching && prevProps.getProductRecap.fetching) {
            this.setState({ productDone: true })
        }

        if (prevProps.wait && !wait) {
            // console.log('ProductRecapping deposit')

            network.getProductRecap(null, null, '')
        }

        if (productDone && !wait && (!prevState.productDone || (!wait && prevProps.wait))) {
            let productList = getProductRecap.data.product
            let excelfile = []
            for (let i in productList) {
                let product = productList[i]

                let row = {
                    ...product,
                }

                excelfile.push(row)
            }

            if (onDone) {
                onDone({
                    productList,
                    excelfile,
                })
            }

            this.setState({
                productList,
                excelfile,
            })
        }
    }

    render() {
        let { getProductRecap, element } = this.props
        let { excelfile, productDone } = this.state

        // console.log('Excelfile is', excelfile)

        if (getProductRecap.fetching) {
            return <Loader active />
        }
        if (!productDone) {
            return null
        } else {
            return (
                <ExcelFile filename={`prodotti`} element={element ? element : <Button>Download</Button>}>
                    <ExcelSheet data={excelfile} name="Riepilogo">
                        <ExcelColumn label="Codice articolo" value="code" />
                        <ExcelColumn label="Descrizione" value="description" />
                        <ExcelColumn label="Categoria" value="categoryName" />
                        <ExcelColumn label="Corp" value="corpName" />
                        <ExcelColumn label="Prezzo" value="price" />
                        <ExcelColumn label="Data creazione" value="date_created" />
                        {/* <ExcelColumn label={<span className="label">ID Corp" value="id_corp</span>} /> */}
                        {/* <ExcelColumn label={<span className="label">ID Categoria" value="id_category</span>} /> */}
                    </ExcelSheet>
                </ExcelFile>
            )
        }
    }
}

const mapStateToProps = (state) => {
    let { login, getProductRecap } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getProductRecap }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(ProductRecap)))
