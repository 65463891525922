export function getClientOrderTypeList(data) {
    let { getClientOrderTypeListRequest, getClientOrderTypeListError, getClientOrderTypeListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_job_type_list',
        this.createBundle(
            'get_job_type_list',
            { data: data },
            this.GET,
            getClientOrderTypeListRequest,
            getClientOrderTypeListResult,
            getClientOrderTypeListError
        )
    )
}
