export function getTurnList(data) {
    return new Promise((resolve, reject) => {
        let { getTurnListRequest, getTurnListError, getTurnListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_turn_list',
            this.createBundle(
                'get_turn_list',
                { data: data },
                this.GET,
                getTurnListRequest,
                (result) => {
                    getTurnListResult(result)
                    resolve(result)
                },
                (error) => {
                    getTurnListError(error)
                    reject(error)
                }
            )
        )
    })
}
