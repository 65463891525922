import { Box, VStack } from '@chakra-ui/layout'
import React, { Component } from 'react'
import { Button, Form, Select } from 'semantic-ui-react'

class Filter extends Component {
    constructor(props) {
        super(props)

        this.state = {
            localValue: '',
        }
    }

    render() {
        const { availableFilters, activeFilter, toExclude } = this.props

        const options = []
        for (const key in availableFilters) {
            if (key in toExclude) {
                continue
            }

            const filter = availableFilters[key]
            options.push({
                key: filter.name,
                value: key,
                text: filter.label,
            })
        }

        let activeFilterObj = null

        if (activeFilter) {
            activeFilterObj = {
                ...availableFilters[activeFilter],
                label: '',
                onChange: availableFilters[activeFilter].onChange,
            }

            if (availableFilters[activeFilter].useLocalValue) {
                activeFilterObj.value = this.state.localValue
                activeFilterObj.onChange = (e) => {
                    const newValue = availableFilters[activeFilter].onChange(e)
                    this.setState({ localValue: newValue })
                }
            }
        }

        return (
            //backgroundColor: 'rgb(215, 255, 241)',
            <Box w="100%">
                {!activeFilter && (
                    <VStack w="200">
                        <Box textStyle="description" style={{ marginBottom: 8 }}>
                            Seleziona un filtro
                        </Box>
                        <Select
                            options={options}
                            onChange={(e, data) => {
                                // this.setState({ activeFilter: data.value })
                                if (this.props.onFilterActivated) {
                                    this.props.onFilterActivated(data.value, true)
                                }
                            }}
                        />
                    </VStack>
                )}
                {activeFilter && (
                    <>
                        <VStack w="200">
                            <Box textStyle="description" style={{ marginBottom: 4 }}>
                                {availableFilters[activeFilter].label}
                            </Box>
                            <Form>
                                <Form.Group widths="equal">
                                    <Form.Field key={`filter_${activeFilter}`} {...activeFilterObj} />
                                </Form.Group>
                            </Form>
                        </VStack>

                        <Button
                            size="mini"
                            basic
                            onClick={(e, data) => {
                                if (this.props.onFilterActivated) {
                                    this.props.onFilterActivated(activeFilter, false)
                                }

                                if (availableFilters[activeFilter].onReset) {
                                    availableFilters[activeFilter].onReset()
                                }

                                // this.setState({ activeFilter: null })
                            }}
                            icon="trash alternate outline"
                            color="red"
                            style={{ position: 'absolute', top: 0, right: 0 }}
                        />
                    </>
                )}
            </Box>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

export default Filter
