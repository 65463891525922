export function getLabelList(articleId, packageId, customerId, isEmployee, isWarehouse, isProduction, isCustom, isLargePackager, isKitLabel) {
    let { getLabelListRequest, getLabelListError, getLabelListResult } = this.props

    let labelType = 0
    if (isKitLabel) {
        labelType = 6
    }
    if (isWarehouse) {
        labelType = 1
    }
    if (isProduction) {
        labelType = 2
    }
    if (isCustom) {
        labelType = -1
    }
    if (isLargePackager) {
        labelType = 3
    }

    this.doAction(
        this.WEB_REQUEST_URL + 'get_label_list',
        this.createBundle(
            'get_label_list',
            {
                articleId: articleId ? articleId : '',
                packageId: packageId ? packageId : '',
                customerId: customerId ? customerId : '',
                labelType,
            },
            this.GET,
            getLabelListRequest,
            getLabelListResult,
            getLabelListError,
            isEmployee
        )
    )
}
