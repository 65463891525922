import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import { Range } from 'rc-slider'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Message } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'
function IntervalEditor(props) {
    const { network, intervalId, onSave } = props
    const [dirty, setDirty] = useState([])
    const [turns, setTurns] = useState([])
    const [worker, setWorker] = useState(null)
    const [interval, setInterval] = useState({})

    useEffect(() => {
        if (intervalId) {
            network.getInterval(intervalId).then((data) => {
                // console.log('Got interval', data.data)
                setInterval(data.data)
            })
        } else {
            setInterval({
                intervalStart: 9,
                intervalEnd: 13,
                intervalType: 1,
                intervalName: '',
            })
        }
    }, [intervalId])

    const handleInput = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setInterval({
            ...interval,
            [name]: value,
        })
    }
    useEffect(() => {
        network.getTurnList().then((data) => {
            const turns = data.data

            setTurns(turns)
            setDirty(false)
        })
    }, [dirty])

    const { intervalStart, intervalEnd, intervalType, intervalName } = interval

    let marks = {}

    for (let i = 0; i <= 24; i += 1) {
        let hours = Math.floor(i)
        let minutes = i % 1
        if (minutes < 10) minutes = `0${minutes}`
        marks[i] = <span>{hours}</span>
    }

    const range = [intervalStart, intervalEnd]

    return (
        <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Form>
                {/* <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-turn-name"
                        control={Input}
                        label={<span className="label">Nome intervallo</span>}
                        placeholder="Turno di mattina"
                        name="intervalName"
                        value={intervalName}
                        onChange={(e) => handleInput(e)}
                    />
                </Form.Group> */}
                <Form.Group>
                    <Range
                        onChange={(value) => {
                            // console.log('Selected range', value)
                            setInterval({
                                ...interval,
                                intervalStart: value[0],
                                intervalEnd: value[1],
                            })
                        }}
                        style={{ height: 50, marginTop: 8 }}
                        value={range}
                        defaultValue={[intervalStart, intervalEnd]}
                        min={0}
                        max={24}
                        step={0.5}
                        pushable={true}
                        dots={true}
                        marks={marks}
                    />
                </Form.Group>
                <Form.Field
                    id="form-button-control-save"
                    control={Button}
                    content="Salva"
                    onClick={() => {
                        if (onSave) {
                            onSave(interval)
                        }
                    }}
                />
                <Message success header="Form Completed" content="Lavoratore inserito con successo!" />
                <Message error header="Errore" content="Errore del server, riprova piu&#39; tardi." />
                <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
            </Form>
        </View>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getWorkerList,
        workday: {
            data: { date: workday },
        },
        getWorkListSpan: {
            data: { data: worksByDay },
        },
    } = state

    return {
        workers: getWorkerList.data.data,
        fetchingWorkers: getWorkerList.fetching,
        workday,
        worksByDay,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(IntervalEditor)))
