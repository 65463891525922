export function createBadge(data) {
    return new Promise((resolve, reject) => {
		let { createBadgeRequest, createBadgeError, createBadgeResult } = this.props;

        delete data.creationDate;
        delete data.updateDate;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_badge',
			this.createBundle(
				'create_badge',
				{ badge: data },
				this.POST,
				createBadgeRequest,
                (result) => {
                    createBadgeResult(result)
                    resolve(result)
                },
                (error) => {
                    createBadgeError(error)
                    reject(error)
                }
			)
		);
	})
}
