export function createMapping(data) {
    let { createMappingRequest, createMappingError, createMappingResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_mapping',
        this.createBundle('create_mapping', { mapping: data }, this.POST, createMappingRequest, createMappingResult, createMappingError)
    )
}
