export function deleteLot(data) {
    return new Promise((resolve, reject) => {
		let { deleteLotRequest, deleteLotError, deleteLotResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_lot',
			this.createBundle(
				'delete_lot',
				{ lot: data },
				this.POST,
				deleteLotRequest,
                (result) => {
                    deleteLotResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteLotError(error)
                    reject(error)
                }
			)
		);
	})
}
