export function getMaster(data) {
    return new Promise((resolve, reject) => {
        let { getMasterRequest, getMasterError, getMasterResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_master',
            this.createBundle(
                'get_master',
                { masterId: data },
                this.GET,
                getMasterRequest,
                (result) => {
                    getMasterResult(result)
                    resolve(result)
                },
                (error) => {
                    getMasterError(error)
                    reject(error)
                }
            )
        )
    })
}
