import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'
import { Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Empty, Variant } from '../components'

const { app, variants, newEl, variantEdit } = routes

class Variants extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteVariant } = this.state
        let { fixedVariants, organization } = this.props
        if (!fixedVariants) {
            if (
                this.state.page !== prevState.page ||
                this.state.items !== prevState.items ||
                (deleteVariant.fetching !== prevState.deleteVariant.fetching && !deleteVariant.fetching) ||
                organization.data !== prevProps.organization.data
            ) {
                this.state.network.getVariantList(this.state.search.data)
            } else if (this.state.search !== prevState.search) {
                this.state.network.getVariantList(this.state.search.data)
                this.setState({ page: 0 })
            }
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_variant) {
            extraTools.push({
                name: 'Crea nuova variante',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, variants, newEl, variantEdit))
                },
            })
        }

        if (!sub) {
            this.props.toolbarResult([
                ...extraTools,
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'right',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: 'none',
                    action: () => {
                        this.next()
                    },
                },
            ])
        }

        this.state.network.getVariantList(this.state.search.data)
    }

    select(variant, skipChange) {
        let { onChange, invertSelection } = this.props
        let {
            selected,
            variants: { data: variants },
        } = this.state

        let id = variant.variantId
        if (selected[id]) {
            delete selected[id]
        } else {
            selected[id] = variant
        }

        if (!skipChange && onChange) {
            if (invertSelection) {
                onChange(this.invert(selected, variants))
            } else onChange(selected)
        }

        this.setState({ selected })
    }

    isSelected = (variant) => {
        const { selected } = this.state
        const { multiple, selectable } = this.props

        if (!selectable) return false

        if (!multiple) {
            return selected.variantId === variant.variantId
        } else {
            return !!selected[variant.variantId]
        }
    }

    renderVariant(key, variant, style) {
        const { pickArticleVariantImage, multiple, onChange, selectable, noActions, article } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (variant) => {
                if (!multiple) {
                    if (onChange) {
                        onChange(variant)
                    }
                    this.setState({ selected: variant })
                } else {
                    this.select(variant, false)
                }
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (article) {
            extraProps.article = article
        }

        if (pickArticleVariantImage) {
            extraProps.pickArticleVariantImage = pickArticleVariantImage
        }

        return (
            <div style={style}>
                <Variant {...extraProps} selected={this.isSelected(variant)} type="full" key={`variant_${key}`} variant={variant} />
            </div>
        )
    }

    renderContent(list) {
        let { page, items } = this.state
        let { noVirtual, noPagination } = this.props

        let content = []
        if (!noPagination) {
            const keyArray = Object.keys(list)
            const upIndex = Math.min(page * items + items, keyArray.length)
            for (let i = page * items; i < upIndex; i++) {
                content.push(this.renderVariant(i, list[keyArray[i]]))
            }
        } else {
            if (!noVirtual) {
                content = (
                    <AutoSizer>
                        {({ height, width }) => (
                            <List height={height} itemCount={list.length} itemSize={200} width={width}>
                                {({ index, style }) => this.renderVariant(index, list[index], style)}
                            </List>
                        )}
                    </AutoSizer>
                )
            } else {
                const keyArray = Object.keys(list)
                content = keyArray.map((value, index) => {
                    return this.renderVariant(index, list[value], {})
                })
            }
        }
        return content
    }

    render() {
        let { variants, fetching } = this.state
        let { fixedVariants, noPagination, onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (fixedVariants) {
            content = this.renderContent(fixedVariants)
        } else if (variants && variants.data && variants.data.length > 0) {
            content = this.renderContent(variants.data)
        } else {
            content = <Empty />
        }

        return (
            <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { getVariantList, deleteVariant, search, organization, login } = state

    let variantList = getVariantList
    return {
        variants: variantList.data,
        fetching: variantList.fetching,
        deleteVariant,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Variants)))
