import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    99: 'Termine Lavori',
}
class DataPoint extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    renderTableHeader() {
        let { hideDate, subidName } = this.props

        return (
            <Table.Header fullWidth>
                <Table.Row>
                    {!hideDate && <Table.HeaderCell>Inizio</Table.HeaderCell>}
                    {!hideDate && <Table.HeaderCell>Fine</Table.HeaderCell>}
                    <Table.HeaderCell>Causale</Table.HeaderCell>
                    <Table.HeaderCell>{subidName ? subidName : 'Ordine di lavoro'}</Table.HeaderCell>
                    <Table.HeaderCell>Quantità prodotta</Table.HeaderCell>
                    <Table.HeaderCell>Scarti</Table.HeaderCell>
                    <Table.HeaderCell>Terminale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForTable() {
        let { data, hideDate } = this.props

        // console.log('GAP CALC Datapoint reason:', data.reason, data)
        return (
            <Table.Row
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick(this.props.data)
                    }
                }}
                className={this.props.selected ? 'rowSelected' : ''}
            >
                {!hideDate && <Table.Cell>{moment(data.startstamp).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>}
                {!hideDate && <Table.Cell>{moment(data.endstamp).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>}
                <Table.Cell>{REASON_LABELS[data.reason]}</Table.Cell>
                <Table.Cell>{data.sub_id}</Table.Cell>
                <Table.Cell>{data.quantity}</Table.Cell>
                <Table.Cell>{data.scrap}</Table.Cell>
                <Table.Cell>{data.terminal}</Table.Cell>
            </Table.Row>
        )
    }

    render() {
        let { type } = this.props
        let extraprops = {
            fluid: type === 'full' ? true : false,
        }
        // if (isBalance) return null

        switch (type) {
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            case 'footer':
                return null
            default:
                return (
                    <View fullw column between>
                        <Box style={styles.dataCard} {...extraprops} >
                            <Box textStyle="content" style={styles.jobContent}>
                                <View fullw row>
                                    <View fullw column>
                                        <span style={styles.header} />
                                    </View>
                                </View>
                            </Box>
                            <Box textStyle="content" extra />
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        height: 128,
        width: 128,
        marginRight: 28,
    },
    jobImage: {
        height: 128,
        width: 128,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dataCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = () => {
    return {}
}
export default connect(mapStateToProps)(connectNetwork(DataPoint))
