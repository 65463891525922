import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
class CostEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id

        let { job, cost } = this.props.match.params

        if (!id) {
            id = cost
        }

        if (props.job) {
            job = props.job
        }

        this.state = {
            id,
            costId: id,
            jobCode: job ? job : '',
            costDate: new Date(),
            costDescription: '',
            producer: '',
            costPrice: 0.0,
        }
    }

    componentDidMount() {
        const { id } = this.state
        const { sub } = this.props

        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Salva',
                    icon: 'save',
                    position: 'right',
                    action: () => {
                        this.save()
                    },
                },
            ])
        }

        if (id) {
            this.state.network.getCost(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.creatingCost !== prevProps.creatingCost) {
            if (!this.props.creatingCost) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                        errorMessage: '',
                    })
                }
            }
        }

        let { getCost } = this.props
        if (getCost && getCost.fetching !== prevProps.getCost.fetching && !getCost.fetching) {
            if (getCost.status.success) {
                let cost = getCost.data.data

                let momobj = moment(cost.costDate, 'YYYY-MM-DD')

                if (momobj.isValid()) {
                    cost.costDate = momobj.toDate()
                } else {
                    cost.costDate = new Date()
                }

                this.setState(cost)
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { id, jobCode, costDate, costDescription, producer, costPrice } = this.state

        if (jobCode === '' || producer === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        let parsedDate = moment(costDate).local().format('YYYY-MM-DD HH:mm:ss')

        this.props.network.createCost({
            costId: id,
            jobCode,
            costDate: parsedDate,
            costDescription,
            producer,
            costPrice,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState(
            {
                [name]: value,
            },
            () => {
                if (this.props.onChange) {
                    let { jobCode, costDate, costDescription, producer, costPrice } = this.state
                    this.props.onChange({ jobCode, costDate, costDescription, producer, costPrice })
                }
            }
        )
    }

    render() {
        let { creatingCost, jobCode, costDate, costDescription, producer, costPrice, errorMessage } = this.state

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creatingCost}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-cost-id"
                            control={Input}
                            label={<span className="label">Codice commessa</span>}
                            name="jobCode"
                            value={jobCode}
                            onChange={(e) => this.handleInput(e)}
                            placeholder="C000"
                        />
                        <Form.Field
                            id="form-input-control-cost-description"
                            control={Input}
                            label={<span className="label">Descrizione</span>}
                            placeholder="Fresature"
                            name="costDescription"
                            value={costDescription}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-cost-date"
                            control={DatePicker}
                            label={<span className="label">Data costo</span>}
                            name="costDate"
                            value={costDate}
                            dateFormat="dd/MM/yyyy"
                            selected={costDate}
                            onChange={(data) => {
                                this.setState({
                                    costDate: data,
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-producer"
                            control={Input}
                            label={<span className="label">Fornitore</span>}
                            placeholder="Clementoni"
                            name="producer"
                            value={producer}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-cost-cost"
                            control={Input}
                            type="number"
                            label={<span className="label">Prezzo</span>}
                            placeholder="0.0"
                            step="0.1"
                            name="costPrice"
                            value={costPrice}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    {!this.props.sub && (
                        <Form.Field
                            id="form-button-control-save"
                            control={Button}
                            content="Salva"
                            onClick={() => {
                                this.save()
                            }}
                        />
                    )}
                    <Message success header="Form Completed" content="Costo inserito con successo!" />
                    <Message error header="Errore" content={`Errore del server, \nriprova piu' tardi. ${errorMessage}`} />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        createCost: { data: createResult, fetching: creatingCost, status: createStatus },
        getCost,
    } = state

    return {
        createResult,
        creatingCost,
        createStatus,
        getCost,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CostEditor)))
