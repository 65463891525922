import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import CustomerDropdown from '../utility/CustomerDropdown'

const { app, customers, customerEdit } = routes

class Customer extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, customer } = props

        this.state = {
            deleting: false,
            ...customer,
            customerMinutes: customer ? parseFloat(customer.customerMinutes) : 0,
        }

        if (onCostChange && customer) {
            onCostChange(this.totalCost(), customer.customerId)
        }
    }

    totalCost() {
        let { customerPrice } = this.state
        let total = customerPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { customer, customerId, network } = this.props

        if ((!customer || !customer.customerId) && customerId) {
            network.getCustomer(customerId)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let {
            hideCustomer,
            hideCustomerName,
            hideCustomerUnitPrice,
            hideCustomerMeasurement,
            isBalance,
            hideActions,
            hideBarcode,
            onRowClick,
        } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideCustomer && <Table.HeaderCell>Cliente</Table.HeaderCell>}
                        {!hideCustomerName && <Table.HeaderCell>Descrizione</Table.HeaderCell>}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!hideActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideCustomer, hideCustomerName, hideBarcode, isBalance, onRowClick, hideActions } = this.props
        let { customerId, customerName, customerDescription } = this.state

        setImmediate(() => {
            try {
                const text = customerName
                JsBarcode(`#matbarcode${customerId}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } catch (e) {
                console.error(e)
            }
        })

        let scanned = this.props.scannedCustomers ? this.props.scannedCustomers.includes(customerName) : false

        // console.log('Customere is', scanned)
        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row style={{ backgroundColor: scanned ? 'green' : 'white' }}>
                    {!hideCustomer && <Table.Cell>{customerName}</Table.Cell>}
                    {!hideCustomerName && <Table.Cell>{customerDescription}</Table.Cell>}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`matbarcode${customerId}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!hideActions && this.state.customerId && (
                        <Table.Cell className="no-print">
                            {/* <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingCustomer: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button> */}
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deleteCustomer({
                                        customerId,
                                        customerName,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            />
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, customer } = this.props

        network.deleteStdCustomer(customer)

        this.setState({ deleting: false })
    }

    render() {
        let { mergeDestinations, mergingCustomer, customerId } = this.state
        let { login, type, customer, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(customer)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.customerCard} {...extraprops} />
                break
        }

        return (
            <>
                <Modal
                    key={`modal_customer_${customerId}`}
                    closeIcon
                    open={!!mergingCustomer}
                    onClose={() => this.setState({ mergingCustomer: false })}
                >
                    <Modal.Header>Modificando cliente</Modal.Header>
                    <Modal.Content>
                        <CustomerDropdown
                            selected={mergeDestinations}
                            onChange={(ev, data, selected, customers) => {
                                this.setState({
                                    mergeDestinations: customers,
                                })
                            }}
                            onlySelection
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            color="red"
                            onClick={() => {
                                this.props.network.mergeCustomer({
                                    sourceId: this.state.customerId,
                                    destinationId: this.state.mergeDestinations[0].customerId,
                                })
                                this.setState({ mergingCustomer: false })
                            }}
                            disabled={
                                !this.state.mergeDestinations ||
                                this.state.mergeDestinations.length !== 1 ||
                                this.state.customerId === this.state.mergeDestinations[0].customerId
                            }
                        >
                            Effettua unione AZIONE IRREVERSIBILE, VERRA{'\''} ELIMINATO IL MATERIALE {'"'}{this.state.customerDescription}{'"'} (
                            {this.state.customerId})
                        </Button>
                    </Modal.Content>
                </Modal>
                {content}
            </>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    customerImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    customerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    customerCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getCustomer } = state
    let rcustomerId = null
    if (login && login.data && login.data.user) rcustomerId = login.data.user.rcustomerId

    return { role: rcustomerId, getCustomer, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Customer)))
