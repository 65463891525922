import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickImage } from '../components'
import { Checkbox } from '@chakra-ui/react'

class MachineEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { machine } = this.props.match.params

        if (!id) {
            id = machine
        }

        this.state = {
            machineId: id,
            machineName: '',
            machineType: 0,
            machineHourlyRate: 0.0,
            machineImage: '',
            listingPrices: {},
            hideFromRecap: false,
        }
    }

    componentDidMount() {
        let { machineId } = this.state

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])
        this.state.network.getMachineTypeList()

        if (machineId) {
            this.state.network.getMachine(machineId)
        }
        this.props.network.getListingList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        // console.log('MachineEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getMachine } = this.props
        if (getMachine && getMachine.fetching !== prevProps.getMachine.fetching && !getMachine.fetching) {
            if (getMachine.status.success) {
                let machine = getMachine.data.data
                const { listingPrices } = this.state
                for (const listing of machine.listings) {
                    listingPrices[listing.listingId] = listing.price
                }
                this.setState(machine)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let {
            machineId,
            machineName,
            machineType,
            machineHourlyRate,
            machineImage,
            machineEndpoint,
            machineHadesRef,
            nodeJob,
            nodeCustomer,
            nodeLot,
            nodeMaterial,
            nodeQuantity,
            nodeMold,
            nodeArticle,
            listingPrices,
            hideFromRecap,
        } = this.state

        if (machineName === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createMachine({
            machineId,
            machineName,
            machineType,
            machineHourlyRate,
            machineImage,
            machineEndpoint,
            machineHadesRef,
            nodeJob,
            nodeCustomer,
            nodeLot,
            nodeMaterial,
            nodeQuantity,
            nodeMold,
            nodeArticle,
            listingPrices,
            hideFromRecap,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let {
            fetching,
            creating,
            options,
            machineName,
            machineType,
            machineHourlyRate,
            machineImage,
            getMachine,
            machineEndpoint,
            machineHadesRef,
            nodeJob,
            nodeCustomer,
            nodeLot,
            nodeMaterial,
            nodeQuantity,
            nodeMold,
            nodeArticle,
            hideFromRecap,
        } = this.state

        const { getListingList } = this.props

        // console.log('MachineEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={fetching || creating || getMachine.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-machine-name"
                            control={Input}
                            label={<span className="label">Nome Macchina</span>}
                            name="machineName"
                            value={machineName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-machine-type"
                            control={Select}
                            options={options}
                            label={<span className="label">Tipo macchina</span>}
                            placeholder="Tipo macchina"
                            name="machineType"
                            value={machineType}
                            onChange={(e, data) => {
                                let id = data.value
                                // console.log('Tipo selected', id)
                                this.setState({ machineType: id })
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-image"
                            control={Input}
                            label={<span className="label">Immagine</span>}
                            placeholder="Immagine"
                            name="machineImage"
                            value={machineImage}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickImage
                            onChange={(image) => {
                                this.setState({ machineImage: image })
                            }}
                            value={this.state.machineImage}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-machine-cost"
                            control={Input}
                            type="number"
                            label={<span className="label">Prezzo orario</span>}
                            placeholder="0.0"
                            step="0.1"
                            name="machineHourlyRate"
                            value={machineHourlyRate}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            control={Checkbox}
                            label={<span className="label">Nascondi dal riepilogo</span>}
                            isChecked={hideFromRecap === 1}
                            onChange={(e, data) => {
                                this.setState({ hideFromRecap: e.target.checked ? 1 : 0 })
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-machine-hadesref"
                            control={Input}
                            label={<span className="label">ID Dati Macchina</span>}
                            name="machineHadesRef"
                            value={machineHadesRef}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-machine-endpoint"
                            control={Input}
                            label={<span className="label">Endpoint</span>}
                            name="machineEndpoint"
                            value={machineEndpoint}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-machine-node-job"
                            control={Input}
                            label={<span className="label">Nodo: Ordine Cliente</span>}
                            placeholder="Nodo: Ordine Cliente"
                            name="nodeJob"
                            value={nodeJob}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-machine-node-customer"
                            control={Input}
                            label={<span className="label">Nodo: Cliente</span>}
                            placeholder="Nodo: Cliente"
                            name="nodeCustomer"
                            value={nodeCustomer}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-machine-node-job"
                            control={Input}
                            label={<span className="label">Nodo: Lotto</span>}
                            placeholder="Nodo: Lotto"
                            name="nodeLot"
                            value={nodeLot}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-machine-node-job"
                            control={Input}
                            label={<span className="label">Nodo: Articolo</span>}
                            placeholder="Nodo: Articolo"
                            name="nodeArticle"
                            value={nodeArticle}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-machine-node-job"
                            control={Input}
                            label={<span className="label">Nodo: Materiale</span>}
                            placeholder="Nodo: Materiale"
                            name="nodeMaterial"
                            value={nodeMaterial}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-machine-node-job"
                            control={Input}
                            label={<span className="label">Nodo: Ordine Cliente</span>}
                            placeholder="Nodo: Quantità"
                            name="nodeQuantity"
                            value={nodeQuantity}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-machine-node-job"
                            control={Input}
                            label={<span className="label">Nodo: Stampo</span>}
                            placeholder="Nodo: Stampo"
                            name="nodeMold"
                            value={nodeMold}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    {getListingList &&
                        getListingList.data &&
                        getListingList.data.data &&
                        getListingList.data.data.length > 0 &&
                        getListingList.data.data.map((value, index) => {
                            return (
                                <Form.Group key={`machine_editor_listing_${index}`}>
                                    <Form.Field
                                        id={`form-textarea-control-machine-listing-${index}`}
                                        control={Input}
                                        label={<span className="label">Prezzo listino {value.listingName}</span>}
                                        placeholder="10.00"
                                        name="listingPrice"
                                        type="number"
                                        step="0.01"
                                        value={this.state.listingPrices[value.listingId] ? this.state.listingPrices[value.listingId] : 0}
                                        onChange={(e) => {
                                            const listingPrices = this.state.listingPrices
                                            listingPrices[value.listingId] = e.target.value
                                            this.setState({ listingPrices })
                                        }}
                                    />
                                </Form.Group>
                            )
                        })}
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getMachineTypeList: {
            data: { data: types },
            fetching,
        },
        createMachine: { data: createResult, fetching: creating, status: createStatus },
        getMachine,
        getListingList,
    } = state

    let options = []
    for (let i in types) {
        let type = types[i]
        if (type.machineType === 1) continue // SKIP SPECIAL TYPE "NO_MACHINE"
        options.push({
            key: type.machineType,
            text: type.typeDescription,
            value: type.machineType,
        })
    }

    return {
        options,
        types,
        fetching,
        createResult,
        creating,
        getMachine,
        createStatus,
        getListingList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MachineEditor)))
