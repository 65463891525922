export function getLotList({ productionId, lotId, workOrderId }) {
    return new Promise((resolve, reject) => {
        let { getLotListRequest, getLotListError, getLotListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_lot_list',
            this.createBundle(
                'get_lot_list',
                { productionId, lotId, workOrderId },
                this.POST,
                getLotListRequest,
                (result) => {
                    getLotListResult(result)
                    resolve(result)
                },
                (error) => {
                    getLotListError(error)
                    reject(error)
                }
            )
        )
    })
}
