import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation, ImageDefault } from '.'
import { floatTimeToString, timeString } from '../lib/util'

const { app, workorders, workorderEdit } = routes

class WorkOrder extends Component {
    constructor(props) {
        super(props)

        this.componentContainerRef = null

        this.setComponentContainerRef = (element) => {
            this.componentContainerRef = element
        }

        let { onCostChange, workorder } = props

        this.state = {
            deleting: false,
            ...workorder,
            workorderMinutes: workorder ? parseFloat(workorder.workorderMinutes) : 0,
        }

        if (onCostChange && workorder) {
            onCostChange(this.totalCost(), workorder.id)
        }
    }

    totalCost() {
        let { workorderHourlyRate, workorderHours, workorderMinutes } = this.state
        let total = (workorderHours + workorderMinutes / 60) * parseFloat(workorderHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { workorder, id, network } = this.props

        if ((!workorder || !workorder.id) && id) {
            network.getWorkOrder(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getWorkOrder, login } = this.props
        let { id, workorderHourlyRate } = this.state
        if (workorderHourlyRate !== prevState.workorderHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        // console.log('WorkOrder getWorkOrder fetching', getWorkOrder.fetching)
        if (getWorkOrder && getWorkOrder.fetching !== prevProps.getWorkOrder.fetching && !getWorkOrder.fetching) {
            // console.log('Done calling getWorkOrder')
            if (getWorkOrder.status.success) {
                // console.log(getWorkOrder.data)
                let workorder = getWorkOrder.data.data

                this.setState({ ...workorder })
                // console.log('Calling Get workorder', login, workorder)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No workorder found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, workorderHourlyRate, workorderHours, workorderMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={workorderHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    workorderHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{workorderHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(workorderHours, workorderMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideWorkOrder, hideWorkOrderHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideWorkOrder && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideWorkOrderHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideWorkOrder, hideWorkOrderHours, isBalance } = this.props
        let { name, workorderHours, workorderMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideWorkOrder && <Table.Cell>{name}</Table.Cell>}
                    {!hideWorkOrderHours && <Table.Cell>{timeString(workorderHours, workorderMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, workorder } = this.props

        network.deleteWorkOrder(workorder)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, workorder, onClick, selected, noActions, traversable } = this.props
        let { deleting, workorderId: id, workorderName, workorderDescription, workorderImages } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(workorder)
            }
        }
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            case 'compact':
                return (
                    <div ref={this.setComponentContainerRef}>
                        <Box style={{ height: 200, width: 200 }}>
                            <Box textStyle="header">WorkOrder compact {workorderName}</Box>
                        </Box>
                    </div>
                )
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.workorderCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa collezione?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.workorderContent}>
                                <View fullw row>
                                    {workorderImages && workorderImages.length > 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={workorderImages[0].picturePath} ui={false} style={styles.workorderImage} />
                                        </div>
                                    )}
                                    {!workorderImages ||
                                        (workorderImages.length === 0 && (
                                            <div style={styles.imageContainer}>
                                                <ImageDefault fromRoot src={null} ui={false} style={styles.workorderImage} />
                                            </div>
                                        ))}
                                    <View fullw column>
                                        <span style={styles.header}>{workorderName}</span>
                                        <Box textStyle="description">{workorderDescription}</Box>
                                    </View>
                                </View>
                            </Box>
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    {user && !!user.manage_workorder && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, workorders, workorderEdit, `${id}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            Modifica
                                        </Button>
                                    )}

                                    {user && !!user.manage_workorder && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            Elimina
                                        </Button>
                                    )}
                                </Box>
                            )}
                            {traversable && (
                                <Box textStyle="content" extra>
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.onTraverse(workorder)
                                        }}
                                    >
                                        <Icon name="folder" />
                                        Apri
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    workorderImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    workorderContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    workorderCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getWorkOrder } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getWorkOrder, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(WorkOrder)))
