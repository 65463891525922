import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Input, Modal, Table } from 'semantic-ui-react'
import ProductionEditor from '../job/ProductionEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import Production from './Production'
import ProductionWizard from './ProductionWizard'
function Productions(props) {
    const {
        network,
        table,
        time,
        label,
        field,
        format,
        toolbarResult,
        filtersResult,
        sortsResult,
        createProduction,
        deleteProduction,
        mergeProduction,
        searchText,
    } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        production: null,
    })

    const [productions, setProductions] = useState([])
    const [dirty, setDirty] = useState(false)
    const [editing, setEditing] = useState(false)
    let [page, setPage] = useState(0)
    const [filters, setFilters] = useState({})
    const [sorts, setSorts] = useState({})
    const [showNewProduction, setShowNewProduction] = useState(false)

    useEffect(() => {
        network.getProductionList(searchText ? searchText : '', filters, sorts).then((data) => {
            const productions = []
            for (const key in data.data) {
                productions.push(data.data[key])
            }
            setProductions(productions)
            setDirty(false)
            setPage(0)
        })
    }, [dirty, createProduction.fetching, deleteProduction.fetching, searchText]) // mergeProduction.fetching

    useEffect(() => {
        let pageItems = [
            {
                name: `${parseInt(page) + 1}`,
                position: 'none',
                type: 'text',
            },
        ]

        const toolbar = [
            {
                name: 'Nuova produzione',
                icon: 'plus',
                position: 'left',
                action: () => {
                    setShowNewProduction(true)
                },
            },
            {
                name: '',
                icon: 'arrow left',
                position: 'right',
                action: () => {
                    if (page > 0) {
                        setPage(page - 1)
                        page--
                    }
                },
            },
            ...pageItems,
            {
                name: '',
                icon: 'arrow right',
                position: 'none',
                action: () => {
                    setPage(page + 1)
                    page++
                },
            },
        ]

        const sortoptions = {
            deliveryDate: {
                name: 'deliveryDate',
                label: 'Data di consegna',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setProductions([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setProductions([])
                    setDirty(true)
                },
            },
            creationDate: {
                name: 'creationDate',
                label: 'Data di creazione',
                onChange: (obj, value) => {
                    sorts[obj.name] = value
                    setSorts(sorts)
                    setProductions([])
                    setDirty(true)
                },
                onReset: (obj) => {
                    delete sorts[obj.name]
                    setSorts(sorts)
                    setProductions([])
                    setDirty(true)
                },
            },
        }

        const filteroptions = {
            productionId: {
                control: Input,
                name: 'productionId',
                input: 'text',
                placeholder: '122',
                label: 'Codice ordine di lavoro interno',
                onChange: (e) => {
                    filters.productionId = { value: e.target.value, key: 'productionId', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setProductions([])
                    setDirty(true)
                },
            },
            articleCode: {
                control: Input,
                name: 'articleCode',
                input: 'text',
                placeholder: 'ART153',
                label: 'Codice articolo',
                onChange: (e) => {
                    filters.articleCode = { value: e.target.value, key: 'articleCode', operator: '=' }
                    setFilters(filters)
                    setDirty(true)
                },
                onReset: () => {
                    setFilters(filters)
                    setProductions([])
                    setDirty(true)
                },
            },
        }

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, filteroptions, sortoptions)
    }, [page])

    return (
        <>
            {productions && productions.length > 0 && productions[0].productionId ? (
                <Table celled sortable>
                    <Production
                        type="header"
                        sorts={sorts}
                        onProductionDeliveryDateHeaderClick={() => {
                            if (!sorts.deliveryDate) {
                                sorts.deliveryDate = 'asc'
                            } else if (sorts.deliveryDate === 'asc') {
                                sorts.deliveryDate = 'desc'
                            } else {
                                delete sorts.deliveryDate
                            }
                            setSorts(sorts)
                            setProductions([])
                            setDirty(true)
                        }}
                    />
                    <Table.Body>
                        {productions.slice(page * 10, page * 10 + 10).map((value, index) => {
                            return (
                                <Production
                                    key={value.productionId}
                                    type="table"
                                    production={value}
                                    onRowClick={(state) => {
                                        const { productionId } = state
                                        setEditing(productionId)
                                    }}
                                />
                            )
                        })}
                    </Table.Body>
                </Table>
            ) : (
                <Box p={6} placeholder>Vuoto</Box>
            )}

            <ProductionWizard
                isOpen={showNewProduction}
                onClose={() => {
                    setShowNewProduction(false)
                }}
                onConfirm={() => {
                    setShowNewProduction(false)
                }}
                onCancel={() => {
                    setShowNewProduction(false)
                }}
            />

            <Modal closeIcon open={!!editing} onClose={() => setEditing(false)}>
                <Modal.Header>Modificando production</Modal.Header>
                <Modal.Content>
                    <ProductionEditor
                        productionId={editing}
                        onSave={(production) => {
                            setEditing(false)
                            setProductions([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let {
        getMachineList,
        createProduction,
        deleteProduction,
        mergeProduction,
        search: { data: searchText },
    } = state

    return {
        getMachineList,
        createProduction,
        deleteProduction,
        mergeProduction,
        searchText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Productions)))
