import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { CustomTable, SWA300Data } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'

const { app, saldatrice, saldatriceeditor } = routes
moment.locale('it')

class SWA300 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            start: moment().subtract(1, 'day'),
            end: moment(),
        }
    }

    prev() {
        // todo moment subtract
    }

    next() {
        // todo moment add
    }

    componentDidMount() {
        let { sub, network } = this.props
        if (!sub) {
            initializePage(
                this,
                [
                    {
                        name: 'Invia dati a SWA300',
                        icon: 'add',
                        action: () => {
                            this.props.history.push(route_from(app, saldatrice, saldatriceeditor))
                        },
                    },
                    {
                        name: '',
                        icon: 'arrow left',
                        position: 'right',
                        action: () => {
                            this.prev()
                        },
                    },
                    {
                        name: '',
                        icon: 'arrow right',
                        position: 'none',
                        action: () => {
                            this.next()
                        },
                    },
                ],
                {}
            )
        }

        let start = this.state.start.format('YYYY-MM-DD HH:mm:ss')
        let end = this.state.end.format('YYYY-MM-DD HH:mm:ss')

        // console.log('Start and end are:', start, end)

        network.getLastSWA300Data()
        network.getSWA300Data(start, end)
    }

    renderData(key, swa300data) {
        return <SWA300Data type="full" key={`swa300data_${key}`} swa300data={swa300data} />
    }

    render() {
        let { fetching, swa300datalist } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (swa300datalist.data) {
            // console.log('swa300datalist is', swa300datalist.data)

            content = <CustomTable name="SWA300Data" shorthand="swa300data" values={swa300datalist.data} child={SWA300Data} />
        }

        return (
            <View fullw fullh column>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getSWA300Data } = state
    return {
        fetching: getSWA300Data.fetching,
        swa300datalist: getSWA300Data.data,
        getSWA300Data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SWA300)))
