import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import SummarySheet from '../job/SummarySheet'
import KitDropdown from '../utility/KitDropdown'

const { app, kits, kitEdit } = routes

class Kit extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, kit } = props

        this.state = {
            deleting: false,
            ...kit,
            kitMinutes: kit ? parseFloat(kit.kitMinutes) : 0,
        }

        if (onCostChange && kit) {
            onCostChange(this.totalCost(), kit.kitId)
        }
    }

    totalCost() {
        let { kitPrice } = this.state
        let total = kitPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { kit, kitId, network } = this.props

        if ((!kit || !kit.kitId) && kitId) {
            network.getKit(kitId)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideKit, hideKitName, hideActions, hideBarcode, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideKit && <Table.HeaderCell>Codice</Table.HeaderCell>}
                        {!hideKitName && <Table.HeaderCell>Kit</Table.HeaderCell>}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!hideActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideKit, hideKitName, isBalance, hideActions, hideBarcode, onRowClick } = this.props
        let { kitId, kitCode, kitName } = this.state

        setImmediate(() => {
            try {
                const text = kitCode
                JsBarcode(`#kitbarcode${kitCode}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } catch (e) {
                console.error(e)
            }
        })

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideKit && <Table.Cell>{kitCode}</Table.Cell>}
                    {!hideKitName && <Table.Cell>{kitName}</Table.Cell>}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`kitbarcode${kitCode}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!hideActions && this.state.kitId && (
                        <Table.Cell>
                            <SummarySheet type="kit" objectToSerialize={this.state} />
                            <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingKit: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button>
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deleteKit({
                                        kitId,
                                        kitCode,
                                        kitName,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            />
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, kit } = this.props

        network.deleteStdKit(kit)

        this.setState({ deleting: false })
    }

    render() {
        const { kitId, mergingKit, mergeDestinations } = this.state
        let { login, type, kit, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(kit)
            }
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.kitCard} {...extraprops} />
                break
        }

        return (
            <>
                <Modal key={`modal_kit_${kitId}`} closeIcon open={!!mergingKit} onClose={() => this.setState({ mergingKit: false })}>
                    <Modal.Header>Modificando kit</Modal.Header>
                    <Modal.Content>
                        <KitDropdown
                            selected={mergeDestinations}
                            onChange={(ev, data, selected, kits) => {
                                this.setState({
                                    mergeDestinations: kits,
                                })
                            }}
                            onlySelection
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            color="red"
                            onClick={() => {
                                this.props.network.mergeKit({
                                    sourceId: this.state.kitId,
                                    destinationId: this.state.mergeDestinations[0].kitId,
                                })
                                this.setState({ mergingKit: false })
                            }}
                            disabled={
                                !this.state.mergeDestinations ||
                                this.state.mergeDestinations.length !== 1 ||
                                this.state.kitId === this.state.mergeDestinations[0].kitId
                            }
                        >
                            Effettua unione AZIONE IRREVERSIBILE, VERRA{'\''} ELIMINATO IL KIT {'"'}{this.state.kitName}{'"'} ({this.state.kitId})
                        </Button>
                    </Modal.Content>
                </Modal>
                {content}
            </>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    kitImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    kitContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    kitCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getKit } = state
    let rkitId = null
    if (login && login.data && login.data.user) rkitId = login.data.user.rkitId

    return { role: rkitId, getKit, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Kit)))
