export function getRecipe(data) {
    return new Promise((resolve, reject) => {
		let { getRecipeRequest, getRecipeError, getRecipeResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_recipe',
			this.createBundle(
				'get_recipe',
				{ recipeId: data },
				this.GET,
				getRecipeRequest,
                (result) => {
                    getRecipeResult(result)
                    resolve(result)
                },
                (error) => {
                    getRecipeError(error)
                    reject(error)
                }
			)
		);
	})
}
