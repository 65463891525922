export function createArticle(data) {
    let { createArticleRequest, createArticleError, createArticleResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'app/stock/create_article',
        this.createBundle('app/stock/create_article', { article: data }, this.POST, createArticleRequest, createArticleResult, createArticleError)
    )
}
