export function createMaster(data) {
    return new Promise((resolve, reject) => {
        let { createMasterRequest, createMasterError, createMasterResult } = this.props

        delete data.creationDate;
        delete data.updateDate;

        this.doAction(
            this.WEB_REQUEST_URL + 'create_master',
            this.createBundle(
                'create_master',
                { master: data },
                this.POST,
                createMasterRequest,
                (result) => {
                    createMasterResult(result)
                    resolve(result)
                },
                (error) => {
                    createMasterError(error)
                    reject(error)
                }
            )
        )
    })
}
