import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Article } from '../components'

class SingleArticle extends Component {
    constructor(props) {
        super(props)
        let { article } = this.props.match.params

        this.state = {
            article,
        }
    }

    componentDidUpdate() {}

    componentDidMount() {}

    render() {
        let { article } = this.state
        // console.log('Loading article', article)
        return (
            <View column fullw fullh>
                <Article forcedOpen={true} showDetails={true} articleId={article} type="full" />
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SingleArticle)))