import React, { useState } from 'react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from 'lib/View'
import Iframe from 'react-iframe'
import { Button, Form, Input, Select } from 'semantic-ui-react'
import { Article } from '../components'
import moment from 'momentconfig'

function Shipments({ toolbarResult, filtersResult, sortsResult, network, machines, isEmployee, depositList }) {
    useEffect(() => {
        network.getDepositList()
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    const [scannedCode, setScannedCode] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [deposit, setDeposit] = useState(1)

    let options = []
    if (depositList) {
        for (let d of depositList) {
            options.push({ key: d.id, value: d.id, text: d.name })
        }
    }

    options.push()

    return (
        <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Scansione</span>}
                        name="scannedCode"
                        value={scannedCode}
                        onChange={(e) => {
                            setScannedCode(e.target.value)
                        }}
                        placeholder="SCANSIONA QUI"
                        style={{
                            fontSize: 64,
                        }}
                    />
                </Form.Group>
                <Form.Group inline widths="equal">
                    <Form.Field
                        id="form-input-control-deposit"
                        control={Select}
                        options={options}
                        label={<span className="label">Deposito</span>}
                        placeholder="Deposito"
                        name="deposit"
                        value={deposit}
                        onChange={(e, data) => {
                            let dep = data.value
                            // console.log('Deposit selected', dep)
                            setDeposit(dep)
                        }}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Quantità</span>}
                        name="quantity"
                        value={quantity}
                        onChange={(e) => {
                            setQuantity(e.target.value)
                        }}
                        placeholder="Quantità"
                    />
                </Form.Group>
            </Form>
            {scannedCode && <Article isEmployee={!!isEmployee} noActions articleCode={scannedCode} />}
            <Button
                disabled={quantity <= 0}
                onClick={() => {
                    network.createDocument(
                        {
                            work_order_id: null,
                            // id_user: userid,
                            // id_deposit_from: 1,
                            // id_deposit_to: 1,
                            id_document_reason: 7,
                            // id_document_related: 1,
                            note: 'Documento generato',
                            date: moment().format('YYYY-MM-DD HH:mm:ss'),
                        },
                        [
                            {
                                code: scannedCode,
                                price: 0,
                                discount: '0',
                                qty: quantity,
                                amount: quantity,
                            },
                        ]
                    )
                }}
            >
                Salva
            </Button>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getDepositList } = state
    return {
        depositList: getDepositList.data.deposit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Shipments)))
