export function deleteRecipe(data) {
    return new Promise((resolve, reject) => {
		let { deleteRecipeRequest, deleteRecipeError, deleteRecipeResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_recipe',
			this.createBundle(
				'delete_recipe',
				{ recipe: data },
				this.POST,
				deleteRecipeRequest,
                (result) => {
                    deleteRecipeResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteRecipeError(error)
                    reject(error)
                }
			)
		);
	})
}
