import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'momentconfig'
import React from 'react'
import ReactExport from 'react-data-export'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Loader } from 'semantic-ui-react'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

let reasons = {
    1: 'Vendita',
    2: 'Rimborso',
    3: 'Trasferimento',
    4: 'Conferma trasferimento',
    5: 'Presa in carico riparazione',
    6: 'Reso riparazione',
    7: 'Rimozione',
    8: 'Aggiunta',
}

class DocumentRecap extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            documentDone: false,
        }
    }

    componentDidMount() {
        let { deposit, start, end, wait, network } = this.props
        // console.log('DocumentRecapping dates', start, end)
        if (!wait) {
            network.getDocumentRecap(
                '',
                deposit,
                start ? moment(start).format('YYYY-MM-DD HH:mm:ss') : null,
                end ? moment(end).format('YYYY-MM-DD HH:mm:ss') : null
            )
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { getDocumentRecap, deposit, start, end, wait, network, onDone, articlecode } = this.props
        let { documentDone } = this.state

        if (!getDocumentRecap.fetching && prevProps.getDocumentRecap.fetching) {
            this.setState({ documentDone: true })
        }

        if (prevProps.wait && !wait) {
            // console.log('DocumentRecapping deposit', deposit)

            network.getDocumentRecap(
                '',
                deposit,
                start ? moment(start).format('YYYY-MM-DD HH:mm:ss') : null,
                end ? moment(end).format('YYYY-MM-DD HH:mm:ss') : null,
                articlecode
            )
        }

        if (documentDone && !wait && (!prevState.documentDone || (!wait && prevProps.wait))) {
            let documents = getDocumentRecap.data.data
            let excelfile = []
            // console.log('Documents are', documents, getDocumentRecap)
            for (let i in documents) {
                let document = documents[i]

                let { rows, fromDeposit, toDeposit, user, id_document_reason, ...header } = document

                let hdr = {
                    ...header,
                    fromDepName: fromDeposit.name,
                    // fromDepId: fromDeposit.id,
                    toDepName: toDeposit.name,
                    // toDepId: toDeposit.id,
                    userId: user.id,
                    email: user.email,
                    userFullname: user.fullname,
                    reason: reasons[id_document_reason],
                }

                if (rows.length > 0) {
                    for (let row of rows) {
                        let { id: document_row_id, code, price, discount, qty, amount } = row
                        excelfile.push({
                            ...hdr,
                            document_row_id,
                            code,
                            price,
                            discount,
                            qty,
                            amount,
                        })
                    }
                } else {
                    excelfile.push(hdr)
                }
            }

            // console.log('excel file', excelfile)

            if (onDone) {
                onDone({
                    documents,
                    excelfile,
                })
            }

            this.setState({
                documents,
                excelfile,
            })
        }
    }

    render() {
        let { getDocumentRecap, deposit, start, end, element, articlecode } = this.props
        let { excelfile, documentDone } = this.state

        if (getDocumentRecap.fetching) {
            return <Loader active />
        }
        if (!documentDone) {
            return null
        } else {
            let filename = 'Documenti'
            if (deposit) {
                filename += `_${deposit}`
            }

            if (articlecode) {
                filename += `_${articlecode}`
            }

            if (start) {
                filename += `_${moment(start).format('DD-MM-YYYY')}`
            }

            if (end) {
                filename += `_${moment(end).format('DD-MM-YYYY')}`
            }

            return (
                <ExcelFile filename={filename} element={element ? element : <Button>Download</Button>}>
                    <ExcelSheet data={excelfile} name="Riepilogo">
                        <ExcelColumn label="Documento" value="id" />
                        {/* <ExcelColumn label="DEPOSIT_FROM'" value="id_deposit_from" /> */}
                        <ExcelColumn label="Da" value="fromDepName" />
                        {/* <ExcelColumn label="DEPOSIT_TO" value="id_deposit_to" /> */}
                        <ExcelColumn label="A" value="toDepName" />
                        <ExcelColumn label="Causale" value="reason" />
                        {/* <ExcelColumn label="DOCUMENT_RELATED" value="id_document_related" /> */}
                        <ExcelColumn label="Note" value="note" />

                        {/* <ExcelColumn label="DOCUMENT_ROW_ID" value="document_row_id" /> */}
                        <ExcelColumn label="Codice" value="code" />
                        <ExcelColumn label="Prezzo" value="price" />
                        <ExcelColumn label="Sconto" value="discount" />
                        <ExcelColumn label="Quantita" value="qty" />
                        <ExcelColumn label="Totale" value="amount" />

                        <ExcelColumn label="Data" value="date" />
                        {/* <ExcelColumn label="USER_ID" value="id_user" />
						<ExcelColumn label="EMAIL" value="email" />
						<ExcelColumn label="USER_NAME" value="userFullname" /> */}
                    </ExcelSheet>
                </ExcelFile>
            )
        }
    }
}

const mapStateToProps = (state) => {
    let { login, getDocumentRecap } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getDocumentRecap }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(DocumentRecap)))
