export function uploadMultipleFiles(data, callback) {
    // console.log('uploadMultipleFiles', data)

    let bundle = this.createBundle('upload_multiple', {}, this.POST, () => {}, callback, callback, false)

    const formData = new FormData()

    for (const file of data) {
        // console.log('Uploading file:', file)
        formData.append('files', file)
    }

    for (var key of formData.entries()) {
        // console.log(key[0] + ', ' + key[1])
    }

    bundle.data = formData
    bundle.data.ACTION = 'upload_multiple'
    bundle.noStringify = true
    //bundle.noAuth = true;
    bundle.contenttype = 'multipart/form-data'
    // console.log('Bundle is:', bundle)

    this.doAction(this.WEB_REQUEST_URL + 'upload_multiple', bundle)
}
