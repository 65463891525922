export function cloneTasks(job, cloneSource) {
    let { cloneTasksRequest, cloneTasksError, cloneTasksResult } = this.props

    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'clone_tasks',
            this.createBundle(
                'clone_tasks',
                { job, cloneSource },
                this.POST,
                cloneTasksRequest,
                (result) => {
                    resolve(result)
                    cloneTasksResult(result)
                },
                (error) => {
                    reject(error)
                    cloneTasksError(error)
                }
            )
        )
    })
}
