export function getProductionList(data) {
    return new Promise((resolve, reject) => {
        let { getProductionListRequest, getProductionListError, getProductionListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_production_list',
            this.createBundle(
                'get_production_list',
                { production: data },
                this.POST,
                getProductionListRequest,
                (result) => {
                    getProductionListResult(result)
                    resolve(result)
                },
                (error) => {
                    getProductionListError(error)
                    reject(error)
                }
            )
        )
    })
}
