export function createContract(data) {
    let { createContractRequest, createContractError, createContractResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_contract',
        this.createBundle('create_contract', { contract: data }, this.GET, createContractRequest, createContractResult, createContractError)
    )
}
