export function createProduct(id, product) {
    let { createProductRequest, createProductError, createProductResult } = this.props

    delete product.creationDate;
    delete product.updateDate;

    // console.log('Creating product with id', id, ':', product)
    this.doAction(
        this.WEB_REQUEST_URL + 'app/product/save',
        this.createBundle('app/product/save', { id, product }, this.POST, createProductRequest, createProductResult, createProductError)
    )
}
