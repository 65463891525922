import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input } from 'semantic-ui-react'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function MoldDropdown({ single, network, onMoldSelected, defaultSelectedMolds = [] }) {
    const [options, setOptions] = useState([])
    const [defaultMolds, setDefaultMolds] = useState({})
    const [molds, setMolds] = useState({})
    const [dirty, setDirty] = useState(false)
    const [search, setSearch] = useState('')
    const [selectedMolds, setSelectedMolds] = useState([])
    const forceUpdate = useForceUpdate()

    let dropdownValue = []
    if (!single) {
        for (const mold of selectedMolds) {
            dropdownValue.push(mold.moldCode)
        }
    } else {
        dropdownValue = selectedMolds
    }

    useEffect(() => {
        if (!single) {
            const defaultmoldobj = {}
            for (const element of defaultSelectedMolds) {
                defaultmoldobj[element.moldCode] = element
            }
            setDefaultMolds(defaultmoldobj)
            setSelectedMolds(defaultSelectedMolds)
        }
    }, [defaultSelectedMolds])

    useEffect(() => {
        network.getMoldList(search).then((data) => {
            const moldlist = data.data
            const moldobj = {}

            let moldOptions = []
            for (let i in moldlist) {
                let mold = moldlist[i]
                moldobj[mold.moldCode] = mold
                moldOptions.push({
                    key: mold.moldCode,
                    text: `${mold.moldCode} - ${mold.moldDescription}`,
                    value: mold.moldCode,
                })
            }

            for (let i in selectedMolds) {
                let mold = selectedMolds[i]
                if (!moldobj[mold.moldCode]) {
                    moldobj[mold.moldCode] = mold
                    moldOptions.push({
                        key: mold.moldCode,
                        text: `${mold.moldCode} - ${mold.moldDescription}`,
                        value: mold.moldCode,
                    })
                }
            }

            for (let i in defaultMolds) {
                let mold = defaultMolds[i]
                if (!moldobj[mold.moldCode]) {
                    moldobj[mold.moldCode] = mold
                    moldOptions.push({
                        key: mold.moldCode,
                        text: `${mold.moldCode} - ${mold.moldDescription}`,
                        value: mold.moldCode,
                    })
                }
            }

            setMolds(moldobj)
            setOptions(moldOptions)
            setDirty(false)
        })
    }, [dirty, search, defaultMolds])

    const extraProps = {}
    if (!single) {
        extraProps.multiple = true
    }

    if (!single) {
        extraProps.value = dropdownValue
    }

    return (
        <View column fullw>
            <Dropdown
                {...extraProps}
                onChange={(ev, data) => {
                    if (onMoldSelected) {
                        if (!single) {
                            const selectedList = []
                            for (const key of data.value) {
                                if (molds[key]) {
                                    selectedList.push(molds[key])
                                } else {
                                    if (defaultMolds[key]) {
                                        selectedList.push(defaultMolds[key])
                                    }
                                }
                            }

                            setSelectedMolds(selectedList)
                            onMoldSelected(ev, data, data.value, selectedList)
                        } else {
                            const selectedList = data.value

                            setSelectedMolds(selectedList)
                            onMoldSelected(ev, data, data.value, selectedList)
                        }
                    }
                }}
                placeholder="Stampo"
                fluid
                search
                selection
                options={options}
                onSearchChange={(event, data) => {
                    setSearch(data.searchQuery)
                }}
            />
            <>
                {selectedMolds && selectedMolds.map
                    ? selectedMolds.map((value, index) => {
                          if (!value) {
                              return null
                          }
                          return (
                              <Form.Group key={`selected-mold-editor-${value.moldCode}`} widths="equal">
                                  <Form.Field
                                      id={`form-input-control-mold-cycle-${index}`}
                                      control={Input}
                                      label={`Tempo ciclo ${value.moldCode}`}
                                      name="cycleTime"
                                      type="number"
                                      step="0.01"
                                      value={value.cycleTime}
                                      onChange={(e) => {
                                          const moldToEdit = selectedMolds[index]
                                          moldToEdit.cycleTime = e.target.value
                                          // console.log('New cycleTime value', e.target.value)
                                          forceUpdate()
                                      }}
                                      placeholder=""
                                  />
                                  <Form.Field
                                      id={`form-input-control-mold-pieces-${index}`}
                                      control={Input}
                                      label={`Pezzi per ciclo ${value.moldCode}`}
                                      name="piecesPerCycle"
                                      type="number"
                                      value={value.piecesPerCycle}
                                      onChange={(e) => {
                                          const moldToEdit = selectedMolds[index]
                                          moldToEdit.piecesPerCycle = e.target.value
                                          // console.log('New pieces value', e.target.value)
                                          forceUpdate()
                                      }}
                                      placeholder=""
                                  />
                              </Form.Group>
                          )
                      })
                    : null}
            </>
        </View>
    )
}

export default withRouter(connectNetwork(MoldDropdown))
