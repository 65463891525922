export function getBadgeScan(data) {
    return new Promise((resolve, reject) => {
		let { getBadgeScanRequest, getBadgeScanError, getBadgeScanResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_badgescan',
			this.createBundle(
				'get_badgescan',
				{ badgescan: data },
				this.GET,
				getBadgeScanRequest,
                (result) => {
                    getBadgeScanResult(result)
                    resolve(result)
                },
                (error) => {
                    getBadgeScanError(error)
                    reject(error)
                }
			)
		);
	})
}
