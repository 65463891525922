import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input, Modal } from 'semantic-ui-react'
import WorkOrderEditor from '../job/WorkOrderEditor'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function WorkOrderDropdown({ network, onChange, defaultSelectedWorkOrders = [], onlySelection }) {
    const [options, setOptions] = useState([])
    const [workOrders, setWorkOrders] = useState({})
    const [input, setInput] = useState('')
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [selectedWorkOrders, setSelectedWorkOrders] = useState([])
    const forceUpdate = useForceUpdate()

    const dropdownValue = []
    for (const workOrder of selectedWorkOrders) {
        if (workOrder && workOrder.workOrderId) {
            dropdownValue.push(workOrder.workOrderId)
        }
    }

    const handleAddition = (e, { value }) => {
        setInput(value)
        setEditing(true)
    }

    let dirtySelection = false
    for (let i = 0; i < defaultSelectedWorkOrders.length; i++) {
        if (JSON.stringify(defaultSelectedWorkOrders[i]) !== JSON.stringify(selectedWorkOrders[i])) {
            dirtySelection = true
        }
    }

    useEffect(() => {
        setSelectedWorkOrders(defaultSelectedWorkOrders)
    }, [dirtySelection])

    useEffect(() => {
        network.getWorkOrderList().then((data) => {
            const workOrderlist = data.data
            const workOrderobj = {}

            let workOrderOptions = []
            for (let i in workOrderlist) {
                let workOrder = workOrderlist[i]
                workOrderobj[workOrder.workOrderId] = workOrder
                workOrderOptions.push({
                    key: `workOrder_${workOrder.workOrderId}`,
                    text: `${workOrder.workOrderId} - ${workOrder.workOrderDescription}`,
                    value: workOrder.workOrderId,
                })
            }

            setWorkOrders(workOrderobj)
            setOptions(workOrderOptions)
            setDirty(false)
        })
    }, [dirty])

    const extraProps = {}
    if (!onlySelection) {
        extraProps.allowAdditions = true
        extraProps.onAddItem = handleAddition
    }

    return (
        <>
            <Dropdown
                onChange={(ev, data) => {
                    if (onChange) {
                        const selectedList = []
                        for (const key of data.value) {
                            selectedList.push(workOrders[key])
                        }

                        setSelectedWorkOrders(selectedList)
                        onChange(ev, data, data.value, selectedList)
                    }
                }}
                placeholder="Articolo"
                fluid
                multiple
                search
                selection
                options={options}
                value={dropdownValue}
                {...extraProps}
            />
            {!onlySelection && (
                <>
                    {selectedWorkOrders.map((value, index) => {
                        if (!value) {
                            return null
                        }
                        return (
                            <Form.Group widths="equal" key={`workorder-dropdown-${value.workOrderId}`}>
                                <Form.Field
                                    id={`form-input-control-workOrder-percent-${index}`}
                                    control={Input}
                                    label={`Percentuale workOrdere ${value.workOrderDescription}`}
                                    name="workOrderPercent"
                                    type="number"
                                    step="0"
                                    value={value.workOrderPercent}
                                    onChange={(e) => {
                                        const toEdit = selectedWorkOrders[index]
                                        toEdit.workOrderPercent = e.target.value
                                        forceUpdate()
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                        )
                    })}
                </>
            )}

            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi workOrdere</Modal.Header>
                <Modal.Content>
                    <WorkOrderEditor
                        defaultDescription={input}
                        onSave={(workOrder) => {
                            setEditing(false)
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default withRouter(connectNetwork(WorkOrderDropdown))
