export function getSchedule(data) {
    return new Promise((resolve, reject) => {
		let { getScheduleRequest, getScheduleError, getScheduleResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_schedule',
			this.createBundle(
				'get_schedule',
				{ schedule: data },
				this.GET,
				getScheduleRequest,
                (result) => {
                    getScheduleResult(result)
                    resolve(result)
                },
                (error) => {
                    getScheduleError(error)
                    reject(error)
                }
			)
		);
	})
}
