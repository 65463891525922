export function getMasterList(search, isEmployee, filters, sorts, page = -1, items = 10) {
    return new Promise((resolve, reject) => {
        let { getMasterListRequest, getMasterListError, getMasterListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_master_list',
            this.createBundle(
                'get_master_list',
                { search, filters, sorts },
                this.POST,
                getMasterListRequest,
                (result) => {
                    getMasterListResult(result)
                    resolve(result)
                },
                (error) => {
                    getMasterListError(error)
                    reject(error)
                }
            )
        )
    })
}
