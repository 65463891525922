export function getJobTypeList(data) {
    return new Promise((resolve, reject) => {
        let { getJobTypeListRequest, getJobTypeListError, getJobTypeListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_job_type_list',
            this.createBundle(
                'get_job_type_list',
                { data: data },
                this.GET,
                getJobTypeListRequest,
                (result) => {
                    getJobTypeListResult(result)
                    resolve(result)
                },
                (error) => {
                    getJobTypeListError(error)
                    reject(error)
                }
            )
        )
    })
}
