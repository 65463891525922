export function getWorkList(page, items, search, jobId, dateRange, minus, notapplied) {
    let { getWorkListRequest, getWorkListError, getWorkListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_work_list',
        this.createBundle(
            'get_work_list',
            { page, items, search, jobId, dateRange, minus, notapplied },
            this.GET,
            getWorkListRequest,
            getWorkListResult,
            getWorkListError
        )
    )
}
