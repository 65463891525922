import { Checkbox } from '@chakra-ui/checkbox'
import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Modal, Popup } from 'semantic-ui-react'
import { Confirmation, ImageDefault, Task } from '../components'
import { alertGenerator, baseViewDispatch, Default, initializePage, Mobile } from '../lib/util'

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    imageContainerMobile: {
        width: 100,
        height: 75,
        margin: 'auto',
    },
    jobImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    jobImageMobile: {
        width: 100,
        height: 75,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        width: '100%',
        marginTop: 8,
        marginBottom: 8,
    },
}
function TaskList(props) {
    const { history, success, error, network, table, time, label, field, format, toolbarResult, filtersResult, sortsResult } = props
    const [deleting, setDeleting] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [taskToDelete, setTaskToDelete] = useState(null)
    const [jobs, setJobs] = useState([])
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        task: null,
    })

    useEffect(() => {
        network.getTaskList().then((data) => {
            // console.log(data)

            const jobs = []
            for (const key in data.data) {
                jobs.push(data.data[key])
            }
            setJobs(jobs)
            setDirty(false)
        })
    }, [table, dirty])

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    const renderJob = (tasklist) => {
        if (!tasklist || tasklist.length === 0) {
            return null
        }
        const { sold, sent, jobCode, jobProgram, jobType, image, description, client } = tasklist[0]

        const job = { sold, sent, jobCode, jobProgram, jobType, image, description, client }

        return (
            <View className="job-content-container" noflex fullw column between>
                <Box id={job.jobCode} style={styles.jobCard}>
                    <View fullw around row key={job.jobCode}>
                        <View around style={styles.imageContainer}>
                            <ImageDefault fromRoot src={job.image} ui={false} style={styles.jobImage} />
                        </View>
                        <View fullw around style={{ paddingBottom: 16 }}>
                            <View
                                fullw
                                column
                                style={{
                                    maxWidth: '95%',
                                }}
                            >
                                <Default>
                                    <span style={styles.header}>{job.jobName ? job.jobName : job.jobCode}</span>
                                </Default>
                                <Mobile>
                                    <span style={styles.smallHeader}>{job.jobName ? job.jobName : job.jobCode}</span>
                                </Mobile>
                                <Box textStyle="description">
                                    <View column>
                                        {job.jobDescription ? job.jobDescription : job.description}

                                        <Checkbox
                                            defaultChecked={sent === 1}
                                            style={{ padding: 10 }}
                                            onChange={(e, val) => {
                                                network.createJob(jobCode, {
                                                    jobCode,
                                                    sent: 1 - sent,
                                                })
                                                setDirty(true)
                                            }}
                                        >
                                            Consegnato
                                        </Checkbox>
                                        <Checkbox
                                            defaultChecked={sold === 1}
                                            style={{ padding: 10 }}
                                            onChange={(e, val) => {
                                                network.createJob(jobCode, {
                                                    jobCode,
                                                    sold: 1 - sold,
                                                })
                                                setDirty(true)
                                            }}
                                        >
                                            Fatturato
                                        </Checkbox>
                                    </View>
                                </Box>
                                <Box textStyle="meta" style={{ textTransform: 'capitalize' }}>
                                    Creata {moment(job.creationDate).format('LLLL')}
                                </Box>

                                {tasklist.map((value, index) => {
                                    return (
                                        <View key={`task_${value.taskId ? value.taskId : index}`} between row style={{ padding: 4 }}>
                                            <View fullw column>
                                                <Task type="compact" task={value} />
                                            </View>
                                            <View row>
                                                <Popup
                                                    content={value.taskNote}
                                                    trigger={
                                                        <Button
                                                            color="blue"
                                                            icon="sticky note"
                                                            onClick={() => {
                                                                setShowNoteEditor({
                                                                    show: true,
                                                                    task: value,
                                                                })
                                                            }}
                                                        />
                                                    }
                                                />
                                                {/* <Button
                                                    color="red"
                                                    icon="trash"
                                                    onClick={() => {
                                                        setTaskToDelete(value)
                                                        setDeleting(true)
                                                    }}
                                                /> */}
                                            </View>
                                        </View>
                                    )
                                })}
                            </View>
                            <View fullw column>
                                <View fullw column style={{ marginTop: 4, justifyContent: 'center', minHeight: 60 }}>
                                    {job.client && (
                                        <View fullw row style={{ justifyContent: 'center', textAlign: 'center' }}>
                                            <span style={{ fontWeight: 'lighter', marginTop: 2, marginRight: 16 }}>Cliente:</span>
                                            <Box textStyle="header" style={{ marginTop: 0 }}>{job.client}</Box>
                                        </View>
                                    )}
                                    {job.deliveryDate && (
                                        <View fullw row style={{ marginTop: 4, justifyContent: 'center', textAlign: 'center' }}>
                                            <span style={{ fontWeight: 'lighter', marginTop: 2, marginRight: 16 }}>Consegna:</span>
                                            <Box textStyle="header" style={{ marginTop: 0 }}>{moment(job.deliveryDate).format('DD/MM/YYYY')}</Box>
                                            <span style={{ fontWeight: 'lighter', marginTop: 2, marginLeft: 8 }}>
                                                (Settimana{' '}
                                                <span style={{ fontWeight: 'bold', marginTop: 0 }}>{moment(job.deliveryDate).get('week')}</span>)
                                            </span>
                                        </View>
                                    )}
                                </View>
                            </View>
                        </View>
                    </View>
                </Box>
            </View>
        )
    }

    if (jobs.length === 0) return null

    return (
        <>
            <Confirmation
                message="Vuoi davvero eliminare questo task?"
                showModal={deleting}
                onConfirm={() => {
                    if (taskToDelete) {
                        network.deleteTask(taskToDelete).then(() => {
                            // console.log('Success is', success)
                            success(
                                alertGenerator({
                                    title: 'Azione completata con successo',
                                    message: 'Task rimosso con successo',
                                })
                            )
                            setDirty(true)
                            setDeleting(false)
                        })
                    }
                }}
                onCancel={() => {
                    setDeleting(false)
                }}
            />
            <Modal open={showNoteEditor.show}>
                <Modal.Header>Modifica nota per task {showNoteEditor.task ? showNoteEditor.task.taskDescription : ''}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field
                            control={Input}
                            label={<span className="label">Nota</span>}
                            name="nota"
                            value={showNoteEditor.task ? showNoteEditor.task.taskNote : ''}
                            onChange={(data) => {
                                // console.log('Input data is', data.target.value)
                                setShowNoteEditor({
                                    show: true,
                                    task: { ...showNoteEditor.task, taskNote: data.target.value },
                                })
                            }}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        onClick={() => {
                            setShowNoteEditor({ show: false, task: null })
                        }}
                    >
                        Annulla <Icon name="check" />
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            // Save note
                            const taskobj = {
                                taskId: showNoteEditor.task.taskId,
                                taskNote: showNoteEditor.task.taskNote,
                            }
                            network.updateTask(taskobj).then(() => {
                                setDirty(true)
                                setShowNoteEditor({ show: false, task: null })
                            })
                        }}
                    >
                        Conferma <Icon name="check" />
                    </Button>
                </Modal.Actions>
            </Modal>
            {jobs.map((value, index) => {
                return renderJob(value)
            })}
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(TaskList)))
