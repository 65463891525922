export function deleteCustomer(data) {
    return new Promise((resolve, reject) => {
		let { deleteCustomerRequest, deleteCustomerError, deleteCustomerResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_customer',
			this.createBundle(
				'delete_customer',
				{ customer: data },
				this.POST,
				deleteCustomerRequest,
                (result) => {
                    deleteCustomerResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteCustomerError(error)
                    reject(error)
                }
			)
		);
	})
}
