export function getWorkOrder(data, isEmployee) {
    return new Promise((resolve, reject) => {
        let { getWorkOrderRequest, getWorkOrderError, getWorkOrderResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_workorder',
            this.createBundle(
                'get_workorder',
                { workorder: data },
                this.GET,
                getWorkOrderRequest,
                (result) => {
                    getWorkOrderResult(result)
                    resolve(result)
                },
                (error) => {
                    getWorkOrderError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
