export function getKitList(data, filters, sorts) {
    return new Promise((resolve, reject) => {
        let { getKitListRequest, getKitListError, getKitListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_kit_list',
            this.createBundle(
                'get_kit_list',
                { kit: data, filters, sorts },
                this.GET,
                getKitListRequest,
                (result) => {
                    getKitListResult(result)
                    resolve(result)
                },
                (error) => {
                    getKitListError(error)
                    reject(error)
                }
            )
        )
    })
}
