import { Box, VStack } from '@chakra-ui/layout'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from '@chakra-ui/react'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { error, hide, info, removeAll, show, success, warning } from 'react-notification-system-redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Message, TextArea } from 'semantic-ui-react'
import DropdownSearch from 'utility/DropdownSearch'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Article, PickMultipleImage, Variant } from '../components'
import { createAlert } from '../lib/util'
import CustomerDropdown from '../utility/CustomerDropdown'
import KitDropdown from '../utility/KitDropdown'
import MasterDropdown from '../utility/MasterDropdown'
import MaterialDropdown from '../utility/MaterialDropdown'
import MoldDropdown from '../utility/MoldDropdown'
import PackageDropdown from '../utility/PackageDropdown'

class ArticleEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { article } = this.props.match.params

        if (!id) {
            id = article
        }

        let molds = []
        if (this.props.location && this.props.location.state && this.props.location.state.moldBase) {
            const mold = this.props.location.state.moldBase
            molds = [
                {
                    moldCode: mold.jobCode,
                    moldCycleTime: 40,
                    moldDescription: mold.description,
                    moldMachine: null,
                },
            ]
        }

        this.state = {
            id,
            articleImages: [],
            articleVariantImages: [],
            variants: [],
            articleVariantToRemove: {},
            childToRemove: {},
            removeParent: false,
            articlePrice: 0,
            articleSetupPrice: 0,
            articleNote: '',
            articleRecipe: '',
            articleMachineRef: null,
            molds,
            articleWeightMU: 'g',
            articleVolumeMU: 'cl',
        }
    }

    componentDidMount() {
        let { id } = this.state
        // console.log('ArticleEditor id is', id)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (id) {
            this.props.network.getStdArticle(id)
        }
        this.props.network.getVariantList('')
        this.props.network.getFamilyList('')
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.props.success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.props.error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getStdArticle } = this.props
        if (getStdArticle && getStdArticle.fetching !== prevProps.getStdArticle.fetching && !getStdArticle.fetching) {
            if (getStdArticle.status.success) {
                let article = getStdArticle.data.data
                // console.log('Setting state with article:', article)
                let { id, ...rest } = article

                const articleVariantToRemove = {}
                for (const key in article.variants) {
                    articleVariantToRemove[key] = false
                }

                const childToRemove = {}
                for (const key in article.children) {
                    childToRemove[key] = false
                }

                if (this.props.isCloning) {
                    rest.articleCode += ' clone'
                }

                this.setState({ ...rest, articleVariantToRemove, childToRemove })
            }
        }
    }

    save() {
        // TODO value checking
        const {
            articleId,
            articleName,
            articleCode,
            articleSize,
            articleWeight,
            articleVolume,
            articleWeightMU,
            articleVolumeMU,
            articleState,
            articleDescription,
            articleDescriptionEng,
            articleOrdering,
            articleImageIndexToPick,
            articleImages,
            customerId,
            showDropdown,
            dropdownName,
            dropdownNameEng,
            familyId,
            variants,
            articleVariantToRemove,
            childToRemove,
            parentArticleId,
            removeParent,
            children,
            articlePrice,
            articleSetupPrice,
            articleNote,
            molds,
            packages,
            materials,
            machines,
            kits,
            masters,
            articleRecipe,
            articleMachineRef,
        } = this.state
        const { variantMap, variantOptions, network, isCloning } = this.props

        for (const key in articleVariantToRemove) {
            if (articleVariantToRemove[key]) {
                const toRemove = variants[key].variantId
                network.deleteArticleVariant(this.state.articleId, variants[key].variantId)
            }
        }

        for (const key in childToRemove) {
            if (childToRemove[key]) {
                const toRemove = children[key]
                network.clearParent(children[key])
            }
        }

        network
            .createStdArticle({
                articleId: isCloning ? undefined : articleId,
                articleName,
                articleCode,
                articleSize,
                articleWeight,
                articleVolume,
                articleWeightMU,
                articleVolumeMU,
                articleState,
                articleDescription,
                articleDescriptionEng,
                articleOrdering,
                articleImageIndexToPick,
                articleImages,
                customerId,
                showDropdown,
                dropdownName,
                dropdownNameEng,
                parentArticleId: removeParent || !parentArticleId ? null : parentArticleId,
                // variants,
                familyId,
                articlePrice,
                articleSetupPrice,
                articleNote,
                molds,
                packages,
                machines,
                materials,
                kits,
                masters,
                articleRecipe,
                articleMachineRef,
            })
            .then((result) => {})
            .catch((err) => {
                this.props.error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
            })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    renderVariant(key, variant, style) {
        if (!variant) return null

        return (
            <div style={style}>
                <Variant noActions type="full" key={`variant_${key}`} variant={variant} />
            </div>
        )
    }

    renderArticle(key, article, style) {
        if (!article) return null

        return (
            <div style={style}>
                <Article noActions type="full" key={`article_${key}`} article={article} />
            </div>
        )
    }

    render() {
        let {
            creating,
            articleId,
            articleCode,
            familyId,
            articleName,
            articleSize,
            articleWeight,
            articleVolume,
            articleWeightMU,
            articleVolumeMU,
            articleState,
            articleDescription,
            articleDescriptionEng,
            articleImages,
            articleOrdering,
            articleImageIndexToPick,
            customerId,
            customerName,
            showDropdown,
            dropdownName,
            dropdownNameEng,
            variants,
            children,
            articleVariantImages,
            parentArticleId,
            parent,
            articlePrice,
            articleSetupPrice,
            molds,
            articleNote,
            packages,
            machines,
            materials,
            kits,
            masters,
            articleRecipe,
            articleMachineRef,
        } = this.state

        let { network, variantList, getStdArticle, collectionOptions, familyOptions, variantOptions, variantMap, login } = this.props

        let variantContent = []
        let childrenContent = []

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        if (variants) {
            const variantsKeyArray = Object.keys(variants)
            variantContent = variantsKeyArray.map((value, index) => {
                return (
                    <View key={`article-editor-variant-${index}`} column fullw fullh style={{ marginBottom: 16 }}>
                        {this.renderVariant(index, variants[value], {})}
                        <View row fullw>
                            {user && !!user.manage_variant && (
                                <>
                                    <View fullw />, this.state.articleVariantToRemove[value] ? (
                                    <Box textStyle="header" as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                        La variante {variants[value].variantName} verrà disassociata
                                    </Box>
                                    ) : (
                                    <Box textStyle="header" as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                        La variante {variants[value].variantName} resterà associata
                                    </Box>
                                    )
                                    <Button
                                        style={{
                                            position: 'relative',
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 3,
                                        }}
                                        color="red"
                                        onClick={(e) => {
                                            const { articleVariantToRemove } = this.state
                                            articleVariantToRemove[value] = !articleVariantToRemove[value]
                                            this.setState({ articleVariantToRemove })
                                        }}
                                    >
                                        <Icon name="trash" />
                                    </Button>
                                </>
                            )}
                        </View>
                    </View>
                )
            })
        }

        if (children) {
            const childrenKeyArray = Object.keys(children)
            childrenContent = childrenKeyArray.map((value, index) => {
                return (
                    <View key={`variant-children-${index}`} column fullw fullh style={{ marginBottom: 16 }}>
                        {this.renderArticle(index, children[value], {})}
                        <View row fullw>
                            {user && !!user.manage_article && (
                                <>
                                    <View fullw />, this.state.childToRemove[value] ? (
                                    <Box textStyle="header" as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                        L{"'"}articolo {children[value].variantName} verrà disassociato
                                    </Box>
                                    ) : (
                                    <Box textStyle="header" as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                        L{"'"}articolo {children[value].variantName} resterà associato
                                    </Box>
                                    )
                                    <Button
                                        style={{
                                            position: 'relative',
                                            right: 0,
                                            bottom: 0,
                                            zIndex: 3,
                                        }}
                                        color="red"
                                        onClick={(e) => {
                                            const { childToRemove } = this.state
                                            childToRemove[value] = !childToRemove[value]
                                            this.setState({ childToRemove })
                                        }}
                                    >
                                        <Icon name="trash" />
                                        Elimina
                                    </Button>
                                </>
                            )}
                        </View>
                    </View>
                )
            })
        }

        return (
            <VStack w="100%" h="100%" column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    style={{ width: '100%' }}
                    loading={creating || getStdArticle.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Anagrafica articolo {parentArticleId ? `(Duplicato di ${parentArticleId})` : ''}
                        </Box>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-article-name"
                                control={Input}
                                label={<span className="label">Nome articolo</span>}
                                name="articleName"
                                value={articleName}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                            <Form.Field
                                id="form-input-control-article-code"
                                control={Input}
                                label={<span className="label">Codice articolo</span>}
                                name="articleCode"
                                value={articleCode}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                                required={true}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-article-weight"
                                control={Input}
                                label={<span className="label">Peso articolo</span>}
                                name="articleWeight"
                                value={articleWeight}
                                type="number"
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                            <Form.Field
                                id="form-input-control-article-weight"
                                control={Input}
                                label={<span className="label">Unità di misura del peso</span>}
                                name="articleWeightMU"
                                value={articleWeightMU}
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-article-price"
                                control={Input}
                                label={<span className="label">Prezzo</span>}
                                name="articlePrice"
                                value={articlePrice}
                                type="number"
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                            <Form.Field
                                id="form-input-control-article-setup-price"
                                control={Input}
                                label={<span className="label">Prezzo attrezzaggio</span>}
                                name="articleSetupPrice"
                                value={articleSetupPrice}
                                type="number"
                                onChange={(e) => this.handleInput(e)}
                                placeholder=""
                            />
                        </Form.Group>

                        <Accordion allowToggle allowMultiple>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            Dimensioni articolo e unità di misura
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Form.Group>
                                        <Form.Field
                                            id="form-input-control-article-size"
                                            control={Input}
                                            label={<span className="label">Dimensione articolo</span>}
                                            name="articleSize"
                                            value={articleSize}
                                            onChange={(e) => this.handleInput(e)}
                                            placeholder=""
                                        />
                                        <Form.Field
                                            id="form-input-control-article-weight"
                                            control={Input}
                                            label={<span className="label">Volume articolo</span>}
                                            name="articleVolume"
                                            value={articleVolume}
                                            type="number"
                                            onChange={(e) => this.handleInput(e)}
                                            placeholder=""
                                        />
                                        <Form.Field
                                            id="form-input-control-article-weight"
                                            control={Input}
                                            label={<span className="label">Unità di misura del volume</span>}
                                            name="articleVolumeMU"
                                            value={articleVolumeMU}
                                            onChange={(e) => this.handleInput(e)}
                                            placeholder=""
                                        />
                                    </Form.Group>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            Descrizione e note
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            id="form-input-control-article-name"
                                            control={TextArea}
                                            label={<span className="label">Descrizione articolo</span>}
                                            name="articleDescription"
                                            value={articleDescription}
                                            onChange={(e) => this.handleInput(e)}
                                            placeholder=""
                                        />
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            id="form-input-control-article-note"
                                            control={Input}
                                            label={<span className="label">Note articolo</span>}
                                            name="articleNote"
                                            value={articleNote}
                                            onChange={(e) => this.handleInput(e)}
                                            placeholder=""
                                        />
                                    </Form.Group>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                        <Box as="span" flex="1" textAlign="left">
                                            Associazione macchina
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                    <Form.Group>
                                        <Form.Field
                                            id="form-input-control-article-recipe"
                                            control={Input}
                                            label={<span className="label">Ricetta articolo</span>}
                                            name="articleRecipe"
                                            value={articleRecipe}
                                            onChange={(e) => this.handleInput(e)}
                                            placeholder=""
                                            required={true}
                                        />
                                    </Form.Group>

                                    <Box p={6}>
                                        <Box textStyle="header" as="h1">
                                            Macchina associata
                                        </Box>
                                        <DropdownSearch
                                            isMulti
                                            onChange={(machines) => {
                                                this.setState({
                                                    machines,
                                                })
                                            }}
                                            value={machines}
                                            entity={'Machine'}
                                            isSearchable={true}
                                            placeholder="Seleziona Macchina"
                                            keyfield="machineId"
                                            descriptionfield="machineName"
                                        />
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>

                        {/* <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-article-type"
                                control={Select}
                                options={familyOptions}
                                label={<span className="label">Famiglia di cui fa parte l{'\''}articolo</span>}
                                placeholder="Famiglia di cui fa parte l{'\''}articolo"
                                name="familyId"
                                value={familyId}
                                onChange={(e, data) => {
                                    let id = data.value
                                    // console.log('Family selected', id)
                                    this.setState({ familyId: id })
                                }}
                            />
                        </Form.Group> */}
                        {/* <View fullw around>
                            <View fullw around>
                                <Checkbox
                                    style={{ marginTop: 10 }}
                                    toggle
                                    label={<span className="label">Nuovo</span>}
                                    checked={articleState === 1}
                                    onChange={(e, data) => {
                                        this.setState({ articleState: data.checked ? 1 : 0 })
                                    }}
                                />
                            </View>
                            <View fullw around style={{ minHeight: 52 }}>
                                <Checkbox
                                    style={{ marginTop: 10 }}
                                    toggle
                                    label={<span className="label">Mostra menù a tendina su scheda articolo</span>}
                                    checked={showDropdown === 1}
                                    onChange={(e, data) => {
                                        this.setState({ showDropdown: data.checked ? 1 : 0 })
                                    }}
                                />
                            </View>
                        </View> */}
                        {showDropdown === 1 && (
                            <Form.Group widths="equal">
                                <Form.Field
                                    id="form-input-control-article-dropdown-name"
                                    control={Input}
                                    label={<span className="label">Titolo menù a tendina</span>}
                                    name="dropdownName"
                                    value={dropdownName}
                                    onChange={(e) => this.handleInput(e)}
                                    placeholder=""
                                />
                                {/* <Form.Field
                                    id="form-input-control-article-dropdown-name"
                                    control={Input}
                                    label={<span className="label">Titolo menù a tendina inglese</span>}
                                    name="dropdownNameEng"
                                    value={dropdownNameEng}
                                    onChange={(e) => this.handleInput(e)}
                                    placeholder=""
                                /> */}
                            </Form.Group>
                        )}
                    </Box>

                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Cliente associato
                        </Box>
                        <CustomerDropdown
                            single={true}
                            onChange={(ev, data, selected, customers) => {
                                this.setState({
                                    customerId: selected,
                                })
                            }}
                            defaultSelectedCustomers={customerId}
                        />
                    </Box>

                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Stampi associati
                        </Box>
                        <DropdownSearch
                            isMulti
                            onChange={(molds) => {
                                this.setState({
                                    molds,
                                })
                            }}
                            value={molds}
                            entity={'Mold'}
                            isSearchable={true}
                            placeholder="Seleziona Stampo"
                            keyfield="moldCode"
                            optionkeylabel="moldName"
                            descriptionfield="moldDescription"
                        />
                    </Box>

                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Master associati
                        </Box>
                        <DropdownSearch
                            isMulti
                            onChange={(masters) => {
                                this.setState({
                                    masters,
                                })
                            }}
                            value={masters}
                            entity={'Master'}
                            isSearchable={true}
                            placeholder="Seleziona Master"
                            keyfield="masterCode"
                            descriptionfield="masterName"
                        />
                    </Box>

                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Materiali associati
                        </Box>
                        <DropdownSearch
                            isMulti
                            onChange={(materials) => {
                                this.setState({
                                    materials,
                                })
                            }}
                            value={materials}
                            entity={'Material'}
                            isSearchable={true}
                            placeholder="Seleziona Materiale"
                            keyfield="materialCode"
                            descriptionfield="materialDescription"
                        />
                    </Box>

                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Imballi associati
                        </Box>
                        <DropdownSearch
                            isMulti
                            onChange={(packages) => {
                                this.setState({
                                    packages,
                                })
                            }}
                            value={packages}
                            entity={'Package'}
                            isSearchable={true}
                            placeholder="Seleziona Imballo"
                            keyfield="packageCode"
                            descriptionfield="packageName"
                        />
                    </Box>

                    {/* <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Kit associati
                        </Box>
                        <DropdownSearch
                            isMulti
                            onChange={(kits) => {
                                this.setState({
                                    kits,
                                })
                            }}
                            value={kits}
                            entity={'Kit'}
                            isSearchable={true}
                            placeholder="Seleziona Kit"
                            keyfield="kitCode"
                            descriptionfield="kitName"
                        />
                    </Box> */}

                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Immagine articolo
                        </Box>
                        <PickMultipleImage
                            onImageClick={(image, index) => {
                                this.setState({ articleImageIndexToPick: index })
                            }}
                            selectedIndex={this.state.articleImageIndexToPick}
                            onChange={(images) => {
                                this.setState({ articleImages: images, articleImageIndexToPick: 0 })
                            }}
                            value={articleImages}
                            singleImage={true}
                        />
                    </Box>

                    {parent && (
                        <Box p={6}>
                            <Box textStyle="header" as="h1">
                                Articolo duplicato di {parent.articleCode}
                            </Box>
                            <View column fullw fullh style={{ marginBottom: 16 }}>
                                {this.renderArticle('parent_article', parent, {})}
                                <View row fullw>
                                    {user && !!user.manage_article && (
                                        <>
                                            <View fullw />
                                            {this.state.removeParent ? (
                                                <Box textStyle="header" as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                                    L{"'"}articolo {parent.variantName} verrà disassociato
                                                </Box>
                                            ) : (
                                                <Box textStyle="header" as="h5" style={{ marginTop: 8, marginLeft: 16 }}>
                                                    L{"'"}articolo {parent.variantName} resterà associato
                                                </Box>
                                            )}
                                            <Button
                                                style={{
                                                    position: 'relative',
                                                    right: 0,
                                                    bottom: 0,
                                                    zIndex: 3,
                                                }}
                                                color="red"
                                                onClick={(e) => {
                                                    let { removeParent } = this.state
                                                    removeParent = !removeParent
                                                    this.setState({ removeParent })
                                                }}
                                            >
                                                <Icon name="trash" />
                                                Elimina
                                            </Button>
                                        </>
                                    )}
                                </View>
                            </View>
                        </Box>
                    )}

                    {childrenContent && childrenContent.length > 0 && (
                        <Box p={6}>
                            <Box textStyle="header" as="h1">
                                Articoli duplicati associati a questo articolo
                            </Box>
                            {childrenContent}
                        </Box>
                    )}

                    {variantContent && variantContent.length > 0 && (
                        <Box p={6}>
                            <Box textStyle="header" as="h1">
                                Varianti associate a questo articolo
                            </Box>
                            {variantContent}
                        </Box>
                    )}

                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form completato con successo" content="Articolo inserito con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </VStack>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        login,
        getVariantList: {
            data: { data: variants },
            fetching: fetchVariant,
        },
        getFamilyList: {
            data: { data: families },
            fetching: fetchFamily,
        },
        createStdArticle: { data: createResult, fetching: creating, status: createStatus },
        getStdArticle,
    } = state

    let variantMap = {}
    let variantOptions = []
    for (let i in variants) {
        let type = variants[i]
        variantMap[variants[i].variantId] = type
        variantOptions.push({
            key: type.variantId,
            text: type.variantName,
            value: type,
        })
    }

    let familyOptions = []
    for (let i in families) {
        let type = families[i]
        familyOptions.push({
            key: type.familyId,
            text: type.collectionName + ': ' + type.familyName,
            value: type.familyId,
        })
    }

    return {
        login,
        variantMap,
        variantList: variants,
        variantOptions,
        familyOptions,
        fetchFamily,
        fetchVariant,
        createResult,
        creating,
        getStdArticle,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        show: (notif) => dispatch(show(notif)),
        success: (notif) => dispatch(success(notif)),
        error: (notif) => dispatch(error(notif)),
        warning: (notif) => dispatch(warning(notif)),
        info: (notif) => dispatch(info(notif)),
        hide: (notif) => dispatch(hide(notif)),
        removeAll: (notif) => dispatch(removeAll(notif)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ArticleEditor)))
