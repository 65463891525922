import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Label, Placeholder } from 'semantic-ui-react'
import { Empty, Task } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
const moment = require('moment-timezone')

const { app, tasks, newEl, taskEdit } = routes

class Tasks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteTask } = this.state
        let { organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteTask.fetching !== prevState.deleteTask.fetching && !deleteTask.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getTaskList(this.state.search.data)
        } else if (this.state.search !== prevState.search) {
            this.state.network.getTaskList(this.state.search.data)
            this.setState({ page: 0 })
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        this.state.network.getTaskList(this.state.search.data)
        initializePage(this, [], [])
    }

    renderTaskGroup(key, taskgroup) {
        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (task) => {
                if (onChange) {
                    onChange(task)
                }
                this.setState({ selected: task })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return [
            <Label key={`table_label_${key}`} as="a" color="blue" ribbon style={{ width: '100vw', fontSize: 24 }}>
                {key} {taskgroup.length > 0 && taskgroup[0].deliveryDate ? `consegna il ${moment(taskgroup[0].deliveryDate).format('DD/MM/YYYY')}` : ''}
            </Label>,
            taskgroup.map((value, index) => {
                return this.renderTask(index, value)
            }),
        ]
    }

    renderTask(key, task) {
        let { selected } = this.state
        let { onChange, selectable, noActions } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (task) => {
                if (onChange) {
                    onChange(task)
                }
                this.setState({ selected: task })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return <Task {...extraProps} selected={task.taskId === selected.taskId} type="full" key={`task_${key}`} task={task} />
    }

    render() {
        let { tasks, fetching } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (tasks && tasks.data && Object.keys(tasks.data).length > 0) {
            content = Object.keys(tasks.data).map((key) => {
                let value = tasks.data[key]
                return this.renderTaskGroup(key, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View noflex fullw fullh column style={{ paddingLeft: 20, paddingRight: 20, overflowX: 'hidden' }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { getTaskList, deleteTask, search, organization, login } = state

    let taskList = getTaskList

    return {
        tasks: taskList.data,
        fetching: taskList.fetching,
        deleteTask,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Tasks)))
