import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Modal } from 'semantic-ui-react'
import CustomerEditor from '../job/CustomerEditor'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function CustomerDropdown({ network, onChange, defaultSelectedCustomers = [], onlySelection, single }) {
    const [options, setOptions] = useState([])
    const [customers, setCustomers] = useState({})
    const [input, setInput] = useState('')
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [selectedCustomers, setSelectedCustomers] = useState([])
    const forceUpdate = useForceUpdate()

    let dropdownValue = single ? undefined : []
    if (!single) {
        for (const customer of selectedCustomers) {
            if (customer && customer.customerId) {
                dropdownValue.push(customer.customerId)
            }
        }
    } else {
        dropdownValue = selectedCustomers ? selectedCustomers.customerId : defaultSelectedCustomers ? defaultSelectedCustomers : undefined
    }

    const handleAddition = (e, { value }) => {
        setInput(value)
        setEditing(true)
    }

    let dirtySelection = false
    if (!single) {
        for (let i = 0; i < defaultSelectedCustomers.length; i++) {
            if (JSON.stringify(defaultSelectedCustomers[i]) !== JSON.stringify(selectedCustomers[i])) {
                dirtySelection = true
            }
        }
    } else {
        if (
            !defaultSelectedCustomers ||
            (defaultSelectedCustomers && defaultSelectedCustomers.length === undefined && defaultSelectedCustomers !== selectedCustomers)
        ) {
            dirtySelection = true
        }
    }

    useEffect(() => {
        setSelectedCustomers(defaultSelectedCustomers)
    }, [dirtySelection])

    useEffect(() => {
        network.getCustomerList().then((data) => {
            const customerlist = data.data
            const customerobj = {}

            let customerOptions = []
            for (let i in customerlist) {
                let customer = customerlist[i]
                customerobj[customer.customerId] = customer
                customerOptions.push({
                    key: `customer_${customer.customerId}`,
                    text: `${customer.customerId} - ${customer.customerName}`,
                    value: customer.customerId,
                })
            }

            setCustomers(customerobj)
            setOptions(customerOptions)
            setDirty(false)
        })
    }, [dirty])

    const extraProps = {}
    if (!onlySelection) {
        extraProps.allowAdditions = true
        extraProps.onAddItem = handleAddition
    }

    if (!single) {
        extraProps.multiple = true
    }

    if (single && !dropdownValue) {
        dropdownValue = defaultSelectedCustomers
    }

    return (
        <>
            <Dropdown
                onChange={(ev, data) => {
                    if (onChange) {
                        if (!single) {
                            const selectedList = []
                            let displayName = ''
                            for (const key of data.value) {
                                selectedList.push(customers[key])
                                displayName = customers[key].customerName
                            }

                            setSelectedCustomers(selectedList)
                            onChange(ev, data, data.value, selectedList, displayName)
                        } else {
                            const selectedList = customers[data.value]

                            setSelectedCustomers(selectedList)
                            onChange(ev, data, data.value, selectedList)
                        }
                    }
                }}
                placeholder="Customer"
                fluid
                search
                selection
                options={options}
                value={dropdownValue}
                {...extraProps}
            />

            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi cliente</Modal.Header>
                <Modal.Content>
                    <CustomerEditor
                        defaultDescription={input}
                        onSave={(customer) => {
                            setEditing(false)
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default withRouter(connectNetwork(CustomerDropdown))
