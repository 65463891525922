import React from 'react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from 'lib/View'
import Iframe from 'react-iframe'

function FanucState({ toolbarResult, filtersResult, sortsResult, network, machines }) {
    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    return (
        <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Iframe url="https://192.168.1.201/proxy.php?urlurl=http://192.168.1.201/Default.aspx" height="100%" id="fanucMonitor" display="initial" position="relative" />
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(FanucState)))
