import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import DatePicker from 'react-datepicker'

class DocumentEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { document } = this.props.match.params

        if (!id) {
            id = document
        }

        this.state = {
            id: id,
            note: '',
        }
    }

    componentDidMount() {
        let { id } = this.state

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])
        this.state.network.getDocumentTypeList()

        if (id) {
            this.state.network.getDocument(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        // console.log('JobEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getDocument } = this.props
        if (getDocument && getDocument.fetching !== prevProps.getDocument.fetching && !getDocument.fetching) {
            if (getDocument.status.success) {
                let document = getDocument.data.data
                this.setState(document)
            }
        }
    }

    save() {
        // TODO value checking
        const { id, id_user, id_deposit_from, id_deposit_to, id_document_reason, id_document_related, note, date } = this.state

        const { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        if (note === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createDocument({
            work_order_id: null,
            id: id ? id : undefined,
            id_user,
            id_deposit_from,
            id_deposit_to,
            id_document_reason,
            id_document_related,
            note,
            date,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { id, id_user, id_deposit_from, id_deposit_to, id_document_reason, id_document_related, note, date } = this.state

        const { fetching, creating, login, getDocument } = this.props

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={fetching || creating || getDocument.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-order-date"
                            control={DatePicker}
                            label={<span className="label">Data documento</span>}
                            name="date"
                            value={date}
                            dateFormat="dd/MM/yyyy"
                            selected={date}
                            onChange={(data) => {
                                this.setState({
                                    date: data,
                                })
                            }}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Documento inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getDocumentTypeList: {
            data: { data: types },
            fetching,
        },
        createDocument: { data: createResult, fetching: creating, status: createStatus },
        getDocument,
        login,
    } = state

    let options = []
    for (let i in types) {
        let type = types[i]
        options.push({
            key: type.documentType,
            text: type.typeDescription,
            value: type.documentType,
        })
    }

    return {
        options,
        types,
        fetching,
        createResult,
        creating,
        getDocument,
        createStatus,
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(DocumentEditor)))
