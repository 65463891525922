export function createRecipe(data) {
    return new Promise((resolve, reject) => {
		let { createRecipeRequest, createRecipeError, createRecipeResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_recipe',
			this.createBundle(
				'create_recipe',
				{ recipe: data },
				this.POST,
				createRecipeRequest,
                (result) => {
                    createRecipeResult(result)
                    resolve(result)
                },
                (error) => {
                    createRecipeError(error)
                    reject(error)
                }
			)
		);
	})
}
