import moment from 'momentconfig'
import React, { Component } from 'react'
import { DragSource } from 'react-dnd'

import '../App.css'

class DraggableBox extends Component {
    render() {
        let { isPreview, key, connectDragSource, value, layoutElementStyle, padding } = this.props
        let paddingToApply = padding ? padding : 0

        return connectDragSource(
            <div
                key={key}
                style={
                    !isPreview
                        ? {
                              left: value.layoutElementX + paddingToApply,
                              top: value.layoutElementY + paddingToApply,
                              position: 'absolute',
                              ...layoutElementStyle,
                          }
                        : { ...layoutElementStyle }
                }
            />
        )
    }
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
    }
}

const spec = {
    beginDrag(props, monitor, component) {
        const item = { component, data: props.value, style: props.layoutElementStyle }
        return item
    },
}

export default DragSource('any', spec, collect)(DraggableBox)
