import { GETSIMPLEARTICLELIST_TYPES } from '../actions/getSimpleArticleList'
import reducer, { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    let requestReducer = reducer(GETSIMPLEARTICLELIST_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer