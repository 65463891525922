export function getMoldList(search, isEmployee, filters, sorts, page = -1, items = 10) {
    return new Promise((resolve, reject) => {
        let { getMoldListRequest, getMoldListError, getMoldListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_mold_list',
            this.createBundle(
                'get_mold_list',
                { search: search ? search : '', filters, sorts, page, items },
                this.POST,
                getMoldListRequest,
                (result) => {
                    getMoldListResult(result)
                    resolve(result)
                },
                (error) => {
                    getMoldListError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
