export function getStdArticleList(data, filters, sorts, isEmployee, page, items, getWorkOrderInfo) {
    let { getStdArticleListRequest, getStdArticleListError, getStdArticleListResult } = this.props
    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'get_article_list',
            this.createBundle(
                'get_article_list',
                { search: data || '', filters, sorts, page, items, getWorkOrderInfo },
                this.POST,
                getStdArticleListRequest,
                (result) => {
                    getStdArticleListResult(result)
                    resolve(result)
                },
                (error) => {
                    getStdArticleListError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
