import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input, Modal } from 'semantic-ui-react'
import MasterEditor from '../job/MasterEditor'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function MasterDropdown({ network, onChange, defaultSelectedMasters = [], onlySelection }) {
    const [options, setOptions] = useState([])
    const [masters, setMasters] = useState({})
    const [input, setInput] = useState('')
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [selectedMasters, setSelectedMasters] = useState([])
    const forceUpdate = useForceUpdate()

    const dropdownValue = []
    for (const master of selectedMasters) {
        if (master && master.masterId) {
            dropdownValue.push(master.masterId)
        }
    }

    const handleAddition = (e, { value }) => {
        setInput(value)
        setEditing(true)
    }

    let dirtySelection = false
    for (let i = 0; i < defaultSelectedMasters.length; i++) {
        if (JSON.stringify(defaultSelectedMasters[i]) !== JSON.stringify(selectedMasters[i])) {
            dirtySelection = true
        }
    }

    useEffect(() => {
        setSelectedMasters(defaultSelectedMasters)
    }, [dirtySelection])

    useEffect(() => {
        network.getMasterList().then((data) => {
            const masterlist = data.data
            const masterobj = {}

            let masterOptions = []
            for (let i in masterlist) {
                let master = masterlist[i]
                masterobj[master.masterId] = master
                masterOptions.push({
                    key: `master_${master.masterId}`,
                    text: `${master.masterId} - ${master.masterName}`,
                    value: master.masterId,
                })
            }

            setMasters(masterobj)
            setOptions(masterOptions)
            setDirty(false)
        })
    }, [dirty])

    const extraProps = {}
    if (!onlySelection) {
        extraProps.allowAdditions = true
        extraProps.onAddItem = handleAddition
    }

    return (
        <>
            <Dropdown
                onChange={(ev, data) => {
                    if (onChange) {
                        const selectedList = []
                        for (const key of data.value) {
                            selectedList.push(masters[key])
                        }

                        setSelectedMasters(selectedList)
                        onChange(ev, data, data.value, selectedList)
                    }
                }}
                placeholder="Master"
                fluid
                multiple
                search
                selection
                options={options}
                value={dropdownValue}
                {...extraProps}
            />
            {!onlySelection && (
                <>
                    {selectedMasters.map((value, index) => {
                        if (!value) {
                            return null
                        }
                        return (
                            <Form.Group widths="equal" key={`master-dropdown-${value.masterId}`}>
                                <Form.Field
                                    id={`form-input-control-master-percent-${index}`}
                                    control={Input}
                                    label={`Percentuale master ${value.masterName}`}
                                    name="masterPercent"
                                    type="number"
                                    step="0"
                                    value={value.masterPercent}
                                    onChange={(e) => {
                                        const toEdit = selectedMasters[index]
                                        toEdit.masterPercent = e.target.value
                                        forceUpdate()
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                        )
                    })}
                </>
            )}

            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi master</Modal.Header>
                <Modal.Content>
                    <MasterEditor
                        defaultDescription={input}
                        onSave={(master) => {
                            setEditing(false)
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default withRouter(connectNetwork(MasterDropdown))
