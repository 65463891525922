import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import Iframe from 'react-iframe'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Label, Popup, Rating, Table, Transition } from 'semantic-ui-react'
import { Confirmation, ImageDefault } from '../components'
import { Default, Mobile, timeString } from '../lib/util'

const { app, organizations, organizationEdit } = routes

class Organization extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, organization } = props

        this.state = {
            deleting: false,
            ...organization,
            organizationMinutes: organization ? parseFloat(organization.organizationMinutes) : 0,
        }

        if (onCostChange && organization) {
            onCostChange(this.totalCost(), organization.organizationId)
        }
    }

    totalCost() {
        let { organizationHourlyRate, organizationHours, organizationMinutes } = this.state
        let total = (organizationHours + organizationMinutes / 60) * parseFloat(organizationHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { organization, organizationId, network } = this.props

        if ((!organization || !organization.organizationId) && organizationId) {
            network.getOrganization(organizationId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getOrganization, login } = this.props
        let { organizationId, organizationHourlyRate } = this.state
        if (organizationHourlyRate !== prevState.organizationHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), organizationId)
            }
        }

        // console.log('Organization getOrganization fetching', getOrganization.fetching)
        if (getOrganization && getOrganization.fetching !== prevProps.getOrganization.fetching && !getOrganization.fetching) {
            // console.log('Done calling getOrganization')
            if (getOrganization.status.success) {
                // console.log(getOrganization.data)
                let organization = getOrganization.data.data

                this.setState({ ...organization })
                // console.log('Calling Get organization', login, organization)
                return
            } else {
                // console.log('calling No organization found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Cell 1</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Cell 2:
                </Table.Cell>
                <Table.Cell width="4">Cell 3</Table.Cell>
                <Table.Cell width="1">Cell 4:</Table.Cell>
                <Table.Cell width="2">Cell 5</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Field 1</Table.HeaderCell>
                    <Table.HeaderCell width="4">Field 2</Table.HeaderCell>
                    <Table.HeaderCell width="4">Field 3</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { organizationName, organizationHourlyRate, organizationHours, organizationMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{organizationName}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={organizationHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    organizationHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{organizationHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(organizationHours, organizationMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideOrganization, hideOrganizationHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideOrganization && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideOrganizationHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideOrganization, hideOrganizationHours, isBalance } = this.props
        let { organizationName, organizationHours, organizationMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideOrganization && <Table.Cell>{organizationName}</Table.Cell>}
                    {!hideOrganizationHours && <Table.Cell>{timeString(organizationHours, organizationMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, organization } = this.props

        network.deleteOrganization(organization)

        this.setState({ deleting: false })
    }

    renderCardContent(direction) {
        let { type } = this.props
        let {
            organizationName,
            organizationImage,
            organizationDescription,
            organizationAddress,
            organizationRating,
        } = this.state

        // organizationName = 'Paricop'
        // organizationImage = 'https://www.paricop.com/media/wysiwyg/porto/homepage/slider/clienti/anc001.jpg'
        // organizationDescription =
        // 	'La Paricop srl è un’azienda giovane e dinamica, che opera in un contesto territoriale dove la vocazione artigiana vanta una tradizione più che centenaria.'
        // organizationAddress = 'Via Giuseppe di Vittorio, 1, 60024, Filottrano (AN), Italy'
        // organizationRating = 4
        // organizationCertified = 1

        // console.log('RENDER Organization list is', organizationName, organizationRating)

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }

        // console.log('Extra view props:', direction, extraViewProps)

        return (
            <View fullw {...extraViewProps}>
                <div style={styles.imageContainer}>
                    <ImageDefault fromRoot src={organizationImage} ui={false} style={styles.organizationImage} />
                </div>
                <View fullw column>
                    <span style={styles.header}>
                        {organizationName} <Rating icon="star" rating={organizationRating} maxRating={5} />
                    </span>
                    <Box textStyle="description" style={{ maxWidth: '80%' }}>{organizationDescription}</Box>
                    <Box textStyle="meta">{organizationAddress}</Box>
                </View>
            </View>
        )
    }

    TableExampleStructured = () => {
        let { organizationId } = this.state
        return {
            paricop: (
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan="2">Prodotto</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">Custom</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">Capacità</Table.HeaderCell>
                            <Table.HeaderCell colSpan="3">Materiali</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Pelle</Table.HeaderCell>
                            <Table.HeaderCell>Gomma</Table.HeaderCell>
                            <Table.HeaderCell>Sintetici</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Cinturini</Table.Cell>
                            <Table.Cell>--</Table.Cell>
                            <Table.Cell textAlign="right">100/giorno</Table.Cell>

                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell />
                            <Table.Cell />
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan="3">Penne</Table.Cell>
                            <Table.Cell>Carabinieri</Table.Cell>
                            <Table.Cell textAlign="right">52/giorno</Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell />
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Esercito</Table.Cell>
                            <Table.Cell textAlign="right">12/giorno</Table.Cell>
                            <Table.Cell />
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell />
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Polizia</Table.Cell>
                            <Table.Cell textAlign="right">21/giorno</Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell />
                            <Table.Cell />
                        </Table.Row>
                    </Table.Body>
                </Table>
            ),
            syncodedemo: (
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell rowSpan="2">Prodotto</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">Custom</Table.HeaderCell>
                            <Table.HeaderCell rowSpan="2">Contratto</Table.HeaderCell>
                            <Table.HeaderCell colSpan="3">Features</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Cloud</Table.HeaderCell>
                            <Table.HeaderCell>Realtime</Table.HeaderCell>
                            <Table.HeaderCell>Analisi</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Monitoraggio</Table.Cell>
                            <Table.Cell>Si</Table.Cell>
                            <Table.Cell>Abbonamento</Table.Cell>

                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan="2">Network</Table.Cell>
                            <Table.Cell>No</Table.Cell>
                            <Table.Cell>Gratis</Table.Cell>

                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell />
                            <Table.Cell />
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Si</Table.Cell>
                            <Table.Cell>Abbonamento</Table.Cell>

                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon color="green" name="checkmark" size="large" />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            ),
        }[organizationId]
    }

    TableExamplePadded = () => {
        let { organizationId } = this.state
        return {
            paricop: (
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell singleLine>Valutazione</Table.HeaderCell>
                            <Table.HeaderCell>Tipologia</Table.HeaderCell>
                            <Table.HeaderCell>Rating</Table.HeaderCell>
                            <Table.HeaderCell>Percentuale</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <Box textStyle="header" as="h2" textAlign="center">
                                    A+
                                </Box>
                            </Table.Cell>
                            <Table.Cell singleLine>Qualità</Table.Cell>
                            <Table.Cell>
                                <Rating icon="star" defaultRating={3} maxRating={3} />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                80% <br />
                                <a href="http://127.0.0.1/#">18 recensioni</a>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <Box textStyle="header" as="h2" textAlign="center">
                                    A
                                </Box>
                            </Table.Cell>
                            <Table.Cell singleLine>Velocità</Table.Cell>
                            <Table.Cell>
                                <Rating icon="star" defaultRating={3} maxRating={3} />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                100% <br />
                                <a href="http://127.0.0.1/#">65 recensioni</a>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            ),
            syncodedemo: (
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell singleLine>Valutazione</Table.HeaderCell>
                            <Table.HeaderCell>Tipologia</Table.HeaderCell>
                            <Table.HeaderCell>Rating</Table.HeaderCell>
                            <Table.HeaderCell>Percentuale</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <Box textStyle="header" as="h2" textAlign="center">
                                    A+
                                </Box>
                            </Table.Cell>
                            <Table.Cell singleLine>Qualità</Table.Cell>
                            <Table.Cell>
                                <Rating icon="star" defaultRating={3} maxRating={3} />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                100% <br />
                                <a href="http://127.0.0.1/#">78 recensioni</a>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <Box textStyle="header" as="h2" textAlign="center">
                                    A+
                                </Box>
                            </Table.Cell>
                            <Table.Cell singleLine>Velocità</Table.Cell>
                            <Table.Cell>
                                <Rating icon="star" defaultRating={3} maxRating={3} />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                100% <br />
                                <a href="http://127.0.0.1/#">180 recensioni</a>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            ),
        }[organizationId]
    }
    render() {
        let { login, type, organization, onClick, selected, noActions, forcedOpen } = this.props
        let {
            deleting,
            organizationId,
            organizationCertified,
            organizationMail,
            organizationPhone,
        } = this.state

        organizationCertified = 1

        let mapSrc = {
            syncodedemo: 'https://www.google.com/maps/d/embed?mid=1QRwEeAMTOyzeamnysrf6xCtQSkK3P6Xa',
            paricop:
                'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2896.9222260245106!2d13.34648471582085!3d43.44134027912906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132d8fdd5199d499%3A0x5ab15eb69efb526f!2sParicop%20Srl!5e0!3m2!1sen!2sit!4v1575319703546!5m2!1sen!2sit',
        }

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(organization)
            }
        }
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View noflex fullw column between>
                        <Box
                            className={selected ? 'rowSelected' : ''}
                            style={styles.organizationCard}
                            {...extraprops}
                            
                        >
                            <Confirmation
                                message="Vuoi davvero eliminare questa Organizzazione?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.organizationContent}>
                                <Default>{this.renderCardContent()}</Default>
                                <Mobile>{this.renderCardContent('column')}</Mobile>
                            </Box>
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    <View between wrap>
                                        <View>
                                            {!forcedOpen && (
                                                <Button
                                                    onClick={() => {
                                                        this.props.history.push(route_from(app, organizations, `${organizationId}`))
                                                    }}
                                                >
                                                    <Icon name="folder open" />
                                                    Dettagli
                                                </Button>
                                            )}
                                            {user && !!user.manage_organizations && (
                                                <Button
                                                    color="blue"
                                                    onClick={() => {
                                                        this.props.history.push(route_from(app, organizations, organizationEdit, `${organizationId}`))
                                                    }}
                                                >
                                                    <Icon name="edit" />
                                                    Modifica
                                                </Button>
                                            )}

                                            {user && !!user.manage_organizations && (
                                                <Button
                                                    color="red"
                                                    onClick={() => {
                                                        this.openRemoveDialog()
                                                    }}
                                                >
                                                    <Icon name="delete" />
                                                    Elimina
                                                </Button>
                                            )}
                                            <Popup
                                                content={organizationMail}
                                                on="hover"
                                                mouseLeaveDelay={2000}
                                                trigger={
                                                    <Button
                                                        color="orange"
                                                        onClick={() => {
                                                            window.location.href = `mailto:${organizationMail}`
                                                        }}
                                                    >
                                                        <Icon name="send" />
                                                        Mail
                                                    </Button>
                                                }
                                            />
                                            <Popup
                                                content={organizationPhone}
                                                on="hover"
                                                mouseLeaveDelay={2000}
                                                trigger={
                                                    <Button
                                                        color="teal"
                                                        onClick={() => {
                                                            window.location.href = `tel::${organizationPhone}`
                                                        }}
                                                    >
                                                        <Icon name="phone" />
                                                        Telefono
                                                    </Button>
                                                }
                                            />
                                        </View>
                                        <View noflex fullh>
                                            <Label color={organizationCertified !== 0 ? 'green' : 'red'} tag>
                                                {organizationCertified !== 0 ? 'CERTIFICATA' : 'NON CERTIFICATA'}
                                            </Label>
                                        </View>
                                    </View>
                                </Box>
                            )}
                            {type === 'full' && forcedOpen && (
                                <Transition visible={forcedOpen ? true : this.state.showDetails} animation="slide down" duration={200}>
                                    <Box textStyle="content">
                                        <View fullw column>
                                            <span style={styles.header}>Come viene valutata</span>
                                            {this.TableExamplePadded()}
                                            <span style={styles.header}>Cosa offre</span>

                                            {this.TableExampleStructured()}
                                            <span style={styles.header}>Dove trovarla</span>

                                            <Iframe src={mapSrc[organizationId]} width="100%" height="450" frameBorder="0" style={{ border: 0 }} />
                                        </View>
                                    </Box>
                                </Transition>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    organizationImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    organizationContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    organizationCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getOrganization } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getOrganization, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Organization)))
