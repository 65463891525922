export function deleteInterval(data) {
    return new Promise((resolve, reject) => {
		let { deleteIntervalRequest, deleteIntervalError, deleteIntervalResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_interval',
			this.createBundle(
				'delete_interval',
				{ interval: data },
				this.POST,
				deleteIntervalRequest,
                (result) => {
                    deleteIntervalResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteIntervalError(error)
                    reject(error)
                }
			)
		);
	})
}
