export function deleteMold(data) {
    return new Promise((resolve, reject) => {
		let { deleteMoldRequest, deleteMoldError, deleteMoldResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_mold',
			this.createBundle(
				'delete_mold',
				{ mold: data },
				this.POST,
				deleteMoldRequest,
                (result) => {
                    deleteMoldResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteMoldError(error)
                    reject(error)
                }
			)
		);
	})
}
