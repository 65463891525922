export function deleteClientOrder(data) {
    let { deleteClientOrderRequest, deleteClientOrderError, deleteClientOrderResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'delete_client_order',
        this.createBundle(
            'delete_client_order',
            { client_order: data },
            this.POST,
            deleteClientOrderRequest,
            deleteClientOrderResult,
            deleteClientOrderError
        )
    )
}
