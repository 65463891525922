export function getClientOrderList(isEmployee, inverse, search) {
    let { getClientOrderListRequest, getClientOrderListError, getClientOrderListResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_client_order_list',
        this.createBundle(
            'get_client_order_list',
            { inverse, search: search ? search : '' },
            this.GET,
            getClientOrderListRequest,
            getClientOrderListResult,
            getClientOrderListError,
            isEmployee
        )
    )
}
