export function createDataImport(data) {
	let { createDataImportRequest, createDataImportError, createDataImportResult } = this.props;

	delete data.creationDate;
	delete data.updateDate;

	this.doAction(
		this.WEB_REQUEST_URL + 'create_dataimport',
		this.createBundle(
			'create_dataimport',
			{ fileToImport: data },
			this.POST,
			createDataImportRequest,
			createDataImportResult,
			createDataImportError
		)
	);
}
