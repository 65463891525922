export function getMappingData(isEmployee) {
    return new Promise((resolve, reject) => {
        let { getMappingDataRequest, getMappingDataError, getMappingDataResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_mapping_list',
            this.createBundle(
                'get_mapping_list',
                {},
                this.GET,
                getMappingDataRequest,
                (result) => {
                    getMappingDataResult(result)
                    resolve(result)
                },
                (error) => {
                    getMappingDataError(error)
                    reject(error)
                },
                !!isEmployee
            )
        )
    })
}
