export function createBadgeScan(data) {
    return new Promise((resolve, reject) => {
		let { createBadgeScanRequest, createBadgeScanError, createBadgeScanResult } = this.props;

        delete data.creationDate;
        delete data.updateDate;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_badgescan',
			this.createBundle(
				'create_badgescan',
				{ badgescan: data },
				this.POST,
				createBadgeScanRequest,
                (result) => {
                    createBadgeScanResult(result)
                    resolve(result)
                },
                (error) => {
                    createBadgeScanError(error)
                    reject(error)
                }
			)
		);
	})
}
