export function createOrganization(data) {
    let { createOrganizationRequest, createOrganizationError, createOrganizationResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_organization',
        this.createBundle(
            'create_organization',
            { organization: data },
            this.GET,
            createOrganizationRequest,
            createOrganizationResult,
            createOrganizationError
        )
    )
}
