import { GETFROMIDSUBIDFILTERING_TYPES } from '../actions/getFromIdSubidFiltering'
import { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    switch (action.type) {
        case GETFROMIDSUBIDFILTERING_TYPES.request: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                ...state,
                data: [],
                fetching: true,
                request_data: result,
            }
        }
        case GETFROMIDSUBIDFILTERING_TYPES.result: {
            let { result } = action
            return {
                ...state,
                fetching: false,
                status: {
                    success: true,
                    error: undefined,
                },
                data: result,
            }
        }
        case GETFROMIDSUBIDFILTERING_TYPES.error: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                ...state,
                fetching: false,
                status: {
                    success: false,
                    error: result,
                },
                data: [],
            }
        }
        default:
            return state
    }
}
export default Reducer
