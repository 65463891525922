export function createDeposit(data) {
    let { createDepositRequest, createDepositError, createDepositResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'app/deposit/save',
        this.createBundle('app/deposit/save', { deposit: data }, this.POST, createDepositRequest, createDepositResult, createDepositError)
    )
}
