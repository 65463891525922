export function deleteSchedule(data) {
    return new Promise((resolve, reject) => {
		let { deleteScheduleRequest, deleteScheduleError, deleteScheduleResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_schedule',
			this.createBundle(
				'delete_schedule',
				{ schedule: data },
				this.POST,
				deleteScheduleRequest,
                (result) => {
                    deleteScheduleResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteScheduleError(error)
                    reject(error)
                }
			)
		);
	})
}
