export function createCollection(data, callback) {
	let { createCollectionRequest, createCollectionError, createCollectionResult } = this.props;

	delete data.creationDate;
	delete data.updateDate;

	this.doAction(
		this.WEB_REQUEST_URL + 'create_collection',
		this.createBundle(
			'create_collection',
			{ collection: data },
			this.POST,
			createCollectionRequest,
			(result) => {
				createCollectionResult(result)
				callback(result)
			},
			createCollectionError
		)
	);
}
