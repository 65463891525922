import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { error, hide, info, removeAll, show, success, warning } from 'react-notification-system-redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, TextArea } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickMultipleImage } from '../components'
import { createAlert } from '../lib/util'

class CollectionEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { collection } = this.props.match.params

        if (!id) {
            id = collection
        }

        // console.log('CollectionEditor params match', this.props.match, id)

        this.state = {
            id,
            collectionImages: [],
        }
    }

    componentDidMount() {
        let { id } = this.state
        // console.log('CollectionEditor id is', id)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (id) {
            this.props.network.getCollection(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('CollectionEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.props.success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.props.error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getCollection } = this.props
        if (getCollection && getCollection.fetching !== prevProps.getCollection.fetching && !getCollection.fetching) {
            if (getCollection.status.success) {
                let collection = getCollection.data.data
                // console.log('Setting state with collection:', collection)
                let { id, ...rest } = collection
                this.setState(rest)
            }
        }
    }

    save() {
        // TODO value checking
        let { collectionId, collectionName, collectionCode, collectionDescription, collectionOrdering, collectionImages } = this.state

        this.props.network.createCollection({
            collectionId,
            collectionName,
            collectionCode,
            collectionDescription,
            collectionOrdering,
            collectionImages,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { creating, collectionId, collectionName, collectionCode, collectionDescription, collectionOrdering, collectionImages } = this.state

        let { getCollection } = this.props

        // console.log('JobEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getCollection.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-collection-name"
                            control={Input}
                            label={<span className="label">Nome collezione</span>}
                            name="collectionName"
                            value={collectionName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-collection-code"
                            control={Input}
                            label={<span className="label">Codice collezione</span>}
                            name="collectionCode"
                            value={collectionCode}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-collection-name"
                            control={TextArea}
                            label={<span className="label">Descrizione</span>}
                            name="collectionDescription"
                            value={collectionDescription}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <PickMultipleImage
                        onChange={(images) => {
                            this.setState({ collectionImages: images })
                        }}
                        value={collectionImages}
                    />
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form completato con successo" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        createCollection: { data: createResult, fetching: creating, status: createStatus },
        getCollection,
    } = state

    return {
        createResult,
        creating,
        getCollection,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        show: (notif) => dispatch(show(notif)),
        success: (notif) => dispatch(success(notif)),
        error: (notif) => dispatch(error(notif)),
        warning: (notif) => dispatch(warning(notif)),
        info: (notif) => dispatch(info(notif)),
        hide: (notif) => dispatch(hide(notif)),
        removeAll: (notif) => dispatch(removeAll(notif)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CollectionEditor)))
