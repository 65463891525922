export function getKit(data) {
    return new Promise((resolve, reject) => {
        let { getKitRequest, getKitError, getKitResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_kit',
            this.createBundle(
                'get_kit',
                { kitId: data },
                this.GET,
                getKitRequest,
                (result) => {
                    getKitResult(result)
                    resolve(result)
                },
                (error) => {
                    getKitError(error)
                    reject(error)
                }
            )
        )
    })
}
