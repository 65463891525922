import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dimmer, Form, Input, Loader, Message, Placeholder, TextArea } from 'semantic-ui-react'
import { baseViewDispatch, createAlert } from '../lib/util'
function PackageEditor({
    network,
    packageId,
    onSave,
    onCancel,
    defaultName,
    defaultDescription,
    error,
    hide,
    info,
    removeAll,
    show,
    success,
    warning,
}) {
    const [pack, setPackage] = useState(null)
    const [status, setStatus] = useState(null)
    const [dirty, setDirty] = useState(false)

    useEffect(() => {
        if (packageId) {
            network.getPackage(packageId).then((data) => {
                // console.log('Got package', data.data)
                setPackage(data.data)
            })
        } else {
            setPackage({
                packageName: defaultName ? defaultName : 'Nome',
                packageType: 0,
                packageParent: null,
                packagePrice: 1,
                packageCode: '',
                packageDescription: defaultDescription ? defaultDescription : '',
            })
        }
    }, [packageId])

    const handleInput = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setPackage({
            ...pack,
            [name]: value,
        })
    }

    const save = () => {
        network
            .createPackage(pack)
            .then((data) => {
                // console.log('Got package', data.data)
                setPackage(data.data)
            })
            .then((result) => {
                success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                setStatus('success')
                if (onSave) {
                    onSave(result)
                }
            })
            .catch((error) => {
                error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                setStatus('error')
            })
    }

    if (!pack) {
        return (
            <Box p={6}>
                <Dimmer active>
                    <Loader />
                </Dimmer>

                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Paragraph>
                </Placeholder>
            </Box>
        )
    }

    const { packageCode, packageName, packageDescription, packagePrice, packageType, packageParent } = pack

    return (
        <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Form success={status === 'success'} error={status === 'error'} warning={status === 'warning'}>
                <Box p={6}>
                    <Box textStyle="header" as="h1">
                        Anagrafica confezione
                    </Box>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-package-code"
                            control={Input}
                            label={<span className="label">Nome confezione</span>}
                            name="packageName"
                            value={packageName}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    {/* <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-package-name"
                            control={TextArea}
                            label={<span className="label">Descrizione confezione</span>}
                            name="packageDescription"
                            value={packageDescription}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group> */}
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-package-code"
                            control={Input}
                            label={<span className="label">Codice confezione (opzionale)</span>}
                            name="packageCode"
                            value={packageCode}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-package-price"
                            control={Input}
                            label={<span className="label">Prezzo</span>}
                            name="packagePrice"
                            value={packagePrice}
                            type="number"
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                            step="0.01"
                        />
                    </Form.Group>
                </Box>

                <Form.Group>
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            save()
                        }}
                        color="green"
                    />
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-cancel"
                        control={Button}
                        content="Annulla"
                        onClick={() => {
                            if (onCancel) {
                                onCancel()
                            }
                        }}
                        color="red"
                    />
                </Form.Group>
                <Message success header="Form completato con successo" content="Imballo inserito con successo!" />
                <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
            </Form>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { login } = state

    return {
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(PackageEditor)))
