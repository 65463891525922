export function deleteBadge(data) {
    return new Promise((resolve, reject) => {
		let { deleteBadgeRequest, deleteBadgeError, deleteBadgeResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_badge',
			this.createBundle(
				'delete_badge',
				{ badge: data },
				this.POST,
				deleteBadgeRequest,
                (result) => {
                    deleteBadgeResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteBadgeError(error)
                    reject(error)
                }
			)
		);
	})
}
