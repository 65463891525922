import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Modal } from 'semantic-ui-react'
import { Confirmation } from '../components'
import { Default, Mobile } from '../lib/util'

const { app, admin, userEdit, login: loginRoute } = routes

class User extends Component {
    constructor(props) {
        super(props)
        let { user } = props

        this.state = {
            pwModal: false,
            ...user,
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, user } = this.props

        network.deleteUser(user)

        this.setState({ deleting: false })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { changePassword, network, history } = this.props

        if (prevProps.changePassword.fetching !== changePassword.fetching && !changePassword.fetching) {
            // Done changing password
            network.logout()
            history.replace(route_from(loginRoute))
        }
    }

    passwordModal() {
        let { email, oldpassword, newpassword, confirmpassword, pwModal, fullname } = this.state
        return (
            <Modal open={pwModal}>
                <Modal.Header>Modifica password di {fullname}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field
                            id="form-input-control-user-old-password"
                            control={Input}
                            label={<span className="label">Vecchia password</span>}
                            placeholder=""
                            name="oldpassword"
                            value={oldpassword}
                            type="password"
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-user-new-password"
                            control={Input}
                            label={<span className="label">Nuova password</span>}
                            placeholder=""
                            name="newpassword"
                            value={newpassword}
                            type="password"
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-user-confirm-password"
                            control={Input}
                            label={<span className="label">Conferma nuova password</span>}
                            placeholder=""
                            name="confirmpassword"
                            value={confirmpassword}
                            type="password"
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        onClick={() => {
                            this.setState({ pwModal: false })
                        }}
                    >
                        Annulla <Icon name="check" />
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            let { network } = this.props
                            if (confirmpassword === newpassword) {
                                network.changePassword(email, oldpassword, newpassword)
                            } else {
                                // Error message
                                console.error("Passwords don't match")
                            }
                        }}
                    >
                        Conferma <Icon name="check" />
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    renderCardContent(direction) {
        let { type } = this.props
        let { fullname, email } = this.state

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }

        return (
            <View fullw row {...extraViewProps}>
                {/* <div style={styles.imageContainer}>
										<ImageDefault fromRoot src={userImage} ui={false} style={styles.jobImage} />
									</div> */}
                <View column style={{ maxWidth: '100%' }}>
                    <Default>
                        <span style={styles.header}>{fullname}</span>
                    </Default>
                    <Mobile>
                        <span style={styles.smallHeader}>{fullname}</span>
                    </Mobile>
                    <Box textStyle="description">{email}</Box>
                </View>
            </View>
        )
    }

    render() {
        let { login, type } = this.props
        let { deleting, email } = this.state
        let extraprops = {
            fluid: type === 'full' ? true : false,
        }
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        // console.log('User user is ', user)

        switch (type) {
            default:
                return (
                    <View noflex wrap fullw column between>
                        <Box style={styles.jobCard} {...extraprops} borderWidth="1px" borderRadius="lg"
                    p="4" >
                            <Confirmation
                                message="Vuoi davvero eliminare questo utente?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            {this.passwordModal()}
                            <Box textStyle="content" style={styles.jobContent}>
                                <Default>{this.renderCardContent()}</Default>
                                <Mobile>{this.renderCardContent('column')}</Mobile>
                            </Box>
                            <Box textStyle="content" extra>
                                {user && !!user.manage_user && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, admin, userEdit, `${email}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        <Default>Modifica</Default>
                                    </Button>
                                )}
                                {user && !!user.reset_other_password && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.setState({ pwModal: true })
                                        }}
                                    >
                                        <Icon name="edit" />
                                        <Default>Cambia password</Default>
                                    </Button>
                                )}
                                {user && !!user.manage_user && (
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.openRemoveDialog()
                                        }}
                                    >
                                        <Icon name="delete" />
                                        <Default>Elimina</Default>
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    jobImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, changePassword } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { login, role: rcode, changePassword }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(User)))
