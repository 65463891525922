export function getBadgeList(data) {
    return new Promise((resolve, reject) => {
		let { getBadgeListRequest, getBadgeListError, getBadgeListResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_badge_list',
			this.createBundle(
				'get_badge_list',
				{ badge: data },
				this.GET,
				getBadgeListRequest,
                (result) => {
                    getBadgeListResult(result)
                    resolve(result)
                },
                (error) => {
                    getBadgeListError(error)
                    reject(error)
                }
			)
		);
	})
}
