export function getCommandList(data) {
	let { getCommandListRequest, getCommandListError, getCommandListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_command_list',
		this.createBundle(
			'get_command_list',
			{ command: data },
			this.GET,
			getCommandListRequest,
			getCommandListResult,
			getCommandListError
		)
	);
}
