export function getTaskList(job) {
    return new Promise((resolve, reject) => {
        let { getTaskListRequest, getTaskListError, getTaskListResult } = this.props

        if (job) {
            this.doAction(
                this.WEB_REQUEST_URL + 'get_task_list',
                this.createBundle(
                    'get_task_list',
                    { job },
                    this.GET,
                    getTaskListRequest,
                    (result) => {
                        getTaskListResult(result)
                        resolve(result)
                    },
                    (error) => {
                        getTaskListError(error)
                        resolve(error)
                    }
                )
            )
        } else {
            this.doAction(
                this.WEB_REQUEST_URL + 'get_task_job_list',
                this.createBundle(
                    'get_task_job_list',
                    {},
                    this.GET,
                    getTaskListRequest,
                    (result) => {
                        getTaskListResult(result)
                        resolve(result)
                    },
                    (error) => {
                        getTaskListError(error)
                        resolve(error)
                    }
                )
            )
        }
    })
}
