export function getInterval(data) {
    return new Promise((resolve, reject) => {
		let { getIntervalRequest, getIntervalError, getIntervalResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_interval',
			this.createBundle(
				'get_interval',
				{ interval: data },
				this.GET,
				getIntervalRequest,
                (result) => {
                    getIntervalResult(result)
                    resolve(result)
                },
                (error) => {
                    getIntervalError(error)
                    reject(error)
                }
			)
		);
	})
}
