import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Label, Modal, Placeholder, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Document, DocumentRecap } from '../components'

class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            wait: true,
            exportModal: false,
            deposit: -1,
            start: moment().subtract(1, 'month').toDate(),
            end: moment().toDate(),
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteDocument, deposit, start, end } = this.state
        let { organization } = this.props
        if (
            deposit !== prevState.deposit ||
            start !== prevState.start ||
            end !== prevState.end ||
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteDocument.fetching !== prevState.deleteDocument.fetching && !deleteDocument.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            this.state.network.getDocumentList(
                this.state.search.data,
                deposit,
                moment(start).format('YYYY-MM-DD HH:mm:ss'),
                moment(end).format('YYYY-MM-DD HH:mm:ss')
            )
        } else if (this.state.search !== prevState.search) {
            this.state.network.getDocumentList(
                this.state.search.data,
                deposit,
                moment(start).format('YYYY-MM-DD HH:mm:ss'),
                moment(end).format('YYYY-MM-DD HH:mm:ss')
            )
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { deposit, sub, network } = this.props
        let { start, end } = this.state

        if (!sub) {
            this.props.toolbarResult([
                // {
                // 	name: 'Crea nuovo documento',
                // 	icon: 'add',
                // 	disabled: () => true,
                // 	action: () => {
                // 		this.props.history.push(route_from(app, documents, documentEdit))
                // 	}
                // },
                {
                    name: 'Esporta documenti',
                    icon: 'download',
                    position: 'right',
                    action: () => {
                        this.setState({ exportModal: true })
                    },
                },
                // {
                // 	name: '',
                // 	icon: 'arrow left',
                // 	position: 'right',
                // 	action: () => {
                // 		this.prev()
                // 	}
                // },
                // {
                // 	name: '',
                // 	icon: 'arrow right',
                // 	position: 'none',
                // 	action: () => {
                // 		this.next()
                // 	}
                // }
            ])
        }

        network.getDepositList()
        network.getDocumentList(
            this.state.search.data,
            deposit,
            moment(start).format('YYYY-MM-DD HH:mm:ss'),
            moment(end).format('YYYY-MM-DD HH:mm:ss')
        )
    }

    renderDocument(key, document) {
        let { selected } = this.state
        let { onChange, selectable, noActions, showNoDocument } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (document) => {
                if (onChange) {
                    onChange(document)
                }
                this.setState({ selected: document })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoDocument) {
            extraProps.showNoDocument = true
        }

        return <Document {...extraProps} selected={document.id === selected.id} type="full" key={`document_${key}`} document={document} />
    }

    render() {
        let { documents, fetching, deposit, depositList, start, end } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        let options = []

        if (depositList) {
            for (let d of depositList) {
                options.push({ key: d.id, value: d.id, text: d.name })
            }
        }

        options.push({ key: -1, value: -1, text: 'Tutti' })

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (documents.data) {
            content = Object.keys(documents.data).map((key) => {
                let value = documents.data[key]
                return this.renderDocument(key, value)
            })
        }

        return [
            <View noflex around row fullw key="stockHeader" style={{ padding: 10 }}>
                <Form>
                    <Form.Group inline widths="equal">
                        <Form.Field
                            id="form-input-control-deposit"
                            control={Select}
                            options={options}
                            label={<span className="label">Deposito</span>}
                            placeholder="Deposito"
                            name="deposit"
                            value={deposit}
                            onChange={(e, data) => {
                                let dep = data.value
                                // console.log('Deposit selected', dep)
                                this.setState({ deposit: dep })
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <View around>
                            <View>
                                <Label>Inizio:</Label>
                                <DatePicker
                                    style={{ width: '100%', textAlign: 'center' }}
                                    onChange={(data) => {
                                        this.setState({ start: data })
                                    }}
                                    selected={start}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </View>
                            <View>
                                <Label>Fine:</Label>
                                <DatePicker
                                    style={{ width: '100%', textAlign: 'center' }}
                                    onChange={(data) => {
                                        this.setState({ end: data })
                                    }}
                                    selected={end}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </View>
                        </View>
                    </Form.Group>
                </Form>
            </View>,
            <View key={`document-page-content`} fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
                {this.renderExportModal()}
            </View>,
        ]
    }

    onDateChange(date) {
        // console.log('On date change:', date)
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    renderExportModal() {
        let { exportModal, wait, deposit, start, end } = this.state

        return (
            <Modal open={exportModal}>
                <Modal.Header>Esportazione documenti</Modal.Header>
                <Modal.Content>
                    {deposit !== -1
                        ? `Esportazione documenti per il periodo dal ${moment(start).format('DD/MM/YYYY')} al ${moment(end).format('DD/MM/YYYY')}`
                        : `Esportazione articoli da tutti i depositi`}
                    Premi il pulsante calcola per avviare il processo, poi scarica quando e{"'"} tutto pronto
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: true, exportModal: false })
                        }}
                    >
                        Annulla <Icon name="cancel" />
                    </Button>
                    <Button
                        primary
                        icon
                        onClick={() => {
                            this.setState({ wait: false })
                        }}
                    >
                        Calcola <Icon name="computer" />
                    </Button>
                    <DocumentRecap
                        deposit={deposit}
                        start={start}
                        end={end}
                        wait={wait}
                        onDone={(data) => {
                            // console.log('On done called', data)
                            this.setState({ wait: true })
                        }}
                        element={
                            <Button
                                primary
                                icon
                                onClick={() => {
                                    this.setState({ wait: true, exportModal: false })
                                }}
                            >
                                Scarica <Icon name="download" />
                            </Button>
                        }
                    />
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getDocumentList, getDepositList, deleteDocument, search, organization } = state

    let documentList = getDocumentList

    let documents = documentList.data
    if (documents) {
        for (let index in documents.data) {
            let document = documents.data[index]
            let { id_document_related } = document

            document.relatedDocuments = []
            let related = id_document_related
            const func = (x) => x.id === related
            while (related !== 0) {
                let parent = documents.data.find(func)
                document.relatedDocuments.push(parent)
                let { id_document_related } = parent

                related = id_document_related
            }
        }
    }

    return {
        depositList: getDepositList.data.deposit,
        documents,
        fetching: documentList.fetching,
        deleteDocument,
        search,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Documents)))
