export function createUser(user, id) {
    let { createUserRequest, createUserError, createUserResult } = this.props

    delete user.creationDate;
    delete user.updateDate;

    // console.log('Creating user', user, id)
    this.doAction(
        this.WEB_REQUEST_URL + 'create_user',
        this.createBundle('create_user', { user, id }, this.POST, createUserRequest, createUserResult, createUserError)
    )
}
