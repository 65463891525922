export function getCommand(data) {
	let { getCommandRequest, getCommandError, getCommandResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_command',
		this.createBundle(
			'get_command',
			{ command: data },
			this.GET,
			getCommandRequest,
			getCommandResult,
			getCommandError
		)
	);
}
