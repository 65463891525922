export function getWorkerList(isEmployee) {
    return new Promise((resolve, reject) => {
        let { getWorkerListRequest, getWorkerListError, getWorkerListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_worker_list',
            this.createBundle(
                'get_worker_list',
                {},
                this.GET,
                getWorkerListRequest,
                (result) => {
                    getWorkerListResult(result)
                    resolve(result)
                },
                (error) => {
                    getWorkerListError(error)
                    reject(error)
                },
                !!isEmployee
            )
        )
    })
}
