export function createMold(id, data) {
    return new Promise((resolve, reject) => {
		let { createMoldRequest, createMoldError, createMoldResult } = this.props;

        delete data.creationDate;
        delete data.updateDate;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_mold',
			this.createBundle(
				'create_mold',
				{ mold: data, id },
				this.POST,
				createMoldRequest,
                (result) => {
                    createMoldResult(result)
                    resolve(result)
                },
                (error) => {
                    createMoldError(error)
                    reject(error)
                }
			)
		);
	})
}
