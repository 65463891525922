export function getBadge(data) {
    return new Promise((resolve, reject) => {
		let { getBadgeRequest, getBadgeError, getBadgeResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_badge',
			this.createBundle(
				'get_badge',
				{ badge: data },
				this.GET,
				getBadgeRequest,
                (result) => {
                    getBadgeResult(result)
                    resolve(result)
                },
                (error) => {
                    getBadgeError(error)
                    reject(error)
                }
			)
		);
	})
}
