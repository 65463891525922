import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { Organization } from '../components'

class Organizations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            wait: true,
            exportModal: false,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { page, items } = this.state
        let { deleteOrganization, search, network } = this.props

        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteOrganization.fetching !== prevProps.deleteOrganization.fetching && !deleteOrganization.fetching)
        ) {
            network.getOrganizationList(page, items, search.data)
        } else if (this.state.search !== prevState.search) {
            network.getOrganizationList(page, items, search.data)
        }
    }

    next() {
        let { organization, items } = this.state

        if (!organization.data || organization.data.length < items) {
            return
        }

        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { sub, network, search } = this.props
        let { page, items } = this.state
        if (!sub) {
            this.props.toolbarResult([
                {
                    name: '',
                    icon: 'arrow left',
                    position: 'right',
                    action: () => {
                        this.prev()
                    },
                },
                {
                    name: '',
                    icon: 'arrow right',
                    position: 'none',
                    action: () => {
                        this.next()
                    },
                },
            ])
        }

        network.getOrganizationList(page, items, search.data)
    }

    renderOrganization(key, organization) {
        let { onChange, selectable, noActions, showNoOrganization } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (organization) => {
                if (onChange) {
                    onChange(organization)
                }
                this.setState({ selected: organization })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoOrganization) {
            extraProps.showNoOrganization = true
        }

        return (
            <Organization
                {...extraProps}
                // selected={organization.organizationId === selected.organizationId}
                type="full"
                key={`organization_${key}`}
                organization={organization}
            />
        )
    }

    render() {
        let { fetching, organization, onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (organization.data) {
            content = organization.data.map((value, index) => {
                return this.renderOrganization(index, value)
            })
        }

        return (
            // <View row fullw key="organizationHeader" around>
            // 	<Form>
            // 		<Form.Group widths="equal">
            // 			<Form.Field
            // 				id="form-input-control-deposit"
            // 				control={Select}
            // 				options={options}
            // 				label={<span className="label">Deposito</span>}
            // 				placeholder="Deposito"
            // 				name="deposit"
            // 				value={deposit}
            // 				onChange={(e, data) => {
            // 					let dep = data.value
            // 					console.log('Deposit selected', dep)
            // 					this.setState({ deposit: dep })
            // 				}}
            // 			/>
            // 		</Form.Group>
            // 	</Form>
            // </View>,
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                <Box textStyle="group" style={{ paddingTop: 16 }} centered content={content} />
                {/* {this.renderExportModal()} */}
            </View>
        )
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }
}

const mapStateToProps = (state) => {
    let { getOrganizationList, deleteOrganization, search } = state

    let organizationList = getOrganizationList

    // console.log('Organization List', organizationList)

    return {
        organization: organizationList.data,
        fetching: organizationList.fetching,
        deleteOrganization,
        search,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Organizations)))
