import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'

class CorpEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { corp } = this.props.match.params

        if (!id) {
            id = corp
        }

        // console.log('CorpEditor params match', this.props.match, id)

        this.state = {
            id,
            code: id,
        }
    }

    componentDidMount() {
        let { id } = this.state
        // console.log('CorpEditor id is', id)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (id) {
            this.state.network.getCorp(id)
        }
        this.state.network.getCorpList()
        this.state.network.getCorpList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Maybe need this if it stores state between different edits (it shouldn't)
        // console.log('CorpEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getCorp } = this.props
        if (getCorp && getCorp.fetching !== prevProps.getCorp.fetching && !getCorp.fetching) {
            if (getCorp.status.success) {
                let corp = getCorp.data.data
                // console.log('Setting state with corp:', corp)
                let { id, ...rest } = corp
                this.setState(rest)
            }
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    save() {
        // TODO value checking
        let { id, name, discount } = this.state

        if (name === '') {
            this.setState({
                status: 'error',
            })
            return
        }

        this.props.network.createCorp(id, {
            name,
            discount,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { creating, name, discount, getCorp } = this.state

        // console.log('CorpEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getCorp.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-corp-name"
                            control={Input}
                            label={<span className="label">Nome corpo</span>}
                            name="name"
                            value={name}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />

                        <Form.Field
                            id="form-input-control-corp-cost"
                            control={Input}
                            type="number"
                            label={<span className="label">Sconto in percentuale</span>}
                            placeholder="0"
                            step="1"
                            name="discount"
                            value={discount}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        createCorp: { data: createResult, fetching: creating, status: createStatus },
        getCorp,
    } = state

    return {
        createResult,
        creating,
        getCorp,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CorpEditor)))
