import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input, Modal } from 'semantic-ui-react'
import PackageEditor from '../job/PackageEditor'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function PackageDropdown({ network, onChange, defaultSelectedPackages = [], onlySelection }) {
    const [options, setOptions] = useState([])
    const [packages, setPackages] = useState({})
    const [input, setInput] = useState('')
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [selectedPackages, setSelectedPackages] = useState([])
    const forceUpdate = useForceUpdate()

    const dropdownValue = []
    for (const pack of selectedPackages) {
        if (pack && pack.packageId) {
            dropdownValue.push(pack.packageId)
        }
    }

    const handleAddition = (e, { value }) => {
        setInput(value)
        setEditing(true)
    }

    let dirtySelection = false
    for (let i = 0; i < defaultSelectedPackages.length; i++) {
        if (JSON.stringify(defaultSelectedPackages[i]) !== JSON.stringify(selectedPackages[i])) {
            dirtySelection = true
        }
    }

    useEffect(() => {
        setSelectedPackages(defaultSelectedPackages)
    }, [dirtySelection])

    useEffect(() => {
        network.getPackageList().then((data) => {
            const packagelist = data.data
            const packageobj = {}

            let packageOptions = []
            for (let i in packagelist) {
                let pack = packagelist[i]
                packageobj[pack.packageId] = pack
                packageOptions.push({
                    key: `package_${pack.packageId}`,
                    text: `${pack.packageId} - ${pack.packageName}: ${pack.packageDescription}`,
                    value: pack.packageId,
                })
            }

            setPackages(packageobj)
            setOptions(packageOptions)
            setDirty(false)
        })
    }, [dirty])

    const extraProps = {}
    if (!onlySelection) {
        extraProps.allowAdditions = true
        extraProps.onAddItem = handleAddition
    }

    return (
        <>
            <Dropdown
                onChange={(ev, data) => {
                    if (onChange) {
                        const selectedList = []
                        for (const key of data.value) {
                            selectedList.push(packages[key])
                        }

                        setSelectedPackages(selectedList)
                        onChange(ev, data, data.value, selectedList)
                    }
                }}
                placeholder="Packages"
                fluid
                multiple
                search
                selection
                options={options}
                value={dropdownValue}
                {...extraProps}
            />
            {!onlySelection && (
                <>
                    {selectedPackages.map((value, index) => {
                        if (!value) {
                            return null
                        }
                        return (
                            <Form.Group widths="equal" key={`package-dropdown-${value.packageId}`}>
                                <Form.Field
                                    id={`form-input-control-package-pcs-${index}`}
                                    control={Input}
                                    label={`Pezzi per pacchetto ${value.packageName} (${value.packageDescription})`}
                                    name="masterPercent"
                                    type="number"
                                    step="0"
                                    value={value.pcsPerPackage}
                                    onChange={(e) => {
                                        const toEdit = selectedPackages[index]
                                        toEdit.pcsPerPackage = e.target.value
                                        forceUpdate()
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                        )
                    })}
                </>
            )}

            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi packagee</Modal.Header>
                <Modal.Content>
                    <PackageEditor
                        defaultDescription={input}
                        onSave={(pack) => {
                            setEditing(false)
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default withRouter(connectNetwork(PackageDropdown))
