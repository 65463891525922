import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button } from 'semantic-ui-react'
import { Default } from '../lib/util'
import Sort from '../utility/Sort'

class SortBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activatedSorts: {},
            showSorts: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState({ activatedSorts: {}, showSorts: false })
        }
    }

    render() {
        const { showSorts, activatedSorts } = this.state
        const { sorts } = this.props
        return (
            <View column style={{ position: 'relative' }}>
                {Object.keys(sorts).length > 0 && (
                    <Box h="90px" borderBottomColor="black" borderBottom="1px" mb="2px" style={!showSorts ? { display: 'none' } : {}}>
                        <Default>
                            <View
                                fullw
                                row
                                style={{
                                    height: 74,
                                    marginTop: 0,
                                    paddingLeft: 4,
                                    paddingRight: 4,
                                    overflowY: 'visible',
                                }}
                            >
                                {Object.keys(sorts).map((value, index) => {
                                    const activeKeys = Object.keys(activatedSorts)

                                    if (index < activeKeys.length) {
                                        return (
                                            <Box w="300px" h="74px">
                                                <Sort
                                                    key={index}
                                                    activeSort={activeKeys[index]}
                                                    availableSorts={sorts}
                                                    toExclude={activatedSorts}
                                                    onSortActivated={(key, value) => {
                                                        const { activatedSorts } = this.state

                                                        // console.log('Activated sorts onSortActivated', key, value)
                                                        if (value) {
                                                            activatedSorts[key] = value
                                                        } else {
                                                            delete activatedSorts[key]
                                                        }

                                                        this.setState({
                                                            activatedSorts,
                                                        })
                                                    }}
                                                />
                                            </Box>
                                        )
                                    } else if (index === activeKeys.length) {
                                        return (
                                            <Box w="300px" h="74px">
                                                <Sort
                                                    activeSort={null}
                                                    key={index}
                                                    availableSorts={sorts}
                                                    toExclude={activatedSorts}
                                                    onSortActivated={(key, value) => {
                                                        const { activatedSorts } = this.state

                                                        // console.log('Activated sorts onSortActivated', key, value)
                                                        if (value) {
                                                            activatedSorts[key] = value
                                                        } else {
                                                            delete activatedSorts[key]
                                                        }

                                                        this.setState({
                                                            activatedSorts,
                                                        })
                                                    }}
                                                />
                                            </Box>
                                        )
                                    } else return null
                                })}
                            </View>
                        </Default>
                        {/* <Divider /> */}
                    </Box>
                )}
                <Button
                    basic
                    style={{ zIndex: 999, position: 'absolute', top: 40, left: 0 }}
                    onClick={() => {
                        let { showSorts } = this.state

                        showSorts = !showSorts
                        this.setState({ showSorts })
                    }}
                    icon="sort amount down"
                />
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const mapStateToProps = (state) => {
    let { sorts } = state

    return {
        sorts,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(SortBar)))
