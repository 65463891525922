import { Box, Stack } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/switch'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Form, Input, Message, Select } from 'semantic-ui-react'
import { PickFile, PickImage } from '../components'
import { baseViewDispatch, createAlert } from '../lib/util'
import CustomerDropdown from '../utility/CustomerDropdown'

const { app, jobs } = routes

class JobEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id

        let { job } = this.props.match.params
        if (job) {
            job = job.replaceAll('__slash__', '/')
        }

        if (!id) {
            id = job
        }

        const defaultJobInfo = {
            jobCode: id ? id : '#AUTO#',
            jobName: moment().format('DDMMYY'),
            jobClientCode: '',
            description: '',
            active: 1,
            jobType: 2,
            client: '',
            image: '',
            urgencyLevel: 0,
            createdExternal: 0,
            workOrders: [],
            lastBillDate: new Date(),
            deliveryDate: new Date(),
            orderDate: new Date(),
            billed: 0.0,
            budgeted: 0.0,
        }

        this.state = {
            errorMessage: '',
            addDate: false,
            deliveryWeek: moment().get('week'),
            updating: false,
            id,
            ...defaultJobInfo,
            productCode: false,
            status: 'ok', // warning, error, success
            deliveryDateChanged: false,
            articles: [],
            autoId: id ? false : true,
            jobLocation: '',
            jobMachineType: 0,
        }

        if (props.onChange) {
            props.onChange(defaultJobInfo)
        }
    }

    componentDidMount() {
        let { jobCode } = this.state
        let { sub } = this.props

        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Salva',
                    icon: 'save',
                    position: 'right',
                    action: () => {
                        this.save()
                    },
                },
            ])
        }

        this.state.network.getProductList(null, null, '')
        this.state.network.getJobTypeList()

        if (jobCode && jobCode !== '#AUTO#') {
            this.state.network.getJob(jobCode)
            this.setState({ updating: true })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { success, error } = this.props

        // Maybe need this if it stores state between different edits (it shouldn't)
        if (this.props.creatingJob !== prevProps.creatingJob) {
            if (!this.props.creatingJob) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    success(
                        createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Visualizza', () => {
                            this.props.history.push(route_from(app, jobs, `${this.state.finalJobCode.replaceAll('/', '__slash__')}`))
                        })
                    )
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    if (
                        this.props.createStatus.error &&
                        this.props.createStatus.error.response &&
                        this.props.createStatus.error.response.status === 409
                    ) {
                        error(
                            createAlert(
                                'Il codice ordine cliente inserito esiste già',
                                'Modificare il codice ordine cliente o modifica dalla lista commesse'
                            )
                        )

                        this.setState({
                            status: 'error',
                            errorMessage:
                                'Il codice ordine cliente inserito esiste già: modificare il codice ordine cliente o modifica dalla lista commesse.',
                        })
                    } else {
                        error(createAlert('Errore del server', 'Riprova piú tardi'))
                        this.setState({
                            status: 'error',
                            errorMessage: '',
                        })
                    }
                }
            }
        }

        let { id } = this.state
        let { getJob } = this.props
        if (getJob && getJob.fetching !== prevProps.getJob.fetching && !getJob.fetching) {
            if (getJob.status.success) {
                let job = getJob.data[id][0]

                let momobj = moment(job.lastBillDate, 'YYYY-MM-DD').add(1, 'days')
                if (momobj.isValid()) {
                    job.lastBillDate = momobj.toDate()
                } else {
                    job.lastBillDate = new Date()
                }

                if (job.deliveryDate) {
                    let deldate = new Date(job.deliveryDate)
                    job.deliveryDate = deldate
                    job.deliveryWeek = moment(deldate).get('week')
                } else {
                    let deldate = new Date()
                    job.deliveryDate = deldate
                    job.deliveryWeek = moment(deldate).get('week')
                }

                let momobj2 = moment(job.orderDate, 'YYYY-MM-DD').add(1, 'days')
                if (momobj2.isValid()) {
                    job.orderDate = momobj2.toDate()
                } else {
                    job.orderDate = new Date()
                }

                job.articles = []
                if (job.workOrders) {
                    for (const wo of job.workOrders) {
                        job.articles.push({
                            articleCode: wo.workCode,
                            articleName: wo.product,
                            quantity: wo.productQuantity,
                            articleId: wo.articleId,
                            deliveryDate: new Date(wo.deliveryDate),
                        })
                    }
                }
                // console.log('Job articles', job.workOrders)

                this.setState(job)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let {
            id,
            jobCode,
            jobName,
            jobClientCode,
            description,
            jobType,
            client,
            image,
            prodMap,
            productCode,
            billed,
            budgeted,
            active,
            urgencyLevel,
            createdExternal,
            addDate,
            updating,
            jobProgram,
            orderDate,
            deliveryDate,
            deliveryDateChanged,
            articles,
            customerId,
            lastBillDate,
            autoId,
            jobLocation,
            jobMachineType,
        } = this.state

        let { warning } = this.props

        if (!jobCode || jobCode === '') {
            warning(createAlert('Inserire il codice ordine cliente', 'Il codice ordine cliente è un campo richiesto'))
            this.setState({
                status: 'warning',
                errorMessage: 'Il codice ordine cliente è un campo richiesto',
            })
            return
        }

        let finalJobCode = jobCode
        let finalDescription = description

        if (productCode) {
            let product = prodMap[productCode]
            finalDescription = `${finalDescription}\n${product.description}`
        }

        // console.log('JobEditor updating:', updating)

        // let finalJobCode = jobCode

        // if (!updating && productCode) finalJobCode = productCode + finalJobCode
        if (!updating && addDate) finalJobCode = finalJobCode + moment().format('DDMMMYY').toUpperCase()

        this.setState({ finalJobCode })

        let workOrders = []
        for (const article of articles) {
            workOrders.push({
                workCode: article.articleCode,
                product: article.articleName,
                productQuantity: article.quantity,
                articleId: article.articleId,
                jobCode: finalJobCode,
                deliveryDate: article.deliveryDate,
            })
        }

        if (autoId) {
            finalJobCode = '#AUTO#'
        }

        const doSave = (mapping) => {
            this.props.network
                .createJob(id, {
                    jobCode: finalJobCode,
                    jobName,
                    jobClientCode,
                    description: finalDescription,
                    jobType,
                    client,
                    image,
                    lastBillDate: moment(lastBillDate).format('YYYY-MM-DD'),
                    billed,
                    budgeted,
                    workOrders,
                    urgencyLevel,
                    createdExternal,
                    active,
                    jobProgram,
                    orderDate: moment(orderDate).format('YYYY-MM-DD'),
                    deliveryDate: deliveryDateChanged ? moment(deliveryDate).add(2, 'hour').format('YYYY-MM-DD') : null,
                    customerId,
                    jobLocation,
                    jobMachineType,
                })
                .then(() => {
                    if (jobType === 2) {
                        this.props.network
                            .createMold({
                                moldCode: jobName,
                                moldDescription: finalDescription,
                            })
                            .then((result) => {
                                // Mold created
                            })
                            .catch((error) => {
                                // Mold not created
                            })
                    }
                    if (mapping) {
                        this.props.network.createMapping({
                            jobCode: finalJobCode,
                            row: mapping.row,
                            column: mapping.column,
                        })
                    }
                })
        }

        if (jobMachineType === 1) {
            this.props.network.getMappingData().then((result) => {
                const { data } = result
                const table = {}
                let maxRow = 10,
                    maxCol = 10

                for (const key in data) {
                    const { row, column } = data[key]
                    if (!table[row]) {
                        table[row] = {}
                    }
                    table[row][column] = true

                    if (row > maxRow) {
                        maxRow = row
                    }

                    if (column > maxCol) {
                        maxCol = column
                    }
                }

                let found = false
                let rowToUse, colToUse
                for (let i = 1; i < maxCol; i++) {
                    for (let j = 1; j < maxRow; j++) {
                        if (!table[j] || !table[j][i]) {
                            rowToUse = j
                            colToUse = i
                            found = true
                            break
                        }
                    }
                    if (found) {
                        break
                    }
                }

                doSave(
                    found
                        ? {
                              row: rowToUse,
                              column: colToUse,
                          }
                        : null
                )
            })
        } else {
            doSave()
        }
    }

    notifyJobChange = () => {
        const { onChange } = this.props
        if (onChange) {
            let {
                jobCode,
                jobName,
                jobClientCode,
                description,
                jobType,
                client,
                image,
                prodMap,
                productCode,
                billed,
                budgeted,
                active,
                urgencyLevel,
                createdExternal,
                addDate,
                updating,
                jobProgram,
                orderDate,
                deliveryDate,
                deliveryDateChanged,
                articles,
                customerId,
                lastBillDate,
                autoId,
                jobLocation,
                jobMachineType,
            } = this.state

            let { warning } = this.props

            if (!jobCode || jobCode === '') {
                warning(createAlert('Inserire il codice ordine cliente', 'Il codice ordine cliente è un campo richiesto'))
                this.setState({
                    status: 'warning',
                    errorMessage: 'Il codice ordine cliente è un campo richiesto',
                })
                return
            }

            let finalJobCode = jobCode
            let finalDescription = description

            if (productCode) {
                let product = prodMap[productCode]
                finalDescription = `${finalDescription}\n${product.description}`
            }

            // console.log('JobEditor updating:', updating)

            // let finalJobCode = jobCode

            // if (!updating && productCode) finalJobCode = productCode + finalJobCode
            if (!updating && addDate) finalJobCode = finalJobCode + moment().format('DDMMMYY').toUpperCase()

            this.setState({ finalJobCode })

            let workOrders = []
            for (const article of articles) {
                workOrders.push({
                    workCode: article.articleCode,
                    product: article.articleName,
                    productQuantity: article.quantity,
                    articleId: article.articleId,
                    jobCode: finalJobCode,
                    deliveryDate: article.deliveryDate,
                })
            }

            if (autoId) {
                finalJobCode = '#AUTO#'
            }

            onChange({
                jobCode: finalJobCode,
                jobName,
                jobClientCode,
                description: finalDescription,
                jobType,
                client,
                image,
                lastBillDate: moment(lastBillDate).format('YYYY-MM-DD'),
                billed,
                budgeted,
                workOrders,
                urgencyLevel,
                createdExternal,
                active,
                jobProgram,
                orderDate: moment(orderDate).format('YYYY-MM-DD'),
                deliveryDate: deliveryDateChanged ? moment(deliveryDate).add('hour', 2).format('YYYY-MM-DD') : null,
                customerId,
                jobLocation,
                jobMachineType,
            })
        }
    }

    handleInput(event) {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (name === 'deliveryWeek') {
            if (value < 1) value = 1
            if (value > 53) value = 53
        }

        this.setState(
            {
                [name]: value,
            },
            this.notifyJobChange
        )

        if (name === 'deliveryWeek') {
            this.setState({
                deliveryDate: moment().set('week', value).toDate(),
                deliveryDateChanged: true,
            })
        }
    }

    render() {
        let {
            fetching,
            creatingJob,
            options,
            prodOptions,
            jobCode,
            jobName,
            jobClientCode,
            description,
            jobType,
            client,
            image,
            lastBillDate,
            billed,
            budgeted,
            errorMessage,
            urgencyLevel,
            createdExternal,
            active,
            productCode,
            workOrders,
            addDate,
            id,
            updating,
            jobProgram,
            orderDate,
            deliveryDate,
            deliveryWeek,
            articles,
            customerId,
            autoId,
            jobLocation,
            jobMachineType,
        } = this.state

        const { role } = this.props

        let finalJobCode = jobCode ? jobCode : ''
        if (productCode) finalJobCode = productCode + finalJobCode
        if (addDate) finalJobCode = finalJobCode + moment().format('DDMMMYY').toUpperCase()
        if (autoId) {
            finalJobCode = '#AUTO#'
        }

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={fetching || creatingJob}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    {/* <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-article-type"
                            control={Select}
                            search
                            options={prodOptions}
                            label={<span className="label">Codice prodotto (Opzionale)</span>}
                            placeholder="Codice prodotto (Opzionale)"
                            name="productCode"
                            value={productCode}
                            disabled={updating}
                            onChange={(e, data) => {
                                let id = data.value
                                // console.log('Tipo selected', id)
                                this.setState({ productCode: id })
                            }}
                        />
                    </Form.Group> */}
                    <Form.Field
                        id="form-input-control-job-name"
                        control={Input}
                        label={<span className="label">Codice ordine cliente interno</span>}
                        name="jobName"
                        value={jobName}
                        // disabled={!!id || updating}
                        onChange={(e) => this.handleInput(e)}
                        placeholder="Ordine cliente interno"
                    />
                    <Form.Field
                        id="form-input-control-job-client-code"
                        control={Input}
                        label={<span className="label">Numero documento cliente</span>}
                        name="jobClientCode"
                        value={jobClientCode}
                        // disabled={!!id || updating}
                        onChange={(e) => this.handleInput(e)}
                        placeholder="Numero documento cliente"
                    />
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-type"
                            control={Select}
                            options={options}
                            label={<span className="label">Tipo ordine cliente</span>}
                            placeholder="Tipo ordine cliente"
                            name="jobType"
                            value={jobType}
                            onChange={(e, data) => {
                                let id = data.value
                                this.setState({ jobType: id }, this.notifyJobChange)
                            }}
                        />
                        <Form.Field
                            id="form-input-control-job-type"
                            control={Select}
                            options={[
                                {
                                    key: 0,
                                    value: 0,
                                    text: 'Normale',
                                },
                                {
                                    key: 1,
                                    value: 1,
                                    text: 'Baby',
                                },
                            ]}
                            label={<span className="label">Tipo macchina</span>}
                            placeholder="Tipo macchina"
                            name="jobMachineType"
                            value={jobMachineType}
                            onChange={(e, data) => {
                                let id = data.value
                                this.setState({ jobMachineType: id }, this.notifyJobChange)
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-description"
                            control={Input}
                            label={<span className="label">Descrizione</span>}
                            placeholder="Fresature"
                            name="description"
                            value={description}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            id="form-input-control-job-location"
                            control={Input}
                            label={<span className="label">Pedana</span>}
                            placeholder="00"
                            name="jobLocation"
                            value={jobLocation}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-job-delivery-week"
                            control={Input}
                            label={<span className="label">Settimana di consegna</span>}
                            placeholder="32"
                            name="deliveryWeek"
                            value={deliveryWeek}
                            onChange={(e) => this.handleInput(e)}
                            autoComplete="off"
                            disabled={jobType === 3}
                        />
                        <Form.Field
                            id="form-input-control-job-delivery-date"
                            control={DatePicker}
                            label={<span className="label">Data di consegna</span>}
                            name="deliveryDate"
                            value={deliveryDate}
                            dateFormat="dd/MM/yyyy"
                            selected={deliveryDate}
                            disabled={jobType === 3}
                            onChange={(data) => {
                                let deliveryWeek = moment(data).get('week')

                                this.setState(
                                    {
                                        deliveryDate: data,
                                        deliveryWeek,
                                        deliveryDateChanged: true,
                                    },
                                    this.notifyJobChange
                                )
                            }}
                            autoComplete="off"
                        />
                        <Form.Field
                            id="form-input-control-job-order-date"
                            control={DatePicker}
                            label={<span className="label">Data ordine</span>}
                            name="orderDate"
                            value={orderDate}
                            dateFormat="dd/MM/yyyy"
                            selected={orderDate}
                            onChange={(data) => {
                                this.setState(
                                    {
                                        orderDate: data,
                                    },
                                    this.notifyJobChange
                                )
                            }}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-job-id"
                            control={Input}
                            label={<span className="label">Codice ordine cliente</span>}
                            name="jobCode"
                            value={jobCode}
                            disabled={autoId}
                            onChange={(e) => this.handleInput(e)}
                            placeholder="C000"
                        />
                        <Form.Field
                            control={Switch}
                            label={<span className="label">Aggiungi data al codice ordine cliente</span>}
                            defaultChecked={addDate}
                            disabled={updating || autoId}
                            onChange={(e) => {
                                this.setState({ addDate: e.target.checked }, this.notifyJobChange)
                            }}
                        />
                        <Form.Field
                            control={Switch}
                            label={<span className="label">Genera automaticamente identificativo ordine</span>}
                            defaultChecked={autoId}
                            disabled={updating || addDate}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    this.setState({ jobCode: '#AUTO#', autoId: e.target.checked }, this.notifyJobChange)
                                } else {
                                    this.setState({ jobCode: '', autoId: e.target.checked }, this.notifyJobChange)
                                }
                            }}
                        />
                        <Form.Field
                            id="form-input-control-job-id"
                            control={Input}
                            label={<span className="label">Identificativo ordine</span>}
                            value={finalJobCode}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-image"
                            control={Input}
                            label={<span className="label">Immagine</span>}
                            placeholder="Immagine"
                            name="image"
                            value={image}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickImage
                            onChange={(image) => {
                                this.setState({ image: image }, this.notifyJobChange)
                            }}
                            value={this.state.image}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-job-program"
                            control={Input}
                            label={<span className="label">Programma</span>}
                            placeholder="Programma"
                            name="jobProgram"
                            value={jobProgram}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickFile
                            onChange={(jobProgram) => {
                                this.setState({ jobProgram: jobProgram }, this.notifyJobChange)
                            }}
                            value={this.state.jobProgram}
                        />
                    </Form.Group>
                    {/* <Form.Group widths="equal" style={{ margin: 0 }}> */}
                    <Box p={6}>
                        <Box textStyle="header" as="h1">
                            Cliente
                        </Box>
                        <Stack direction={['column', 'row']} spacing="24px" horizontal>
                            {/* <Box p={6}>
                                <Form.Field
                                    id="form-textarea-control-client"
                                    control={Input}
                                    placeholder="Cliente"
                                    name="client"
                                    value={client}
                                    onChange={(e) => this.handleInput(e)}
                                />
                            </Box> */}
                            <View fullw>
                                <CustomerDropdown
                                    single={true}
                                    onChange={(ev, data, selected, customer) => {
                                        if (customer) {
                                            this.setState(
                                                {
                                                    customerId: selected,
                                                    client: customer.customerName,
                                                },
                                                this.notifyJobChange
                                            )
                                        }
                                    }}
                                    defaultSelectedCustomers={customerId ? customerId : 0}
                                />
                            </View>
                        </Stack>
                        {/* </Form.Group> */}
                    </Box>
                    {/* {!id && (
                        <Button
                            onClick={() => {
                                // console.log('Adding work order')
                                let { workOrders } = this.state
                                workOrders.push({
                                    workCode: 'Nuovo ordine di lavoro',
                                    product: 'Prodotto',
                                    productQuantity: 1,
                                })
                                this.setState({ workOrders })
                            }}
                        >
                            +
                        </Button>
                    )} */}

                    {(!role || role === 'admin') && (
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-job-bill-date"
                                control={DatePicker}
                                label={<span className="label">Data ultima fatturazione</span>}
                                name="lastBillDate"
                                value={lastBillDate}
                                dateFormat="dd/MM/yyyy"
                                selected={lastBillDate}
                                onChange={(data) => {
                                    this.setState(
                                        {
                                            lastBillDate: data,
                                        },
                                        this.notifyJobChange
                                    )
                                }}
                            />
                            <Form.Field
                                id="form-input-control-job-billed"
                                control={Input}
                                type="number"
                                label={<span className="label">Fatturato</span>}
                                placeholder="0.0"
                                step="0.1"
                                name="billed"
                                value={billed}
                                className="rightTextField"
                                onChange={(e) => this.handleInput(e)}
                            />
                            <span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
                            <Form.Field
                                id="form-input-control-job-budgeted"
                                control={Input}
                                type="number"
                                label={<span className="label">Preventivato</span>}
                                placeholder="0.0"
                                step="0.1"
                                name="budgeted"
                                value={budgeted}
                                className="rightTextField"
                                onChange={(e) => this.handleInput(e)}
                            />
                            <span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
                        </Form.Group>
                    )}

                    <Form.Group widths="equal">
                        <Form.Field
                            control={Switch}
                            label={<span className="label">Commessa URGENTE</span>}
                            defaultChecked={urgencyLevel > 0}
                            onChange={(e) => {
                                this.setState({ urgencyLevel: e.target.checked ? 1 : 0 }, this.notifyJobChange)
                            }}
                        />
                        <Form.Field
                            control={Switch}
                            label={<span className="label">Commessa creata esternamente</span>}
                            defaultChecked={createdExternal === 1}
                            onChange={(e) => {
                                this.setState({ createdExternal: e.target.checked ? 1 : 0 }, this.notifyJobChange)
                            }}
                        />
                        <Form.Field
                            control={Switch}
                            label={<span className="label">Commessa attiva</span>}
                            defaultChecked={active === 1}
                            onChange={(e) => {
                                this.setState({ active: e.target.checked ? 1 : 0 }, this.notifyJobChange)
                            }}
                        />
                    </Form.Group>
                    {!this.props.sub && (
                        <Form.Field
                            id="form-button-control-save"
                            control={Button}
                            content="Salva"
                            onClick={() => {
                                this.save()
                            }}
                        />
                    )}
                    <Message success header="Form Completed" content="Commessa inserita con successo!" />
                    <Message error header="Errore" content={`Errore del server, \nriprova piu' tardi. ${errorMessage}`} />
                    <Message warning header="Errore inserimento" list={[`${errorMessage}`]} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        login,
        getProductList: {
            data: { product: products },
            fetching: fetchProduct,
        },
        getJobTypeList: {
            data: { data: types },
            fetching,
        },
        createJob: { data: createResult, fetching: creatingJob, status: createStatus },
        getJob,
    } = state

    let options = []
    for (let i in types) {
        let type = types[i]
        options.push({
            key: type.jobType,
            text: type.typeName,
            value: type.jobType,
        })
    }

    let prodMap = {}
    let prodOptions = []
    for (let i in products) {
        let type = products[i]
        prodOptions.push({
            key: type.code,
            text: type.code + ' ' + type.description,
            value: type.code,
        })
        prodMap[type.code] = type
    }
    prodOptions.push({
        key: 0,
        text: 'Nessun riferimento a prodotto',
        value: false,
    })

    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return {
        prodOptions,
        prodMap,
        fetchProduct,
        options,
        types,
        fetching,
        createResult,
        creatingJob,
        createStatus,
        getJob,
        role: rcode,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(JobEditor)))
