export function deleteProduction(data) {
    return new Promise((resolve, reject) => {
		let { deleteProductionRequest, deleteProductionError, deleteProductionResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_production',
			this.createBundle(
				'delete_production',
				{ production: data },
				this.POST,
				deleteProductionRequest,
                (result) => {
                    deleteProductionResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteProductionError(error)
                    reject(error)
                }
			)
		);
	})
}
