export function deleteBadgeScan(data) {
    return new Promise((resolve, reject) => {
		let { deleteBadgeScanRequest, deleteBadgeScanError, deleteBadgeScanResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_badgescan',
			this.createBundle(
				'delete_badgescan',
				{ badgescan: data },
				this.POST,
				deleteBadgeScanRequest,
                (result) => {
                    deleteBadgeScanResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteBadgeScanError(error)
                    reject(error)
                }
			)
		);
	})
}
