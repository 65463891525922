export function getTask(data) {
	let { getTaskRequest, getTaskError, getTaskResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_task',
		this.createBundle(
			'get_task',
			{ task: data },
			this.GET,
			getTaskRequest,
			getTaskResult,
			getTaskError
		)
	);
}
