import axios from 'axios'
import * as htmlToImage from 'html-to-image'
import { connectNetwork } from '../lib/NetworkProvider'
import View from '../lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { pdfjs } from 'react-pdf'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Form, Header, Icon, Input, Popup, Segment, Select } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { GETSESSIONDATA_ACTIONS } from '../actions/getSessionData'
import { ImageDefault, Label } from '../components'
import { Labels } from '../containers'
import { baseViewDispatch, Desktop, alertGenerator } from '../lib/util'
import { documentToSVG, elementToSVG, inlineResources, formatXML } from '../../node_modules/dom-to-svg'
import { UserAgent, withUserAgent } from 'react-useragent'
import { v4 as uuidv4 } from 'uuid'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

moment.locale('it')

let loopsRunning = 0

class Labeling extends Component {
    constructor(props) {
        super(props)
        this.state = this.defaultState(props)
        this.printRef = React.createRef()

        // this.registeredInterval = setInterval(updatepackagerinfo, 10000)
        this.updatepackagerinfo()
    }

    updatepackagerinfo = () => {
        // axios
        //     .get(`${this.props.network.WEB_REQUEST_URL}get_packager_info`, {
        //         params: {},
        //     })
        //     .then((result) => {
        //         const { currentCounter, currentStatus } = result.data
        //         // const counter = (currentCounter.data[0] << 16) + currentCounter.data[1]
        //         // const status = (currentStatus.data[0] << 16) + currentStatus.data[1]
        //         this.setState({
        //             packagerCounter: currentCounter,
        //             packagerStatus: currentStatus,
        //         })
        //     })
        //     .catch((error) => {
        //         // console.error('Error getting Packager info')
        //     })
    }

    defaultState(props) {
        const { articleId, packageId, customerId, jobCode, arti, currentSession } = this.props

        this.debounceUpdateCurrentImage = debounce(1000, true, this.updateCurrentImage)
        let lotto = ''
        if (props.arti) {
            if (props.arti.lotto) {
                lotto = props.arti.lotto
            } else if (props.arti.op) {
                lotto = props.arti.op
            }
        }

        let toFill = 0
        if (arti && currentSession && currentSession.targetPackage) {
            const packToFill = arti.packageobj[currentSession.targetPackage.packageParent]
            if (packToFill) {
                toFill = packToFill.pcsPerPackage / currentSession.targetPackage.pcsPerPackage
            }
        }

        let packagerSeq = (currentSession.sessionTotalPackagesDone ? currentSession.sessionTotalPackagesDone : 0) % toFill

        if (currentSession && currentSession.sessionKit && currentSession.sessionKit !== 'SINGOLO') {
            packagerSeq = (currentSession.sessionTotalPackagesDone ? currentSession.sessionTotalPackagesDone : 0) % 1000
        }

        return {
            arti: props.arti ? props.arti : {},
            label: {},
            pageNumber: 1,
            articleId,
            packageId,
            customerId,
            jobCode,
            showLabel: false,
            currentSession: props.currentSession,
            printCount: 1,
            customData: true,
            customArticleQuantity: 1,
            customLotto: lotto,
            labelPreviewScale: 0.2,
            computedImage: `${props.arti.workCode}.bmp`,
            images: [],
            useClientArticleCode: true,
            imageIndex: 0,
            printingKit: 0,
            customerName: props.customerName ? props.customerName : '',
            packagerCounter: 0,
            packagerStatus: 0,
            ownPrinter: props.useOwnPrinter ? 'zebra' : null,
            setupDone: false,
            settingUp: false,
            printDivId: `print-preview-${uuidv4()}`,
            packagerSeq,
            toFill,
            customerLabelMissing: false,
        }
    }

    updateCurrentImage = async () => {
        if (this.state.arti.kit === 'SINGOLO') {
            // Remove true if in need of different image search for kits
            const artiinfo = {
                codice: this.state.arti.workCode,
                clientWorkCode:
                    this.state.arti.clientWorkCode && this.state.arti.clientWorkCode !== ''
                        ? this.state.arti.clientWorkCode
                        : this.state.arti.workCode,
                clientDescription: this.state.arti.description,
            }

            if (this.state.packageId) {
                const pack = this.state.arti.packageobj[this.state.packageId]
                let pcode = ''
                if (pack) {
                    pcode = pack.packageCode.substring(0, 2)
                }
                if (this.state.arti.kit !== 'SINGOLO' && this.state.arti.kitlabeltype === 1 && pcode !== 'BU' && pcode !== 'BA') {
                    artiinfo.codice = this.state.arti.kit
                }
            }

            axios
                .post(`${this.props.network.WEB_REQUEST_URL}find_image`, {
                    artiinfo,
                })
                .then((result) => {
                    const images = result.data.data
                    this.setState({
                        computedImage: images && images.length > 0 && this.state.useClientArticleCode ? images[0] : `${this.state.arti.workCode}.bmp`,
                        images,
                        imageIndex: 0,
                    })
                })
                .catch((error) => {})
        }
    }

    componentWillUnmount() {
        // if (this.registeredInterval) {
        // clearInterval(this.registeredInterval)
        // }
    }

    componentDidMount() {
        const updFunc = async () => {
            const { arti } = this.state
            if (arti && arti.kitArtis && arti.kitArtis.length > 0) {
                for (const key in arti.kitArtis) {
                    const kitarti = arti.kitArtis[key]
                    const articleclientcodeoverride = await this.props.network.getSyncJobs(kitarti.codice)
                    kitarti.clientWorkCode =
                        articleclientcodeoverride && articleclientcodeoverride.data && articleclientcodeoverride.data[0]
                            ? articleclientcodeoverride.data[0]['Art Cli / For']
                            : ''
                }
            } else {
                if (!arti.kit || arti.kit === '') arti.kit = 'SINGOLO'
            }
            this.setState({ arti })
        }

        updFunc()
        this.debounceUpdateCurrentImage()
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value,
        })
    }

    handleArtiChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        const { arti } = this.state
        arti[name] = value

        this.setState({
            arti,
        })
    }

    handleSessionDataChange(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        const { currentSession } = this.state
        currentSession[name] = value

        this.setState({
            currentSession,
        })
    }

    getContext() {
        const { arti } = this.state
        const { worker } = this.props

        return {
            ...(this.state.customData
                ? {
                      ...arti,
                      lotto: !!this.state.customLotto && this.state.customLotto !== '' ? this.state.customLotto : arti.lotto,
                      articleQuantity: this.state.customArticleQuantity > 0 ? this.state.customArticleQuantity : arti.articleQuantity,
                      computedImage: this.state.computedImage,
                  }
                : { ...arti, computedImage: this.state.computedImage }),
            ...worker,
            ...(this.state.customData
                ? {
                      ...this.state.currentSession,
                      sessionTargetPackageCount:
                          this.state.customArticleQuantity > 0
                              ? this.state.customArticleQuantity
                              : this.state.currentSession.sessionTargetPackageCount,
                  }
                : this.state.currentSession),
        }
    }

    resetKit() {
        return new Promise((resolve, reject) => {
            this.setState({ printingKit: 0 }, () => {
                resolve()
            })
        })
    }

    nextKit() {
        return new Promise((resolve, reject) => {
            this.setState({ printingKit: this.state.printingKit + 1 }, () => {
                resolve()
            })
        })
    }

    nextSeq(currentPackagerCounter) {
        return new Promise((resolve, reject) => {
            const { toFill, arti, currentSession } = this.state

            // let toFill = 0
            // if (arti && currentSession) {
            //     const packToFill = arti.packageobj[currentSession.targetPackage.packageParent]
            //     if (packToFill) {
            //         toFill = packToFill.pcsPerPackage / currentSession.targetPackage.pcsPerPackage
            //     }
            // }

            if (currentPackagerCounter !== undefined) {
                const newSeq =
                    ((currentSession.sessionTotalPackagesDone ? currentSession.sessionTotalPackagesDone : 0) + currentPackagerCounter) % toFill
                arti.seq = newSeq
                this.setState({ seq: newSeq, packagerSeq: newSeq }, () => {
                    setTimeout(() => {
                        resolve()
                    }, 500)
                })
            } else {
                arti.seq = arti.seq ? arti.seq + 1 : 2
                this.setState({ seq: this.state.seq + 1, packagerSeq: this.state.packagerSeq + 1 }, () => {
                    setTimeout(() => {
                        resolve()
                    }, 500)
                })
            }
        })
    }

    startPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 1 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    stopPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 0.2 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    waitMilliseconds(ms) {
        return new Promise(async (resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, ms)
        })
    }

    waitPauseEnd() {
        return new Promise(async (resolve, reject) => {
            while (this.props.isPaused() && !this.props.abort) {
                await this.waitMilliseconds(1000)
            }
            resolve(true)
        })
    }

    waitForNextCounter(currentCounter) {
        return new Promise(async (resolve, reject) => {
            let newCounter = currentCounter

            while (newCounter === currentCounter && !this.props.abort) {
                await this.waitMilliseconds(2000)
                // newCounter = await axios
                //     .get(`${this.props.network.WEB_REQUEST_URL}get_packager_info`, {
                //         params: {},
                //     })
                //     .then((result) => {
                //         const { currentCounter } = result.data
                //         const counter = currentCounter
                //         this.setState({
                //             packagerCounter: counter,
                //         })
                //         return counter
                //     })
                //     .catch((error) => {
                //         console.error('Error getting Packager info', error)
                //         return 0
                //     })

                // resolve(newCounter + 1) // Only in development
            }
            resolve(newCounter)
        })
    }

    resetCounter = () => {
        return axios
            .get(`${this.props.network.WEB_REQUEST_URL}set_packager_counter`, {
                params: {
                    count: 0,
                },
            })
            .then((result) => {
                // axios
                //     .get(`${this.props.network.WEB_REQUEST_URL}get_packager_info`, {
                //         params: {},
                //     })
                //     .then((result) => {
                //         const { currentCounter } = result.data
                //         const counter = currentCounter
                //         this.setState({
                //             packagerCounter: counter,
                //         })
                //         return counter
                //     })
                //     .catch((error) => {
                //         console.error('Error getting Packager info', error)
                //         return 0
                //     })
            })
            .catch((error) => {
                console.error('Error setting Packager counter')
            })
    }

    render() {
        const {
            arti,
            label,
            pageNumber,
            numPages,
            jobCode,
            articleId,
            packageId,
            customerId,
            labelPreviewScale,
            useClientArticleCode,
            printing,
            printingKit,
            customerName,
            settingUp,
            setupDone,
            packagerSeq,
        } = this.state

        const printerOptions = [
            {
                key: 'zebra',
                value: 'zebra',
                text: 'ZT230 (Imballaggio)',
            },
            {
                key: 'zd',
                value: 'zd',
                text: 'ZD420 (Produzione)',
            },
            {
                key: 'gk420t',
                value: 'gk420t',
                text: 'GK420t',
            },
        ]

        let isPackager = !this.props.useOwnPrinter && (this.props.printer === 'zebraimball' || this.props.printer === 'zebraimball2')

        const { worker } = this.props

        const labelTransform = `scale(${labelPreviewScale}, ${labelPreviewScale})`

        let printingKitArti = null
        if (arti && arti.kit !== 'SINGOLO' && arti.kitArtis) {
            printingKitArti = arti.kitArtis[printingKit]
        }

        //
        //
        //

        let selectedArti = null
        let currentSectionWithCustom = null
        if (label && label.layout && arti) {
            // if (arti === 'SINGOLO') {
            selectedArti = this.state.customData
                ? {
                      ...arti,
                      lotto: !!this.state.customLotto && this.state.customLotto !== '' ? this.state.customLotto : arti.lotto,
                      articleQuantity: this.state.customArticleQuantity > 0 ? this.state.customArticleQuantity : arti.articleQuantity,
                      computedImage: this.state.computedImage,
                  }
                : { ...arti, computedImage: this.state.computedImage }

            currentSectionWithCustom = this.state.customData
                ? {
                      ...this.state.currentSession,
                      sessionTargetPackageCount:
                          this.state.customArticleQuantity > 0
                              ? this.state.customArticleQuantity
                              : this.state.currentSession.sessionTargetPackageCount,
                  }
                : this.state.currentSession

            if (selectedArti.kit !== 'SINGOLO') {
                const pack = arti.packageobj[packageId]
                let pcode = ''
                if (pack) {
                    pcode = pack.packageCode.substring(0, 2)
                }

                if (selectedArti.kitlabeltype && selectedArti.kitlabeltype === 1 && pcode !== 'BU' && pcode !== 'BA') {
                    selectedArti.description = selectedArti.kitname
                    selectedArti.workCode = selectedArti.kitlabelcode ? selectedArti.kitlabelcode : selectedArti.kit
                    selectedArti.articleQuantity = arti.kitlabelquantity ? arti.kitlabelquantity : selectedArti.articleQuantity
                    currentSectionWithCustom.sessionTargetPackageCount = arti.kitlabelquantity ? arti.kitlabelquantity : selectedArti.articleQuantity
                    selectedArti.clientWorkCode = ''
                }
            }

            if (isPackager) {
                // selectedArti.seq = (this.state.currentSession.sessionTotalPackagesDone + 1) % toFill

                selectedArti.seq = packagerSeq
            }

            // } else {
            //     selectedArti = this.state.customData
            //         ? {
            //               ...printingKitArti,
            //               lotto: this.state.customLotto && this.state.customLotto !== '' ? this.state.customLotto : printingKitArti.lotto,
            //               articleQuantity: this.state.customArticleQuantity > 0 ? this.state.customArticleQuantity : printingKitArti.articleQuantity,
            //               computedImage: this.state.computedImage[printingKit],
            //           }
            //         : { ...printingKitArti, computedImage: this.state.computedImage[printingKit] }
            // this.state.customData
            //     ? {
            //           ...this.state.currentSession,
            //           sessionTargetPackageCount:
            //               this.state.customArticleQuantity > 0
            //                   ? this.state.customArticleQuantity
            //                   : this.state.currentSession.sessionTargetPackageCount,
            //       }
            //     : this.state.currentSession
            // }
        }

        let btnText = isPackager ? 'Invia etichetta' : 'Stampa etichetta'

        if (isPackager && settingUp) {
            btnText = 'Termina rimozione buste vuote'
        }

        if (isPackager && setupDone) {
            btnText = 'Avvia imbustatrice'
        }

        const onClickPrintButton = (setupNotDone) => {
            return new Promise(async (resolve, reject) => {
                loopsRunning++
                if (isPackager && this.state.settingUp && this.props.numberOfPackagesToMake && !setupNotDone) {
                    //
                    this.setState({
                        settingUp: false,
                        setupDone: true,
                    })
                    resolve()
                    return
                }

                const { network } = this.props
                var node = document.getElementById(this.state.printDivId)
                //

                let printCount = this.state.printCount
                let currentPackagerCounter = this.state.packagerCounter

                if (isPackager && this.props.numberOfPackagesToMake) {
                    printCount = this.props.numberOfPackagesToMake ? this.props.numberOfPackagesToMake : printCount
                    printCount = Math.max(this.state.currentSession.targetPackage.pcsPerPackage, printCount)
                }

                if (isPackager && this.props.numberOfPackagesToMake && !setupNotDone) {
                    axios
                        .get(`${this.props.network.WEB_REQUEST_URL}set_packager_threshold`, {
                            params: {
                                count: this.props.numberOfPackagesToMake,
                            },
                        })
                        .then((result) => {})
                        .catch((error) => {
                            console.error('Error setting Packager counter')
                        })
                }

                let startingPackagesDone = this.state.currentSession.sessionTotalPackagesDone ? this.state.currentSession.sessionTotalPackagesDone : 0
                let currentPackageStart = startingPackagesDone ? startingPackagesDone - (startingPackagesDone % this.state.toFill) : 0

                const elementsToPrint = arti.kit !== 'SINGOLO' ? arti.kitArtis.length : 1
                if ((!isPackager || this.state.setupDone || !this.props.numberOfPackagesToMake) && !setupNotDone) {
                    if (this.props.onStartPrinting) {
                        this.props.onStartPrinting()
                    }
                    await this.startPrinting()
                    this.setState({ printing: true })
                }

                let singlePackageCounter = startingPackagesDone % this.props.toFill

                try {
                    /* eslint-disable */
                    await network.createDocument(
                        {
                            work_order_id: arti.workOrderId,
                            // id_user: userid,
                            id_deposit_from: 0, // From nowhere; maybe specify this on the DB?
                            id_deposit_to: 1, // To "Production" warehouse, temporary
                            id_document_reason: 8,
                            // id_document_related: 1,
                            note: 'Produzione etichettata',
                            date: moment().format('YYYY-MM-DD HH:mm:ss'),
                        },
                        [
                            {
                                code: arti.workCode,
                                price: 0,
                                discount: '0',
                                qty: printCount * (this.state.customArticleQuantity > 0 ? this.state.customArticleQuantity : arti.articleQuantity),
                                amount: printCount * (this.state.customArticleQuantity > 0 ? this.state.customArticleQuantity : arti.articleQuantity),
                            },
                        ],
                        !!this.props.isEmployee
                    )

                    for (let i = 0; i < printCount; i++) {
                        try {
                            if (this.props.isPaused && this.props.isPaused()) {
                                await this.waitPauseEnd()
                                if (currentPackageStart + this.state.toFill < startingPackagesDone + i) {
                                    startingPackagesDone += this.state.toFill
                                }
                            }

                            if (this.props.abort) {
                                await this.props.onPrintLabel(0)
                                break
                            }
                        } catch (e) {
                            console.error('Error while waiting', e)
                        }

                        singlePackageCounter = singlePackageCounter + 1

                        // if (arti.kit !== 'SINGOLO') {
                        //     await this.resetKit()
                        // }
                        // for (let j = 0; j < elementsToPrint; j++) {

                        if (this.props.ua.mobile || this.props.ua.tablet) {
                            const bounds = {
                                height: label.layout.layoutHeight,
                                width: label.layout.layoutWidth,
                                x: 0,
                                y: 0,
                            }
                            const svgDocument = elementToSVG(document.querySelector(`#${this.state.printDivId}`), {
                                bounds,
                                avoidTextSelection: true,
                            })

                            await inlineResources(svgDocument.documentElement)

                            let svgString = new XMLSerializer().serializeToString(svgDocument)

                            const regex = /<style>.*?<\/style>/gis
                            const mregex = /<mask[^>]*?>.*?<\/mask>/gis
                            const amregex = /mask=".*?"/gis
                            svgString = svgString.replace(regex, '').replace(mregex, '').replace(amregex, '')

                            await network.createCommand(
                                {
                                    node: 'down_labels',
                                    action: 5,
                                    value: 'down_labels',
                                    endpoint: 'down_labels',
                                    extradata: `${svgString}`,
                                },
                                true
                            )

                            // await network
                            //     .sendToPrinter(svgString, 'filename', printCount, this.getContext(), {
                            //         width: label.layout.layoutWidth,
                            //         height: label.layout.layoutHeight,
                            //         mobile: true,
                            //         printer: this.props.useOwnPrinter ? this.props.ownPrinter : this.props.printer,
                            //         customerLabelMissing: this.state.customerLabelMissing,
                            //         labelId: label.labelId,
                            //     })
                            //     .then((result) => {
                            //         // this.props.onPrintLabel(result)
                            //     })
                        } else {
                            await htmlToImage
                                .toPng(node, {
                                    width: label.layout.layoutWidth,
                                    height: label.layout.layoutHeight,
                                })
                                .then(async (dataUrl) => {
                                    const filename = arti.workCode

                                    var img = new Image()
                                    img.src = dataUrl

                                    await network.createCommand(
                                        {
                                            node: 'down_labels',
                                            action: 5,
                                            value: 'down_labels',
                                            endpoint: 'down_labels',
                                            extradata: `${dataUrl}`,
                                        },
                                        true
                                    )

                                    // await network.sendToPrinter(dataUrl, filename, printCount, this.getContext(), {
                                    //     width: label.layout.layoutWidth,
                                    //     height: label.layout.layoutHeight,
                                    //     mobile: false,
                                    //     printer: this.props.useOwnPrinter ? this.props.ownPrinter : this.props.printer,
                                    //     customerLabelMissing: this.state.customerLabelMissing,
                                    //     labelId: label.labelId,
                                    // })
                                })
                                .catch(function (error) {
                                    console.error('oops, something went wrong!', error)
                                })
                        }

                        // if (arti.kit !== 'SINGOLO') {
                        //     await this.nextKit()
                        // }
                        // }

                        if (!(isPackager && !settingUp && !setupDone && !setupNotDone)) {
                            if (isPackager && !setupNotDone) {
                                currentPackagerCounter = await this.waitForNextCounter(currentPackagerCounter)
                            }

                            const result = await this.props.onPrintLabel(
                                printCount - (i + 1),
                                startingPackagesDone + i + 1,
                                startingPackagesDone,
                                currentPackagerCounter
                            )
                            if (result) {
                                // i = result % this.state.toFill
                                // startingPackagesDone = result
                                i = result - startingPackagesDone
                            }

                            await this.nextSeq(isPackager ? currentPackagerCounter : undefined)
                        }

                        if (isPackager && !settingUp && !setupDone && this.props.numberOfPackagesToMake && !setupNotDone) {
                            this.resetCounter()
                            this.setState({
                                settingUp: true,
                            })

                            // eslint-disable-next-line no-loop-func
                            const setupFunc = () => {
                                if (this.state.settingUp && !this.state.setupDone) {
                                    this.waitForNextCounter(this.state.packagerCounter).then((newCounter) => {
                                        if (this.state.settingUp && !this.state.setupDone) {
                                            if (newCounter > 0) {
                                                this.nextSeq(newCounter).then(() => {
                                                    if (this.state.settingUp && !this.state.setupDone) {
                                                        onClickPrintButton(true).then(() => {
                                                            if (this.state.settingUp && !this.state.setupDone) {
                                                                setTimeout(setupFunc, 1000)
                                                            }
                                                            loopsRunning--
                                                        })
                                                    }
                                                })
                                            } else {
                                                if (this.state.settingUp && !this.state.setupDone) {
                                                    setTimeout(setupFunc, 1000)
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                            setTimeout(setupFunc(), 1000)
                            resolve()
                            return
                        }

                        if (this.state.settingUp || setupNotDone) {
                            resolve()
                            return
                        }

                        if (this.props.abort) {
                            await this.props.onPrintLabel(0)
                            break
                        }
                    }
                } catch (e) {
                    this.props.error(alertGenerator({ title: 'Errore durante la stampa', message: JSON.stringify(e) }))
                    console.error(e)
                }
                this.setState({ printCount: 1, printing: false })
                await this.stopPrinting()
                resolve()
            })
        }

        const currentSessionIsBox =
            this.props.currentSession && this.props.currentSession.targetPackage && this.props.currentSession.targetPackage.packageCode
                ? this.props.currentSession.targetPackage.packageCode.startsWith('SC') && this.props.currentSession.kitlabeltype === 1
                : false

        return (
            <View column fullw fullh>
                <View row fullw fullh>
                    <View column fullw fullh>
                        {arti.workCode && (
                            <>
                                <Segment className="label-info-container">
                                    <View fullw fullh column>
                                        <View fullw fullh row between style={{ margin: 8 }}>
                                            <View fullw fullh row>
                                                <Header as="h3" style={{ paddingTop: 8 }}>
                                                    Codice:
                                                </Header>
                                                <Header as="h1" style={{ marginLeft: 16 }}>
                                                    {arti.workCode}
                                                </Header>
                                                {!!this.state.currentSession && (
                                                    <>
                                                        <Header as="h3" style={{ marginLeft: 48, paddingTop: 8 }}>
                                                            Imballati:
                                                        </Header>
                                                        {/* {!isPackager && ( */}
                                                        <Header as="h1" style={{ marginLeft: 16 }}>
                                                            {this.state.currentSession.sessionTotalPackagesDone
                                                                ? this.state.currentSession.sessionTotalPackagesDone
                                                                : 0}
                                                        </Header>
                                                        {/* )} */}
                                                        {/* {isPackager && (
                                                            <Header as="h1" style={{ marginLeft: 16 }}>
                                                                {this.state.currentSession.sessionTotalPackagesDone
                                                                    ? this.state.currentSession.sessionTotalPackagesDone + this.state.packagerCounter
                                                                    : 0}
                                                            </Header>
                                                        )} */}
                                                    </>
                                                )}
                                                {/* {!!this.props.numberOfPackagesToMake && (
                                                    <>
                                                        <Header as="h3" style={{ marginLeft: 48, paddingTop: 8 }}>
                                                            Da imballare:
                                                        </Header>
                                                        <Header as="h1" style={{ marginLeft: 16 }}>
                                                            {this.props.numberOfPackagesToMake ? this.props.numberOfPackagesToMake : '--'}
                                                        </Header>
                                                    </>
                                                )} */}
                                            </View>
                                            <View style={{ marginRight: 16 }}>
                                                {this.props.useOwnPrinter && (
                                                    <Select
                                                        style={{ zIndex: 10 }}
                                                        placeholder="Stampanti"
                                                        options={printerOptions}
                                                        value={this.state.ownPrinter}
                                                        onChange={(e, data) => {
                                                            this.setState({
                                                                ownPrinter: data.value,
                                                            })
                                                        }}
                                                    />
                                                )}
                                            </View>
                                        </View>
                                        <View fullw fullh row style={{ margin: 8 }}>
                                            <Header as="h3">Codice cliente:</Header>
                                            <Header as="h3" style={{ marginLeft: 16 }}>
                                                {arti.clientWorkCode}
                                            </Header>
                                            {isPackager && (
                                                <>
                                                    <Header as="h3" style={{ marginLeft: 48, paddingTop: 8 }}>
                                                        Stato imbustatrice:
                                                    </Header>
                                                    <Header as="h1" style={{ marginLeft: 16 }}>
                                                        {this.state.packagerStatus}
                                                        {this.state.packagerStatus === 0 && (
                                                            <Popup
                                                                content="Riconnetti"
                                                                trigger={
                                                                    <Button
                                                                        style={{ marginLeft: 8, paddingRight: 8, paddingLeft: 4 }}
                                                                        icon
                                                                        onClick={() => {
                                                                            this.updatepackagerinfo()
                                                                            setTimeout(() => {
                                                                                this.updatepackagerinfo()
                                                                            }, 1000)
                                                                        }}
                                                                    >
                                                                        <Icon color="red" name="wifi" />
                                                                    </Button>
                                                                }
                                                            />
                                                        )}
                                                    </Header>
                                                    <Header as="h3" style={{ marginLeft: 48, paddingTop: 8 }}>
                                                        Buste fatte:
                                                    </Header>
                                                    <Header as="h1" style={{ marginLeft: 16 }}>
                                                        {this.state.packagerCounter}
                                                    </Header>
                                                </>
                                            )}
                                        </View>
                                        <Form>
                                            {!arti.jobCode && (
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        id="form-input-control-job-type"
                                                        control={Select}
                                                        options={this.props.customerOptions}
                                                        label="Cliente"
                                                        placeholder="Cliente"
                                                        name="customerId"
                                                        value={customerId}
                                                        onChange={(e, data) => {
                                                            let id = data.value

                                                            const name = this.props.customerMap[id]

                                                            this.setState({ customerId: id, customerName: name })
                                                        }}
                                                    />
                                                </Form.Group>
                                            )}
                                            <Form.Field
                                                style={{ paddingTop: 8 }}
                                                control={Checkbox}
                                                label="Modifica dati stampa"
                                                checked={this.state.customData}
                                                onChange={(e, data) => {
                                                    this.setState({ customData: data.checked })
                                                }}
                                            />
                                            {this.state.customData && (
                                                <Form.Group widths="equal">
                                                    <View column fullh>
                                                        <Button
                                                            color="green"
                                                            onClick={() => {
                                                                this.setState({
                                                                    customArticleQuantity: this.state.customArticleQuantity + 1,
                                                                })
                                                            }}
                                                        >
                                                            +
                                                        </Button>
                                                        <Button
                                                            color="red"
                                                            onClick={() => {
                                                                if (this.state.customArticleQuantity > 1) {
                                                                    this.setState({
                                                                        customArticleQuantity: this.state.customArticleQuantity - 1,
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            -
                                                        </Button>
                                                    </View>

                                                    <Form.Field
                                                        id="form-input-control-print-quantity"
                                                        control={Input}
                                                        label="Quantità"
                                                        name="customArticleQuantity"
                                                        type="number"
                                                        value={this.state.customArticleQuantity}
                                                        onChange={(e) => this.handleInput(e)}
                                                        placeholder="Quantità"
                                                    />

                                                    <Form.Field
                                                        id="form-input-control-print-lot"
                                                        control={Input}
                                                        label="Lotto"
                                                        name="customLotto"
                                                        type="text"
                                                        value={this.state.customLotto}
                                                        onChange={(e) => this.handleInput(e)}
                                                        placeholder="Lotto"
                                                    />
                                                </Form.Group>
                                            )}
                                        </Form>
                                    </View>
                                </Segment>

                                <View column fullw fullh style={{ overflowY: 'scroll' }}>
                                    <Labels
                                        sub
                                        selectable
                                        selected={label}
                                        onChange={(label, customerLabelMissing) => {
                                            this.setState({ label, showLabel: true, customerLabelMissing })
                                        }}
                                        noActions
                                        articleId={arti.articleId}
                                        packageId={arti.packageobj[packageId] ? arti.packageobj[packageId].packageId : 2116}
                                        customerId={customerId}
                                        arti={this.props.arti}
                                        currentSession={this.props.currentSession}
                                        largepackager={
                                            this.props.useOwnPrinter
                                                ? this.props.ownPrinter === 'zebraimball2'
                                                : this.props.printer === 'zebraimball2'
                                        }
                                        kitlabels={
                                            this.props.currentSession.sessionKit &&
                                            this.props.currentSession.sessionKit !== 'SINGOLO' &&
                                            currentSessionIsBox
                                        }
                                    />
                                </View>
                                {/* <View column>
                                    <ReactToPrint
                                        ref={(el) => (this.printButton = el)}
                                        trigger={() => (
                                            <button style={{ display: 'none' }} id="print-button-report">
                                                Stampa
                                            </button>
                                        )}
                                        content={() => {
                                            const { network } = this.props

                                            // if (!session[arti.jobCode]) {
                                            //     session[arti.jobCode] = {
                                            //         ...arti,
                                            //         labels: [],
                                            //     }
                                            // }
                                            // session[arti.jobCode].labels.push({
                                            //     ...label,
                                            //     selectedPackage: arti.packages[packageIndex],
                                            //     selectedCustomer: arti,
                                            //     selectedArti: arti,
                                            // })

                                            const pack = arti.packageobj[packageId]

                                            let targetSize = arti.packageobj[pack.packageParent] ? arti.packageobj[pack.packageParent].packageSize : 0

                                            if (arti.packageobj[pack.packageParent] && arti.packageobj[pack.packageParent].pcsPerPackage) {
                                                targetSize = arti.packageobj[pack.packageParent].pcsPerPackage
                                            }

                                            const sessionObj = {
                                                sessionClientOrder: arti.jobCode,
                                                sessionArticleCode: arti.workCode,
                                                sessionPackagedIn: pack.packageId,
                                                sessionTargetPackage: pack.packageParent,
                                                sessionTargetPackageCount: targetSize,
                                                addToSourceCounter: true,
                                            }

                                            // return this.printRef.current
                                            network.addSessionData(sessionObj)
                                        }}
                                    />
                                </View> */}
                            </>
                        )}
                    </View>
                </View>

                {label && label.layout && arti && (
                    <>
                        <View
                            row
                            fullw
                            style={{
                                position: 'absolute',
                                bottom: 8,
                                right: -2,
                                width: Math.max(label.layout.layoutWidth * labelPreviewScale, 600),
                                height: label.layout.layoutHeight * labelPreviewScale + 64 + 128,
                                display: this.state.showLabel ? 'block' : 'none',
                                backgroundColor: 'white',
                                zIndex: 999,
                                border: 'black solid 2px',
                            }}
                        >
                            <View row fullw>
                                {(this.props.printer !== 'zebraimball' || this.props.printer === 'zebraimball2') && (
                                    <Form style={{ margin: 8 }}>
                                        <Form.Group widths="equal">
                                            <View column fullh>
                                                <Button
                                                    color="green"
                                                    onClick={() => {
                                                        this.setState({
                                                            printCount: this.state.printCount + 1,
                                                        })
                                                    }}
                                                >
                                                    +
                                                </Button>
                                                <Button
                                                    color="red"
                                                    onClick={() => {
                                                        if (this.state.printCount > 1) {
                                                            this.setState({
                                                                printCount: this.state.printCount - 1,
                                                            })
                                                        }
                                                    }}
                                                >
                                                    -
                                                </Button>
                                            </View>
                                            <Form.Field
                                                id="form-input-control-print-count"
                                                control={Input}
                                                label="Numero stampe"
                                                name="printCount"
                                                type="number"
                                                value={this.state.printCount}
                                                onChange={(e) => this.handleInput(e)}
                                                placeholder="1"
                                            />
                                        </Form.Group>
                                    </Form>
                                )}
                            </View>
                            <View row around>
                                {this.state.images && this.state.images.length > 1 && (
                                    <View>
                                        <span style={{ marginTop: 8, marginRight: 8 }}>Trovate {this.state.images.length} immagini applicabili</span>
                                        <Button
                                            onClick={() => {
                                                let { imageIndex } = this.state
                                                imageIndex = (imageIndex + 1) % this.state.images.length
                                                this.setState({
                                                    computedImage:
                                                        this.state.images && this.state.images.length > 0 && useClientArticleCode
                                                            ? this.state.images[imageIndex]
                                                            : `${this.state.arti.workCode}.bmp`,
                                                    imageIndex: imageIndex,
                                                })
                                            }}
                                        >
                                            Cambia
                                        </Button>
                                    </View>
                                )}
                                <Button
                                    color="green"
                                    disabled={this.state.printing}
                                    onClick={() => {
                                        onClickPrintButton(false).then(() => {
                                            loopsRunning--
                                        })
                                    }}
                                >
                                    {btnText}
                                </Button>
                                {isPackager && (
                                    <Button color="red" disabled={this.state.printing} onClick={this.resetCounter}>
                                        Azzera contatore imbustatrice
                                    </Button>
                                )}
                                <Button
                                    color="red"
                                    disabled={this.state.printing}
                                    onClick={() => {
                                        this.setState({ showLabel: false })
                                    }}
                                >
                                    Chiudi anteprima di stampa
                                </Button>
                                <Desktop>
                                    <Button
                                        color="grey"
                                        disabled={this.state.printing}
                                        onClick={() => {
                                            this.setState({ labelPreviewScale: labelPreviewScale >= 0.8 ? 0.2 : 0.8 })
                                        }}
                                    >
                                        {labelPreviewScale >= 0.8 ? 'Comprimi' : 'Espandi'}
                                    </Button>
                                </Desktop>
                            </View>
                            <div
                                style={{
                                    transformOrigin: 'top left',
                                    msTransformOrigin: 'top left',
                                    MozTransformOrigin: 'top left',
                                    WebkitTransformOrigin: 'top left',
                                    transform: labelTransform,
                                    MsTransform: labelTransform,
                                    WebkitTransform: labelTransform,
                                    OTransform: labelTransform,
                                    MozTransform: labelTransform,
                                }}
                            >
                                <div id={this.state.printDivId} style={{ position: 'relative' }} ref={this.printRef}>
                                    {/* {label.layout.elements.map((value, index) => {
                                        return this.renderLayoutElement(value, index)
                                    })} */}
                                    {/* {arti.kit === 'SINGOLO' && ( */} {/* Re enable check if in need of different printing for kits */}
                                    <Label
                                        key={label.labelId}
                                        label={label}
                                        type="visualization"
                                        selectedArti={selectedArti}
                                        selectedCustomer={
                                            arti.jobCode
                                                ? arti
                                                : {
                                                      ...arti,
                                                      customerName,
                                                  }
                                        }
                                        worker={worker}
                                        selectedPackage={arti.packageobj[packageId]}
                                        currentSession={currentSectionWithCustom}
                                    />
                                    {/* )} */}
                                    {arti.kit !== 'SINGOLO' && false && (
                                        <Label
                                            key={label.labelId}
                                            label={label}
                                            type="visualization"
                                            selectedArti={selectedArti}
                                            selectedCustomer={
                                                arti.jobCode
                                                    ? arti
                                                    : {
                                                          ...arti,
                                                          customerName,
                                                      }
                                            }
                                            worker={worker}
                                            selectedPackage={arti.packageobj[packageId]}
                                            currentSession={currentSectionWithCustom}
                                        />
                                    )}
                                </div>
                            </div>
                        </View>
                        {!this.state.showLabel && (
                            <Button
                                color="green"
                                disabled={this.state.printing}
                                style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 999 }}
                                onClick={() => {
                                    this.setState({ showLabel: true })
                                }}
                            >
                                STAMPA
                            </Button>
                        )}
                    </>
                )}
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),

        getSessionDataRequest: (result) => GETSESSIONDATA_ACTIONS.request(result, dispatch),
        getSessionDataResult: (result) => GETSESSIONDATA_ACTIONS.result(result, dispatch),
        getSessionDataError: (result) => GETSESSIONDATA_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getCustomerList: {
            data: { data: customers },
            fetching: fetchCustomers,
        },
        getSessionData: {
            data: { data: session },
        },
        getLabelList,
        loginEmployee: {
            data: { user: worker },
        },
        printer,
    } = state

    let customerOptions = []
    let customerMap = {}
    for (let i in customers) {
        let type = customers[i]
        customerOptions.push({
            key: type.customerId,
            text: type.customerName,
            value: type.customerId,
        })
        customerMap[type.customerId] = type.customerName
    }

    return {
        session: session ? session : [],
        getLabelList,
        worker,
        customerOptions,
        customerMap,
        printer,
    }
}
export default withUserAgent(withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Labeling))))
