import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input, Modal } from 'semantic-ui-react'
import MaterialEditor from '../job/MaterialEditor'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function MaterialDropdown({ network, onChange, defaultSelectedMaterials = [], onlySelection }) {
    const [options, setOptions] = useState([])
    const [materials, setMaterials] = useState({})
    const [input, setInput] = useState('')
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [selectedMaterials, setSelectedMaterials] = useState([])
    const forceUpdate = useForceUpdate()

    const dropdownValue = []
    for (const material of selectedMaterials) {
        if (material && material.materialId) {
            dropdownValue.push(material.materialId)
        }
    }

    const handleAddition = (e, { value }) => {
        setInput(value)
        setEditing(true)
    }

    let dirtySelection = false
    for (let i = 0; i < defaultSelectedMaterials.length; i++) {
        if (JSON.stringify(defaultSelectedMaterials[i]) !== JSON.stringify(selectedMaterials[i])) {
            dirtySelection = true
        }
    }

    useEffect(() => {
        setSelectedMaterials(defaultSelectedMaterials)
    }, [dirtySelection])

    useEffect(() => {
        network.getMaterialList().then((data) => {
            const materiallist = data.data
            const materialobj = {}

            let materialOptions = []
            for (let i in materiallist) {
                let material = materiallist[i]
                materialobj[material.materialId] = material
                materialOptions.push({
                    key: `material_${material.materialId}`,
                    text: `${material.materialId} - ${material.materialDescription}`,
                    value: material.materialId,
                })
            }

            setMaterials(materialobj)
            setOptions(materialOptions)
            setDirty(false)
        })
    }, [dirty])

    const extraProps = {}
    if (!onlySelection) {
        extraProps.allowAdditions = true
        extraProps.onAddItem = handleAddition
    }

    return (
        <>
            <Dropdown
                onChange={(ev, data) => {
                    if (onChange) {
                        const selectedList = []
                        for (const key of data.value) {
                            selectedList.push(materials[key])
                        }

                        setSelectedMaterials(selectedList)
                        onChange(ev, data, data.value, selectedList)
                    }
                }}
                placeholder="Materiale"
                fluid
                multiple
                search
                selection
                options={options}
                value={dropdownValue}
                {...extraProps}
            />
            {!onlySelection && (
                <>
                    {selectedMaterials.map((value, index) => {
                        if (!value || (value.materialType && value.materialType !== 0)) {
                            return null
                        }
                        return (
                            <Form.Group widths="equal" key={`material-dropdown-${value.materialId}`}>
                                <Form.Field
                                    id={`form-input-control-material-percent-${index}`}
                                    control={Input}
                                    label={`Percentuale materiale ${value.materialDescription}`}
                                    name="materialPercent"
                                    type="number"
                                    step="0"
                                    value={value.materialPercent}
                                    onChange={(e) => {
                                        const toEdit = selectedMaterials[index]
                                        toEdit.materialPercent = e.target.value
                                        forceUpdate()
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                        )
                    })}
                </>
            )}

            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi materiale</Modal.Header>
                <Modal.Content>
                    <MaterialEditor
                        defaultDescription={input}
                        onSave={(material) => {
                            setEditing(false)
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default withRouter(connectNetwork(MaterialDropdown))
