export function createCategory(id, category) {
    let { createCategoryRequest, createCategoryError, createCategoryResult } = this.props

    delete category.creationDate;
    delete category.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'app/category/save',
        this.createBundle('app/category/save', { id, category }, this.POST, createCategoryRequest, createCategoryResult, createCategoryError)
    )
}
