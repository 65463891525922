export function deleteTask(data) {
    return new Promise((resolve, reject) => {
        let { deleteTaskRequest, deleteTaskError, deleteTaskResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'delete_task',
            this.createBundle(
                'delete_task',
                { task: data },
                this.POST,
                deleteTaskRequest,
                (result) => {
                    deleteTaskResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteTaskError(error)
                    reject(error)
                }
            )
        )
    })
}
