import { DELETEPACKAGE_TYPES } from '../actions/deletePackage'
import reducer, { defaultState } from './requests'
const Reducer = (state = defaultState, action) => {
    let requestReducer = reducer(DELETEPACKAGE_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer