import { Box } from '@chakra-ui/layout'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

export default function DropFile({ children, onFileSelected }) {
    const onDrop = useCallback(onFileSelected, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <>
            <Box p={6} placeholder style={{ minHeight: 'auto', margin: 0, padding: 0 }} {...getRootProps()}>
                <Box textStyle="header" style={{ marginBottom: 0, marginTop: 10, marginLeft: 10 }}>
                    {isDragActive ? <p>Trascina qui il file...</p> : <p>Trascina qui un file, o clicca per selezionarlo</p>}
                    <input {...getInputProps()} />
                </Box>
            </Box>
            {children}
        </>
    )
}
