export function getMaterial(data, materialCode) {
    return new Promise((resolve, reject) => {
        let { getMaterialRequest, getMaterialError, getMaterialResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_material',
            this.createBundle(
                'get_material',
                { materialId: data, materialCode },
                this.GET,
                getMaterialRequest,
                (result) => {
                    getMaterialResult(result)
                    resolve(result)
                },
                (error) => {
                    getMaterialError(error)
                    reject(error)
                }
            )
        )
    })
}
