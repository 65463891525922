import { Center, GridItem, Text } from '@chakra-ui/layout'
import 'moment/locale/it'
import React from 'react'
import { ImageDefault } from '../components'

export default function GridCell({ title, text, image, imageAltText, rowSpan, colSpan, barcode }) {
    // console.log('Image is', image, imageAltText)
    return (
        <GridItem border="1px" rowSpan={rowSpan ? rowSpan : 1} colSpan={colSpan ? colSpan : 1}>
            <span style={{ position: 'relative', top: 2, left: 2, fontWeight: 'bold' }}>{title}</span>
            {text && (
                <Center>
                    <Text fontSize="2xl">{text}</Text>
                </Center>
            )}
            {image && (
                <ImageDefault
                    fromRoot
                    src={image}
                    alt={imageAltText}
                    style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 2, width: 220, height: 220, objectFit: 'contain' }}
                />
            )}
            {barcode && (
                <div className="barcode-container" style={{ margin: 16 }}>
                    <svg id={barcode}></svg>
                </div>
            )}
        </GridItem>
    )
}
