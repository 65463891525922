import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Modal, Table } from 'semantic-ui-react'
import KitEditor from '../job/KitEditor'
import { baseViewDispatch, initializePage } from '../lib/util'
import Kit from './Kit'
function Kits(props) {
    const { network, table, time, label, field, format, toolbarResult, filtersResult, sortsResult, deleteKit, mergeKit } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        kit: null,
    })

    const [kits, setKits] = useState([])
    const [dirty, setDirty] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        network.getKitList().then((data) => {
            // console.log(data)

            const kits = []
            for (const key in data.data) {
                kits.push(data.data[key])
            }
            setKits(kits)
            setDirty(false)
        })
    }, [dirty, deleteKit.fetching, mergeKit.fetching])

    useEffect(() => {
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    if (!kits || kits.length === 0) return null

    // console.log('Kits are', kits)
    return (
        <>
            <Table celled>
                <Kit type="header" />
                <Table.Body>
                    {kits.map((value, index) => {
                        return (
                            <Kit
                                key={value.kitId}
                                type="table"
                                kit={value}
                                onRowClick={(state) => {
                                    const { kitId } = state
                                    setEditing(kitId)
                                }}
                            />
                        )
                    })}
                </Table.Body>
            </Table>

            <Modal closeIcon open={!!editing} onClose={() => setEditing(false)}>
                <Modal.Header>Modificando kit</Modal.Header>
                <Modal.Content>
                    <KitEditor
                        kitId={editing}
                        onSave={(kit) => {
                            setEditing(false)
                            setKits([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteKit, mergeKit } = state

    return {
        getMachineList,
        deleteKit,
        mergeKit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Kits)))
