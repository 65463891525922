import { SORTS_TYPES } from '../actions/sorts'
const Reducer = (
    state = {
        
    },
    action
) => {
    switch (action.type) {
        case SORTS_TYPES.result: {
            let { result } = action
            return {
                ...result
            }
        }
        default:
            return state
    }
}
export default Reducer
