import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Modal, Table } from 'semantic-ui-react'
import Customer from '../job/Customer'
import { baseViewDispatch, initializePage } from '../lib/util'
import CustomerEditor from './CustomerEditor'
function Customers(props) {
    const { network, table, time, label, field, format, toolbarResult, filtersResult, sortsResult, deleteCustomer, mergeCustomer, sub, login } = props
    const [showNoteEditor, setShowNoteEditor] = useState({
        show: false,
        customer: null,
    })

    const [customers, setCustomers] = useState([])
    const [dirty, setDirty] = useState(false)
    const [creating, setCreating] = useState(false)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        network.getCustomerList().then((data) => {
            // console.log(data)

            const customers = []
            for (const key in data.data) {
                customers.push(data.data[key])
            }
            setCustomers(customers)
            setDirty(false)
        })
    }, [dirty, deleteCustomer.fetching]) // mergeCustomer.fetching

    useEffect(() => {
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []
        if (user && !!user.manage_article) {
            extraTools.push({
                name: 'Crea nuovo cliente',
                icon: 'add',
                action: () => {
                    setCreating(true)
                },
            })
            // extraTools.push({
            //     name: 'Importa stampi da file',
            //     icon: 'check',
            //     action: () => {
            //         this.setState({
            //             showImport: true,
            //         })
            //     },
            // })
        }

        const toolbar = sub
            ? []
            : [
                  ...extraTools,
                  {
                      name: '',
                      icon: 'arrow left',
                      position: 'right',
                      action: () => {
                          this.prev()
                      },
                  },
                  {
                      name: '',
                      icon: 'arrow right',
                      position: 'none',
                      action: () => {
                          this.next()
                      },
                  },
              ]

        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, toolbar, [], [])
    }, [])

    if (!customers || customers.length === 0) return null

    return (
        <>
            <Table celled>
                <Customer type="header" />
                <Table.Body>
                    {customers.map((value, index) => {
                        return (
                            <Customer
                                key={value.customerId}
                                type="table"
                                customer={value}
                                onRowClick={(state) => {
                                    const { customerId } = state
                                    setEditing(customerId)
                                }}
                            />
                        )
                    })}
                </Table.Body>
            </Table>

            <Modal
                closeIcon
                open={!!editing || !!creating}
                onClose={() => {
                    setCreating(false)
                    setEditing(false)
                }}
            >
                <Modal.Header>{creating ? 'Creando cliente' : 'Modificando cliente'}</Modal.Header>
                <Modal.Content>
                    <CustomerEditor
                        customerId={creating ? null : editing}
                        onSave={(customer) => {
                            setCreating(false)
                            setEditing(false)
                            setCustomers([])
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setCreating(false)
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, deleteCustomer, mergeCustomer, login } = state

    return {
        getMachineList,
        deleteCustomer,
        mergeCustomer,
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Customers)))
