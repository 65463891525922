import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import LocationPicker from 'react-leaflet-location-picker'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickMultipleImage } from '../components'

class OfficeEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { office } = this.props.match.params

        if (!id) {
            id = office
        }

        // console.log('OfficeEditor params match', this.props.match, id)

        this.state = {
            id,
            officeImages: [],
        }
        this.runGeocoding = debounce(1200, false, this.throttleRunGeocoding)
    }

    componentDidMount() {
        let { id } = this.state
        // console.log('OfficeEditor id is', id)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (id) {
            this.props.network.getOffice(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('OfficeEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        const { runReverseGeocoding } = this.props
        if (!runReverseGeocoding.fetching && prevProps.runReverseGeocoding.fetching) {
            // console.log('Run reverse geocoding result:', runReverseGeocoding.data)
            const result = runReverseGeocoding.data.data.items[0]
            this.setState({
                officeLatitude: result.position.lat,
                officeLongitude: result.position.lng,
            })
        }

        let { getOffice } = this.props
        if (getOffice && getOffice.fetching !== prevProps.getOffice.fetching && !getOffice.fetching) {
            if (getOffice.status.success) {
                let office = getOffice.data.data
                // console.log('Setting state with office:', office)
                let { id, ...rest } = office

                this.setState({
                    ...rest,
                })
            }
        }
    }

    save() {
        // TODO value checking
        let { officeId, officeName, officeDescription, officeOrdering, officeAddress, officeImages, officeLatitude, officeLongitude } = this.state

        this.props.network.createOffice({
            officeId,
            officeName,
            officeDescription,
            officeOrdering,
            officeAddress,
            officeImages,
            officeLatitude,
            officeLongitude,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    throttleRunGeocoding() {
        const { officeAddress } = this.state
        const { network } = this.props

        network.runReverseGeocoding(officeAddress)
    }

    render() {
        let { creating, officeName, officeDescription, officeImages, officeLatitude, officeLongitude, officeAddress } = this.state

        let { getOffice } = this.props

        // console.log('OfficeEditor state:', officeLatitude, officeLongitude)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getOffice.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-office-name"
                            control={Input}
                            label={<span className="label">Nome sede</span>}
                            name="officeName"
                            value={officeName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-office-name"
                            control={Input}
                            label={<span className="label">Descrizione</span>}
                            name="officeDescription"
                            value={officeDescription}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>

                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-office-name"
                            control={Input}
                            label={<span className="label">Indirizzo</span>}
                            name="officeAddress"
                            value={officeAddress}
                            onChange={(e) => this.handleInput(e)}
                            onKeyPress={(key) => {
                                if (key.key === 'Enter') {
                                    this.runGeocoding()
                                }
                            }}
                            onBlur={() => {
                                this.runGeocoding()
                            }}
                            placeholder=""
                            labelPosition="right"
                        >
                            <input />
                            <Button
                                icon
                                onClick={() => {
                                    this.runGeocoding()
                                }}
                            >
                                <Icon name="search" />
                            </Button>
                        </Form.Field>
                    </Form.Group>

                    <div className="location-picker">
                        <LocationPicker
                            showInputs={false}
                            showControls={false}
                            startPort={
                                officeLatitude && officeLongitude
                                    ? {
                                          center: [officeLatitude, officeLongitude],
                                          zoom: 10,
                                      }
                                    : {
                                          center: [43.615849, 13.51874],
                                          zoom: 10,
                                      }
                            }
                            pointMode={{
                                banner: false,
                                control: {
                                    values: [[officeLatitude ? parseFloat(officeLatitude) : 0, officeLongitude ? parseFloat(officeLongitude) : 0]],
                                    onClick: (point) => {
                                        this.setState({
                                            officeLatitude: point[0],
                                            officeLongitude: point[1],
                                        })
                                    },
                                    onRemove: () => {
                                        this.setState({
                                            officeLatitude: 0,
                                            officeLongitude: 0,
                                        })
                                    },
                                },
                            }}
                        />
                    </div>

                    <PickMultipleImage
                        onChange={(images) => {
                            this.setState({ officeImages: images })
                        }}
                        value={officeImages}
                    />
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form completato con successo" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        runReverseGeocoding,
        createOffice: { data: createResult, fetching: creating, status: createStatus },
        getOffice,
    } = state

    return {
        runReverseGeocoding,
        createResult,
        creating,
        getOffice,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(OfficeEditor)))
