export function createWork(data, isEmployee, workorders) {
    let { createWorkRequest, createWorkError, createWorkResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_work',
        this.createBundle('create_work', { work: data, workorders }, this.POST, createWorkRequest, createWorkResult, createWorkError, isEmployee)
    )
}
