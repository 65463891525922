export function getDetailedClientOrderList(page, items, search, isEmployee, external, urgencyLevel, filters) {
    let { getDetailedClientOrderListRequest, getDetailedClientOrderListError, getDetailedClientOrderListResult } = this.props

    let reqobj = {
        page,
        items,
        search,
        urgency: urgencyLevel !== undefined ? urgencyLevel : 'all',
    }

    if (filters) {
        reqobj.filters = filters
    }

    if (external !== undefined && external !== null && external !== 'false') {
        reqobj.external = 1
    }

    // console.log('ClientOrders', 'get_detailed_client_order_list')
    let bundle = this.createBundle(
        'get_detailed_client_order_list',
        reqobj,
        this.POST,
        getDetailedClientOrderListRequest,
        getDetailedClientOrderListResult,
        getDetailedClientOrderListError,
        isEmployee
    )

    this.doAction(this.WEB_REQUEST_URL + 'get_detailed_client_order_list', bundle)
}
