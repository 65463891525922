import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { } from 'semantic-ui-react'

class Admin extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <View fullw>Amministratori</View>
    }
}

const mapStateToProps = (state, ownProps) => ({})
export default withRouter(connect(mapStateToProps)(connectNetwork(Admin)))
