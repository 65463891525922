export function getSessionDataList(data) {
	let { getSessionDataListRequest, getSessionDataListError, getSessionDataListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_sessiondata_list',
		this.createBundle(
			'get_sessiondata_list',
			{ sessiondata: data },
			this.GET,
			getSessionDataListRequest,
			getSessionDataListResult,
			getSessionDataListError
		)
	);
}
