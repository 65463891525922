export function getProduction(data) {
    return new Promise((resolve, reject) => {
		let { getProductionRequest, getProductionError, getProductionResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_production',
			this.createBundle(
				'get_production',
				{ production: data },
				this.GET,
				getProductionRequest,
                (result) => {
                    getProductionResult(result)
                    resolve(result)
                },
                (error) => {
                    getProductionError(error)
                    reject(error)
                }
			)
		);
	})
}
