export function createMachine(data) {
    let { createMachineRequest, createMachineError, createMachineResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_machine',
        this.createBundle('create_machine', { machine: data }, this.POST, createMachineRequest, createMachineResult, createMachineError)
    )
}
