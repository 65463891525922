export function getFromIdFiltering(name, id, filters) {
    let { getFromIdFilteringRequest, getFromIdFilteringError, getFromIdFilteringResult } = this.props

    // this.doAction(
    //     this.HADES + 'get_from_id_filtering',
    //     this.createBundle(
    //         'get_from_id_filtering',
    //         { name, id, filters },
    //         this.POST,
    //         getFromIdFilteringRequest,
    //         getFromIdFilteringResult,
    //         getFromIdFilteringError
    //     )
    // )
}
