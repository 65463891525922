export function getIntervalList(data) {
    return new Promise((resolve, reject) => {
		let { getIntervalListRequest, getIntervalListError, getIntervalListResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_interval_list',
			this.createBundle(
				'get_interval_list',
				{ interval: data },
				this.GET,
				getIntervalListRequest,
                (result) => {
                    getIntervalListResult(result)
                    resolve(result)
                },
                (error) => {
                    getIntervalListError(error)
                    reject(error)
                }
			)
		);
	})
}
