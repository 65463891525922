import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { } from 'semantic-ui-react'
import { baseViewDispatch, floatTimeToString, timeObject } from '../lib/util'

class TimeSpan extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { label, display, time, totalTime, size, hideHours, hideMinutes } = this.props

        let timeArray = time
        let totalTimeFloat = 0
        let timeObj = {}

        if (time) {
            totalTimeFloat = time[0] + time[1]
            timeObj = timeObject(time[0], time[1])
        } else if (totalTime !== undefined && totalTime !== null) {
            timeArray = [0, 0]
            timeArray[0] = Math.floor(totalTime)
            timeArray[1] = (totalTime % 1) * 60
            timeObj = timeObject(timeArray[0], timeArray[1])
            totalTimeFloat = totalTime
        }

        let asize = null
        switch (display) {
            case 'block':
                asize = null
                if (size) {
                    asize = size
                }
                return (
                    <Box alignItems="center" size={asize} style={{ marginBottom: 0, alignSelf: 'center' }}>
                        {label && <Box fontSize="lg" textAlign={['center', 'center']}>{label}</Box>}
                        {!hideHours && <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{timeObj.hstring}</Box>}
                        {!hideMinutes && <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']} className="smallStatistic">{timeObj.mstring}</Box>}
                    </Box>
                )
            default:
            case 'inline':
                asize = 'mini'
                if (size) {
                    asize = size
                }
                return (
                    <Box alignItems="center" size={asize} style={{ marginTop: 8 }}>
                        <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>{floatTimeToString(totalTimeFloat)}</Box>
                    </Box>
                )
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(TimeSpan)))
