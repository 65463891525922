import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Input, Message, Select } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickMultipleImage } from '../components'

class FamilyEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { family } = this.props.match.params

        if (!id) {
            id = family
        }

        // console.log('FamilyEditor params match', this.props.match, id)

        this.state = {
            id,
            familyImages: [],
        }
    }

    componentDidMount() {
        let { id } = this.state
        // console.log('FamilyEditor id is', id)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (id) {
            this.props.network.getFamily(id)
        }
        this.props.network.getCollectionList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('FamilyEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getFamily } = this.props
        if (getFamily && getFamily.fetching !== prevProps.getFamily.fetching && !getFamily.fetching) {
            if (getFamily.status.success) {
                let family = getFamily.data.data
                // console.log('Setting state with family:', family)
                let { id, ...rest } = family
                this.setState(rest)
            }
        }
    }

    save() {
        // TODO value checking
        let { familyId, familyName, familyCode, familyDescription, familyOrdering, familyImages, collectionId } = this.state

        this.props.network.createFamily({
            familyId,
            familyName,
            familyCode,
            familyDescription,
            familyOrdering,
            familyImages,
            collectionId,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { creating, collectionId, familyName, familyCode, familyDescription, familyImages } = this.state

        let { getFamily, collectionOptions } = this.props

        // console.log('JobEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getFamily.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-family-name"
                            control={Input}
                            label={<span className="label">Nome famiglia</span>}
                            name="familyName"
                            value={familyName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-family-code"
                            control={Input}
                            label={<span className="label">Codice famiglia</span>}
                            name="familyCode"
                            value={familyCode}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-family-name"
                            control={Input}
                            label={<span className="label">Descrizione</span>}
                            name="familyDescription"
                            value={familyDescription}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-family-type"
                            control={Select}
                            options={collectionOptions}
                            label={<span className="label">Collezione di cui fa parte la famiglia</span>}
                            placeholder="Collezione di cui fa parte la famiglia"
                            name="collectionId"
                            value={collectionId}
                            onChange={(e, data) => {
                                let id = data.value
                                // console.log('Collection selected', id)
                                this.setState({ collectionId: id })
                            }}
                        />
                    </Form.Group>
                    <PickMultipleImage
                        onChange={(images) => {
                            this.setState({ familyImages: images })
                        }}
                        value={familyImages}
                    />
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form completato con successo" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getCollectionList: {
            data: { data: collections },
            fetching: fetchCollection,
        },
        createFamily: { data: createResult, fetching: creating, status: createStatus },
        getFamily,
    } = state

    let collectionOptions = []
    for (let i in collections) {
        let type = collections[i]
        collectionOptions.push({
            key: type.collectionId,
            text: type.collectionName,
            value: type.collectionId,
        })
    }

    // console.log('Collection options:', collectionOptions, collections)

    return {
        collectionOptions,
        fetchCollection,
        createResult,
        creating,
        getFamily,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(FamilyEditor)))
