export function getTimeCalendarList(data) {
    return new Promise((resolve, reject) => {
		let { getTimeCalendarListRequest, getTimeCalendarListError, getTimeCalendarListResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_timecalendar_list',
			this.createBundle(
				'get_timecalendar_list',
				{ timecalendar: data },
				this.GET,
				getTimeCalendarListRequest,
                (result) => {
                    getTimeCalendarListResult(result)
                    resolve(result)
                },
                (error) => {
                    getTimeCalendarListError(error)
                    reject(error)
                }
			)
		);
	})
}
