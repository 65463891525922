import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { CustomTable, Item } from '../components'
import moment from 'momentconfig'

class Items extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { getItemCosts, network } = this.props

        if (this.props.jobId !== prevProps.jobId) {
            network.getItemCosts(
                this.props.jobId,
                [moment(this.props.dateRange[0]).format('YYYY-MM-DD'), moment(this.props.dateRange[1]).format('YYYY-MM-DD')],
                this.props.jobAliases
            )
        }

        if (getItemCosts && getItemCosts.fetching !== prevProps.getItemCosts.fetching && !getItemCosts.fetching) {
            if (getItemCosts.status.success && getItemCosts.data.data) {
                let transactions = {}
                let categories = {}
                for (const data of getItemCosts.data.data) {
                    let records = data.data.recordset
                    for (let i in records) {
                        let action = records[i].FUNCTIONCODE
                        if (!transactions[action]) {
                            transactions[action] = {
                                itemDict: {},
                                items: [],
                                action,
                            }
                        }
                        for (let j in records[i].SUPPLIERPARTNUMBER) {
                            if (!transactions[action].itemDict[records[i].SUPPLIERPARTNUMBER[j]]) {
                                transactions[action].itemDict[records[i].SUPPLIERPARTNUMBER[j]] = []
                            }
                            let item = {
                                ...records[i],
                                cost: records[i].UNITCOST[j],
                                supplier: records[i].SUPPLIERNUMBER[j],
                                groupcode: records[i].ITEMGROUP[j],
                                itemcode: records[i].ITEMNUMBER[j],
                                itemaliascode: records[i].ITEMALIASNUMBER[j],
                            }
                            transactions[action].itemDict[records[i].SUPPLIERPARTNUMBER[j]].push(item)
                        }

                        let lastUpdateIndex = records[i].UNITCOST.length - 1
                        let lastUpdatedItem = {
                            ...records[i],
                            cost: records[i].UNITCOST[lastUpdateIndex],
                            supplier: records[i].SUPPLIERNUMBER[lastUpdateIndex],
                            groupcode: records[i].ITEMGROUP[lastUpdateIndex],
                            itemcode: records[i].ITEMNUMBER[lastUpdateIndex],
                            itemaliascode: records[i].ITEMALIASNUMBER[lastUpdateIndex],
                            lastUpdateIndex,
                        }

                        transactions[action].items.push(lastUpdatedItem)
                        if (!categories[lastUpdatedItem.groupcode]) {
                            categories[lastUpdatedItem.groupcode] = []
                        }
                        categories[lastUpdatedItem.groupcode].push(lastUpdatedItem)
                    }
                }

                let groups = []
                for (let category in categories) {
                    let list = categories[category]

                    let totalgroup = {
                        OPERATIONID: category,
                        groupcode: category,
                        QTY: 0,
                        totalcost: 0,
                        cost: 0, //avg per piece
                    }

                    for (let i in list) {
                        totalgroup.QTY += list[i].QTY
                        totalgroup.cost += list[i].cost
                        totalgroup.totalcost += list[i].cost * list[i].QTY
                    }
                    totalgroup.cost = Math.round((totalgroup.totalcost / totalgroup.QTY) * 100) / 100
                    totalgroup.totalcost = Math.round(totalgroup.totalcost * 100) / 100
                    groups.push(totalgroup)
                }

                this.setState({
                    itemCosts: transactions,
                    groups,
                })
            }
        }
    }

    componentDidMount() {
        let { jobId, network } = this.props
        network.getItemCosts(
            jobId,
            [moment(this.props.dateRange[0]).format('YYYY-MM-DD'), moment(this.props.dateRange[1]).format('YYYY-MM-DD')],
            this.props.jobAliases
        )
    }

    render() {
        let { itemCosts, groups } = this.state
        let {
            isBalance,
            getItemCosts: { fetching },
            onCostChange,
            onChange,
            selectable,
            selected,
            noActions,
            type,
            ...rest
        } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (itemCosts && itemCosts.WITHDRAW && itemCosts.WITHDRAW.items.length > 0) {
            let extraProps = {}
            if (isBalance) {
                extraProps = {
                    isBalance,
                    onCostChange: (total, shorthand) => (onCostChange ? onCostChange(total, shorthand) : 0),
                }
            }
            // console.log('itemCosts', itemCosts, groups)
            return (
                <CustomTable
                    childProps={{
                        grouped: type === 'grouped',
                    }}
                    {...extraProps}
                    name="Materiale"
                    shorthand="item"
                    values={type === 'grouped' ? groups : itemCosts.WITHDRAW.items}
                    child={Item}
                />
            )
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getItemCosts, search } = state

    return {
        getItemCosts,
        search,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Items)))
