export function getMachineList(search, isEmployee, filters, sorts, page = -1, items = 10) {
    return new Promise((resolve, reject) => {
        let { getMachineListRequest, getMachineListError, getMachineListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_machine_list',
            this.createBundle(
                'get_machine_list',
                { search: search ? search : '', filters, sorts, page, items },
                this.GET,
                getMachineListRequest,
                (result) => {
                    resolve(result)
                    getMachineListResult(result)
                },
                (error) => {
                    reject(error)
                    getMachineListError(error)
                },
                !!isEmployee
            )
        )
    })
}
