import * as htmlToImage from 'html-to-image'
import { connectNetwork } from '../lib/NetworkProvider'
import View from '../lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { pdfjs } from 'react-pdf'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Form, Icon, Input, Select } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { GETSESSIONDATA_ACTIONS } from '../actions/getSessionData'
import { Label } from '../components'
import { Labels } from '../containers'
import { baseViewDispatch } from '../lib/util'
import axios from 'axios'
import { documentToSVG, elementToSVG, inlineResources, formatXML } from 'dom-to-svg'
import { UserAgent, withUserAgent } from 'react-useragent'
import { Article } from 'components'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

moment.locale('it')

class CustomLabels extends Component {
    constructor(props) {
        super(props)
        this.state = this.defaultState(props)
        this.printRef = React.createRef()
    }

    defaultState(props) {
        const { articlecleId, packageId, customerId, jobCode } = this.props

        this.updateArticleList = debounce(1000, false, () => {
            const { network } = this.props
        })

        this.debounceUpdateCurrentImage = debounce(1000, true, this.updateCurrentImage)

        return {
            article: props.article ? props.article : {},
            label: {},
            pageNumber: 1,
            packageIndex: 0,
            articlecleId,
            packageId,
            customerId,
            jobCode,
            showLabel: false,
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
            workCode: '',
            clientWorkCode: '',
            description: '',
            amount: 0,
            lotto: '',
            seq: 1,
            search: '',
            customerName: '',
            printCount: 1,
            labelPreviewScale: 0.2,
        }
    }

    updateCurrentImage = () => {
        const artiinfo = {
            codice: this.state.workCode,
            clientWorkCode: this.state.clientWorkCode && this.state.clientWorkCode !== '' ? this.state.clientWorkCode : this.state.workCode,
            clientDescription: this.state.description,
        }
        axios
            .post(`${this.props.network.WEB_REQUEST_URL}find_image`, {
                artiinfo,
            })
            .then((result) => {})
            .catch((error) => {
                console.error('Error getting image for info', artiinfo)
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.search !== prevState.search) {
            this.updateArticleList()
        }

        if (this.state.workCode !== prevState.workCode || this.state.clientWorkCode !== prevState.clientWorkCode) {
            this.debounceUpdateCurrentImage()
        }
    }

    componentDidMount() {
        const { network } = this.props

        network.getStdArticleList(this.state.search)
        network.getCustomerList()
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages })
    }

    renderArticle(key, article) {
        let { selected } = this.state
        let { onChange, noActions } = this.props

        let extraProps = {}
        extraProps.onClick = (article) => {
            if (onChange) {
                onChange(article)
            }
            this.setState({ selected: article, workCode: article.articleCode, description: article.descrizio })
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return (
            <Article
                {...extraProps}
                selected={article.articleCode === selected.codice}
                type="full"
                key={`article_${key}`}
                article={article}
                noActions
            />
        )
    }

    padToDigits(toPad, digits) {
        let pad = ''
        for (let i = 0; i < digits + 1; i++) {
            pad += '0'
        }
        const formattedNumber = (pad + toPad).slice(-digits)

        return formattedNumber
    }

    generateSeq() {
        const { selected, lotto, seq, amount } = this.state

        switch (selected.tipo) {
            default:
            case 'PF':
                return `${selected.codice}${this.padToDigits(lotto, 6)}${this.padToDigits(amount, 6)}${this.padToDigits(seq, 3)}`
            case 'MP':
                return `${selected.codice}${this.padToDigits(seq, 15)}`
        }
    }

    handleInput(event) {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (typeof value === 'number') {
            value = value > 0 ? value : 0
        }

        this.setState({
            [name]: value,
        })
    }

    startPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 1 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    stopPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 0.2 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    render() {
        const { label, page, items, selected, workCode, customerName, clientWorkCode, descrizio, amount, seq, lotto, labelPreviewScale } = this.state
        const { worker, articles, fetchArticles, customerOptions } = this.props

        const article = selected

        const labelTransform = `scale(${labelPreviewScale}, ${labelPreviewScale})`

        return (
            <View column fullw fullh>
                <Input
                    icon={this.state.search.length > 0 ? <Icon name="delete" link onClick={() => this.clearSearch()} /> : <Icon name="search" />}
                    placeholder="Cerca..."
                    value={this.state.search}
                    onChange={(e, data) => {
                        let text = data.value
                        this.setState({ search: text })
                    }}
                />

                <View row fullw fullh>
                    <View column fullw fullh>
                        <View column style={{ width: '50%', height: '50%', overflowY: 'scroll' }}>
                            {articles &&
                                articles.slice(page, items + page * items).map((value, index) => {
                                    return this.renderArticle(value.codice, value)
                                })}
                        </View>
                        {article.articleCode && (
                            <>
                                <View column style={{ width: '50%', height: '20%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                    <View fullw fullh row style={{ marginLeft: 16, overflowX: 'hidden' }}>
                                        <Form
                                            success={this.state.status === 'success'}
                                            error={this.state.status === 'error'}
                                            warning={this.state.status === 'warning'}
                                        >
                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    id="form-input-control-arti-description"
                                                    control={Input}
                                                    label="Codice"
                                                    placeholder="Codice"
                                                    name="workCode"
                                                    value={workCode}
                                                    onChange={(e) => this.handleInput(e)}
                                                />
                                                <Form.Field
                                                    id="form-input-control-arti-description"
                                                    control={Input}
                                                    label="Codice cliente"
                                                    placeholder="Codice cliente"
                                                    name="clientWorkCode"
                                                    value={clientWorkCode}
                                                    onChange={(e) => this.handleInput(e)}
                                                />
                                                <Form.Field
                                                    id="form-input-control-job-type"
                                                    control={Select}
                                                    options={customerOptions}
                                                    label="Cliente"
                                                    placeholder="Cliente"
                                                    name="customerName"
                                                    value={customerName}
                                                    onChange={(e, data) => {
                                                        let name = data.value
                                                        this.setState({ customerName: name })
                                                    }}
                                                />
                                                <Form.Field
                                                    id="form-input-control-arti-description"
                                                    control={Input}
                                                    label="Descrizione"
                                                    placeholder="Descrizione"
                                                    name="descrizio"
                                                    value={descrizio}
                                                    onChange={(e) => this.handleInput(e)}
                                                />
                                            </Form.Group>

                                            <Form.Group widths="equal">
                                                <Form.Field
                                                    id="form-input-control-cost-cost"
                                                    control={Input}
                                                    type="number"
                                                    label="Quantità"
                                                    placeholder="0"
                                                    step="1"
                                                    name="amount"
                                                    value={amount}
                                                    onChange={(e) => this.handleInput(e)}
                                                />
                                                <Form.Field
                                                    id="form-input-control-cost-cost"
                                                    control={Input}
                                                    type="number"
                                                    label="Lotto"
                                                    placeholder="0"
                                                    step="1"
                                                    name="lotto"
                                                    value={lotto}
                                                    onChange={(e) => this.handleInput(e)}
                                                />
                                                <Form.Field
                                                    id="form-input-control-cost-cost"
                                                    control={Input}
                                                    type="number"
                                                    label="Sequenziale"
                                                    placeholder="0"
                                                    step="1"
                                                    name="seq"
                                                    value={seq}
                                                    onChange={(e) => this.handleInput(e)}
                                                />
                                                <Form.Field
                                                    id="form-input-control-print-count"
                                                    control={Input}
                                                    label="Numero stampe"
                                                    name="printCount"
                                                    type="number"
                                                    value={this.state.printCount}
                                                    onChange={(e) => this.handleInput(e)}
                                                    placeholder="1"
                                                />
                                            </Form.Group>
                                        </Form>
                                    </View>
                                </View>

                                <View column fullw style={{ width: '50%', height: '30%', overflowY: 'scroll' }}>
                                    <Labels
                                        sub
                                        selectable
                                        selected={label}
                                        onChange={(label) => {
                                            this.setState({ label, showLabel: true })
                                        }}
                                        custom
                                        noActions
                                    />
                                </View>
                            </>
                        )}
                    </View>
                </View>

                {label && label.layout && article && (
                    <>
                        <View
                            row
                            fullw
                            style={{
                                position: 'absolute',
                                bottom: 16,
                                right: -2,
                                width: Math.max(label.layout.layoutWidth * labelPreviewScale, 340),
                                height: label.layout.layoutHeight * labelPreviewScale + 64,
                                display: this.state.showLabel ? 'block' : 'none',
                                backgroundColor: 'white',
                                zIndex: 999,
                                border: 'black solid 2px',
                            }}
                        >
                            <View row around>
                                <Button
                                    color="green"
                                    onClick={async () => {
                                        const { network } = this.props
                                        var node = document.getElementById('print-preview')

                                        await this.startPrinting()
                                        this.setState({ printing: true })
                                        if (this.props.ua.mobile || this.props.ua.tablet) {
                                            const bounds = {
                                                height: label.layout.layoutHeight,
                                                width: label.layout.layoutWidth,
                                                x: 0,
                                                y: 0,
                                            }
                                            const svgDocument = elementToSVG(document.querySelector('#print-preview'), {
                                                bounds,
                                                avoidTextSelection: true,
                                            })

                                            await inlineResources(svgDocument.documentElement)

                                            let svgString = new XMLSerializer().serializeToString(svgDocument)

                                            const regex = /<style>.*?<\/style>/gis
                                            const mregex = /<mask[^>]*?>.*?<\/mask>/gis
                                            const amregex = /mask=".*?"/gis
                                            svgString = svgString.replace(regex, '').replace(mregex, '').replace(amregex, '')

                                            await network.createCommand({
                                                node: 'down_labels',
                                                action: 5,
                                                value: this.state.printCount ? this.state.printCount : 1,
                                                endpoint: 'down_labels',
                                                extradata: `${svgString}`,
                                            })
                                            // await network
                                            //     .sendToPrinter(
                                            //         svgString,
                                            //         'filename',
                                            //         this.state.printCount,
                                            //         {
                                            //             warehouseSequential: this.generateSeq(),
                                            //             creationDate: moment().format('DD/MM/YYYY'),
                                            //             currentDate: moment().format('DD/MM/YYYY'),
                                            //             pcsPerPackage: this.state.amount,
                                            //             articleQuantity: this.state.amount,
                                            //             paddedArticleQuantity: this.padToDigits(this.state.amount, 6),
                                            //             codop: this.state.lotto,
                                            //             lotto: this.state.lotto,
                                            //             seq: this.padToDigits(this.state.seq, 3),
                                            //             workCode: this.state.workCode,
                                            //             description: this.state.descrizio,
                                            //             clientWorkCode: this.state.clientWorkCode,
                                            //             sessionTargetPackageCount: this.state.amount,
                                            //             customerName: this.state.customerName,
                                            //         },
                                            //         {
                                            //             width: label.layout.layoutWidth,
                                            //             height: label.layout.layoutHeight,
                                            //             mobile: true,
                                            //             printer: this.props.printer,
                                            //         }
                                            //     )
                                            //     .then((result) => {
                                            //         // this.props.onPrintLabel(result)
                                            //     })
                                        } else {
                                            await htmlToImage
                                                .toPng(node, {
                                                    width: label.layout.layoutWidth,
                                                    height: label.layout.layoutHeight,
                                                })
                                                .then(async (dataUrl) => {
                                                    const filename = this.state.workCode
                                                    var img = new Image()
                                                    img.src = dataUrl

                                                    /* eslint-disable */
                                                    console.log('Data url is', dataUrl)

                                                    await network.createCommand({
                                                        node: 'down_labels',
                                                        action: 5,
                                                        value: this.state.printCount ? this.state.printCount : 1,
                                                        endpoint: 'down_labels',
                                                        extradata: `${dataUrl}`,
                                                    })
                                                    // network
                                                    //     .sendToPrinter(
                                                    //         dataUrl,
                                                    //         filename,
                                                    //         this.state.printCount,
                                                    //         {
                                                    //             warehouseSequential: this.generateSeq(),
                                                    //             creationDate: moment().format('DD/MM/YYYY'),
                                                    //             currentDate: moment().format('DD/MM/YYYY'),
                                                    //             pcsPerPackage: this.state.amount,
                                                    //             articleQuantity: this.state.amount,
                                                    //             paddedArticleQuantity: this.padToDigits(this.state.amount, 6),
                                                    //             codop: this.state.lotto,
                                                    //             lotto: this.state.lotto,
                                                    //             seq: this.padToDigits(this.state.seq, 3),
                                                    //             workCode: this.state.workCode,
                                                    //             description: this.state.descrizio,
                                                    //             clientWorkCode: this.state.clientWorkCode,
                                                    //             sessionTargetPackageCount: this.state.amount,
                                                    //             customerName: this.state.customerName,
                                                    //         },
                                                    //         {
                                                    //             width: label.layout.layoutWidth,
                                                    //             height: label.layout.layoutHeight,
                                                    //             mobile: false,
                                                    //             printer: this.props.printer,
                                                    //         }
                                                    //     )
                                                    //     .then((result) => {
                                                    //         // this.props.onPrintLabel(result)
                                                    //     })
                                                })
                                                .catch(function (error) {
                                                    console.error('oops, something went wrong!', error)
                                                })
                                        }
                                        this.setState({ printing: false })
                                        await this.stopPrinting()
                                    }}
                                >
                                    Stampa etichetta
                                </Button>
                                <Button
                                    color="red"
                                    onClick={() => {
                                        this.setState({ showLabel: false })
                                    }}
                                >
                                    Chiudi anteprima di stampa
                                </Button>
                                <Button
                                    color="grey"
                                    onClick={() => {
                                        this.setState({ labelPreviewScale: labelPreviewScale >= 0.8 ? 0.2 : 0.8 })
                                    }}
                                >
                                    {labelPreviewScale >= 0.8 ? 'Comprimi' : 'Espandi'}
                                </Button>
                            </View>
                            <div
                                style={{
                                    transformOrigin: 'top left',
                                    msTransformOrigin: 'top left',
                                    MozTransformOrigin: 'top left',
                                    WebkitTransformOrigin: 'top left',
                                    transform: labelTransform,
                                    MsTransform: labelTransform,
                                    WebkitTransform: labelTransform,
                                    OTransform: labelTransform,
                                    MozTransform: labelTransform,
                                }}
                            >
                                <div
                                    id="print-preview"
                                    style={{
                                        position: 'relative',
                                        width: label.layout.layoutWidth,
                                        height: label.layout.layoutHeight,
                                        backgroundColor: 'white',
                                    }}
                                    ref={this.printRef}
                                >
                                    <Label
                                        key={label.labelId}
                                        label={label}
                                        type="visualization"
                                        selectedArti={{
                                            warehouseSequential: this.generateSeq(),
                                            creationDate: moment().format('DD/MM/YYYY'),
                                            currentDate: moment().format('DD/MM/YYYY'),
                                            pcsPerPackage: this.state.amount,
                                            articleQuantity: this.state.amount,
                                            paddedArticleQuantity: this.padToDigits(this.state.amount, 6),
                                            codop: this.state.lotto,
                                            lotto: this.state.lotto,
                                            seq: this.padToDigits(this.state.seq, 3),
                                            workCode: this.state.workCode,
                                            description: this.state.descrizio,
                                            clientWorkCode: this.state.clientWorkCode,
                                            ...this.state.selected,
                                        }}
                                        currentSession={{
                                            sessionTargetPackageCount: this.state.amount,
                                        }}
                                        selectedCustomer={{
                                            customerName: this.state.customerName,
                                        }}
                                    />
                                </div>
                            </div>
                        </View>
                        {!this.state.showLabel && (
                            <Button
                                color="green"
                                style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 999 }}
                                onClick={() => {
                                    this.setState({ showLabel: true })
                                }}
                            >
                                Anteprima di stampa
                            </Button>
                        )}
                    </>
                )}
            </View>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),

        getSessionDataRequest: (result) => GETSESSIONDATA_ACTIONS.request(result, dispatch),
        getSessionDataResult: (result) => GETSESSIONDATA_ACTIONS.result(result, dispatch),
        getSessionDataError: (result) => GETSESSIONDATA_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getCustomerList: {
            data: { data: customers },
            fetching: fetchCustomers,
        },
        getStdArticleList: {
            data: { data: articles },
            fetching: fetchArticles,
        },
        getSessionData,
        getLabelList,
        loginEmployee: {
            data: { user: worker },
        },
        printer,
    } = state

    let customerOptions = []
    for (let i in customers) {
        let type = customers[i]
        customerOptions.push({
            key: type.customerId,
            text: type.customerName,
            value: type.customerName,
        })
    }

    return {
        fetchCustomers,
        customerOptions,
        articles,
        fetchArticles,
        session: getSessionData && getSessionData.data && getSessionData.data.data ? getSessionData.data.data : [],
        getLabelList,
        worker,
        printer,
    }
}
export default withUserAgent(withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CustomLabels))))
