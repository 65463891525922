export function getFromIdSubidFiltering(name, id, sub_id, filters) {
    let { getFromIdSubidFilteringRequest, getFromIdSubidFilteringError, getFromIdSubidFilteringResult } = this.props

    this.doAction(
        this.HADES + 'get_from_id_subid_filtering',
        this.createBundle(
            'get_from_id_subid_filtering',
            { name, id, sub_id, filters },
            this.POST,
            getFromIdSubidFilteringRequest,
            getFromIdSubidFilteringResult,
            getFromIdSubidFilteringError
        )
    )
}
