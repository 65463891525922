import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation } from '../components'
import { floatTimeToString, formatMoney, getAmount, timeString } from '../lib/util'

const { app, products: productRoute, productEdit } = routes

class Product extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, product } = props

        this.state = {
            deleting: false,
            ...product,
            productMinutes: product ? parseFloat(product.productMinutes) : 0,
        }

        if (onCostChange && product) {
            onCostChange(this.totalCost(), product.code)
        }
    }

    totalCost() {
        let { productHourlyRate, productHours, productMinutes } = this.state
        let total = (productHours + productMinutes / 60) * parseFloat(productHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { product, code, network } = this.props

        if ((!product || !product.code) && code) {
            network.getProduct(code)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getProduct, login } = this.props
        let { code, productHourlyRate } = this.state
        if (productHourlyRate !== prevState.productHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), code)
            }
        }

        // console.log('Product getProduct fetching', getProduct.fetching)
        if (getProduct && getProduct.fetching !== prevProps.getProduct.fetching && !getProduct.fetching) {
            // console.log('Done calling getProduct')
            if (getProduct.status.success) {
                // console.log(getProduct.data)
                let product = getProduct.data.data

                this.setState({ ...product })
                // console.log('Calling Get product', login, product)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No product found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { code, productHourlyRate, productHours, productMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{code}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={productHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    productHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{productHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(productHours, productMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideProduct, hideProductHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideProduct && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideProductHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideProduct, hideProductHours, isBalance } = this.props
        let { code, productHours, productMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideProduct && <Table.Cell>{code}</Table.Cell>}
                    {!hideProductHours && <Table.Cell>{timeString(productHours, productMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network } = this.props
        let { code } = this.state

        network.deleteProduct(code)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, product, onClick, selected, noActions } = this.props
        let {
            deleting,
            code,
            corpName,
            categoryName,
            description,
            price,
            categoryDiscount,
            corpDiscount,
            // productImage,
        } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(product)
            }
        }
        if (product && product.code === 1) {
            return null
        }

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()

            default:
                return (
                    <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.productCard} {...extraprops} >
                        <Confirmation
                            message="Vuoi davvero eliminare questo prodotto?"
                            showModal={deleting}
                            onConfirm={() => {
                                this.remove()
                            }}
                            onCancel={() => {
                                this.setState({ deleting: false })
                            }}
                        />
                        <Box textStyle="content">
                            <Box textStyle="header">{description}</Box>
                            <View between>
                                <Box textStyle="meta">{code}</Box>
                                <Box textStyle="meta">{categoryName}</Box>
                                <Box textStyle="meta" style={{ textTransform: 'capitalize' }}>{corpName}</Box>
                            </View>
                            <View style={{ paddingTop: 8 }}>
                                <View>
                                    <Box textStyle="description">Intero &nbsp;</Box>
                                    <Box textStyle="meta"> {formatMoney(price)}€</Box>
                                </View>
                                <View style={{ paddingLeft: 18 }}>
                                    <Box textStyle="description">Base &nbsp;</Box>
                                    <Box textStyle="meta">{formatMoney(getAmount(price, 1, `${categoryDiscount} + ${corpDiscount}`))}€</Box>
                                </View>
                            </View>
                        </Box>
                        {!noActions && (
                            <Box textStyle="content" extra>
                                {user && !!user.manage_product && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            // console.log('Going to', app, productRoute, productEdit, code)
                                            this.props.history.push(route_from(app, productRoute, productEdit, `${code}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Modifica
                                    </Button>
                                )}
                                {user && !!user.manage_product && (
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.openRemoveDialog()
                                        }}
                                    >
                                        <Icon name="delete" />
                                        Elimina
                                    </Button>
                                )}
                            </Box>
                        )}
                    </Box>
                )
            // return (
            // 	<View fullw column between>
            // 		<Box
            // 			className={selected ? 'rowSelected' : ''}
            // 			style={styles.productCard}
            // 			{...extraprops}
            // 			>
            // 			<Confirmation
            // 				message='Vuoi davvero eliminare questo prodotto?'
            // 				showModal={deleting}
            // 				onConfirm={() => {
            // 					this.remove()
            // 				}}
            // 				onCancel={() => {
            // 					this.setState({ deleting: false })
            // 				}}
            // 			/>
            // 			<Box textStyle="content" style={styles.productContent}>
            // 				<View fullw row>
            // 					{/* <div style={styles.imageContainer}>
            // 						<ImageDefault
            // 							fromRoot
            // 							src={productImage}
            // 							ui={false}
            // 							style={styles.productImage}
            // 						/>
            // 					</div> */}
            // 					<View fullw column>
            // 						<span style={styles.header}>{description}</span>

            // 						<Box textStyle="description">{code}</Box>
            // 						<Box textStyle="description">{corpName}</Box>
            // 						<Box textStyle="description">{categoryName}</Box>
            // 						<Box textStyle="description">{price}€</Box>
            // 						<Box textStyle="description">
            // 							{moment(date_created).format('DD/MM/YYYY HH:mm:ss')}
            // 						</Box>
            // 					</View>
            // 				</View>
            // 			</Box>
            // 			{!noActions && (
            // 				<Box textStyle="content" extra>
            // 					<Button
            // 						color='blue'
            // 						disabled={true}
            // 						onClick={() => {
            // 							this.props.history.push(route_from(app, product, productEdit, `${code}`))
            // 						}}>
            // 						<Icon name='edit' />
            // 						Modifica
            // 					</Button>
            // 					<Button
            // 						color='red'
            // 						disabled={true}
            // 						onClick={() => {
            // 							this.openRemoveDialog()
            // 						}}>
            // 						<Icon name='delete' />
            // 						Elimina
            // 					</Button>
            // 				</Box>
            // 			)}
            // 		</Box>
            // 	</View>
            // )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    productImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    productContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    productCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getProduct } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getProduct, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Product)))
