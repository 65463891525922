import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input, Modal } from 'semantic-ui-react'
import KitEditor from '../job/KitEditor'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function KitDropdown({ network, onChange, defaultSelectedKits = [], onlySelection }) {
    const [options, setOptions] = useState([])
    const [kits, setKits] = useState({})
    const [input, setInput] = useState('')
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [selectedKits, setSelectedKits] = useState([])
    const forceUpdate = useForceUpdate()

    const dropdownValue = []
    for (const kit of selectedKits) {
        if (kit && kit.kitId) {
            dropdownValue.push(kit.kitId)
        }
    }

    const handleAddition = (e, { value }) => {
        setInput(value)
        setEditing(true)
    }

    let dirtySelection = false
    for (let i = 0; i < defaultSelectedKits.length; i++) {
        if (JSON.stringify(defaultSelectedKits[i]) !== JSON.stringify(selectedKits[i])) {
            dirtySelection = true
        }
    }

    useEffect(() => {
        setSelectedKits(defaultSelectedKits)
    }, [dirtySelection])

    useEffect(() => {
        network.getKitList().then((data) => {
            const kitlist = data.data
            const kitobj = {}

            let kitOptions = []
            for (let i in kitlist) {
                let kit = kitlist[i]
                kitobj[kit.kitId] = kit
                kitOptions.push({
                    key: `kit_${kit.kitId}`,
                    text: `${kit.kitId} - ${kit.kitName}`,
                    value: kit.kitId,
                })
            }

            setKits(kitobj)
            setOptions(kitOptions)
            setDirty(false)
        })
    }, [dirty])

    const extraProps = {}
    if (!onlySelection) {
        extraProps.allowAdditions = true
        extraProps.onAddItem = handleAddition
    }

    return (
        <>
            <Dropdown
                onChange={(ev, data) => {
                    if (onChange) {
                        const selectedList = []
                        for (const key of data.value) {
                            selectedList.push(kits[key])
                        }

                        setSelectedKits(selectedList)
                        onChange(ev, data, data.value, selectedList)
                    }
                }}
                placeholder="Kit"
                fluid
                multiple
                search
                selection
                options={options}
                value={dropdownValue}
                {...extraProps}
            />
            {!onlySelection && (
                <>
                    {selectedKits.map((value, index) => {
                        if (!value) {
                            return null
                        }
                        return (
                            <Form.Group widths="equal" key={`kit-dropdown-${value.kitId}`}>
                                <Form.Field
                                    id={`form-input-control-kit-pcs-${index}`}
                                    control={Input}
                                    label={`Pezzi per kit ${value.kitName}`}
                                    name="masterPercent"
                                    type="number"
                                    step="0"
                                    value={value.pcsPerKit}
                                    onChange={(e) => {
                                        const toEdit = selectedKits[index]
                                        toEdit.pcsPerKit = e.target.value
                                        forceUpdate()
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                        )
                    })}
                </>
            )}

            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi kit</Modal.Header>
                <Modal.Content>
                    <KitEditor
                        defaultName={input}
                        onSave={(kit) => {
                            setEditing(false)
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default withRouter(connectNetwork(KitDropdown))
