export function deletePackage(data) {
	let { deletePackageRequest, deletePackageError, deletePackageResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_package',
		this.createBundle(
			'delete_package',
			{ package: data },
			this.POST,
			deletePackageRequest,
			deletePackageResult,
			deletePackageError
		)
	);
}
