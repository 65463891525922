import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { DashboardChart } from '../components'
class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chartData: [],
            chartDataReference: [],
            buffer: [],
            radialQuantity: [],
            radialScrap: [],
            stopChart: false,
        }
    }

    componentDidMount() {
        let { network } = this.props

        this.props.toolbarResult([])
        network.getMachineList('')
        network.getWorkerList()
    }

    render() {
        let { getMachineList } = this.props

        if (getMachineList.fetching || !getMachineList.data.data) {
            return null
        } else {
            let list = getMachineList.data.data
            return (
                <View noflex column fullw>
                    {list.map((value, index) => {
                        return <DashboardChart key={`dashboard-${value.machineId}`} terminal={value.machineIdentifier} />
                    })}
                </View>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Dashboard)))
