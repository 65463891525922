import React, { useState } from 'react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from 'lib/View'
import Iframe from 'react-iframe'
import { Button, Form, Input, Select, Table } from 'semantic-ui-react'
import { Article, Deposit } from '../components'
import moment from 'momentconfig'
import Material from 'printing/Material'

function WarehouseLoad({ toolbarResult, filtersResult, sortsResult, network, machines, isEmployee, depositList }) {
    useEffect(() => {
        network.getDepositList()
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    const [scannedCode, setScannedCode] = useState('')
    const [lastCode, setLastCode] = useState('')
    const [articleCode, setArticleCode] = useState('')
    const [depositCode, setDepositCode] = useState(null)
    const [quantity, setQuantity] = useState(0)
    const [deposit, setDeposit] = useState(null)
    const [article, setArticle] = useState(null)
    const [material, setMaterial] = useState(null)
    const [sorts, setSorts] = useState({})

    let options = []
    if (depositList) {
        for (let d of depositList) {
            options.push({ key: d.id, value: d.id, text: d.name })
        }
    }

    options.push()

    return (
        <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Scansione</span>}
                        name="scannedCode"
                        value={scannedCode}
                        onChange={(e) => {
                            setScannedCode(e.target.value)
                        }}
                        placeholder={!article && !material ? 'SCANSIONA ARTICOLO O MATERIALE' : !deposit ? 'SCANSIONA DEPOSITO' : 'INSERIRE QUANTITA'}
                        style={{
                            fontSize: 64,
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                if (!article && !material) {
                                    setArticleCode(scannedCode)
                                } else if (!deposit) {
                                    setDepositCode(scannedCode)
                                } else {
                                    setQuantity(parseFloat(scannedCode))
                                }
                                setLastCode(scannedCode)
                                setScannedCode('')
                            }
                        }}
                    />
                </Form.Group>
                {/* <Form.Group inline widths="equal">
                    <Form.Field
                        id="form-input-control-deposit"
                        control={Select}
                        options={options}
                        label={<span className="label">Deposito</span>}
                        placeholder="Deposito"
                        name="deposit"
                        value={deposit}
                        onChange={(e, data) => {
                            let dep = data.value
                            // console.log('Deposit selected', dep)
                            setDeposit(dep)
                        }}
                    />
                </Form.Group> */}
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-article-code"
                        control={Input}
                        label={<span className="label">Quantità</span>}
                        name="quantity"
                        value={quantity}
                        onChange={(e) => {
                            setQuantity(e.target.value)
                        }}
                        placeholder="Quantità"
                    />
                </Form.Group>
            </Form>

            {articleCode && (
                <Article
                    isEmployee={!!isEmployee}
                    noActions
                    articleCode={articleCode}
                    onLoad={(article) => {
                        if (article && article.articleId) {
                            setArticle(article)
                        }
                    }}
                />
            )}
            {articleCode && (
                <>
                    <Table celled sortable>
                        <Material
                            type="header"
                            sorts={sorts}
                            onMaterialCodeHeaderClick={() => {
                                if (!sorts.materialCode) {
                                    sorts.materialCode = 'asc'
                                } else if (sorts.materialCode === 'asc') {
                                    sorts.materialCode = 'desc'
                                } else {
                                    delete sorts.materialCode
                                }
                                setSorts(sorts)
                            }}
                            noActions
                        />
                        <Material
                            type="table"
                            isEmployee={!!isEmployee}
                            noActions
                            materialCode={articleCode}
                            onLoad={(material) => {
                                if (material && material.materialId) {
                                    setMaterial(material)
                                }
                            }}
                        />
                    </Table>
                </>
            )}

            {depositCode && (
                <Deposit
                    isEmployee={!!isEmployee}
                    noActions
                    id={depositCode}
                    onLoad={(deposit) => {
                        setDeposit(deposit)
                    }}
                />
            )}

            <Button
                disabled={quantity <= 0 || (!article && !material) || !deposit}
                onClick={() => {
                    network
                        .createDocument(
                            {
                                work_order_id: null,
                                // id_user: userid,
                                id_deposit_from: depositCode ? depositCode : 1,
                                id_deposit_to: depositCode ? depositCode : 1,
                                id_document_reason: 8,
                                // id_document_related: 1,
                                note: 'Documento generato',
                                date: moment().format('YYYY-MM-DD HH:mm:ss'),
                            },
                            [
                                {
                                    code: articleCode,
                                    price: 0,
                                    discount: '0',
                                    qty: quantity,
                                    amount: quantity,
                                },
                            ]
                        )
                        .then((result) => {
                            setArticleCode('')
                            setDepositCode('')
                            setQuantity(0)
                            setDeposit(null)
                            setArticle(null)
                            setMaterial(null)
                        })
                }}
            >
                Salva
            </Button>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getDepositList } = state
    return {
        depositList: getDepositList.data.deposit,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WarehouseLoad)))
