export function getStockFiltered({ id_deposit, keysearch, category }) {
    return new Promise((resolve, reject) => {
        let { getStockFilteredRequest, getStockFilteredError, getStockFilteredResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'app/stock/filteredlist',
            this.createBundle(
                'app/stock/filteredlist',
                { id_deposit, keysearch, category },
                this.POST,
                getStockFilteredRequest,
                (result) => {
                    getStockFilteredResult(result)
                    resolve(result)
                },
                (error) => {
                    getStockFilteredError(error)
                    reject(error)
                }
            )
        )
    })
}
