export function deleteWorkOrder(data) {
    return new Promise((resolve, reject) => {
        let { deleteWorkOrderRequest, deleteWorkOrderError, deleteWorkOrderResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'delete_workorder',
            this.createBundle(
                'delete_workorder',
                { workorder: data },
                this.POST,
                deleteWorkOrderRequest,
                (result) => {
                    deleteWorkOrderResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteWorkOrderError(error)
                    reject(error)
                }
            )
        )
    })
}
