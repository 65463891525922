import { useColorMode } from '@chakra-ui/color-mode'
import React from 'react'
import { Icon, Menu } from 'semantic-ui-react'

export default function ThemeButton() {
    const { colorMode, toggleColorMode } = useColorMode()
    return (
        <Menu.Item name="pdf" onClick={toggleColorMode}>
            <Icon size="large" name="sun" />
            <span>Attiva modalità {colorMode === 'light' ? 'Scura' : 'Chiara'}</span>
        </Menu.Item>
    )
}
