import React, { useState } from 'react'
import { connectNetwork } from 'lib/NetworkProvider'
import 'moment/locale/it'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch, initializePage } from '../lib/util'
import View from 'lib/View'
import Iframe from 'react-iframe'
import { Button, Form, Input, Select, Table } from 'semantic-ui-react'
import { Article, Deposit } from '../components'
import moment from 'momentconfig'
import Material from 'printing/Material'

function WarehouseConfirm({ toolbarResult, filtersResult, sortsResult, network, machines, isEmployee, depositList, stockFiltered }) {
    useEffect(() => {
        network.getDepositList()
        network.getStockFiltered({ id_deposit: 1 }) // Produzione
        initializePage({ props: { toolbarResult, filtersResult, sortsResult } }, [], [], [])
    }, [])

    let options = []
    if (depositList) {
        for (let d of depositList) {
            options.push({ key: d.id, value: d.id, text: d.name })
        }
    }

    options.push()

    return (
        <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }}>
            <table style={{ flex: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5 }}>
                <tr>
                    <th>Articolo</th>
                    <th>Quantità</th>
                    <th>Azioni</th>
                </tr>
                {stockFiltered &&
                    stockFiltered.map((value, index) => {
                        return (
                            <tr key={`stock_${value.articleCode}_${index}`}>
                                <td>{value.articleCode}</td>
                                <td>{value.qty}</td>
                                <td>
                                    <Button
                                        onClick={() => {
                                            network
                                                .createDocument(
                                                    {
                                                        work_order_id: null,
                                                        // id_user: userid,
                                                        id_deposit_from: 1,
                                                        id_deposit_to: 1,
                                                        id_document_reason: 7,
                                                        // id_document_related: 1,
                                                        note: 'Produzione confermata SCARICO',
                                                        date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                                    },
                                                    [
                                                        {
                                                            code: value.articleCode,
                                                            price: 0,
                                                            discount: '0',
                                                            qty: value.qty,
                                                            amount: value.qty,
                                                        },
                                                    ]
                                                )
                                                .then((result) => {})
                                            network
                                                .createDocument(
                                                    {
                                                        work_order_id: null,
                                                        // id_user: userid,
                                                        id_deposit_from: 2,
                                                        id_deposit_to: 2,
                                                        id_document_reason: 8,
                                                        // id_document_related: 1,
                                                        note: 'Produzione confermata CARICO',
                                                        date: moment().format('YYYY-MM-DD HH:mm:ss'),
                                                    },
                                                    [
                                                        {
                                                            code: value.articleCode,
                                                            price: 0,
                                                            discount: '0',
                                                            qty: value.qty,
                                                            amount: value.qty,
                                                        },
                                                    ]
                                                )
                                                .then((result) => {
                                                    network.getStockFiltered({ id_deposit: 1 }) // Produzione
                                                })
                                        }}
                                    >
                                        Conferma
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
            </table>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getDepositList, getStockFiltered } = state
    return {
        depositList: getDepositList.data.deposit,
        stockFiltered: getStockFiltered.data.stock,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(WarehouseConfirm)))
