export function createCorp(id, corp) {
    let { createCorpRequest, createCorpError, createCorpResult } = this.props

    delete corp.creationDate;
    delete corp.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'app/corp/save',
        this.createBundle('app/corp/save', { id, corp }, this.POST, createCorpRequest, createCorpResult, createCorpError)
    )
}
