import axios from 'axios'
import * as htmlToImage from 'html-to-image'
import { connectNetwork } from '../lib/NetworkProvider'
import View from '../lib/View'
import moment from 'momentconfig'
import qs from 'qs'
import React, { Component } from 'react'
import { pdfjs } from 'react-pdf'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Dimmer, Form, Icon, Input, Loader, Select } from 'semantic-ui-react'
import { debounce } from 'throttle-debounce'
import { GETSESSIONDATA_ACTIONS } from '../actions/getSessionData'
import { Label } from '../components'
import { Labels } from '../containers'
import { baseViewDispatch, alertGenerator } from '../lib/util'
import { documentToSVG, elementToSVG, inlineResources, formatXML } from 'dom-to-svg'
import { UserAgent, withUserAgent } from 'react-useragent'
import { PRODUCTIONINDEX_ACTIONS } from '../actions/productionindex'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

moment.locale('it')

class ProductionLabels extends Component {
    constructor(props) {
        super(props)
        this.state = this.defaultState(props)
        this.printRef = React.createRef()
    }

    loadArtiFromQString = async (query) => {
        return new Promise(async (resolve, reject) => {
            let artistr = []
            if (query.ordprods && query.ordprods.indexOf('|') >= 0) {
                artistr = query.ordprods.split('|')
            } else {
                artistr = [query.ordprods ? query.ordprods : '']
            }

            const artis = []
            for (const sartistr of artistr) {
                const split = sartistr.split(';')
                const op = split[0]
                const num = split[1]
                const description = split[2].replace(/%20/g, ' ')
                let ordprod = null
                let images = []
                try {
                    ordprod = await axios.get(`${this.props.network.WEB_REQUEST_URL}get_arti_from_codop`, {
                        params: {
                            codop: op,
                        },
                    })
                } catch (e) {
                    console.error('Error getting arti from codop', op)
                }

                const opobj = ordprod && ordprod.data && ordprod.data.data ? ordprod.data.data : {}
                const artiinfo = {
                    op,
                    num,
                    description,
                    name: description,
                    descrizio: description,
                    ordprod: opobj,
                    codice: opobj.codmod,
                    arti: opobj.codmod,
                    tipo: opobj.tipo,
                }

                try {
                    const fromZanzar = await this.props.network.getSyncJobs(artiinfo.codice)
                    if (fromZanzar && fromZanzar.data && fromZanzar.data.length > 0) {
                        const code = fromZanzar.data[0]['Art Cli / For']
                        artiinfo.clientWorkCode = code ? code.substring(0, 8) : code
                    }
                } catch (e) {
                    console.error('Error while getting client article code')
                }

                try {
                    images = await axios.post(`${this.props.network.WEB_REQUEST_URL}find_image`, {
                        artiinfo,
                    })
                } catch (e) {
                    console.error('Error getting images from artiinfo', artiinfo)
                }

                if (images && images.data && images.data.data) {
                    artiinfo.images = images.data.data
                }
                // artiinfo.images = ['ACPL0453.bmp', 'ACPL0466.bmp', 'ACPL0482.bmp', 'ACPL0503.bmp'] For tests

                artis.push(artiinfo)
            }
            resolve(artis)
        })
    }

    defaultState = (props) => {
        const { categocleId, packageId, customerId, jobCode } = this.props

        this.updateCategoList = debounce(1000, false, () => {
            const { network } = this.props

            network.getCategoList(this.state.search)
        })

        const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

        this.loadArtiFromQString(query)
            .then((artis) => {
                if (artis && artis.length > 0) {
                    const artiobj = artis[0]
                    this.setState({
                        artis,
                        selected: {
                            codice: artiobj.arti,
                            tipo: artiobj.tipo,
                        },
                        workCode: artiobj.arti,
                        description: artiobj.name,
                        amount: artiobj.amount,
                        customerName: artiobj.customer ? artiobj.customer : '',
                        lotto: artiobj.lotto ? artiobj.lotto : '',
                        op: artiobj.op ? artiobj.op : '',
                        currentarti: 0,
                        computedImage:
                            artiobj.images && artiobj.images.length > 0 && this.state.useClientArticleCode
                                ? artiobj.images[0]
                                : `${artiobj.arti}.bmp`,
                        imageIndex: 0,
                        images: artiobj.images,
                    })
                }
            })
            .catch((error) => {
                console.error('error')
            })

        return {
            article: props.article ? props.article : {},
            label: {},
            pageNumber: 1,
            packageIndex: 0,
            categocleId,
            packageId,
            customerId,
            jobCode,
            showLabel: false,
            page: 0,
            items: 10,
            selected: {
                codice: query.arti,
                tipo: query.tipo,
            },
            workCode: query.arti,
            description: query.name,
            amount: query.amount,
            customerName: query.customer ? query.customer : '',
            lotto: query.lotto ? query.lotto : '',
            op: query.op ? query.op : '',
            seq: 1,
            search: '',
            currentarti: -1,
            artis: [],
            computedImage: `${query.arti}.bmp`,
            imageIndex: 0,
            images: [],
            useClientArticleCode: true,
            printCount: 1,
            labelPreviewScale: 0.2,
            printing: false,
            printClientLabels: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.search !== prevState.search) {
            this.updateCategoList()
        }
    }

    componentDidMount() {
        const { network } = this.props

        network.getCustomerList()
        network.getCategoList(this.state.search)
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages: numPages })
    }

    renderCatego(key, article, index) {
        let { selected, useClientArticleCode } = this.state
        let { onChange, noActions } = this.props

        let extraProps = {}
        extraProps.onClick = (article) => {
            if (onChange) {
                onChange(article)
            }
            this.setState({
                selected: article,
                workCode: article.codice,
                description: article.descrizio,
                computedImage: article.images && article.images.length > 0 && useClientArticleCode ? article.images[0] : `${article.arti}.bmp`,
                imageIndex: 0,
                images: article.images,
                op: article.op,
            })
            this.props.productionIndexResult(index)
        }

        if (noActions) {
            extraProps.noActions = true
        }

        return null // <Article {...extraProps} selected={article.codice === selected.codice} type="full" key={`catego_${key}`} article={article} />
    }

    padToDigits(toPad, digits) {
        let pad = ''
        for (let i = 0; i < digits + 1; i++) {
            pad += '0'
        }
        const formattedNumber = (pad + toPad).slice(-digits)
        return formattedNumber
    }

    generateSeq() {
        const { selected, lotto, seq, amount } = this.state

        switch (selected.tipo) {
            default:
            case 'PF':
                return `${selected.codice}${this.padToDigits(lotto, 6)}${this.padToDigits(amount, 6)}${this.padToDigits(seq, 3)}`
            case 'MP':
                return `${selected.codice}${this.padToDigits(seq, 15)}`
        }
    }

    handleInput(event) {
        const target = event.target
        let value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        if (typeof value === 'number') {
            value = value > 0 ? value : 0
        }

        this.setState({
            [name]: value,
        })
    }

    startPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 1 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    stopPrinting() {
        return new Promise((resolve, reject) => {
            this.setState({ labelPreviewScale: 0.2 }, () => {
                setTimeout(() => {
                    resolve()
                }, 500)
            })
        })
    }

    render() {
        const {
            label,
            page,
            items,
            selected,
            workCode,
            description,
            amount,
            seq,
            artis,
            useClientArticleCode,
            labelPreviewScale,
            customerName,
        } = this.state
        const { worker, categos, fetchCategos } = this.props

        const article = selected
        const labelTransform = `scale(${labelPreviewScale}, ${labelPreviewScale})`

        return (
            <Dimmer.Dimmable as={View} column fullw fullh dimmed={this.state.printing}>
                <Dimmer active={this.state.printing} inverted>
                    <Loader>Stampa in corso, non chiudere la pagina...</Loader>
                </Dimmer>
                <View column fullw fullh>
                    <Input
                        icon={this.state.search.length > 0 ? <Icon name="delete" link onClick={() => this.clearSearch()} /> : <Icon name="search" />}
                        placeholder="Cerca..."
                        value={this.state.search}
                        onChange={(e, data) => {
                            let text = data.value
                            this.setState({ search: text })
                        }}
                    />

                    <View row fullw fullh>
                        <View column fullw fullh>
                            <View column style={{ width: '50%', height: '50%', overflowY: 'scroll' }}>
                                {artis &&
                                    artis.slice(page, items + page * items).map((value, index) => {
                                        return this.renderCatego(value.codice, value, index)
                                    })}
                            </View>
                            {article.codice && (
                                <>
                                    <View column style={{ width: '50%', height: '20%', overflowY: 'scroll', overflowX: 'hidden' }}>
                                        {/* {!this.state.printClientLabels && (
                                            <Button
                                                onClick={() => {
                                                    this.setState({
                                                        customerId: this.props.customers[0].customerId,
                                                        customerName: this.props.customers[0].customerName,
                                                        printClientLabels: true,
                                                    })
                                                }}
                                            >
                                                Passa a etichette cliente
                                            </Button>
                                        )}
                                        {this.state.printClientLabels && (
                                            <Button
                                                onClick={() => {
                                                    this.setState({ printClientLabels: false })
                                                }}
                                            >
                                                Passa a etichette produzione
                                            </Button>
                                        )} */}
                                        <View fullw fullh row style={{ marginLeft: 16, overflowX: 'hidden' }}>
                                            <Form
                                                style={{ width: '100%' }}
                                                success={this.state.status === 'success'}
                                                error={this.state.status === 'error'}
                                                warning={this.state.status === 'warning'}
                                            >
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        id="form-input-control-arti-description"
                                                        control={Input}
                                                        label="Codice"
                                                        placeholder="Codice"
                                                        name="workCode"
                                                        value={workCode}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-arti-description"
                                                        control={Input}
                                                        label="Descrizione"
                                                        placeholder="Descrizione"
                                                        name="description"
                                                        value={description}
                                                        onChange={(e) => this.handleInput(e)}
                                                    />
                                                    <Form.Field
                                                        id="form-input-control-print-count"
                                                        control={Input}
                                                        label="Numero stampe"
                                                        name="printCount"
                                                        type="number"
                                                        value={this.state.printCount}
                                                        onChange={(e) => this.handleInput(e)}
                                                        placeholder="1"
                                                    />
                                                </Form.Group>

                                                {this.state.printClientLabels && (
                                                    <Form.Group widths="equal">
                                                        <Form.Field
                                                            id="form-input-control-job-type"
                                                            control={Select}
                                                            options={this.props.customerOptions}
                                                            label="Cliente"
                                                            placeholder="Cliente"
                                                            name="customerId"
                                                            value={this.state.customerId}
                                                            onChange={(e, data) => {
                                                                let id = data.value
                                                                this.setState({ customerId: id, customerName: this.props.customerMap[id] })
                                                            }}
                                                        />
                                                    </Form.Group>
                                                )}

                                                <Form.Field
                                                    control={Checkbox}
                                                    label="Utilizza codice articolo cliente per l'immagine"
                                                    checked={useClientArticleCode}
                                                    onChange={(e, data) => {
                                                        let { imageIndex } = this.state
                                                        this.setState({
                                                            computedImage:
                                                                this.state.images && this.state.images.length > 0 && data.checked
                                                                    ? this.state.images[imageIndex]
                                                                    : `${article.codice}.bmp`,
                                                            useClientArticleCode: data.checked,
                                                        })
                                                    }}
                                                />
                                            </Form>
                                        </View>
                                    </View>

                                    <View column fullw style={{ width: '50%', height: '30%', overflowY: 'scroll' }}>
                                        {!this.state.printClientLabels && (
                                            <Labels
                                                sub
                                                selectable
                                                selected={label}
                                                onChange={(label) => {
                                                    this.setState({ label, showLabel: true })
                                                }}
                                                warehouse
                                                production
                                                noActions
                                                autoselect
                                            />
                                        )}

                                        {this.state.printClientLabels && (
                                            <Labels
                                                sub
                                                selectable
                                                selected={label}
                                                onChange={(label) => {
                                                    this.setState({ label, showLabel: true })
                                                }}
                                                noActions
                                                autoselect
                                                customerId={this.state.customerId}
                                            />
                                        )}
                                    </View>
                                </>
                            )}
                        </View>
                    </View>

                    {label && label.layout && article && (
                        <>
                            <View
                                row
                                fullw
                                style={{
                                    position: 'absolute',
                                    bottom: 16,
                                    right: -2,
                                    width: Math.max(label.layout.layoutWidth * labelPreviewScale, 340),
                                    height: label.layout.layoutHeight * labelPreviewScale + 64,
                                    display: this.state.showLabel ? 'block' : 'none',
                                    backgroundColor: 'white',
                                    zIndex: 999,
                                    border: 'black solid 2px',
                                }}
                            >
                                <View row around>
                                    {this.state.images && this.state.images.length > 1 && (
                                        <View>
                                            <span style={{ marginTop: 8, marginRight: 8 }}>
                                                Trovate {this.state.images.length} immagini applicabili
                                            </span>
                                            <Button
                                                onClick={() => {
                                                    let { imageIndex } = this.state
                                                    imageIndex = (imageIndex + 1) % this.state.images.length
                                                    this.setState({
                                                        computedImage:
                                                            this.state.images && this.state.images.length > 0 && useClientArticleCode
                                                                ? this.state.images[imageIndex]
                                                                : `${article.codice}.bmp`,
                                                        imageIndex: imageIndex,
                                                    })
                                                }}
                                            >
                                                Cambia
                                            </Button>
                                        </View>
                                    )}
                                    <Button
                                        color="green"
                                        onClick={async () => {
                                            const { network } = this.props
                                            var node = document.getElementById('print-preview')

                                            await this.startPrinting()
                                            this.setState({ printing: true })

                                            try {
                                                for (let i = 0; i < this.state.printCount; i++) {
                                                    if (this.props.ua.mobile || this.props.ua.tablet) {
                                                        const bounds = {
                                                            height: label.layout.layoutHeight,
                                                            width: label.layout.layoutWidth,
                                                            x: 0,
                                                            y: 0,
                                                        }
                                                        const svgDocument = elementToSVG(document.querySelector('#print-preview'), {
                                                            bounds,
                                                            avoidTextSelection: true,
                                                        })

                                                        await inlineResources(svgDocument.documentElement)

                                                        let svgString = new XMLSerializer().serializeToString(svgDocument)

                                                        const regex = /<style>.*?<\/style>/gis
                                                        const mregex = /<mask[^>]*?>.*?<\/mask>/gis
                                                        const amregex = /mask=".*?"/gis
                                                        svgString = svgString.replace(regex, '').replace(mregex, '').replace(amregex, '')

                                                        await network
                                                            .sendToPrinter(svgString, 'filename', this.state.printCount, null, {
                                                                width: label.layout.layoutWidth,
                                                                height: label.layout.layoutHeight,
                                                                mobile: true,
                                                                printer: this.props.printer,
                                                            })
                                                            .then((result) => {
                                                                // this.props.onPrintLabel(result)
                                                            })
                                                    } else {
                                                        await htmlToImage
                                                            .toPng(node, {
                                                                width: label.layout.layoutWidth,
                                                                height: label.layout.layoutHeight,
                                                            })
                                                            .then(async (dataUrl) => {
                                                                const { network } = this.props

                                                                const filename = this.state.codice

                                                                var img = new Image()
                                                                img.src = dataUrl
                                                                await network.sendToPrinter(dataUrl, filename, this.state.printCount, null, {
                                                                    width: label.layout.layoutWidth,
                                                                    height: label.layout.layoutHeight,
                                                                    mobile: false,
                                                                    printer: this.props.printer,
                                                                })
                                                            })
                                                            .catch(function (error) {
                                                                console.error('oops, something went wrong!', error)
                                                            })
                                                    }
                                                }
                                            } catch (e) {
                                                this.props.error(alertGenerator({ title: 'Errore durante la stampa', message: JSON.stringify(e) }))
                                            }
                                            this.setState({ printing: false })
                                            await this.stopPrinting()
                                        }}
                                    >
                                        Stampa etichetta
                                    </Button>
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.setState({ showLabel: false })
                                        }}
                                    >
                                        Chiudi anteprima di stampa
                                    </Button>
                                    <Button
                                        color="grey"
                                        onClick={() => {
                                            this.setState({ labelPreviewScale: labelPreviewScale >= 0.8 ? 0.2 : 0.8 })
                                        }}
                                    >
                                        {labelPreviewScale >= 0.8 ? 'Comprimi' : 'Espandi'}
                                    </Button>
                                </View>
                                <div
                                    style={{
                                        transformOrigin: 'top left',
                                        msTransformOrigin: 'top left',
                                        MozTransformOrigin: 'top left',
                                        WebkitTransformOrigin: 'top left',
                                        transform: labelTransform,
                                        MsTransform: labelTransform,
                                        WebkitTransform: labelTransform,
                                        OTransform: labelTransform,
                                        MozTransform: labelTransform,
                                    }}
                                >
                                    <div id="print-preview" style={{ position: 'relative' }} ref={this.printRef}>
                                        <Label
                                            key={label.labelId}
                                            label={label}
                                            type="visualization"
                                            selectedArti={{
                                                warehouseSequential: this.generateSeq(),
                                                creationDate: moment().format('DD/MM/YYYY'),
                                                currentDate: moment().format('DD/MM/YYYY'),
                                                pcsPerPackage: this.state.amount,
                                                articleQuantity: this.state.amount,
                                                paddedArticleQuantity: this.padToDigits(this.state.amount, 6),
                                                lotto: this.state.lotto,
                                                seq: this.padToDigits(this.state.seq, 3),
                                                workCode: this.state.workCode,
                                                description: this.state.description,
                                                op: this.state.op,
                                                computedImage: this.state.computedImage,
                                                customerName,
                                            }}
                                            currentSession={this.props.currentSession}
                                            customerId={0}
                                        />
                                    </div>
                                </div>
                            </View>
                            {!this.state.showLabel && (
                                <Button
                                    color="green"
                                    style={{ position: 'absolute', bottom: 16, right: 16, zIndex: 999 }}
                                    onClick={() => {
                                        this.setState({ showLabel: true })
                                    }}
                                >
                                    STAMPA
                                </Button>
                            )}
                        </>
                    )}
                </View>
            </Dimmer.Dimmable>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),

        getSessionDataRequest: (result) => GETSESSIONDATA_ACTIONS.request(result, dispatch),
        getSessionDataResult: (result) => GETSESSIONDATA_ACTIONS.result(result, dispatch),
        getSessionDataError: (result) => GETSESSIONDATA_ACTIONS.error(result, dispatch),

        productionIndexRequest: (result) => PRODUCTIONINDEX_ACTIONS.request(result, dispatch),
        productionIndexResult: (result) => PRODUCTIONINDEX_ACTIONS.result(result, dispatch),
        productionIndexError: (result) => PRODUCTIONINDEX_ACTIONS.error(result, dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getCustomerList: {
            data: { data: customers },
            fetching: fetchCustomers,
        },
        getCategoList: {
            data: { data: categos },
            fetching: fetchCategos,
        },
        getSessionData: {
            data: { data: session },
        },
        getLabelList,
        loginEmployee: {
            data: { user: worker },
        },
        printer,
    } = state

    let customerOptions = []
    let customerMap = {}
    for (let i in customers) {
        let type = customers[i]
        customerOptions.push({
            key: type.customerId,
            text: type.customerName,
            value: type.customerId,
        })
        customerMap[type.customerId] = type.customerName
    }

    return {
        categos,
        fetchCategos,
        session: session ? session : [],
        getLabelList,
        worker,
        printer,
        customers,
        customerOptions,
        customerMap,
    }
}
export default withUserAgent(withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ProductionLabels))))
