export function getDetailedJobList(page, items, search, isEmployee, external, urgencyLevel, filters, sorts) {
    let { getDetailedJobListRequest, getDetailedJobListError, getDetailedJobListResult } = this.props

    let reqobj = {
        page,
        items,
        search,
        urgency: urgencyLevel !== undefined ? urgencyLevel : 'all',
    }

    if (filters) {
        reqobj.filters = filters
    }

    if (sorts) {
        reqobj.sorts = sorts
    }

    if (external !== undefined && external !== null && external !== 'false') {
        reqobj.external = 1
    }

    let bundle = this.createBundle(
        'get_detailed_job_list',
        reqobj,
        this.POST,
        getDetailedJobListRequest,
        getDetailedJobListResult,
        getDetailedJobListError,
        isEmployee
    )

    this.doAction(this.WEB_REQUEST_URL + 'get_detailed_job_list', bundle)
}
