import { Box } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/switch'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Divider, Form, Icon, Input, Modal, Placeholder } from 'semantic-ui-react'
import { baseViewDispatch, createAlert } from '../lib/util'
import JobDropdown from './JobDropdown'


class TaskModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tasks: [],
            cloneSource: null,
            searchJob: '',
        }
    }

    cancel = () => {
        let { onCancel } = this.props
        if (onCancel) {
            onCancel()
        }
    }

    confirm = () => {
        const { tasks } = this.state
        let { onConfirm, job } = this.props
        if (onConfirm) {
            onConfirm(tasks)
        }

        this.props.network.createTask(job.jobCode, tasks)
    }

    clone = () => {
        const { cloneSource } = this.state
        let { job, success } = this.props

        if (cloneSource) {
            this.props.network
                .cloneTasks(job.jobCode, cloneSource)
                .then((result) => {
                    success(createAlert('Operazione completata', 'Operazione avvenuta con successo!'))
                    // this.props.network.getJobTaskList(job.jobCode)
                    const { tasks } = this.state

                    for (const index in result.data) {
                        const task = result.data[index]
                        tasks.push(task)
                    }

                    this.setState({
                        tasks,
                    })
                })
                .catch((error) => {
                    error(createAlert('Operazione fallita', 'Errore durante la clonazione'))
                })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { network } = this.props
        if (!prevProps.showModal && this.props.showModal) {
            network.getJobList(false)
            network.getJobTaskList(this.props.job.jobCode)
        }

        if (prevProps.getJobTaskList.fetching && !this.props.getJobTaskList.fetching) {
            this.setState({ tasks: this.props.tasks })
        }

        // if (this.state.searchJob !== prevState.searchJob) {
        //     network.getJobList(false, this.state.searchJob)
        // }
    }

    render() {
        let { searchJob } = this.state
        let { showModal, job, tasks, getJobTaskList, jobOptions, cloneTasks } = this.props

        if (!job || !tasks) return null

        return (
            <Modal onClose={this.closeModal} open={showModal}>
                <Modal.Header>Task per lo stampo {job.jobName ? job.jobName : job.jobCode}</Modal.Header>
                <Modal.Content image scrolling style={{ padding: 0 }}>
                    <Box p={6} style={{ width: '100%' }}>
                        {getJobTaskList.fetching && (
                            <Box p={6} loading>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Box>
                        )}
                        {!cloneTasks.fetching &&
                            !getJobTaskList.fetching && [
                                tasks.map((value, index) => {
                                    return (
                                        <View fullw row key={`task-modal-${value.taskId}`}>
                                            <View fullw around row style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                                                <Form style={{ width: '100%' }}>
                                                    <Form.Group>
                                                        <Switch
                                                            width={4}
                                                            style={{ marginTop: 30 }}
                                                            label={<span className="label"></span>}
                                                            defaultChecked={value.taskStatus === 1}
                                                            onChange={(e) => {
                                                                const { tasks } = this.state
                                                                tasks[index].taskStatus = e.target.checked ? 1 : 0
                                                                this.setState({ tasks })
                                                            }}
                                                        />
                                                        <Form.Field
                                                            width={12}
                                                            id="form-input-control-task-description"
                                                            control={Input}
                                                            label={<span className="label">Descrizione Task</span>}
                                                            placeholder="Descrizione Task"
                                                            name="taskDescription"
                                                            value={value.taskDescription}
                                                            onChange={(ev) => {
                                                                const { tasks } = this.state
                                                                tasks[index].taskDescription = ev.target.value
                                                                this.setState({ tasks })
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </View>
                                            <Button
                                                icon
                                                color="red"
                                                onClick={() => {
                                                    const { tasks } = this.state
                                                    delete tasks[index]
                                                    this.setState({ tasks })
                                                }}
                                            >
                                                <Icon name="trash alternate outline" />
                                            </Button>
                                        </View>
                                    )
                                }),
                                <View fullw around key={`task-modal-action-row-${job.jobCode}`}>
                                    <Button
                                        primary
                                        onClick={() => {
                                            const { tasks } = this.state
                                            tasks.push({ taskDescription: 'Nuovo task', taskStatus: 0, jobCode: job.jobCode })
                                            this.setState({ tasks })
                                        }}
                                    >
                                        Aggiungi
                                    </Button>
                                </View>,
                            ]}
                    </Box>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="grey" onClick={this.cancel}>
                        Annulla <Icon name="cancel" />
                    </Button>
                    <Button primary onClick={this.confirm}>
                        Salva <Icon name="check" />
                    </Button>
                    <Divider />
                    <View row>
                        {/* <Dropdown
                            placeholder="Seleziona stampo"
                            fluid
                            search={(value) => {
                                const result = []
                                for (const j of value) {
                                    if (j.text.indexOf(searchJob) >= 0) {
                                        result.push(j)
                                    }
                                }
                                return result
                            }}
                            selection
                            options={jobOptions}
                            onChange={(event, data) => {
                                this.setState({
                                    cloneSource: data.value,
                                })
                            }}
                            onSearchChange={(event, data) => {
                                this.setState({
                                    searchJob: data.searchQuery,
                                })
                            }}
                            lazyLoad
                        /> */}
                        <JobDropdown
                            single={true}
                            onJobSelected={(ev, data, selected, molds) => {
                                this.setState({
                                    cloneSource: selected,
                                })
                            }}
                        />
                        <Button color="yellow" onClick={this.clone}>
                            Clona <Icon name="copy" />
                        </Button>
                    </View>
                </Modal.Actions>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getJobList: {
            data: { data: joblist },
            fetching: fetchingJob,
        },
        login,
        getJobTaskList,
        getJobTaskList: {
            data: { data: tasks },
        },
        cloneTasks,
    } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    let options = []
    for (let i in joblist) {
        let job = joblist[i]
        options.push({
            key: job.jobCode,
            text: `${job.jobName ? job.jobName : job.jobCode} - ${job.description}`,
            value: job.jobCode,
        })
    }

    return { role: rcode, getJobTaskList, tasks, login, jobOptions: options, cloneTasks }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectNetwork(TaskModal))
