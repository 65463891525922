export function getMold(data) {
    return new Promise((resolve, reject) => {
		let { getMoldRequest, getMoldError, getMoldResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_mold',
			this.createBundle(
				'get_mold',
				{ mold: data },
				this.GET,
				getMoldRequest,
                (result) => {
                    getMoldResult(result)
                    resolve(result)
                },
                (error) => {
                    getMoldError(error)
                    reject(error)
                }
			)
		);
	})
}
