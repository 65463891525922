export function createMaterial(data) {
    return new Promise((resolve, reject) => {
        let { createMaterialRequest, createMaterialError, createMaterialResult } = this.props

        delete data.creationDate;
        delete data.updateDate;
        
        this.doAction(
            this.WEB_REQUEST_URL + 'create_material',
            this.createBundle(
                'create_material',
                { material: data },
                this.POST,
                createMaterialRequest,
                (result) => {
                    createMaterialResult(result)
                    resolve(result)
                },
                (error) => {
                    createMaterialError(error)
                    reject(error)
                }
            )
        )
    })
}
