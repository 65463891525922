import moment from 'momentconfig'
moment.locale('it')

export function login(email, password) {
    return new Promise((resolve, reject) => {
        let { loginRequest, loginError, loginResult, cookies } = this.props

        let user = {
            email,
            password,
        }

        this.doAction(
            this.WEB_REQUEST_URL + 'login',
            this.createBundle(
                'login',
                { user: user },
                this.POST,
                loginRequest,
                (data) => {
                    cookies.set('user', data.user, {
                        path: '/',
                        expires: moment().add(1, 'months').toDate(),
                    })
                    loginResult(data)
                    resolve(data)
                },
                (error) => {
                    loginError(error)
                    reject(error)
                }
            )
        )
    })
}

export function logout() {
    const { logout, cookies } = this.props

    cookies.remove('user', {
        path: '/',
    })
    logout()
}
