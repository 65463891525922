import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { Empty, Machine } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'

const { app, machines, newEl, machineEdit } = routes

class Machines extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            items: 10,
            selected: this.props.selected ? this.props.selected : {},
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { deleteMachine } = this.state
        let { isEmployee, workerId, organization } = this.props
        if (
            this.state.page !== prevState.page ||
            this.state.items !== prevState.items ||
            (deleteMachine.fetching !== prevState.deleteMachine.fetching && !deleteMachine.fetching) ||
            organization.data !== prevProps.organization.data
        ) {
            if (isEmployee) {
                this.state.network.getRecentMachineList(!this.props.nosearch ? this.state.search.data : '', workerId, isEmployee)
            } else {
                this.state.network.getMachineList(!this.props.nosearch ? this.state.search.data : '', isEmployee)
            }
        } else if (this.state.search !== prevState.search) {
            if (isEmployee) {
                this.state.network.getRecentMachineList(!this.props.nosearch ? this.state.search.data : '', workerId, isEmployee)
            } else {
                this.state.network.getMachineList(!this.props.nosearch ? this.state.search.data : '', isEmployee)
            }
        }
    }

    next() {
        this.setState({
            page: this.state.page + 1,
        })
    }

    prev() {
        if (this.state.page === 0) return

        this.setState({
            page: this.state.page - 1,
        })
    }

    componentDidMount() {
        let { isEmployee, sub, workerId } = this.props

        let { login } = this.props

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        let extraTools = []

        if (user && !!user.manage_machine) {
            extraTools.push({
                name: 'Crea nuova macchina',
                icon: 'add',
                action: () => {
                    this.props.history.push(route_from(app, machines, newEl, machineEdit))
                },
            })
        }

        if (!sub) {
            initializePage(
                this,
                [
                    ...extraTools,
                    // Not paginated yet
                    // {
                    // 	name: '',
                    // 	icon: 'arrow left',
                    // 	position: 'right',
                    // 	action: () => {
                    // 		this.prev()
                    // 	}
                    // },
                    // {
                    // 	name: '',
                    // 	icon: 'arrow right',
                    // 	position: 'none',
                    // 	action: () => {
                    // 		this.next()
                    // 	}
                    // }
                ],
                {}
            )
        }

        if (isEmployee) {
            this.state.network.getRecentMachineList(!this.props.nosearch ? this.state.search.data : '', workerId, isEmployee)
        } else {
            this.state.network.getMachineList(!this.props.nosearch ? this.state.search.data : '', isEmployee)
        }
    }

    renderMachine(key, machine) {
        let { selected } = this.state
        let { onChange, selectable, noActions, showNoMachine } = this.props

        let extraProps = {}
        if (selectable) {
            extraProps.onClick = (machine) => {
                if (onChange) {
                    onChange(machine)
                }
                this.setState({ selected: machine })
            }
        }

        if (noActions) {
            extraProps.noActions = true
        }

        if (showNoMachine) {
            extraProps.showNoMachine = true
        }

        return <Machine {...extraProps} selected={machine.machineId === selected.machineId} type="full" key={`machine_${key}`} machine={machine} />
    }

    render() {
        let { machines, fetching } = this.state
        let { onChange, selectable, selected, noActions, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View noflex fullw column>
                    {placeholders}
                </View>
            )
        } else if (machines.data && machines.data.length > 0) {
            content = Object.keys(machines.data).map((key) => {
                let value = machines.data[key]
                return this.renderMachine(key, value)
            })
        } else {
            content = <Empty />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList, getRecentMachineList, deleteMachine, search, organization, login } = state
    let { isEmployee } = ownProps

    let machineList = getMachineList
    if (isEmployee) {
        machineList = getRecentMachineList
    }
    return {
        machines: machineList.data,
        fetching: machineList.fetching,
        deleteMachine,
        search,
        login,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(Machines)))
