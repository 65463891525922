import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Table } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'
function AnalyticRealTime({ network, table }) {
    const [datapoints, setDatapoints] = useState([])
    useEffect(() => {
        const id = setInterval(() => {
            network
                .doRequest({
                    action: `table/line/${table}`,
                    method: network.GET,
                    data: {
                        id: '0',
                        sub_id: '0',
                        limit: '100',
                    },
                    service: network.HADES,
                    token: network.MACHINES,
                })
                .then((data) => {
                    // console.log(data)
                    setDatapoints(data)
                })
        }, 10000)

        return () => {
            clearInterval(id)
        }
    }, [table])

    if (datapoints.length === 0) return null

    const firstDatapoint = datapoints[0]
    return (
        <View noflex column fullw>
            <Table>
                <Table.Header>
                    {Object.keys(firstDatapoint).map((field, index) => (
                        <Table.Cell key={index}>{field}</Table.Cell>
                    ))}
                </Table.Header>
                {datapoints.map((datapoint, index) => (
                    <Table.Body key={index}>
                        {Object.keys(datapoint).map((field, index) => {
                            switch (field) {
                                case 'timestamp':
                                    return <Table.Cell key={index}>{moment.utc(datapoint[field]).local().format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                                default:
                                    return <Table.Cell key={index}>{datapoint[field]}</Table.Cell>
                            }
                        })}
                    </Table.Body>
                ))}
            </Table>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(AnalyticRealTime)))
