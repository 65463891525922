export function getListing(data) {
    return new Promise((resolve, reject) => {
		let { getListingRequest, getListingError, getListingResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_listing',
			this.createBundle(
				'get_listing',
				{ listing: data },
				this.GET,
				getListingRequest,
                (result) => {
                    getListingResult(result)
                    resolve(result)
                },
                (error) => {
                    getListingError(error)
                    reject(error)
                }
			)
		);
	})
}
