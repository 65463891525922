export function addSessionData(sessionobj) {
    let { addSessionDataRequest, addSessionDataError, addSessionDataResult } = this.props

    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'add_session_data',
            this.createBundle(
                'add_session_data',
                { sessionobj },
                this.POST,
                addSessionDataRequest,
                (result) => {
                    resolve(result)
                    addSessionDataResult(result)
                },
                (err) => {
                    reject(err)
                    addSessionDataError(err)
                },
                true
            )
        )
    })
}
