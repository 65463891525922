import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { baseViewDispatch } from '../lib/util'
function LastRealTimeSmall({ network, table, time, label, field, format }) {
    const [datapoints, setDatapoints] = useState([])
    useEffect(() => {
        network
            .doRequest({
                action: `table/${table}`,
                method: network.GET,
                data: {
                    id: '0',
                    sub_id: '0',
                    limit: '1',
                },
                service: network.HADES,
                token: network.MACHINES,
            })
            .then((data) => {
                // console.log(data)
                setDatapoints(data)
            })
    }, [table, time])

    if (datapoints.length === 0) return null

    const firstDatapoint = datapoints[0]
    return (
        <>
            <Box alignItems="center" horizontal size="mini">
                <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>{format ? format(firstDatapoint[field]) : firstDatapoint[field]}</Box>
                <Box fontSize="lg" textAlign={['center', 'center']}>{label}</Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(LastRealTimeSmall)))
