export function mergePackaging(data) {
    return new Promise((resolve, reject) => {
		let { mergePackagingRequest, mergePackagingError, mergePackagingResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'merge_packaging',
			this.createBundle(
				'merge_packaging',
				{ data: data },
				this.POST,
				mergePackagingRequest,
                (result) => {
                    mergePackagingResult(result)
                    resolve(result)
                },
                (error) => {
                    mergePackagingError(error)
                    reject(error)
                }
			)
		);
	})
}
