import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { error, hide, info, removeAll, show, success, warning } from 'react-notification-system-redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Form, Input, Message } from 'semantic-ui-react'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { PickMultipleImage } from '../components'
import { createAlert } from '../lib/util'

class VariantEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id
        let { variant } = this.props.match.params

        if (!id) {
            id = variant
        }

        // console.log('VariantEditor params match', this.props.match, id)

        this.state = {
            id,
            variantImages: [],
        }
    }

    componentDidMount() {
        let { id } = this.state
        // console.log('VariantEditor id is', id)

        this.props.toolbarResult([
            {
                name: 'Salva',
                icon: 'save',
                position: 'right',
                action: () => {
                    this.save()
                },
            },
        ])

        if (id) {
            this.props.network.getVariant(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('VariantEditor did update', this.props.creating)
        if (this.props.creating !== prevProps.creating) {
            if (!this.props.creating) {
                
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    this.props.success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    this.props.error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                    this.setState({
                        status: 'error',
                    })
                }
            }
        }

        let { getVariant } = this.props
        if (getVariant && getVariant.fetching !== prevProps.getVariant.fetching && !getVariant.fetching) {
            if (getVariant.status.success) {
                let variant = getVariant.data.data
                // console.log('Setting state with variant:', variant)
                let { id, ...rest } = variant
                this.setState(rest)
            }
        }
    }

    save() {
        // TODO value checking
        let { variantId, variantName, variantCode, variantDescription, variantDescriptionEng, variantImages, variantOrdering, variantEnvironmental } = this.state

        this.props.network.createVariant({
            variantId,
            variantName,
            variantCode,
            variantDescription,
            variantDescriptionEng,
            variantImages,
            variantOrdering,
            variantEnvironmental,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let {
            creating,
            variantId,
            variantCode,
            familyId,
            variantName,
            variantDescription,
            variantDescriptionEng,
            variantImages,
            variantOrdering,
            variantEnvironmental,
        } = this.state

        let { getVariant, collectionOptions, familyOptions } = this.props

        // console.log('JobEditor state:', this.state)
        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={creating || getVariant.fetching}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-variant-name"
                            control={Input}
                            label={<span className="label">Nome variante</span>}
                            name="variantName"
                            value={variantName}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-variant-code"
                            control={Input}
                            label={<span className="label">Codice variante</span>}
                            name="variantCode"
                            value={variantCode}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-variant-name"
                            control={Input}
                            label={<span className="label">Descrizione variante</span>}
                            name="variantDescription"
                            value={variantDescription}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-variant-name"
                            control={Input}
                            label={<span className="label">Descrizione variante inglese</span>}
                            name="variantDescriptionEng"
                            value={variantDescriptionEng}
                            onChange={(e) => this.handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>

                    <View fullw around>
                        <Checkbox
                            style={{ marginTop: 10 }}
                            toggle
                            label={<span className="label">Ambientale</span>}
                            checked={variantEnvironmental === 1}
                            onChange={(e, data) => {
                                this.setState({ variantEnvironmental: data.checked ? 1 : 0 })
                            }}
                        />
                    </View>

                    <PickMultipleImage
                        onChange={(images) => {
                            this.setState({ variantImages: images })
                        }}
                        value={variantImages}
                    />
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form completato con successo" content="Macchina inserita con successo!" />
                    <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                    <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let {
        createVariant: { data: createResult, fetching: creating, status: createStatus },
        getVariant,
    } = state

    return {
        createResult,
        creating,
        getVariant,
        createStatus,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        show: (notif) => dispatch(show(notif)),
        success: (notif) => dispatch(success(notif)),
        error: (notif) => dispatch(error(notif)),
        warning: (notif) => dispatch(warning(notif)),
        info: (notif) => dispatch(info(notif)),
        hide: (notif) => dispatch(hide(notif)),
        removeAll: (notif) => dispatch(removeAll(notif)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(VariantEditor)))
