export function getStdArticle(data, articleCode, isEmployee) {
    let { getStdArticleRequest, getStdArticleError, getStdArticleResult } = this.props
    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'get_article',
            this.createBundle(
                'get_article',
                { stdarticle: data, articleCode },
                this.GET,
                getStdArticleRequest,
                (result) => {
                    getStdArticleResult(result)
                    resolve(result.data)
                },
                (error) => {
                    getStdArticleError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
