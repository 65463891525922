export function createInterval(data) {
    return new Promise((resolve, reject) => {
		let { createIntervalRequest, createIntervalError, createIntervalResult } = this.props;

        delete data.creationDate;
        delete data.updateDate;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_interval',
			this.createBundle(
				'create_interval',
				{ interval: data },
				this.POST,
				createIntervalRequest,
                (result) => {
                    createIntervalResult(result)
                    resolve(result)
                },
                (error) => {
                    createIntervalError(error)
                    reject(error)
                }
			)
		);
	})
}
