export function createTurn(turn) {
    let { createTurnRequest, createTurnError, createTurnResult } = this.props

    delete turn.creationDate;
    delete turn.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_turn',
        this.createBundle('create_turn', { turn }, this.POST, createTurnRequest, createTurnResult, createTurnError)
    )
}
