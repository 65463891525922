import { CLIENTORDERLISTSTATE_TYPES } from '../actions/clientOrderListState'
const Reducer = (
    state = {
        stored: false,
        state: {},
    },
    action
) => {
    switch (action.type) {
        case CLIENTORDERLISTSTATE_TYPES.request: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                ...state,
                stored: false,
                state: result,
            }
        }
        case CLIENTORDERLISTSTATE_TYPES.result: {
            let { result } = action
            return {
                ...state,
                stored: true,
                state: result,
            }
        }
        case CLIENTORDERLISTSTATE_TYPES.error: {
            let { result } = action
            if (result === undefined) result = {}

            return {
                ...state,
                stored: false,
                state: result,
            }
        }
        default:
            return state
    }
}
export default Reducer
