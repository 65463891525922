export function getBabypressaData(start, end, id) {
    return new Promise((resolve, reject) => {
        let { getBabypressaDataRequest, getBabypressaDataError, getBabypressaDataResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_babypressa_data',
            this.createBundle(
                'get_babypressa_data',
                { start, end, id },
                this.POST,
                getBabypressaDataRequest,
                (result) => {
                    getBabypressaDataResult(result)
                    resolve(result)
                },
                (error) => {
                    getBabypressaDataError(error)
                    reject(error)
                }
            )
        )
    })
}
