export function getSessionData(data) {
    return new Promise((resolve, reject) => {
        let { getSessionDataRequest, getSessionDataError, getSessionDataResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_session_data',
            this.createBundle(
                'get_session_data',
                { sessiondata: data },
                this.GET,
                getSessionDataRequest,
                (result) => {
                    getSessionDataResult(result)
                    resolve(result)
                },
                (error) => {
                    getSessionDataError(error)
                    reject(error)
                },
                true
            )
        )
    })
}
