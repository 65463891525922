export function getLabel(data) {
	let { getLabelRequest, getLabelError, getLabelResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_label',
		this.createBundle(
			'get_label',
			{ id: data },
			this.GET,
			getLabelRequest,
			getLabelResult,
			getLabelError
		)
	);
}
