import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon, Input, Table } from 'semantic-ui-react'
import { Confirmation, ImageDefault, PickMultipleImage } from '../components'
import { floatTimeToString, timeString } from '../lib/util'

const { app, variants, variantEdit } = routes

class Variant extends Component {
    constructor(props) {
        super(props)

        let { variant } = props

        this.state = {
            deleting: false,
            articleVariantImages: [],
            ...variant,
        }
    }

    componentDidMount() {
        let { article, variant, id, network } = this.props

        if ((!variant || !variant.id) && id) {
            network.getVariant(id)
        }

        if (article) {
            network.getArticleVariantImageList(article.articleId, this.state.variantId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getVariant, login } = this.props
        let { id, variantHourlyRate } = this.state
        if (variantHourlyRate !== prevState.variantHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        // console.log('Variant getVariant fetching', getVariant.fetching)
        if (getVariant && getVariant.fetching !== prevProps.getVariant.fetching && !getVariant.fetching) {
            // console.log('Done calling getVariant')
            if (getVariant.status.success) {
                // console.log(getVariant.data)
                let variant = getVariant.data.data

                this.setState({ ...variant })
                // console.log('Calling Get variant', login, variant)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No variant found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, variantHourlyRate, variantHours, variantMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={variantHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    variantHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{variantHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(variantHours, variantMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideVariant, hideVariantHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideVariant && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideVariantHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideVariant, hideVariantHours, isBalance } = this.props
        let { name, variantHours, variantMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideVariant && <Table.Cell>{name}</Table.Cell>}
                    {!hideVariantHours && <Table.Cell>{timeString(variantHours, variantMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, variant } = this.props

        network.deleteVariant(variant)

        this.setState({ deleting: false })
    }

    render() {
        let {
            pickArticleVariantImage,
            getArticleVariantImageList,
            article,
            login,
            type,
            variant,
            onClick,
            selected,
            noActions,
        } = this.props
        let { deleting, variantId: id, variantName, variantDescription, variantImages, articleVariantImages } = this.state

        // console.log('Variant rendering:', getArticleVariantImageList)

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(variant)
            }
        }
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.variantCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questa variante?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.variantContent}>
                                <View fullw row>
                                    {variantImages && variantImages.length > 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={variantImages[0].picturePath} ui={false} style={styles.variantImage} />
                                        </div>
                                    )}
                                    {(!variantImages || variantImages.length === 0) && articleVariantImages && articleVariantImages.length > 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={articleVariantImages[0].picturePath} ui={false} style={styles.variantImage} />
                                        </div>
                                    )}
                                    {(!variantImages || variantImages.length === 0) && (!articleVariantImages || articleVariantImages.length === 0) && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault fromRoot src={null} ui={false} style={styles.variantImage} />
                                        </div>
                                    )}
                                    <View fullw column>
                                        <span style={styles.header}>{variantName}</span>
                                        <Box textStyle="description">{variantDescription}</Box>
                                    </View>
                                    {article && (
                                        <View fullw column>
                                            <Box textStyle="description">Riferita all{'\''}articolo {article.articleName}</Box>
                                        </View>
                                    )}
                                    {!pickArticleVariantImage && article && article.articleVariantImages && article.articleVariantImages.length > 0 && (
                                        <div style={styles.imageContainer}>
                                            <ImageDefault
                                                fromRoot
                                                src={article.articleVariantImages[0].picturePath}
                                                ui={false}
                                                style={styles.variantImage}
                                            />
                                        </div>
                                    )}
                                </View>
                            </Box>
                            {pickArticleVariantImage && article && (
                                <Box textStyle="content">
                                    <PickMultipleImage
                                        onChange={(images) => {
                                            articleVariantImages = images
                                            variant.articleVariantImages = images
                                            this.setState({ articleVariantImages })
                                        }}
                                        value={articleVariantImages}
                                    />
                                </Box>
                            )}
                            {!pickArticleVariantImage && article && (
                                <Box textStyle="content">
                                    <PickMultipleImage readonly value={articleVariantImages} />
                                </Box>
                            )}
                            {!noActions && (
                                <Box textStyle="content" extra>
                                    {user && !!user.manage_variant && (
                                        <Button
                                            color="blue"
                                            onClick={() => {
                                                this.props.history.push(route_from(app, variants, variantEdit, `${id}`))
                                            }}
                                        >
                                            <Icon name="edit" />
                                            Modifica
                                        </Button>
                                    )}

                                    {user && !!user.manage_variant && (
                                        <Button
                                            color="red"
                                            onClick={() => {
                                                this.openRemoveDialog()
                                            }}
                                        >
                                            <Icon name="delete" />
                                            Elimina
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    variantImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    variantContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    variantCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { getArticleVariantImageList, login, getVariant } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { getArticleVariantImageList, role: rcode, getVariant, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Variant)))
