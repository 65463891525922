export function getScheduleList(data) {
    return new Promise((resolve, reject) => {
		let { getScheduleListRequest, getScheduleListError, getScheduleListResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_schedule_list',
			this.createBundle(
				'get_schedule_list',
				{ schedule: data },
				this.GET,
				getScheduleListRequest,
                (result) => {
                    getScheduleListResult(result)
                    resolve(result)
                },
                (error) => {
                    getScheduleListError(error)
                    reject(error)
                }
			)
		);
	})
}
