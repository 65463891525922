export function createStdArticle(data, callback) {
    return new Promise((resolve, reject) => {
        let { createStdArticleRequest, createStdArticleError, createStdArticleResult } = this.props

        delete data.creationDate;
        delete data.updateDate;

        this.doAction(
            this.WEB_REQUEST_URL + 'create_article',
            this.createBundle(
                'create_article',
                { article: data },
                this.POST,
                createStdArticleRequest,
                (result) => {
                    createStdArticleResult(result)
                    if (callback) {
                        callback(result)
                    }
                    resolve(result)
                },
                (error) => {
                    createStdArticleError(error)
                    reject(error)
                }
            )
        )
    })
}
