import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Checkbox, Form, Input, Message } from 'semantic-ui-react'
import { PickImage } from '../components'
import { baseViewDispatch, createAlert } from '../lib/util'
const { app, clientOrders } = routes

class ClientOrderEditor extends Component {
    constructor(props) {
        super(props)
        let id = props.id

        let { clientOrder } = this.props.match.params

        if (!id) {
            id = clientOrder
        }

        this.state = {
            updating: false,
            id,
            clientOrderCode: id,
            description: '',
            active: 1,
            clientOrderType: 2,
            client: '',
            image: '',
            urgencyLevel: 0,
            createdExternal: 0,
            errorMessage: '',
            addDate: false,
            workOrders: [
                {
                    workCode: 'Ordine di lavoro 1',
                    product: 'Lavorazione 1',
                    productQuantity: 1,
                },
            ],
            lastBillDate: new Date(),
            billed: 0.0,
            budgeted: 0.0,
            productCode: false,
            status: 'ok', // warning, error, success
            deliveryDateChanged: false,
        }
    }

    componentDidMount() {
        let { clientOrderCode } = this.state
        let { sub } = this.props

        if (!sub) {
            this.props.toolbarResult([
                {
                    name: 'Salva',
                    icon: 'save',
                    position: 'right',
                    action: () => {
                        this.save()
                    },
                },
            ])
        }

        this.state.network.getProductList(null, null, '')
        this.state.network.getClientOrderTypeList()

        if (clientOrderCode) {
            this.state.network.getClientOrder(clientOrderCode)
            this.setState({ updating: true })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { success, error } = this.props

        // Maybe need this if it stores state between different edits (it shouldn't)
        if (this.props.creatingClientOrder !== prevProps.creatingClientOrder) {
            if (!this.props.creatingClientOrder) {
                if (this.props.createStatus.success) {
                    // Navigate back, success!
                    success(
                        createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Visualizza', () => {
                            this.props.history.push(route_from(app, clientOrders, `${this.state.finalClientOrderCode}`))
                        })
                    )
                    this.setState({
                        status: 'success',
                    })
                    this.props.history.goBack()
                } else {
                    if (
                        this.props.createStatus.error &&
                        this.props.createStatus.error.response &&
                        this.props.createStatus.error.response.status === 409
                    ) {
                        error(
                            createAlert(
                                'Il codice ordine cliente inserito esiste già',
                                'Modificare il codice ordine cliente o modifica dalla lista commesse'
                            )
                        )

                        this.setState({
                            status: 'error',
                            errorMessage:
                                'Il codice ordine cliente inserito esiste già: modificare il codice ordine cliente o modifica dalla lista commesse.',
                        })
                    } else {
                        error(createAlert('Errore del server', 'Riprova piú tardi'))
                        this.setState({
                            status: 'error',
                            errorMessage: '',
                        })
                    }
                }
            }
        }

        let { id } = this.state
        let { getClientOrder } = this.props
        if (getClientOrder && getClientOrder.fetching !== prevProps.getClientOrder.fetching && !getClientOrder.fetching) {
            if (getClientOrder.status.success) {
                let clientOrder = getClientOrder.data[id][0]

                let momobj = moment(clientOrder.lastBillDate, 'YYYY-MM-DD').add('days', 1)
                if (momobj.isValid()) {
                    clientOrder.lastBillDate = momobj.toDate()
                } else {
                    clientOrder.lastBillDate = new Date()
                }

                if (clientOrder.deliveryDate) {
                    let deldate = new Date(clientOrder.deliveryDate)
                    clientOrder.deliveryDate = deldate
                    clientOrder.deliveryWeek = moment(deldate).get('week')
                } else {
                    let deldate = new Date()
                    clientOrder.deliveryDate = deldate
                    clientOrder.deliveryWeek = moment(deldate).get('week')
                }

                // console.log('Setting state to client order', clientOrder)
                this.setState(clientOrder)
            }
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return nextProps
    }

    save() {
        // TODO value checking
        let {
            id,
            clientOrderCode,
            description,
            clientOrderType,
            client,
            image,
            prodMap,
            productCode,
            billed,
            budgeted,
            active,
            urgencyLevel,
            createdExternal,
            addDate,
            workOrders,
            updating,
            clientOrderProgram,
            deliveryDate,
            deliveryDateChanged,
        } = this.state

        let { warning } = this.props

        if (!clientOrderCode || clientOrderCode === '') {
            warning(createAlert('Inserire il codice ordine cliente', 'Il codice ordine cliente è un campo richiesto'))
            this.setState({
                status: 'warning',
                errorMessage: 'Il codice ordine cliente è un campo richiesto',
            })
            return
        }

        // let finalClientOrderCode = clientOrderCode
        let finalDescription = description

        if (productCode) {
            let product = prodMap[productCode]
            finalDescription = `${finalDescription}\n${product.description}`
        }

        // console.log('ClientOrderEditor updating:', updating)

        let finalClientOrderCode = clientOrderCode

        // if (!updating && productCode) finalClientOrderCode = productCode + finalClientOrderCode
        // if (!updating && addDate) finalClientOrderCode = finalClientOrderCode + moment().format('DDMMMYY').toUpperCase()

        // console.log('Final clientOrder code will be', finalClientOrderCode)

        this.setState({ finalClientOrderCode })

        this.props.network.createClientOrder(id, {
            clientOrderCode: finalClientOrderCode,
            description: finalDescription,
            clientOrderType,
            client,
            image,
            //lastBillDate: moment(lastBillDate).format('YYYY-MM-DD'),
            billed,
            budgeted,
            workOrders,
            urgencyLevel,
            createdExternal,
            active,
            clientOrderProgram,
            deliveryDate: deliveryDateChanged ? moment(deliveryDate).add('hour', 2).format('YYYY-MM-DD') : null,
        })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState(
            {
                [name]: value,
            },
            () => {
                let { onChange } = this.props
                if (onChange) {
                    let {
                        clientOrderCode,
                        description,
                        clientOrderType,
                        client,
                        image,
                        urgencyLevel,
                        createdExternal,
                        billed,
                        active,
                        budgeted,
                        clientOrderProgram,
                        deliveryDate,
                    } = this.state
                    onChange({
                        clientOrderCode,
                        description,
                        clientOrderType,
                        client,
                        image,
                        urgencyLevel,
                        createdExternal,
                        billed,
                        active,
                        budgeted,
                        clientOrderProgram,
                        deliveryDate,
                    })
                }
            }
        )

        if (name === 'deliveryWeek') {
            this.setState({
                deliveryDate: moment().set('week', value).toDate(),
                deliveryDateChanged: true,
            })
        }
    }

    render() {
        let {
            fetching,
            creatingClientOrder,
            options,
            prodOptions,
            clientOrderCode,
            description,
            clientOrderType,
            client,
            image,
            lastBillDate,
            billed,
            budgeted,
            errorMessage,
            urgencyLevel,
            createdExternal,
            active,
            productCode,
            workOrders,
            addDate,
            id,
            updating,
            clientOrderProgram,
            deliveryDate,
            deliveryWeek,
        } = this.state

        let finalClientOrderCode = clientOrderCode ? clientOrderCode : ''
        if (productCode) finalClientOrderCode = productCode + finalClientOrderCode
        if (addDate) finalClientOrderCode = finalClientOrderCode + moment().format('DDMMMYY').toUpperCase()

        return (
            <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                <Form
                    loading={fetching || creatingClientOrder}
                    success={this.state.status === 'success'}
                    error={this.state.status === 'error'}
                    warning={this.state.status === 'warning'}
                >
                    {/* <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-article-type"
                            control={Select}
                            search
                            options={prodOptions}
                            label={<span className="label">Codice prodotto (Opzionale)</span>}
                            placeholder="Codice prodotto (Opzionale)"
                            name="productCode"
                            value={productCode}
                            disabled={updating}
                            onChange={(e, data) => {
                                let id = data.value
                                // console.log('Tipo selected', id)
                                this.setState({ productCode: id })
                            }}
                        />
                    </Form.Group> */}
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-clientOrder-id"
                            control={Input}
                            label={<span className="label">Codice ordine cliente</span>}
                            name="clientOrderCode"
                            value={clientOrderCode}
                            // disabled={!!id || updating}
                            onChange={(e) => this.handleInput(e)}
                            placeholder="0001"
                        />
                        <Form.Field
                            id="form-input-control-clientOrder-description"
                            control={Input}
                            label={<span className="label">Descrizione</span>}
                            placeholder="Fresature"
                            name="description"
                            value={description}
                            onChange={(e) => this.handleInput(e)}
                        />
                        {/* <Form.Field
                            id="form-input-control-clientOrder-type"
                            control={Select}
                            options={options}
                            label={<span className="label">Tipo ordine cliente</span>}
                            placeholder="Tipo ordine cliente"
                            name="clientOrderType"
                            value={clientOrderType}
                            onChange={(e, data) => {
                                let id = data.value
                                this.setState({ clientOrderType: id })
                            }}
                        /> */}
                    </Form.Group>
                    <Form.Group>
                        <Form.Field
                            id="form-input-control-clientOrder-delivery-week"
                            control={Input}
                            label={<span className="label">Settimana di consegna</span>}
                            placeholder="32"
                            name="deliveryWeek"
                            value={deliveryWeek}
                            onChange={(e) => this.handleInput(e)}
                        />
                        <Form.Field
                            id="form-input-control-clientOrder-delivery-date"
                            control={DatePicker}
                            label={<span className="label">Data di consegna</span>}
                            name="deliveryDate"
                            value={deliveryDate}
                            dateFormat="dd/MM/yyyy"
                            selected={deliveryDate}
                            onChange={(data) => {
                                let deliveryWeek = moment(data).get('week')

                                this.setState({
                                    deliveryDate: data,
                                    deliveryWeek,
                                    deliveryDateChanged: true,
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        {/* <Form.Field
                            control={Checkbox}
                            label={<span className="label">Aggiungi data al codice ordine cliente</span>}
                            checked={addDate}
                            disabled={updating}
                            onChange={(e, data) => {
                                this.setState({ addDate: data.checked })
                            }}
                        /> */}
                        {/* <Form.Field id="form-input-control-clientOrder-id" control={Input} label={<span className="label">Codice ordine cliente finale</span>} value={finalClientOrderCode} disabled /> */}
                        <Form.Field
                            id="form-textarea-control-image"
                            control={Input}
                            label={<span className="label">Immagine</span>}
                            placeholder="Immagine"
                            name="image"
                            value={image}
                            disabled
                            onChange={(e) => this.handleInput(e)}
                        />
                        <PickImage
                            onChange={(image) => {
                                this.setState({ image: image })
                            }}
                            value={this.state.image}
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-textarea-control-client"
                            control={Input}
                            label={<span className="label">Cliente</span>}
                            placeholder="Cliente"
                            name="client"
                            value={client}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Form.Group>
                    {workOrders.map((value, index) => {
                        return (
                            <Form.Group widths="equal" key={`client-order-editor-work-orders-${value.workOrderId}-${index}`}>
                                <Form.Field
                                    id="form-input-control-workorder-id"
                                    control={Input}
                                    label={<span className="label">Identificativo ordine di lavoro</span>}
                                    name="workCode"
                                    disabled={!!id}
                                    value={value.workCode}
                                    onChange={(event) => {
                                        const target = event.target
                                        const value = target.type === 'checkbox' ? target.checked : target.value

                                        workOrders[index].workCode = value
                                        this.setState({ workOrders })
                                    }}
                                    placeholder="Ordine di lavoro 1"
                                />
                                <Form.Field
                                    id="form-input-control-workorder-product"
                                    control={Input}
                                    label={<span className="label">Oggetto da produrre</span>}
                                    placeholder="Prodotto 1"
                                    name="description"
                                    value={value.product}
                                    onChange={(event) => {
                                        const target = event.target
                                        const value = target.type === 'checkbox' ? target.checked : target.value

                                        workOrders[index].product = value
                                        this.setState({ workOrders })
                                    }}
                                />
                                <Form.Field
                                    id="form-input-control-workorder-quantity"
                                    control={Input}
                                    label={<span className="label">Quantita&#39; da produrre</span>}
                                    placeholder="Quantita"
                                    name="productQuantity"
                                    value={value.productQuantity}
                                    onChange={(event) => {
                                        const target = event.target
                                        const value = target.type === 'checkbox' ? target.checked : target.value

                                        workOrders[index].productQuantity = value
                                        this.setState({ workOrders })
                                    }}
                                />
                                {!id && (
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            // console.log('Adding work order')
                                            let { workOrders } = this.state
                                            // console.log('Work orders in state', workOrders, index)
                                            workOrders.splice(index, 1)
                                            // console.log('Work orders in state', workOrders, index)
                                            this.setState({ workOrders })
                                        }}
                                        icon="trash"
                                    />
                                )}
                            </Form.Group>
                        )
                    })}
                    {!id && (
                        <Button
                            color="green"
                            icon="plus"
                            onClick={() => {
                                // console.log('Adding work order')
                                let { workOrders } = this.state
                                workOrders.push({
                                    workCode: 'Ordine di lavoro ' + (workOrders.length + 1),
                                    product: 'Prodotto',
                                    productQuantity: 1,
                                })
                                this.setState({ workOrders })
                            }}
                        />
                    )}

                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-clientOrder-bill-date"
                            control={DatePicker}
                            label={<span className="label">Data ultima fatturazione</span>}
                            name="lastBillDate"
                            value={lastBillDate}
                            dateFormat="dd/MM/yyyy"
                            selected={lastBillDate}
                            onChange={(data) => {
                                this.setState({
                                    lastBillDate: data,
                                })
                            }}
                        />
                        <Form.Field
                            id="form-input-control-clientOrder-billed"
                            control={Input}
                            type="number"
                            label={<span className="label">Fatturato</span>}
                            placeholder="0.0"
                            step="0.1"
                            name="billed"
                            value={billed}
                            className="rightTextField"
                            onChange={(e) => this.handleInput(e)}
                        />
                        <span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
                        <Form.Field
                            id="form-input-control-clientOrder-budgeted"
                            control={Input}
                            type="number"
                            label={<span className="label">Preventivato</span>}
                            placeholder="0.0"
                            step="0.1"
                            name="budgeted"
                            value={budgeted}
                            className="rightTextField"
                            onChange={(e) => this.handleInput(e)}
                        />
                        <span style={{ overflow: 'unset', alignSelf: 'flex-end', marginBottom: 10 }}>€</span>
                    </Form.Group>

                    <Form.Field
                        control={Checkbox}
                        label={<span className="label">Ordine cliente URGENTE</span>}
                        checked={urgencyLevel > 0}
                        onChange={(e, data) => {
                            this.setState({ urgencyLevel: data.checked ? 1 : 0 }, () => {
                                let { onChange } = this.props
                                if (onChange) {
                                    let {
                                        description,
                                        clientOrderType,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        clientOrderProgram,
                                    } = this.state
                                    onChange({
                                        clientOrderType,
                                        description,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        clientOrderProgram,
                                    })
                                }
                            })
                        }}
                    />
                    {/* <Form.Field
                        control={Checkbox}
                        label={<span className="label">Ordine cliente creata esternamente</span>}
                        checked={createdExternal === 1}
                        onChange={(e, data) => {
                            this.setState({ createdExternal: data.checked ? 1 : 0 }, () => {
                                let { onChange } = this.props
                                if (onChange) {
                                    let {
                                        description,
                                        clientOrderType,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        clientOrderProgram,
                                    } = this.state
                                    onChange({
                                        clientOrderType,
                                        description,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        clientOrderProgram,
                                    })
                                }
                            })
                        }}
                    /> */}
                    <Form.Field
                        control={Checkbox}
                        label={<span className="label">Ordine cliente attivo</span>}
                        checked={active === 1}
                        onChange={(e, data) => {
                            this.setState({ active: data.checked ? 1 : 0 }, () => {
                                let { onChange } = this.props
                                if (onChange) {
                                    let {
                                        description,
                                        clientOrderType,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        clientOrderProgram,
                                    } = this.state
                                    onChange({
                                        clientOrderType,
                                        description,
                                        client,
                                        image,
                                        lastBillDate,
                                        urgencyLevel,
                                        createdExternal,
                                        active,
                                        billed,
                                        budgeted,
                                        clientOrderProgram,
                                    })
                                }
                            })
                        }}
                    />
                    <Form.Field
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            this.save()
                        }}
                    />
                    <Message success header="Form Completed" content="Ordine cliente inserita con successo!" />
                    <Message error header="Errore" content={`Errore del server, \nriprova piu' tardi. ${errorMessage}`} />
                    <Message warning header="Errore inserimento" list={[`${errorMessage}`]} />
                </Form>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        getProductList: {
            data: { product: products },
            fetching: fetchProduct,
        },
        getClientOrderTypeList: {
            data: { data: types },
            fetching,
        },
        createClientOrder: { data: createResult, fetching: creatingClientOrder, status: createStatus },
        getClientOrder,
    } = state

    let options = []
    for (let i in types) {
        let type = types[i]
        options.push({
            key: type.clientOrderType,
            text: type.typeName,
            value: type.clientOrderType,
        })
    }

    let prodMap = {}
    let prodOptions = []
    for (let i in products) {
        let type = products[i]
        prodOptions.push({
            key: type.code,
            text: type.code + ' ' + type.description,
            value: type.code,
        })
        prodMap[type.code] = type
    }
    prodOptions.push({
        key: 0,
        text: 'Nessun riferimento a prodotto',
        value: false,
    })

    return {
        prodOptions,
        prodMap,
        fetchProduct,
        options,
        types,
        fetching,
        createResult,
        creatingClientOrder,
        createStatus,
        getClientOrder,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ClientOrderEditor)))
