export function createListing(data) {
    return new Promise((resolve, reject) => {
		let { createListingRequest, createListingError, createListingResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_listing',
			this.createBundle(
				'create_listing',
				{ listing: data },
				this.POST,
				createListingRequest,
                (result) => {
                    createListingResult(result)
                    resolve(result)
                },
                (error) => {
                    createListingError(error)
                    reject(error)
                }
			)
		);
	})
}
