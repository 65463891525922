import { Box } from '@chakra-ui/layout'
import { Confirmation, ImageDefault } from 'components'
import {} from 'containers'
import JsBarcode from 'jsbarcode'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import ReactToPrint from 'react-to-print'
import { Button, Icon, Modal, Table } from 'semantic-ui-react'
import SummarySheet from '../job/SummarySheet'
import { mapOrdToOrdering } from '../lib/util'
import MaterialDropdown from '../utility/MaterialDropdown'

const { app, materials, materialEdit, materialClone } = routes

class Material extends Component {
    constructor(props) {
        super(props)

        let { onCostChange, material } = props

        this.state = {
            deleting: false,
            ...material,
            materialMinutes: material ? parseFloat(material.materialMinutes) : 0,
        }

        if (onCostChange && material) {
            onCostChange(this.totalCost(), material.materialId)
        }
    }

    totalCost() {
        let { materialPrice } = this.state
        let total = materialPrice
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidUpdate(prevProps, prevState) {
        let { material, materialId, materialCode, network, getMaterial } = this.props
        if (
            (!material || !material.materialId) &&
            (this.props.materialId !== prevProps.materialId || this.props.materialCode !== prevProps.materialCode)
        ) {
            if ((!material || !material.materialId) && materialId) {
                network.getMaterial(materialId)
            }

            if ((!material || !material.materialId) && materialCode) {
                network.getMaterial(undefined, materialCode)
            }
        }

        if (getMaterial && getMaterial.fetching !== prevProps.getMaterial.fetching && !getMaterial.fetching) {
            if (getMaterial.status.success) {
                let material = getMaterial.data.data

                this.setState({ ...material })
                if (this.props.onLoad) {
                    this.props.onLoad(material)
                }
                return
            } else {
                if (this.props.onLoad) {
                    this.props.onLoad(false)
                }
            }
        }
    }

    componentDidMount() {
        let { material, materialId, materialCode, network } = this.props

        if ((!material || !material.materialId) && materialId) {
            network.getMaterial(materialId)
        }

        if ((!material || !material.materialId) && materialCode) {
            network.getMaterial(undefined, materialCode)
        }
    }

    renderBalanceFooter() {
        return <Table.Row></Table.Row>
    }

    renderBalanceHeader() {
        return <Table.Header fullWidth></Table.Header>
    }

    renderForBalance() {
        return <Table.Row></Table.Row>
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let {
            onMaterialCodeHeaderClick,
            hideMaterial,
            hideMaterialName,
            hideMaterialUnitPrice,
            hideMaterialMeasurement,
            isBalance,
            hideActions,
            noActions,
            hideBarcode,
            onRowClick,
            sorts,
        } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideMaterial && (
                            <Table.HeaderCell
                                {...(sorts ? { sorted: sorts.materialCode ? mapOrdToOrdering[sorts.materialCode] : null } : {})}
                                onClick={onMaterialCodeHeaderClick ? onMaterialCodeHeaderClick : () => {}}
                            >
                                Materiale
                            </Table.HeaderCell>
                        )}
                        {!hideMaterialName && <Table.HeaderCell>Descrizione</Table.HeaderCell>}
                        {!hideMaterialUnitPrice && <Table.HeaderCell>Prezzo per unità</Table.HeaderCell>}
                        {!hideMaterialMeasurement && <Table.HeaderCell>Unità di misura</Table.HeaderCell>}
                        {!hideBarcode && <Table.HeaderCell>Codice a barre</Table.HeaderCell>}
                        {!hideActions && !noActions && <Table.HeaderCell>Azioni</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let {
            hideMaterial,
            hideMaterialName,
            hideMaterialUnitPrice,
            hideMaterialMeasurement,
            hideBarcode,
            isBalance,
            onRowClick,
            hideActions,
            noActions,
        } = this.props
        let { materialId, materialCode, materialDescription, pricePerUnit, unit } = this.state

        setImmediate(() => {
            try {
                const text = materialCode
                JsBarcode(`#matbarcode${materialCode}`, text, {
                    format: 'CODE128',
                    // lineColor: '#0aa',
                    // width: 4,
                    // height: 40,
                    // displayValue: false,
                })
            } catch (e) {
                console.error(e)
            }
        })

        let scanned = this.props.scannedMaterials ? this.props.scannedMaterials.includes(materialCode) : false

        // console.log('Materiale is', scanned)
        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row style={{ backgroundColor: scanned ? 'green' : 'white' }}>
                    {!hideMaterial && <Table.Cell>{materialCode}</Table.Cell>}
                    {!hideMaterialName && <Table.Cell>{materialDescription}</Table.Cell>}
                    {!hideMaterialUnitPrice && <Table.Cell>{pricePerUnit}</Table.Cell>}
                    {!hideMaterialMeasurement && <Table.Cell>{unit}</Table.Cell>}
                    {!hideBarcode && (
                        <Table.Cell>
                            <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                <svg id={`matbarcode${materialCode}`}></svg>
                            </div>
                        </Table.Cell>
                    )}
                    {!hideActions && !noActions && this.state.materialId && (
                        <Table.Cell className="no-print">
                            <SummarySheet type="material" objectToSerialize={this.state} />
                            <Button
                                icon
                                color="yellow"
                                onClick={(e) => {
                                    this.setState({
                                        mergingMaterial: true,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="exchange" />
                            </Button>
                            <Button
                                icon
                                color="blue"
                                onClick={(e) => {
                                    if (onRowClick) {
                                        onRowClick(this.state)
                                    }
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="edit" />
                            </Button>
                            <Button
                                icon
                                color="red"
                                onClick={(e) => {
                                    this.props.network.deleteMaterial({
                                        materialId,
                                        materialCode,
                                    })
                                    e.stopPropagation()
                                }}
                            >
                                <Icon name="remove" />
                            </Button>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button icon color="grey">
                                            <Icon name="print" />
                                        </Button>
                                    )
                                }}
                                content={() => this.componentRef}
                            />
                        </Table.Cell>
                    )}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, material } = this.props

        network.deleteStdMaterial(material)

        this.setState({ deleting: false })
    }

    render() {
        let { materialImages, materialDescription, creationDate, mergeDestinations, mergingMaterial, materialId, materialCode, deleting } = this.state
        let { login, type, material, onClick, selected, noActions } = this.props

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(material)
            }
        }

        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        if (!materialId && !materialCode) {
            return null
        }

        let content = null
        switch (type) {
            case 'footer':
                content = this.renderTableFooter()
                break
            case 'header':
                content = this.renderTableHeader()
                break
            case 'table':
                content = this.renderForTable()
                break
            default:
                content = (
                    <Box
                        ref={(el) => (this.componentRef = el)}
                        className={selected ? 'rowSelected' : ''}
                        style={styles.materialCard}
                        {...extraprops}
                        borderWidth="1px"
                        borderRadius="lg"
                        p="4"
                        overflow="hidden"
                    >
                        <Confirmation
                            message="Vuoi davvero eliminare questo materiale?"
                            showModal={deleting}
                            onConfirm={() => {
                                this.remove()
                            }}
                            onCancel={() => {
                                this.setState({ deleting: false })
                            }}
                        />
                        <Box textStyle="content" style={styles.collectionContent}>
                            <View fullw row>
                                {materialImages && materialImages.length > 0 && (
                                    <div style={styles.imageContainer}>
                                        <ImageDefault fromRoot src={materialImages[0].picturePath} ui={false} style={styles.materialImage} />
                                    </div>
                                )}
                                <View fullw column>
                                    <Box textStyle="header">{materialCode}</Box>
                                    <Box textStyle="description">{materialDescription}</Box>
                                    {creationDate && (
                                        <Box textStyle="description">Data di creazione: {moment(creationDate).format('DD/MM/YYYY HH:mm:ss')}</Box>
                                    )}

                                    {this.state.molds && this.state.molds.length > 0 ? (
                                        <>
                                            {this.state.molds.map((value, index) => {
                                                return (
                                                    <Box
                                                        textStyle="description"
                                                        key={`material-mold-map-${materialCode}-${index}-${value.moldCode}-${value.jobCode}`}
                                                    >
                                                        {value.moldCode} {value.jobDescription ? value.jobDescription : value.moldDescription}
                                                    </Box>
                                                )
                                            })}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </View>

                                <View fullw column around>
                                    <Box
                                        key={`quantity-material-${materialCode}-${this.state.qty}`}
                                        alignItems="center"
                                        style={{ marginBottom: 0, alignSelf: 'center' }}
                                    >
                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                            Giacenza
                                        </Box>
                                        <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                            {this.state.qty}
                                        </Box>
                                    </Box>
                                </View>

                                {this.state.id_deposit && this.state.id_deposit !== undefined && this.state.id_deposit !== null && (
                                    <View fullw column around>
                                        <Box
                                            key={`quantity-material-${materialCode}-${this.state.qty}`}
                                            alignItems="center"
                                            style={{ marginBottom: 0, alignSelf: 'center' }}
                                        >
                                            <Box fontSize="lg" textAlign={['center', 'center']}>
                                                Deposito
                                            </Box>
                                            <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                                {this.state.name}
                                            </Box>
                                        </Box>
                                    </View>
                                )}

                                {!this.props.hideBarcode && (
                                    <View fullw column around>
                                        <Box textStyle="header" style={{ textAlign: 'center' }}>
                                            Codice a barre
                                        </Box>
                                        <div className="barcode-container" ref={(el) => (this.componentRef = el)}>
                                            <svg id={`artbarcode${materialCode}`}></svg>
                                        </div>
                                    </View>
                                )}

                                {this.state.masters && this.state.masters.length > 0 && (
                                    <View fullw column around>
                                        <Box textStyle="header" style={{ textAlign: 'center' }}>
                                            Master
                                        </Box>
                                        <View style={{ minHeight: 103 }} fullw row around>
                                            {this.state.masters &&
                                                this.state.masters.map((value, index) => (
                                                    <Box
                                                        key={`master-material-${index}-${value.masterId}`}
                                                        alignItems="center"
                                                        style={{ marginBottom: 0, alignSelf: 'center' }}
                                                    >
                                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                                            {value.masterName}
                                                        </Box>
                                                        <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                                            {value.masterPercent}%
                                                        </Box>
                                                    </Box>
                                                ))}
                                        </View>
                                    </View>
                                )}
                                {this.state.materials && this.state.materials.length > 0 && (
                                    <View fullw column around>
                                        <Box textStyle="header" style={{ textAlign: 'center' }}>
                                            Materiali
                                        </Box>
                                        <View style={{ minHeight: 103 }} fullw row around>
                                            {this.state.materials &&
                                                this.state.materials.map((value, index) => (
                                                    <Box
                                                        key={`material-material-${index}-${value.materialId}`}
                                                        alignItems="center"
                                                        style={{ marginBottom: 0, alignSelf: 'center' }}
                                                    >
                                                        <Box fontSize="lg" textAlign={['center', 'center']}>
                                                            {value.materialDescription}
                                                        </Box>
                                                        <Box fontSize="4xl" textStyle="buc" mb={0} mt={0} textAlign={['center', 'center']}>
                                                            {value.materialPercent}%
                                                        </Box>
                                                    </Box>
                                                ))}
                                        </View>
                                    </View>
                                )}
                            </View>
                        </Box>
                        {!noActions && (
                            <Box textStyle="content" extra className="no-print">
                                {!this.props.workOrderSummary && <SummarySheet type="material" objectToSerialize={this.state} />}
                                {this.props.workOrderSummary && <SummarySheet type="workorder" objectToSerialize={this.state} />}
                                {user && !!user.manage_material && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, materials, materialClone, `${materialId}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Clona
                                    </Button>
                                )}

                                {user && !!user.manage_material && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, materials, materialEdit, `${materialId}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        Modifica
                                    </Button>
                                )}

                                {user && !!user.manage_material && (
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.openRemoveDialog()
                                        }}
                                    >
                                        <Icon name="delete" />
                                        Elimina
                                    </Button>
                                )}
                                <ReactToPrint
                                    trigger={() => {
                                        return (
                                            <Button color="grey">
                                                <Icon name="print" />
                                                Stampa codice a barre
                                            </Button>
                                        )
                                    }}
                                    content={() => this.componentRef}
                                />
                            </Box>
                        )}
                    </Box>
                )
                break
        }

        return (
            <>
                <Modal
                    key={`modal_material_${materialId}`}
                    closeIcon
                    open={!!mergingMaterial}
                    onClose={() => this.setState({ mergingMaterial: false })}
                >
                    <Modal.Header>Modificando materiale</Modal.Header>
                    <Modal.Content>
                        <MaterialDropdown
                            selected={mergeDestinations}
                            onChange={(ev, data, selected, materials) => {
                                this.setState({
                                    mergeDestinations: materials,
                                })
                            }}
                            onlySelection
                        />
                        <Button
                            style={{ marginTop: 16 }}
                            color="red"
                            onClick={() => {
                                this.props.network.mergeMaterial({
                                    sourceId: this.state.materialId,
                                    destinationId: this.state.mergeDestinations[0].materialId,
                                })
                                this.setState({ mergingMaterial: false })
                            }}
                            disabled={
                                !this.state.mergeDestinations ||
                                this.state.mergeDestinations.length !== 1 ||
                                this.state.materialId === this.state.mergeDestinations[0].materialId
                            }
                        >
                            Effettua unione AZIONE IRREVERSIBILE, VERRA{"'"} ELIMINATO IL MATERIALE {'"'}
                            {this.state.materialDescription}
                            {'"'} ({this.state.materialId})
                        </Button>
                    </Modal.Content>
                </Modal>
                {content}
            </>
        )
    }
}

const styles = {
    header: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    materialImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    materialContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    materialCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getMaterial } = state
    let rmaterialId = null
    if (login && login.data && login.data.user) rmaterialId = login.data.user.rmaterialId

    return { role: rmaterialId, getMaterial, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Material)))
