export function getMachine(data) {
    let { getMachineRequest, getMachineError, getMachineResult } = this.props

    this.doAction(
        this.WEB_REQUEST_URL + 'get_machine',
        this.createBundle('get_machine', { id: data }, this.GET, getMachineRequest, getMachineResult, (err) => {
            // console.log('getMachineError')
            getMachineError(err)
        })
    )
}
