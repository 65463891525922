import moment from 'momentconfig'

export function createJob(id, job, isEmployee) {
    return new Promise((resolve, reject) => {
        let { createJobRequest, createJobError, createJobResult } = this.props

        delete job.creationDate
        delete job.updateDate

        if (job.orderDate) {
            job.orderDate = moment(job.orderDate).format('YYYY-MM-DD')
        }

        this.doAction(
            this.WEB_REQUEST_URL + 'create_job',
            this.createBundle(
                'create_job',
                { id, job },
                this.POST,
                createJobRequest,
                (result) => {
                    createJobResult(result)
                    resolve(result)
                },
                (error) => {
                    createJobError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
