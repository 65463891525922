import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Dropdown, Form, Input, Modal } from 'semantic-ui-react'
import ProductionEditor from '../job/ProductionEditor'
function useForceUpdate() {
    const [value, setValue] = useState(0) // integer state
    return () => setValue((value) => value + 1) // update the state to force render
}

function ProductionDropdown({ network, onChange, defaultSelectedProductions = [], onlySelection }) {
    const [options, setOptions] = useState([])
    const [productions, setProductions] = useState({})
    const [input, setInput] = useState('')
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [selectedProductions, setSelectedProductions] = useState([])
    const forceUpdate = useForceUpdate()

    const dropdownValue = []
    for (const production of selectedProductions) {
        if (production && production.productionId) {
            dropdownValue.push(production.productionId)
        }
    }

    const handleAddition = (e, { value }) => {
        setInput(value)
        setEditing(true)
    }

    let dirtySelection = false
    for (let i = 0; i < defaultSelectedProductions.length; i++) {
        if (JSON.stringify(defaultSelectedProductions[i]) !== JSON.stringify(selectedProductions[i])) {
            dirtySelection = true
        }
    }

    useEffect(() => {
        setSelectedProductions(defaultSelectedProductions)
    }, [dirtySelection])

    useEffect(() => {
        network.getProductionList().then((data) => {
            const productionlist = data.data
            const productionobj = {}

            let productionOptions = []
            for (let i in productionlist) {
                let production = productionlist[i]
                productionobj[production.productionId] = production
                productionOptions.push({
                    key: `production_${production.productionId}`,
                    text: `${production.productionId} - ${production.productionDescription}`,
                    value: production.productionId,
                })
            }

            setProductions(productionobj)
            setOptions(productionOptions)
            setDirty(false)
        })
    }, [dirty])

    const extraProps = {}
    if (!onlySelection) {
        extraProps.allowAdditions = true
        extraProps.onAddItem = handleAddition
    }

    return (
        <>
            <Dropdown
                onChange={(ev, data) => {
                    if (onChange) {
                        const selectedList = []
                        for (const key of data.value) {
                            selectedList.push(productions[key])
                        }

                        setSelectedProductions(selectedList)
                        onChange(ev, data, data.value, selectedList)
                    }
                }}
                placeholder="Productione"
                fluid
                multiple
                search
                selection
                options={options}
                value={dropdownValue}
                {...extraProps}
            />
            {!onlySelection && (
                <>
                    {selectedProductions.map((value, index) => {
                        if (!value) {
                            return null
                        }
                        return (
                            <Form.Group widths="equal" key={`production-dropdown-${value.productionId}`}>
                                <Form.Field
                                    id={`form-input-control-production-percent-${index}`}
                                    control={Input}
                                    label={`Percentuale productione ${value.productionDescription}`}
                                    name="productionPercent"
                                    type="number"
                                    step="0"
                                    value={value.productionPercent}
                                    onChange={(e) => {
                                        const toEdit = selectedProductions[index]
                                        toEdit.productionPercent = e.target.value
                                        forceUpdate()
                                    }}
                                    placeholder=""
                                />
                            </Form.Group>
                        )
                    })}
                </>
            )}

            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi productione</Modal.Header>
                <Modal.Content>
                    <ProductionEditor
                        defaultDescription={input}
                        onSave={(production) => {
                            setEditing(false)
                            setDirty(true)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

export default withRouter(connectNetwork(ProductionDropdown))
