export function deleteKit(data) {
	let { deleteKitRequest, deleteKitError, deleteKitResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_kit',
		this.createBundle(
			'delete_kit',
			{ kit: data },
			this.POST,
			deleteKitRequest,
			deleteKitResult,
			deleteKitError
		)
	);
}
