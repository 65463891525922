import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Placeholder } from 'semantic-ui-react'
import { CustomTable, GT2000Data } from '../components'
import { baseViewDispatch, initializePage } from '../lib/util'
class GT2000 extends Component {
    constructor(props) {
        super(props)

        this.state = {
            start: moment().subtract(1, 'day'),
            end: moment(),
        }
    }

    prev() {
        // todo moment subtract
    }

    next() {
        // todo moment add
    }

    componentDidMount() {
        let { sub, network } = this.props
        if (!sub) {
            initializePage(
                this,
                [
                    // {
                    // 	name: 'Invia dati a GT2000',
                    // 	icon: 'add',
                    // 	action: () => {
                    // 		this.props.history.push(route_from(app, gt2000, gt2000editor))
                    // 	}
                    // },
                    {
                        name: '',
                        icon: 'arrow left',
                        position: 'right',
                        action: () => {
                            this.prev()
                        },
                    },
                    {
                        name: '',
                        icon: 'arrow right',
                        position: 'none',
                        action: () => {
                            this.next()
                        },
                    },
                ],
                {}
            )
        }

        let start = this.state.start.format('YYYY-MM-DD HH:mm:ss')
        let end = this.state.end.format('YYYY-MM-DD HH:mm:ss')

        // console.log('Start and end are:', start, end)

        network.getLastGT2000Data()
        network.getGT2000Data(start, end)
    }

    renderData(key, gt2000data) {
        return (
            <View fullw type="full" key={`gt2000data_${key}`}>
                {gt2000data.line}
            </View>
        )
    }

    render() {
        let { fetching, gt2000datalist, getLastGT2000Data, ...rest } = this.props
        let content = <div />

        if (fetching) {
            let placeholders = []
            for (let i = 0; i < 3; i++) {
                placeholders.push(
                    <Box key={i} style={{ width: '100%', margin: 8 }}>
                        <Box textStyle="content">
                            <Placeholder>
                                <Placeholder.Header image>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Box>
                    </Box>
                )
            }
            content = (
                <View fullw column>
                    {placeholders}
                </View>
            )
        } else if (gt2000datalist.data) {
            // console.log('gt2000datalist is', gt2000datalist.data)

            content = <CustomTable name="GT2000Data" shorthand="gt2000data" values={gt2000datalist.data} child={GT2000Data} />
        }

        return (
            <View fullw fullh column style={{ paddingLeft: 20, paddingRight: 20 }} {...rest}>
                {content}
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    let { getGT2000Data } = state
    return {
        fetching: getGT2000Data.fetching,
        gt2000datalist: getGT2000Data.data,
        getGT2000Data,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(GT2000)))
