import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Modal, Select } from 'semantic-ui-react'
import { baseViewDispatch, initializePage } from '../lib/util'
import Calendar from './Calendar'
import IntervalEditor from './IntervalEditor'
function CalendarEditor(props) {
    const { network, isEmployee, scheduleId } = props
    const [dirty, setDirty] = useState([])
    const [calendarData, setCalendarData] = useState([])
    const [worker, setWorker] = useState(null)
    const [editing, setEditing] = useState(false)
    const [editday, setEditday] = useState(null)
    const [schedule, setSchedule] = useState(null)

    /*
            calendarData: [
                calendarDate,
                calendarTurnId,
                calendarScheduleId,
                calendarWorkerId,
            ]
        }
    */
    const saveCalendar = () => {
        network.createTimeCalendar({ calendarData })
    }

    useEffect(() => {
        const { workers } = props

        let options = [
            {
                key: 'TUTTI',
                text: 'Tutti',
                value: null,
            },
        ]

        for (let i in workers) {
            let worker = workers[i]
            options.push({
                key: worker,
                text: worker.workerName,
                value: worker,
            })
        }

        const filters = {
            calendario_seleziona_impiegato: {
                control: Select,
                name: 'calendario_seleziona_impiegato',
                type: 'number',
                placeholder: 'Seleziona impiegato',
                label: 'Seleziona impiegato',
                options,
                onChange: (e, data) => {
                    let worker = data.value
                    if (worker) {
                        worker.id = worker.workerId
                    }
                    setWorker(worker)
                },
                onReset: () => {
                    setWorker(null)
                },
            },
        }

        initializePage(
            props,
            [
                // {
                //     name: 'Salva',
                //     icon: 'save',
                //     position: 'right',
                //     action: saveSchedule,
                // },
            ],
            filters
        )
    }, [])

    useEffect(() => {
        network.getTimeCalendarList(moment().get('month')).then((data) => {
            const calendarData = data.data

            setCalendarData(calendarData)
            setDirty(false)
        })
    }, [dirty])

    return (
        <>
            <View column fullw>
                <Calendar
                    worker={worker}
                    isEmployee={isEmployee}
                    customTitle={`Editor calendario`}
                    onDayClicked={(value) => {
                        setEditing(true)
                        setEditday(value)
                    }}
                    customContent={(value, index) => {
                        return <div>Giorno {value.key}</div>
                    }}
                />
                <Button onClick={saveCalendar}>Salva</Button>
            </View>
            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi Intervallo</Modal.Header>
                <Modal.Content>
                    <IntervalEditor
                        onSave={(interval) => {
                            setEditing(false)
                            setDirty(true)

                            const day = editday.index
                            const week = Math.floor(day / 7)
                            const dayInWeek = day - week * 7

                            if (!schedule.calendarData[day]) {
                                schedule.calendarData[day] = {
                                    intervals: [],
                                }
                            }

                            schedule.calendarData[day].intervals.push(interval)
                            setSchedule(schedule)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getWorkerList,
        workday: {
            data: { date: workday },
        },
        getWorkListSpan: {
            data: { data: worksByDay },
        },
    } = state

    return {
        workers: getWorkerList.data.data,
        fetchingWorkers: getWorkerList.fetching,
        workday,
        worksByDay,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(CalendarEditor)))
