import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Column from 'react-virtualized/dist/commonjs/Table/Column'
// import List from 'react-virtualized/dist/commonjs/List';
import Table from 'react-virtualized/dist/commonjs/Table/Table'
import 'react-virtualized/styles.css'
// import { Table } from 'semantic-ui-react'
import { baseViewDispatch } from '../lib/util'
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function BrowseData({ network, table, startTime, endTime }) {
    const [datapoints, setDatapoints] = useState([])
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        network
            .getBabypressaData(
                moment(startTime).format('YYYY-MM-DD'),
                moment(endTime).format('YYYY-MM-DD'),
                table.substring ? table.substring(table.indexOf('_') + 1) : table
            )
            .then((data) => {
                setDatapoints(data.data)
            })
    }, [table, startTime, endTime])

    if (datapoints.length === 0) return null

    const firstDatapoint = datapoints[0]
    const columnCount = Object.keys(firstDatapoint).length
    const columnWidth = 200
    return (
        <Box p={6}>
            <div style={{ overflow: 'scroll' }}>
                <Table
                    headerHeight={32}
                    disableHeader={false}
                    // headerClassName={styles.headerColumn}
                    // headerHeight={headerHeight}
                    height={260}
                    // height={windowDimensions.height - 100}
                    noRowsRenderer={() => {
                        return <div>Nessuna riga</div>
                    }}
                    // overscanRowCount={overscanRowCount}
                    // rowClassName={this._rowClassName}
                    rowHeight={32}
                    rowGetter={(index) => {
                        return datapoints[index.index]
                    }}
                    rowCount={datapoints.length}
                    // scrollToIndex={scrollToIndex}
                    // sort={this._sort}
                    // sortBy={sortBy}
                    // sortDirection={sortDirection}
                    width={columnCount * columnWidth}
                >
                    {Object.keys(firstDatapoint).map((field, index) => (
                        <Column
                            key={`dat_col_${table}_${index}`}
                            label={capitalizeFirstLetter(field)}
                            cellDataGetter={({ rowData }) =>
                                field === 'timestamp' ? moment(rowData[field]).format('DD/MM/YYYY HH:mm:ss') : rowData[field]
                            }
                            dataKey={field}
                            width={columnWidth}
                            // disableSort={!this._isSortEnabled()}
                            // width={60}
                        />
                    ))}
                </Table>
            </div>

            {/* <Table>
                <Table.Header>
                    {Object.keys(firstDatapoint).map((field, index) => (
                        <Table.Cell key={index}>{field}</Table.Cell>
                    ))}
                </Table.Header>
                {datapoints.map((datapoint, index) => (
                    <Table.Body key={index}>
                        {Object.keys(datapoint).map((field, index) => {
                            switch (field) {
                                case 'timestamp':
                                    return <Table.Cell key={index}>{moment.utc(datapoint[field]).local().format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                                default:
                                    return <Table.Cell key={index}>{datapoint[field]}</Table.Cell>
                            }
                        })}
                    </Table.Body>
                ))}
            </Table> */}
        </Box>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { getMachineList } = state

    return {
        getMachineList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(BrowseData)))
