import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Dimmer, Form, Input, Loader, Message, Placeholder, TextArea } from 'semantic-ui-react'
import { baseViewDispatch, createAlert } from '../lib/util'
function MasterEditor({ network, masterId, onSave, onCancel, defaultDescription, error, hide, info, removeAll, show, success, warning }) {
    const [master, setMaster] = useState(null)
    const [status, setStatus] = useState(null)
    const [dirty, setDirty] = useState(false)

    useEffect(() => {
        if (masterId) {
            network.getMaster(masterId).then((data) => {
                setMaster(data.data)
            })
        } else {
            setMaster({
                masterPrice: 1,
                masterSupplier: '',
                masterCode: '',
                masterName: defaultDescription ? defaultDescription : 'Descrizione',
            })
        }
    }, [masterId])

    const handleInput = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        setMaster({
            ...master,
            [name]: value,
        })
    }

    const save = () => {
        const updateobj = master
        if(masterId) {
            master.masterId = masterId
        }

        network
            .createMaster(updateobj)
            .then((data) => {
                setMaster(data.data)
            })
            .then((result) => {
                success(createAlert('Operazione completata.', 'Operazione avvenuta con successo!', 'Ok', () => {}))
                setStatus('success')
                if (onSave) {
                    onSave(result)
                }
            })
            .catch((error) => {
                error(createAlert('Errore nel tentativo di contattare il server', 'Riprova piú tardi'))
                setStatus('error')
            })
    }

    if (!master) {
        return (
            <Box p={6}>
                <Dimmer active>
                    <Loader />
                </Dimmer>

                <Placeholder>
                    <Placeholder.Header image>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Header>
                    <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder.Paragraph>
                </Placeholder>
            </Box>
        )
    }

    const { masterPrice, masterSupplier, masterCode, masterName } = master

    return (
        <View fullw fullh column between style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Form success={status === 'success'} error={status === 'error'} warning={status === 'warning'}>
                <Box p={6}>
                    <Box textStyle="header" as="h1">Anagrafica master</Box>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-master-name"
                            control={TextArea}
                            label={<span className="label">Descrizione Master</span>}
                            name="masterName"
                            value={masterName}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            id="form-input-control-master-supplier"
                            control={Input}
                            label={<span className="label">Fornitore Master</span>}
                            name="masterSupplier"
                            value={masterSupplier}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-master-code"
                            control={Input}
                            label={<span className="label">Codice master</span>}
                            name="masterCode"
                            value={masterCode}
                            onChange={(e) => handleInput(e)}
                            placeholder=""
                        />
                        <Form.Field
                            id="form-input-control-master-cost"
                            control={Input}
                            type="number"
                            label={<span className="label">Prezzo</span>}
                            placeholder="0.0"
                            step="0.1"
                            name="masterPrice"
                            value={masterPrice}
                            onChange={(e) => handleInput(e)}
                        />
                    </Form.Group>
                </Box>

                <Form.Group>
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-save"
                        control={Button}
                        content="Salva"
                        onClick={() => {
                            save()
                        }}
                        color="green"
                    />
                    <Form.Field
                        style={{ marginTop: 16 }}
                        id="form-button-control-cancel"
                        control={Button}
                        content="Annulla"
                        onClick={() => {
                            if (onCancel) {
                                onCancel()
                            }
                        }}
                        color="red"
                    />
                </Form.Group>
                <Message success header="Form completato con successo" content="Macchina inserita con successo!" />
                <Message error header="Errore" content="Errore del server, riprova piu' tardi." />
                <Message warning header="Errore inserimento" list={['Ricontrolla i dati inseriti']} />
            </Form>
        </View>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { login } = state

    return {
        login,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(MasterEditor)))
