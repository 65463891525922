export function getJobTaskList(data) {
	let { getJobTaskListRequest, getJobTaskListError, getJobTaskListResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'get_job_task_list',
		this.createBundle(
			'get_job_task_list',
			{ job: data },
			this.GET,
			getJobTaskListRequest,
			getJobTaskListResult,
			getJobTaskListError
		)
	);
}
