import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import moment from 'moment-timezone'
import 'moment/locale/it'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Modal, Select } from 'semantic-ui-react'
import { baseViewDispatch, initializePage } from '../lib/util'
import Calendar from './Calendar'
import IntervalEditor from './IntervalEditor'
function ScheduleEditor(props) {
    const { network, isEmployee, scheduleId } = props
    const [dirty, setDirty] = useState([])
    const [turns, setTurns] = useState([])
    const [worker, setWorker] = useState(null)
    const [editing, setEditing] = useState(false)
    const [editday, setEditday] = useState(null)
    const [schedule, setSchedule] = useState(null)

    /*
        schedule: {
            turns: { // 7 keys for the 7 days, 0->6, object because a day can be empty
                0: {
                    intervals: [

                    ]
                }
                ...
            }
        }
    */

    useEffect(() => {
        if (scheduleId) {
            network.getSchedule(scheduleId).then((data) => {
                // console.log('Got schedule', data.data)
                setSchedule(data.data)
            })
        } else {
            setSchedule({
                turns: {},
            })
        }
    }, [scheduleId])

    const saveSchedule = () => {
        network.createSchedule({ ...schedule, scheduleId })
    }

    useEffect(() => {
        const { workers } = props

        let options = [
            {
                key: 'TUTTI',
                text: 'Tutti',
                value: null,
            },
        ]

        for (let i in workers) {
            let worker = workers[i]
            options.push({
                key: worker,
                text: worker.workerName,
                value: worker,
            })
        }

        const filters = {
            calendario_seleziona_impiegato: {
                control: Select,
                name: 'calendario_seleziona_impiegato',
                type: 'number',
                placeholder: 'Seleziona impiegato',
                label: 'Seleziona impiegato',
                options,
                onChange: (e, data) => {
                    let worker = data.value
                    if (worker) {
                        worker.id = worker.workerId
                    }
                    setWorker(worker)
                },
                onReset: () => {
                    setWorker(null)
                },
            },
        }

        initializePage(
            props,
            [
                // {
                //     name: 'Salva',
                //     icon: 'save',
                //     position: 'right',
                //     action: saveSchedule,
                // },
            ],
            filters
        )
    }, [])

    // useEffect(() => {
    //     network.getTurnList().then((data) => {
    //         const turns = data.data

    //         setTurns(turns)
    //         setDirty(false)
    //     })
    // }, [dirty])

    return (
        <>
            <View column fullw>
                <Calendar
                    worker={worker}
                    isEmployee={isEmployee}
                    hideday
                    customTitle={`Editor periodo`}
                    customWeeks={2}
                    onDayClicked={(value) => {
                        setEditing(true)
                        setEditday(value)
                    }}
                    customContent={(value, index) => {
                        return (
                            <div>
                                Intervallo {index} {schedule ? JSON.stringify(schedule.turns[index]) : null}
                            </div>
                        )
                    }}
                />
                <Button onClick={saveSchedule}>Salva</Button>
            </View>
            <Modal closeIcon open={editing} onClose={() => setEditing(false)}>
                <Modal.Header>Aggiungi Intervallo</Modal.Header>
                <Modal.Content>
                    <IntervalEditor
                        onSave={(interval) => {
                            setEditing(false)
                            setDirty(true)

                            const day = editday.index
                            const week = Math.floor(day / 7)
                            const dayInWeek = day - week * 7

                            if (!schedule.turns[day]) {
                                schedule.turns[day] = {
                                    intervals: [],
                                }
                            }

                            schedule.turns[day].intervals.push(interval)
                            setSchedule(schedule)
                        }}
                        onCancel={() => {
                            setEditing(false)
                        }}
                    />
                </Modal.Content>
            </Modal>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...baseViewDispatch(dispatch),
    }
}

const mapStateToProps = (state) => {
    let {
        getWorkerList,
        workday: {
            data: { date: workday },
        },
        getWorkListSpan: {
            data: { data: worksByDay },
        },
    } = state

    return {
        workers: getWorkerList.data.data,
        fetchingWorkers: getWorkerList.fetching,
        workday,
        worksByDay,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connectNetwork(ScheduleEditor)))
