import React from 'react'
import { debounce } from 'throttle-debounce'

const debounce_h = (...args) => {
    const debounced = debounce(...args)
    return (e) => {
        e.persist()
        return debounced(e)
    }
}

const View = ({ noflex, monitorPos, column, row, wrap, fullh, fullw, float, between, around, className, children, style }) => {
    const defaults = {
        ...(noflex ? {} : { display: 'flex' }),
        ...(column ? { flexDirection: 'column' } : {}),
        ...(row ? { flexDirection: 'row' } : {}),
        ...(wrap ? { flexWrap: 'wrap' } : {}),
        ...(fullh ? { height: '100%' } : {}),
        ...(fullw ? { width: '100%' } : {}),
        ...(float ? { float } : {}),
        ...(between ? { justifyContent: 'space-between' } : {}),
        ...(around ? { justifyContent: 'space-around' } : {}),
    }
    monitorPos = typeof monitorPos === 'function' ? monitorPos : () => null

    return (
        <div
            className={`${className ? className : ''} react-view`}
            onScroll={debounce_h(200, (e) => monitorPos(e.target.scrollTop))}
            style={{
                ...styles.self,
                ...defaults,
                ...style,
            }}
        >
            {children}
        </div>
    )
}
export default View

const styles = {
    self: {
        // display: 'flex'
    },
}
