export function deleteCommand(data) {
	let { deleteCommandRequest, deleteCommandError, deleteCommandResult } = this.props;

	this.doAction(
		this.WEB_REQUEST_URL + 'delete_command',
		this.createBundle(
			'delete_command',
			{ command: data },
			this.GET,
			deleteCommandRequest,
			deleteCommandResult,
			deleteCommandError
		)
	);
}
