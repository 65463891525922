export function createTimeCalendar(data) {
    return new Promise((resolve, reject) => {
		let { createTimeCalendarRequest, createTimeCalendarError, createTimeCalendarResult } = this.props;

        delete data.creationDate;
        delete data.updateDate;

		this.doAction(
			this.WEB_REQUEST_URL + 'create_timecalendar',
			this.createBundle(
				'create_timecalendar',
				{ timecalendar: data },
				this.POST,
				createTimeCalendarRequest,
                (result) => {
                    createTimeCalendarResult(result)
                    resolve(result)
                },
                (error) => {
                    createTimeCalendarError(error)
                    reject(error)
                }
			)
		);
	})
}
