import React, { Component, Children } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { withCookies } from 'react-cookie'

import * as requests from './requests'

/* @actions */
import { GETSTOCKFILTERED_ACTIONS } from '../actions/getStockFiltered' 
import { ADDSESSIONDATA_ACTIONS } from '../actions/addSessionData'
import { GETWORKORDERLISTSPAN_ACTIONS } from '../actions/getWorkOrderListSpan'
import { DELETERECIPE_ACTIONS } from '../actions/deleteRecipe'
import { CREATERECIPE_ACTIONS } from '../actions/createRecipe'
import { GETRECIPELIST_ACTIONS } from '../actions/getRecipeList'
import { GETRECIPE_ACTIONS } from '../actions/getRecipe'
import { GETLISTINGFORWORKER_ACTIONS } from '../actions/getListingForWorker'
import { DELETELISTING_ACTIONS } from '../actions/deleteListing'
import { CREATELISTING_ACTIONS } from '../actions/createListing'
import { GETLISTINGLIST_ACTIONS } from '../actions/getListingList'
import { GETLISTING_ACTIONS } from '../actions/getListing'
import { DELETEPRODUCTION_ACTIONS } from '../actions/deleteProduction'
import { CREATEPRODUCTION_ACTIONS } from '../actions/createProduction'
import { GETPRODUCTIONLIST_ACTIONS } from '../actions/getProductionList'
import { GETPRODUCTION_ACTIONS } from '../actions/getProduction'
import { DELETELOT_ACTIONS } from '../actions/deleteLot'
import { CREATELOT_ACTIONS } from '../actions/createLot'
import { GETLOTLIST_ACTIONS } from '../actions/getLotList'
import { GETLOT_ACTIONS } from '../actions/getLot'
import { GETSIMPLEARTICLELIST_ACTIONS } from '../actions/getSimpleArticleList'
import { MERGEMOLD_ACTIONS } from '../actions/mergeMold'
import { DELETEMOLD_ACTIONS } from '../actions/deleteMold'
import { CREATEMOLD_ACTIONS } from '../actions/createMold'
import { GETMOLDLIST_ACTIONS } from '../actions/getMoldList'
import { GETMOLD_ACTIONS } from '../actions/getMold'
import { MERGEKIT_ACTIONS } from '../actions/mergeKit'
import { MERGEPACKAGING_ACTIONS } from '../actions/mergePackaging'
import { MERGEMATERIAL_ACTIONS } from '../actions/mergeMaterial'
import { MERGEMASTER_ACTIONS } from '../actions/mergeMaster'
import { DELETECUSTOMER_ACTIONS } from '../actions/deleteCustomer'
import { CREATECUSTOMER_ACTIONS } from '../actions/createCustomer'
import { GETCUSTOMERLIST_ACTIONS } from '../actions/getCustomerList'
import { GETCUSTOMER_ACTIONS } from '../actions/getCustomer'
import { DELETEBADGE_ACTIONS } from '../actions/deleteBadge'
import { CREATEBADGE_ACTIONS } from '../actions/createBadge'
import { GETBADGELIST_ACTIONS } from '../actions/getBadgeList'
import { GETBADGE_ACTIONS } from '../actions/getBadge'
import { DELETEBADGESCAN_ACTIONS } from '../actions/deleteBadgeScan'
import { CREATEBADGESCAN_ACTIONS } from '../actions/createBadgeScan'
import { GETBADGESCANLIST_ACTIONS } from '../actions/getBadgeScanList'
import { GETBADGESCAN_ACTIONS } from '../actions/getBadgeScan'
import { DELETETIMECALENDAR_ACTIONS } from '../actions/deleteTimeCalendar'
import { CREATETIMECALENDAR_ACTIONS } from '../actions/createTimeCalendar'
import { GETTIMECALENDARLIST_ACTIONS } from '../actions/getTimeCalendarList'
import { GETTIMECALENDAR_ACTIONS } from '../actions/getTimeCalendar'
import { DELETESCHEDULE_ACTIONS } from '../actions/deleteSchedule'
import { CREATESCHEDULE_ACTIONS } from '../actions/createSchedule'
import { GETSCHEDULELIST_ACTIONS } from '../actions/getScheduleList'
import { GETSCHEDULE_ACTIONS } from '../actions/getSchedule'
import { DELETEINTERVAL_ACTIONS } from '../actions/deleteInterval'
import { CREATEINTERVAL_ACTIONS } from '../actions/createInterval'
import { GETINTERVALLIST_ACTIONS } from '../actions/getIntervalList'
import { GETINTERVAL_ACTIONS } from '../actions/getInterval'
import { CREATEARTICLEIMPORT_ACTIONS } from '../actions/createArticleImport'
import { DELETEMASTER_ACTIONS } from '../actions/deleteMaster'
import { CREATEMASTER_ACTIONS } from '../actions/createMaster'
import { GETMASTERLIST_ACTIONS } from '../actions/getMasterList'
import { GETMASTER_ACTIONS } from '../actions/getMaster'
import { DELETEKIT_ACTIONS } from '../actions/deleteKit'
import { CREATEKIT_ACTIONS } from '../actions/createKit'
import { GETKITLIST_ACTIONS } from '../actions/getKitList'
import { GETKIT_ACTIONS } from '../actions/getKit'
import { DELETEPACKAGE_ACTIONS } from '../actions/deletePackage'
import { CREATEPACKAGE_ACTIONS } from '../actions/createPackage'
import { GETPACKAGELIST_ACTIONS } from '../actions/getPackageList'
import { GETPACKAGE_ACTIONS } from '../actions/getPackage'
import { DELETEMATERIAL_ACTIONS } from '../actions/deleteMaterial'
import { CREATEMATERIAL_ACTIONS } from '../actions/createMaterial'
import { GETMATERIALLIST_ACTIONS } from '../actions/getMaterialList'
import { GETMATERIAL_ACTIONS } from '../actions/getMaterial'
import { DELETECOMMAND_ACTIONS } from '../actions/deleteCommand'
import { CREATECOMMAND_ACTIONS } from '../actions/createCommand'
import { GETCOMMANDLIST_ACTIONS } from '../actions/getCommandList'
import { GETCOMMAND_ACTIONS } from '../actions/getCommand'
import { DELETEWORKORDER_ACTIONS } from '../actions/deleteWorkOrder'
import { CREATEWORKORDER_ACTIONS } from '../actions/createWorkOrder'
import { GETWORKORDERLIST_ACTIONS } from '../actions/getWorkOrderList'
import { GETWORKORDER_ACTIONS } from '../actions/getWorkOrder'
import { GETCLIENTORDER_ACTIONS } from '../actions/getClientOrder'
import { GETCLIENTORDERTYPELIST_ACTIONS } from '../actions/getClientOrderTypeList'
import { CLIENTORDERLISTSTATE_ACTIONS } from '../actions/clientOrderListState'
import { GETRECENTCLIENTORDERLIST_ACTIONS } from '../actions/getRecentClientOrderList'
import { GETURGENTCLIENTORDERLIST_ACTIONS } from '../actions/getUrgentClientOrderList'
import { DELETECLIENTORDER_ACTIONS } from '../actions/deleteClientOrder'
import { CREATECLIENTORDER_ACTIONS } from '../actions/createClientOrder'
import { GETDETAILEDCLIENTORDERLIST_ACTIONS } from '../actions/getDetailedClientOrderList'
// import { GETPAGEDCLIENTORDERLIST_ACTIONS } from '../actions/getPagedClientOrderList'
import { GETCLIENTORDERLIST_ACTIONS } from '../actions/getClientOrderList'
// import { GETCLIENTORDERTASKLIST_ACTIONS } from '../actions/getClientOrderTaskList'
import { CLONETASKS_ACTIONS } from '../actions/cloneTasks'
import { UPDATETASK_ACTIONS } from '../actions/updateTask'
import { GETJOBTASKLIST_ACTIONS } from '../actions/getJobTaskList'
import { DELETETASK_ACTIONS } from '../actions/deleteTask'
import { CREATETASK_ACTIONS } from '../actions/createTask'
import { GETTASKLIST_ACTIONS } from '../actions/getTaskList'
import { GETTASK_ACTIONS } from '../actions/getTask'
import { FILTERS_ACTIONS } from '../actions/filters'
import { RUNREVERSEGEOCODING_ACTIONS } from '../actions/runReverseGeocoding'
import { CLEARPARENT_ACTIONS } from '../actions/clearParent'
import { DELETEARTICLEVARIANT_ACTIONS } from '../actions/deleteArticleVariant'
import { GETDATAIMPORTLIST_ACTIONS } from '../actions/getDataImportList'
import { DOASSIGNDUPLICATES_ACTIONS } from '../actions/doAssignDuplicates'
import { SETARTICLEVARIANTORDERING_ACTIONS } from '../actions/setArticleVariantOrdering'
import { DOASSIGNVARIANTS_ACTIONS } from '../actions/doAssignVariants'
import { DELETEOFFICE_ACTIONS } from '../actions/deleteOffice'
import { CREATEOFFICE_ACTIONS } from '../actions/createOffice'
import { GETOFFICELIST_ACTIONS } from '../actions/getOfficeList'
import { GETOFFICE_ACTIONS } from '../actions/getOffice'
import { GETARTICLEVARIANTIMAGELIST_ACTIONS } from '../actions/getArticleVariantImageList'
import { GETCOLLECTIONSFULL_ACTIONS } from '../actions/getCollectionsFull'
import { UPLOADMULTIPLEFILES_ACTIONS } from '../actions/uploadMultipleFiles'
import { DELETEPICTURE_ACTIONS } from '../actions/deletePicture'
import { CREATEPICTURE_ACTIONS } from '../actions/createPicture'
import { GETPICTURELIST_ACTIONS } from '../actions/getPictureList'
import { GETPICTURE_ACTIONS } from '../actions/getPicture'
import { DELETESTDARTICLE_ACTIONS } from '../actions/deleteStdArticle'
import { CREATESTDARTICLE_ACTIONS } from '../actions/createStdArticle'
import { GETSTDARTICLELIST_ACTIONS } from '../actions/getStdArticleList'
import { GETSTDARTICLE_ACTIONS } from '../actions/getStdArticle'
import { DELETEVARIANT_ACTIONS } from '../actions/deleteVariant'
import { CREATEVARIANT_ACTIONS } from '../actions/createVariant'
import { GETVARIANTLIST_ACTIONS } from '../actions/getVariantList'
import { GETVARIANT_ACTIONS } from '../actions/getVariant'
import { DELETEFAMILY_ACTIONS } from '../actions/deleteFamily'
import { CREATEFAMILY_ACTIONS } from '../actions/createFamily'
import { GETFAMILYLIST_ACTIONS } from '../actions/getFamilyList'
import { GETFAMILY_ACTIONS } from '../actions/getFamily'
import { DELETECOLLECTION_ACTIONS } from '../actions/deleteCollection'
import { CREATECOLLECTION_ACTIONS } from '../actions/createCollection'
import { GETCOLLECTION_ACTIONS } from '../actions/getCollection'
import { GETCOLLECTIONLIST_ACTIONS } from '../actions/getCollectionList'
import { GETURGENTJOBLIST_ACTIONS } from '../actions/getUrgentJobList'
import { DELETEORGANIZATION_ACTIONS } from '../actions/deleteOrganization'
import { CREATEORGANIZATION_ACTIONS } from '../actions/createOrganization'
import { GETORGANIZATIONLIST_ACTIONS } from '../actions/getOrganizationList'
import { GETORGANIZATION_ACTIONS } from '../actions/getOrganization'
import { DELETECONTRACT_ACTIONS } from '../actions/deleteContract'
import { CREATECONTRACT_ACTIONS } from '../actions/createContract'
import { GETCONTRACTLIST_ACTIONS } from '../actions/getContractList'
import { GETCONTRACT_ACTIONS } from '../actions/getContract'
import { SENDNOTIFICATION_ACTIONS } from '../actions/sendNotification'
import { GETARTICLERECAP_ACTIONS } from '../actions/getArticleRecap'
import { GETPRODUCTRECAP_ACTIONS } from '../actions/getProductRecap'
import { GETDOCUMENTRECAP_ACTIONS } from '../actions/getDocumentRecap'
import { DELETEDEPOSIT_ACTIONS } from '../actions/deleteDeposit'
import { CREATEDEPOSIT_ACTIONS } from '../actions/createDeposit'
import { GETDEPOSIT_ACTIONS } from '../actions/getDeposit'
import { GETCATEGORY_ACTIONS } from '../actions/getCategory'
import { GETCORP_ACTIONS } from '../actions/getCorp'
import { DELETECORP_ACTIONS } from '../actions/deleteCorp'
import { CREATECORP_ACTIONS } from '../actions/createCorp'
import { DELETECATEGORY_ACTIONS } from '../actions/deleteCategory'
import { CREATECATEGORY_ACTIONS } from '../actions/createCategory'
import { DELETEUSER_ACTIONS } from '../actions/deleteUser'
import { CREATEPRODUCT_ACTIONS } from '../actions/createProduct'
import { GETCORPLIST_ACTIONS } from '../actions/getCorpList'
import { GETCATEGORYLIST_ACTIONS } from '../actions/getCategoryList'
import { GETPRODUCT_ACTIONS } from '../actions/getProduct'
import { DELETEPRODUCT_ACTIONS } from '../actions/deleteProduct'
import { GETPRODUCTLIST_ACTIONS } from '../actions/getProductList'
import { GETDEPOSITLIST_ACTIONS } from '../actions/getDepositList'
import { GETDOCUMENT_ACTIONS } from '../actions/getDocument'
import { DELETEDOCUMENT_ACTIONS } from '../actions/deleteDocument'
import { CREATEDOCUMENT_ACTIONS } from '../actions/createDocument'
import { GETDOCUMENTLIST_ACTIONS } from '../actions/getDocumentList'
import { GETARTICLELIST_ACTIONS } from '../actions/getArticleList'
import { GETARTICLE_ACTIONS } from '../actions/getArticle'
import { DELETEARTICLE_ACTIONS } from '../actions/deleteArticle'
import { CREATEARTICLE_ACTIONS } from '../actions/createArticle'
import { GETTURN_ACTIONS } from '../actions/getTurn'
import { DELETETURN_ACTIONS } from '../actions/deleteTurn'
import { CREATETURN_ACTIONS } from '../actions/createTurn'
import { GETTURNLIST_ACTIONS } from '../actions/getTurnList'
import { GETROLELIST_ACTIONS } from '../actions/getRoleList'
import { GETUSER_ACTIONS } from '../actions/getUser'
import { CHANGEPASSWORD_ACTIONS } from '../actions/changePassword'
import { CREATEUSER_ACTIONS } from '../actions/createUser'
import { GETUSERLIST_ACTIONS } from '../actions/getUserList'
import { ORGANIZATION_ACTIONS } from '../actions/organization'
import { GETFROMIDSUBIDFILTERING_ACTIONS } from '../actions/getFromIdSubidFiltering'
import { GETFROMIDSUBID_ACTIONS } from '../actions/getFromIdSubid'
import { GETFROMIDFILTERING_ACTIONS } from '../actions/getFromIdFiltering'
import { GETFROMID_ACTIONS } from '../actions/getFromId'
import { RECAP_ACTIONS } from '../actions/recap'
import { GETRECENTJOBLIST_ACTIONS } from '../actions/getRecentJobList'
import { GETRECENTMACHINELIST_ACTIONS } from '../actions/getRecentMachineList'
import { DELETEWORKLIST_ACTIONS } from '../actions/deleteWorkList'
import { SAVESERVICE_ACTIONS } from '../actions/saveService'
import { DELETEBLACKBOX_ACTIONS } from '../actions/deleteBlackbox'
import { GETBLACKBOXLIST_ACTIONS } from '../actions/getBlackboxList'
import { REALTIME_ACTIONS } from '../actions/realtime'
import { SOCKET_HERCULES_ACTIONS } from '../actions/socketHercules'
import { JOBLISTSTATE_ACTIONS } from '../actions/jobListState'
import { DELETEMAPPING_ACTIONS } from '../actions/deleteMapping'
import { CREATEMAPPING_ACTIONS } from '../actions/createMapping'
import { GETMAPPINGDATA_ACTIONS } from '../actions/getMappingData'
import { GETBABYPRESSADATAFILTER_ACTIONS } from '../actions/getBabypressaDataFilter'
import { GETGT2000DATAFILTER_ACTIONS } from '../actions/getGT2000DataFilter'
import { GETLASTGT2000DATA_ACTIONS } from '../actions/getLastGT2000Data'
import { GETGT2000DATA_ACTIONS } from '../actions/getGT2000Data'
import { GETLASTSWA300DATA_ACTIONS } from '../actions/getLastSWA300Data'
import { GETLASTBABYPRESSADATA_ACTIONS } from '../actions/getLastBabypressaData'
import { GETBABYPRESSADATA_ACTIONS } from '../actions/getBabypressaData'
import { GETSWA300DATA_ACTIONS } from '../actions/getSWA300Data'
import { WRITESWA300CONFIG_ACTIONS } from '../actions/writeSWA300Config'
import { UPLOADIMAGE_ACTIONS } from '../actions/uploadImage'
import { CREATEITEM_ACTIONS } from '../actions/createItem'
import { DELETEITEM_ACTIONS } from '../actions/deleteItem'
import { GETITEMLIST_ACTIONS } from '../actions/getItemList'
import { MERGEJOB_ACTIONS } from '../actions/mergeJob'
import { GETCOST_ACTIONS } from '../actions/getCost'
import { GETWORKER_ACTIONS } from '../actions/getWorker'
import { GETMACHINE_ACTIONS } from '../actions/getMachine'
import { GETJOB_ACTIONS } from '../actions/getJob'
import { GETWORKLISTFORDATE_ACTIONS } from '../actions/getWorkListForDate'
import { GETWORKLISTFORDAE_ACTIONS } from '../actions/getWorkListForDae'
import { GETWORKLISTFORDAY_ACTIONS } from '../actions/getWorkListForDay'
import { WORKDAY_ACTIONS } from '../actions/workday'
import { ASSIGNWORK_ACTIONS } from '../actions/assignWork'
import { UPLOADFILE_ACTIONS } from '../actions/uploadFile'
import { GETIMAGELIST_ACTIONS } from '../actions/getImageList'
import { GETJOBTYPELIST_ACTIONS } from '../actions/getJobTypeList'
import { SEARCH_ACTIONS } from '../actions/search'
import { TOOLBAR_ACTIONS } from '../actions/toolbar'
import { GETDETAILEDJOBLIST_ACTIONS } from '../actions/getDetailedJobList'
import { GETPAGEDJOBLIST_ACTIONS } from '../actions/getPagedJobList'
import { GETJOBLIST_ACTIONS } from '../actions/getJobList'
import { GETWORKERLIST_ACTIONS } from '../actions/getWorkerList'
import { GETITEMCOSTS_ACTIONS } from '../actions/getItemCosts'
import { DELETEWORKER_ACTIONS } from '../actions/deleteWorker'
import { UPDATEWORKER_ACTIONS } from '../actions/updateWorker'
import { CREATEWORKER_ACTIONS } from '../actions/createWorker'
import { GETPAGEDCOSTLIST_ACTIONS } from '../actions/getPagedCostList'
import { GETCOSTLIST_ACTIONS } from '../actions/getCostList'
import { DELETECOST_ACTIONS } from '../actions/deleteCost'
import { UPDATECOST_ACTIONS } from '../actions/updateCost'
import { CREATECOST_ACTIONS } from '../actions/createCost'
import { GETMACHINETYPELIST_ACTIONS } from '../actions/getMachineTypeList'
import { DELETEMACHINETYPE_ACTIONS } from '../actions/deleteMachineType'
import { UPDATEMACHINETYPE_ACTIONS } from '../actions/updateMachineType'
import { CREATEMACHINETYPE_ACTIONS } from '../actions/createMachineType'
import { GETMACHINELIST_ACTIONS } from '../actions/getMachineList'
import { DELETEMACHINE_ACTIONS } from '../actions/deleteMachine'
import { UPDATEMACHINE_ACTIONS } from '../actions/updateMachine'
import { CREATEMACHINE_ACTIONS } from '../actions/createMachine'
import { GETWORKLISTFILTER_ACTIONS } from '../actions/getWorkListFilter'
import { GETWORKLISTSPAN_ACTIONS } from '../actions/getWorkListSpan'
import { GETWORKLIST_ACTIONS } from '../actions/getWorkList'
import { GETWORK_ACTIONS } from '../actions/getWork'
import { DELETEWORK_ACTIONS } from '../actions/deleteWork'
import { UPDATEWORK_ACTIONS } from '../actions/updateWork'
import { CREATEWORK_ACTIONS } from '../actions/createWork'
import { DELETEJOB_ACTIONS } from '../actions/deleteJob'
import { SWITCHJOB_ACTIONS } from '../actions/switchJob'
import { UPDATEJOB_ACTIONS } from '../actions/updateJob'
import { CREATEJOB_ACTIONS } from '../actions/createJob'
import { LOGINEMPLOYEE_ACTIONS } from '../actions/loginEmployee'
import { LOGIN_ACTIONS } from '../actions/login'
import { GETSESSIONDATA_ACTIONS } from '../actions/getSessionData'
import { GETSESSIONDATALIST_ACTIONS } from '../actions/getSessionDataList'
import { CREATELABEL_ACTIONS } from '../actions/createLabel'
import { DELETELABEL_ACTIONS } from '../actions/deleteLabel'
import { GETLABELLIST_ACTIONS } from '../actions/getLabelList'
import { GETLABEL_ACTIONS } from '../actions/getLabel'

import * as io from 'socket.io-client'

import moment from 'momentconfig'
import { debounce } from 'throttle-debounce'
moment.locale('it')

var requestCounter = 0

const abortExclude = [
    'create_job',
    'create_collection',
    'create_family',
    'create_article',
    'create_variant',
    'get_simple_article_list',
    'create_work_order',
    'app/document/save',
]

class NetworkProvider extends Component {
    get WEB_REQUEST_URL() {
        return process.env.REACT_APP_HERCULES
    }

    get HADES() {
        return process.env.REACT_APP_HADES
    }

    get MACHINES() {
        return process.env.REACT_APP_HADES_NEGRIBOSSI_TOKEN
    }

    get GET() {
        return 'GET'
    }

    get POST() {
        return 'POST'
    }

    static childContextTypes = {
        network: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.state = {
            activeRequests: {},
            socket: null,
            socket_hercules: null,
        }
    }

    dataFromSocket(data) {
        this.props.realtimeNewdata(data)
    }

    sendMessage = (to, messages) => {
        let { socket_hercules } = this.state
        if (!socket_hercules) return

        socket_hercules.emit('sendMessage', to, messages, (response) => {
            //console.log('response', response)
        })
    }
    connectSocket(token) {
        let socket_hercules
        let socket

        // if (process.env.REACT_APP_SOCKETIO_HERCULES) {
        //     socket_hercules = io.Manager(process.env.REACT_APP_SOCKETIO_HERCULES, {
        //         transports: ['websocket'],
        //         secure: true,
        //         rejectUnauthorized: false,
        //         query: {
        //             token,
        //         },
        //     })
        // }
        // if (process.env.REACT_APP_SOCKETIO) {
        //     socket = io.Manager(process.env.REACT_APP_SOCKETIO, {
        //         transports: ['websocket'],
        //         secure: true,
        //         rejectUnauthorized: false,
        //         query: {
        //             token,
        //         },
        //     })
        // }

        if (socket) {
            socket.on('connect', () => {
                this.props.realtimeConnect()
                //console.log('NetworkProvider socketio: connected')
            })

            socket.on('connect_error', (err) => {
                console.error('NetworkProvider socketio: Connect error on socketio connection:', err)
            })

            socket.on('error', (err) => {
                console.error('NetworkProvider socketio: Error on socketio connection:', err)
            })

            socket.on('reconnect', (err) => {
                console.error('NetworkProvider socketio: Reconnect on socketio connection:', err)
            })

            socket.on('disconnect', () => {
                this.props.realtimeDisconnect()
                //console.log('NetworkProvider socketio: disconnected')
            })

            socket.on('onRealtimeData', (data) => {
                this.dataFromSocket(data)
            })

            this.setState({ socket })
        }
    }

    socketSend = (func, who, data) => {
        this.state.socket.emit(func, who, data)
    }

    socketReq = (func, what, response) => {
        this.state.socket.emit(func, what, response)
    }

    disconnectSocket() {
        if (!this.state.socket) return

        this.state.socket.disconnect()
        this.setState({ socket: null })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { login, loginEmployee } = this.props

        if (!prevProps.login.authenticated && login.authenticated) {
            this.connectSocket(login.data.user.token)
        } else if (!prevProps.loginEmployee.authenticated && loginEmployee.authenticated) {
            this.connectSocket(loginEmployee.data.user.token)
        } else if (
            (prevProps.login.authenticated && !login.authenticated) ||
            (prevProps.loginEmployee.authenticated && !loginEmployee.authenticated)
        ) {
            this.disconnectSocket()
        }
    }

    componentDidMount() {
        let cookieuser = this.props.cookies.get('user')
        if (cookieuser) {
            this.props.loginResult({ user: cookieuser })
        }
    }

    setCookie = (name, value) => this.props.cookies.set(name, value, { path: '/' })
    getCookie = (name) => this.props.cookies.get(name)
    removeCookie = (name) => this.props.cookies.remove(name, { path: '/' })

    /**
     * doAction
     * service: URI
     * bundle: {
     *      data: {... action: one of the exported constants},
     *      headers: [],
     *      *** REDUX functions ***
     *      request: ()=>{},
     *      receive: ()=>{},
     *      failure: ()=>{},
     *      ***********************
     *      method: 'get'|'post'
     *  }
     */
    doAction = (service, bundle) => {
        let { activeRequests } = this.state
        let currentAction = bundle.data.ACTION ? bundle.data.ACTION : service

        //console.log('doAction', currentAction, 'actives:', activeRequests)

        if (activeRequests[currentAction] && !bundle.doNotAbort) {
            let ignore = false
            for (const excluded of abortExclude) {
                if (excluded === currentAction) {
                    ignore = true
                }
            }
            if (!ignore) {
                activeRequests[currentAction].abort()
                delete activeRequests[currentAction]
            }
        }

        let currentRequestController = new AbortController()
        let signal = currentRequestController.signal
        signal.addEventListener('abort', () => {})
        activeRequests[currentAction] = currentRequestController

        this.setState({
            activeRequests,
        })

        if (bundle.request) {
            bundle.request(bundle.data)
        }

        if (!bundle.loginType) {
            bundle.loginType = 'admin'
        }

        let tokenobj = {}
        let { login, loginEmployee } = this.props
        let loginobj = login

        switch (bundle.loginType) {
            case 'admin':
                loginobj = login
                break
            case 'employee':
                loginobj = loginEmployee
                break
            default:
                break
        }

        if (bundle.token) {
            tokenobj['Authorization'] = `Bearer ${bundle.token}`
        } else if (!bundle.noAuth && loginobj.data.user && loginobj.data.user.token) {
            tokenobj['Authorization'] = `Bearer ${loginobj.data.user.token}`
        }

        //console.log('Action', bundle, 'Adding organization id', this.props.organization.data)
        bundle.data.organizationIndexes = this.props.organization.data ? this.props.organization.data : [0]

        let website = service
        let arrays = []
        if (bundle.method === 'GET') {
            website = new URL(service)
            Object.keys(bundle.data).forEach((key) => {
                if (Array.isArray(bundle.data[key])) {
                    arrays.push({ key, array: bundle.data[key] })
                } else {
                    website.searchParams.append(key, bundle.data[key])
                }
            })

            let arrayreq = ''
            for (const array of arrays) {
                for (const element of array.array) {
                    // website.searchParams.append(`${element.key}[]`, JSON.stringify(element.array))
                    if (typeof element === 'string') {
                        arrayreq += `&${array.key}[]=${element}`
                    } else {
                        arrayreq += `&${array.key}[]=${JSON.stringify(element)}`
                    }
                }
            }

            const newurl = arrayreq ? website.href + arrayreq : website.href
            website = newurl
        }

        let body = {}
        if (bundle.noStringify) {
            body = bundle.data
        } else {
            body = JSON.stringify(bundle.data)
        }

        let formData = body
        // if (formData.entries) {
        //     for (var key of formData.entries()) {
        //         console.dir(key[0])
        //         console.dir(key[1])
        //     }
        // }

        let headers = {
            ...bundle.headers,
            ...tokenobj,
        }

        if (bundle.contenttype !== 'multipart/form-data') {
            headers['Content-Type'] = bundle.contenttype ? bundle.contenttype : 'application/json'
        }

        headers['cache-control'] = 'no-cache'

        let reqObj = {
            signal: currentRequestController.signal,
            method: bundle.method ? bundle.method : 'POST',
            headers,
            body: bundle.method === 'POST' ? body : undefined,
        }

        fetch(website, reqObj)
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response
                } else {
                    var error = new Error(response.statusText)
                    error.response = response
                    if (bundle.failure) {
                        bundle.failure(error)
                    }
                    throw error
                }
            })
            .then((response) => {
                let json = response.json()
                return json
            })
            .then((json) => {
                if (bundle.receive) {
                    //console.log('Request SUCCESS:', bundle)
                    bundle.receive(json)
                }
            })
            .catch((ex) => {
                if (ex.name === 'AbortError') {
                    console.warn('Request ABORTED:', bundle)
                    return
                }

                if (bundle.failure) {
                    console.error('Request FAILED:', ex, bundle)
                    bundle.failure(ex)
                }
            })
    }

    createBundle = (action, data, method, request, receive, failure, isEmployee, token) => {
        let loginType = isEmployee ? 'employee' : 'admin'
        requestCounter += 1

        return {
            data: {
                ACTION: action,
                request_count: requestCounter,
                ...data,
            },
            method,
            request,
            receive,
            failure,
            loginType,
            token,
        }
    }

    doRequest({ action, data, method, isEmployee, service, token, allowAbort }) {
        return new Promise((resolve, reject) => {
            const bundle = this.createBundle(
                action,
                data,
                method,
                () => {},
                (result) => {
                    resolve(result)
                },
                (error) => {
                    reject(error)
                },
                !!isEmployee,
                token
            )

            bundle.doNotAbort = !allowAbort

            this.doAction(`${service ? service : this.WEB_REQUEST_URL}${action}`, bundle)
        })
    }

    getChildContext() {
        const {
            getCookie,
            setCookie,
            removeCookie,
            socketSend,
            socketReq,
            sendMessage,
            createBundle,
            doAction,
            WEB_REQUEST_URL,
            HADES,
            GET,
            POST,
            doRequest,
            MACHINES,
        } = this

        let obj = {}
        for (let [key, value] of Object.entries(requests)) {
            obj[key] = (...args) => value.bind(this)(...args)
            obj[`${key}Debounced`] = debounce(1000, (...args) => value.bind(this)(...args))
        }

        return {
            network: {
                ...obj,
                getUrl: () => this.WEB_REQUEST_URL,
                socketSend,
                socketReq,
                getCookie,
                setCookie,
                removeCookie,
                sendMessage,
                createBundle,
                doAction,
                WEB_REQUEST_URL,
                HADES,
                GET,
                POST,
                doRequest,
                MACHINES,
            },
        }
    }

    render() {
        return Children.only(this.props.children)
    }
}

const mapStateToProps = (state) => {
    let { login, loginEmployee, organization } = state

    return {
        login,
        loginEmployee,
        organization,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        /* @dispatch */
		getStockFilteredRequest: result => GETSTOCKFILTERED_ACTIONS.request(result, dispatch), 
		getStockFilteredResult: result => GETSTOCKFILTERED_ACTIONS.result(result, dispatch), 
		getStockFilteredError: result => GETSTOCKFILTERED_ACTIONS.error(result, dispatch), 

        addSessionDataRequest: (result) => ADDSESSIONDATA_ACTIONS.request(result, dispatch),
        addSessionDataResult: (result) => ADDSESSIONDATA_ACTIONS.result(result, dispatch),
        addSessionDataError: (result) => ADDSESSIONDATA_ACTIONS.error(result, dispatch),

        getWorkOrderListSpanRequest: (result) => GETWORKORDERLISTSPAN_ACTIONS.request(result, dispatch),
        getWorkOrderListSpanResult: (result) => GETWORKORDERLISTSPAN_ACTIONS.result(result, dispatch),
        getWorkOrderListSpanError: (result) => GETWORKORDERLISTSPAN_ACTIONS.error(result, dispatch),

        deleteRecipeRequest: (result) => DELETERECIPE_ACTIONS.request(result, dispatch),
        deleteRecipeResult: (result) => DELETERECIPE_ACTIONS.result(result, dispatch),
        deleteRecipeError: (result) => DELETERECIPE_ACTIONS.error(result, dispatch),

        createRecipeRequest: (result) => CREATERECIPE_ACTIONS.request(result, dispatch),
        createRecipeResult: (result) => CREATERECIPE_ACTIONS.result(result, dispatch),
        createRecipeError: (result) => CREATERECIPE_ACTIONS.error(result, dispatch),

        getRecipeListRequest: (result) => GETRECIPELIST_ACTIONS.request(result, dispatch),
        getRecipeListResult: (result) => GETRECIPELIST_ACTIONS.result(result, dispatch),
        getRecipeListError: (result) => GETRECIPELIST_ACTIONS.error(result, dispatch),

        getRecipeRequest: (result) => GETRECIPE_ACTIONS.request(result, dispatch),
        getRecipeResult: (result) => GETRECIPE_ACTIONS.result(result, dispatch),
        getRecipeError: (result) => GETRECIPE_ACTIONS.error(result, dispatch),

        getListingForWorkerRequest: (result) => GETLISTINGFORWORKER_ACTIONS.request(result, dispatch),
        getListingForWorkerResult: (result) => GETLISTINGFORWORKER_ACTIONS.result(result, dispatch),
        getListingForWorkerError: (result) => GETLISTINGFORWORKER_ACTIONS.error(result, dispatch),

        deleteListingRequest: (result) => DELETELISTING_ACTIONS.request(result, dispatch),
        deleteListingResult: (result) => DELETELISTING_ACTIONS.result(result, dispatch),
        deleteListingError: (result) => DELETELISTING_ACTIONS.error(result, dispatch),

        createListingRequest: (result) => CREATELISTING_ACTIONS.request(result, dispatch),
        createListingResult: (result) => CREATELISTING_ACTIONS.result(result, dispatch),
        createListingError: (result) => CREATELISTING_ACTIONS.error(result, dispatch),

        getListingListRequest: (result) => GETLISTINGLIST_ACTIONS.request(result, dispatch),
        getListingListResult: (result) => GETLISTINGLIST_ACTIONS.result(result, dispatch),
        getListingListError: (result) => GETLISTINGLIST_ACTIONS.error(result, dispatch),

        getListingRequest: (result) => GETLISTING_ACTIONS.request(result, dispatch),
        getListingResult: (result) => GETLISTING_ACTIONS.result(result, dispatch),
        getListingError: (result) => GETLISTING_ACTIONS.error(result, dispatch),

        getSessionDataRequest: (result) => GETSESSIONDATA_ACTIONS.request(result, dispatch),
        getSessionDataResult: (result) => GETSESSIONDATA_ACTIONS.result(result, dispatch),
        getSessionDataError: (result) => GETSESSIONDATA_ACTIONS.error(result, dispatch),

        getSessionDataListRequest: (result) => GETSESSIONDATALIST_ACTIONS.request(result, dispatch),
        getSessionDataListResult: (result) => GETSESSIONDATALIST_ACTIONS.result(result, dispatch),
        getSessionDataListError: (result) => GETSESSIONDATALIST_ACTIONS.error(result, dispatch),

        deleteLabelRequest: (result) => DELETELABEL_ACTIONS.request(result, dispatch),
        deleteLabelResult: (result) => DELETELABEL_ACTIONS.result(result, dispatch),
        deleteLabelError: (result) => DELETELABEL_ACTIONS.error(result, dispatch),

        createLabelRequest: (result) => CREATELABEL_ACTIONS.request(result, dispatch),
        createLabelResult: (result) => CREATELABEL_ACTIONS.result(result, dispatch),
        createLabelError: (result) => CREATELABEL_ACTIONS.error(result, dispatch),

        getLabelListRequest: (result) => GETLABELLIST_ACTIONS.request(result, dispatch),
        getLabelListResult: (result) => GETLABELLIST_ACTIONS.result(result, dispatch),
        getLabelListError: (result) => GETLABELLIST_ACTIONS.error(result, dispatch),

        getLabelRequest: (result) => GETLABEL_ACTIONS.request(result, dispatch),
        getLabelResult: (result) => GETLABEL_ACTIONS.result(result, dispatch),
        getLabelError: (result) => GETLABEL_ACTIONS.error(result, dispatch),

        deleteProductionRequest: (result) => DELETEPRODUCTION_ACTIONS.request(result, dispatch),
        deleteProductionResult: (result) => DELETEPRODUCTION_ACTIONS.result(result, dispatch),
        deleteProductionError: (result) => DELETEPRODUCTION_ACTIONS.error(result, dispatch),

        createProductionRequest: (result) => CREATEPRODUCTION_ACTIONS.request(result, dispatch),
        createProductionResult: (result) => CREATEPRODUCTION_ACTIONS.result(result, dispatch),
        createProductionError: (result) => CREATEPRODUCTION_ACTIONS.error(result, dispatch),

        getProductionListRequest: (result) => GETPRODUCTIONLIST_ACTIONS.request(result, dispatch),
        getProductionListResult: (result) => GETPRODUCTIONLIST_ACTIONS.result(result, dispatch),
        getProductionListError: (result) => GETPRODUCTIONLIST_ACTIONS.error(result, dispatch),

        getProductionRequest: (result) => GETPRODUCTION_ACTIONS.request(result, dispatch),
        getProductionResult: (result) => GETPRODUCTION_ACTIONS.result(result, dispatch),
        getProductionError: (result) => GETPRODUCTION_ACTIONS.error(result, dispatch),

        deleteLotRequest: (result) => DELETELOT_ACTIONS.request(result, dispatch),
        deleteLotResult: (result) => DELETELOT_ACTIONS.result(result, dispatch),
        deleteLotError: (result) => DELETELOT_ACTIONS.error(result, dispatch),

        createLotRequest: (result) => CREATELOT_ACTIONS.request(result, dispatch),
        createLotResult: (result) => CREATELOT_ACTIONS.result(result, dispatch),
        createLotError: (result) => CREATELOT_ACTIONS.error(result, dispatch),

        getLotListRequest: (result) => GETLOTLIST_ACTIONS.request(result, dispatch),
        getLotListResult: (result) => GETLOTLIST_ACTIONS.result(result, dispatch),
        getLotListError: (result) => GETLOTLIST_ACTIONS.error(result, dispatch),

        getLotRequest: (result) => GETLOT_ACTIONS.request(result, dispatch),
        getLotResult: (result) => GETLOT_ACTIONS.result(result, dispatch),
        getLotError: (result) => GETLOT_ACTIONS.error(result, dispatch),

        getSimpleArticleListRequest: (result) => GETSIMPLEARTICLELIST_ACTIONS.request(result, dispatch),
        getSimpleArticleListResult: (result) => GETSIMPLEARTICLELIST_ACTIONS.result(result, dispatch),
        getSimpleArticleListError: (result) => GETSIMPLEARTICLELIST_ACTIONS.error(result, dispatch),

        mergeMoldRequest: (result) => MERGEMOLD_ACTIONS.request(result, dispatch),
        mergeMoldResult: (result) => MERGEMOLD_ACTIONS.result(result, dispatch),
        mergeMoldError: (result) => MERGEMOLD_ACTIONS.error(result, dispatch),

        deleteMoldRequest: (result) => DELETEMOLD_ACTIONS.request(result, dispatch),
        deleteMoldResult: (result) => DELETEMOLD_ACTIONS.result(result, dispatch),
        deleteMoldError: (result) => DELETEMOLD_ACTIONS.error(result, dispatch),

        createMoldRequest: (result) => CREATEMOLD_ACTIONS.request(result, dispatch),
        createMoldResult: (result) => CREATEMOLD_ACTIONS.result(result, dispatch),
        createMoldError: (result) => CREATEMOLD_ACTIONS.error(result, dispatch),

        getMoldListRequest: (result) => GETMOLDLIST_ACTIONS.request(result, dispatch),
        getMoldListResult: (result) => GETMOLDLIST_ACTIONS.result(result, dispatch),
        getMoldListError: (result) => GETMOLDLIST_ACTIONS.error(result, dispatch),

        getMoldRequest: (result) => GETMOLD_ACTIONS.request(result, dispatch),
        getMoldResult: (result) => GETMOLD_ACTIONS.result(result, dispatch),
        getMoldError: (result) => GETMOLD_ACTIONS.error(result, dispatch),

        mergeKitRequest: (result) => MERGEKIT_ACTIONS.request(result, dispatch),
        mergeKitResult: (result) => MERGEKIT_ACTIONS.result(result, dispatch),
        mergeKitError: (result) => MERGEKIT_ACTIONS.error(result, dispatch),

        mergePackagingRequest: (result) => MERGEPACKAGING_ACTIONS.request(result, dispatch),
        mergePackagingResult: (result) => MERGEPACKAGING_ACTIONS.result(result, dispatch),
        mergePackagingError: (result) => MERGEPACKAGING_ACTIONS.error(result, dispatch),

        mergeMaterialRequest: (result) => MERGEMATERIAL_ACTIONS.request(result, dispatch),
        mergeMaterialResult: (result) => MERGEMATERIAL_ACTIONS.result(result, dispatch),
        mergeMaterialError: (result) => MERGEMATERIAL_ACTIONS.error(result, dispatch),

        mergeMasterRequest: (result) => MERGEMASTER_ACTIONS.request(result, dispatch),
        mergeMasterResult: (result) => MERGEMASTER_ACTIONS.result(result, dispatch),
        mergeMasterError: (result) => MERGEMASTER_ACTIONS.error(result, dispatch),

        deleteCustomerRequest: (result) => DELETECUSTOMER_ACTIONS.request(result, dispatch),
        deleteCustomerResult: (result) => DELETECUSTOMER_ACTIONS.result(result, dispatch),
        deleteCustomerError: (result) => DELETECUSTOMER_ACTIONS.error(result, dispatch),

        createCustomerRequest: (result) => CREATECUSTOMER_ACTIONS.request(result, dispatch),
        createCustomerResult: (result) => CREATECUSTOMER_ACTIONS.result(result, dispatch),
        createCustomerError: (result) => CREATECUSTOMER_ACTIONS.error(result, dispatch),

        getCustomerListRequest: (result) => GETCUSTOMERLIST_ACTIONS.request(result, dispatch),
        getCustomerListResult: (result) => GETCUSTOMERLIST_ACTIONS.result(result, dispatch),
        getCustomerListError: (result) => GETCUSTOMERLIST_ACTIONS.error(result, dispatch),

        getCustomerRequest: (result) => GETCUSTOMER_ACTIONS.request(result, dispatch),
        getCustomerResult: (result) => GETCUSTOMER_ACTIONS.result(result, dispatch),
        getCustomerError: (result) => GETCUSTOMER_ACTIONS.error(result, dispatch),

        deleteBadgeRequest: (result) => DELETEBADGE_ACTIONS.request(result, dispatch),
        deleteBadgeResult: (result) => DELETEBADGE_ACTIONS.result(result, dispatch),
        deleteBadgeError: (result) => DELETEBADGE_ACTIONS.error(result, dispatch),

        createBadgeRequest: (result) => CREATEBADGE_ACTIONS.request(result, dispatch),
        createBadgeResult: (result) => CREATEBADGE_ACTIONS.result(result, dispatch),
        createBadgeError: (result) => CREATEBADGE_ACTIONS.error(result, dispatch),

        getBadgeListRequest: (result) => GETBADGELIST_ACTIONS.request(result, dispatch),
        getBadgeListResult: (result) => GETBADGELIST_ACTIONS.result(result, dispatch),
        getBadgeListError: (result) => GETBADGELIST_ACTIONS.error(result, dispatch),

        getBadgeRequest: (result) => GETBADGE_ACTIONS.request(result, dispatch),
        getBadgeResult: (result) => GETBADGE_ACTIONS.result(result, dispatch),
        getBadgeError: (result) => GETBADGE_ACTIONS.error(result, dispatch),

        deleteBadgeScanRequest: (result) => DELETEBADGESCAN_ACTIONS.request(result, dispatch),
        deleteBadgeScanResult: (result) => DELETEBADGESCAN_ACTIONS.result(result, dispatch),
        deleteBadgeScanError: (result) => DELETEBADGESCAN_ACTIONS.error(result, dispatch),

        createBadgeScanRequest: (result) => CREATEBADGESCAN_ACTIONS.request(result, dispatch),
        createBadgeScanResult: (result) => CREATEBADGESCAN_ACTIONS.result(result, dispatch),
        createBadgeScanError: (result) => CREATEBADGESCAN_ACTIONS.error(result, dispatch),

        getBadgeScanListRequest: (result) => GETBADGESCANLIST_ACTIONS.request(result, dispatch),
        getBadgeScanListResult: (result) => GETBADGESCANLIST_ACTIONS.result(result, dispatch),
        getBadgeScanListError: (result) => GETBADGESCANLIST_ACTIONS.error(result, dispatch),

        getBadgeScanRequest: (result) => GETBADGESCAN_ACTIONS.request(result, dispatch),
        getBadgeScanResult: (result) => GETBADGESCAN_ACTIONS.result(result, dispatch),
        getBadgeScanError: (result) => GETBADGESCAN_ACTIONS.error(result, dispatch),

        deleteTimeCalendarRequest: (result) => DELETETIMECALENDAR_ACTIONS.request(result, dispatch),
        deleteTimeCalendarResult: (result) => DELETETIMECALENDAR_ACTIONS.result(result, dispatch),
        deleteTimeCalendarError: (result) => DELETETIMECALENDAR_ACTIONS.error(result, dispatch),

        createTimeCalendarRequest: (result) => CREATETIMECALENDAR_ACTIONS.request(result, dispatch),
        createTimeCalendarResult: (result) => CREATETIMECALENDAR_ACTIONS.result(result, dispatch),
        createTimeCalendarError: (result) => CREATETIMECALENDAR_ACTIONS.error(result, dispatch),

        getTimeCalendarListRequest: (result) => GETTIMECALENDARLIST_ACTIONS.request(result, dispatch),
        getTimeCalendarListResult: (result) => GETTIMECALENDARLIST_ACTIONS.result(result, dispatch),
        getTimeCalendarListError: (result) => GETTIMECALENDARLIST_ACTIONS.error(result, dispatch),

        getTimeCalendarRequest: (result) => GETTIMECALENDAR_ACTIONS.request(result, dispatch),
        getTimeCalendarResult: (result) => GETTIMECALENDAR_ACTIONS.result(result, dispatch),
        getTimeCalendarError: (result) => GETTIMECALENDAR_ACTIONS.error(result, dispatch),

        deleteScheduleRequest: (result) => DELETESCHEDULE_ACTIONS.request(result, dispatch),
        deleteScheduleResult: (result) => DELETESCHEDULE_ACTIONS.result(result, dispatch),
        deleteScheduleError: (result) => DELETESCHEDULE_ACTIONS.error(result, dispatch),

        createScheduleRequest: (result) => CREATESCHEDULE_ACTIONS.request(result, dispatch),
        createScheduleResult: (result) => CREATESCHEDULE_ACTIONS.result(result, dispatch),
        createScheduleError: (result) => CREATESCHEDULE_ACTIONS.error(result, dispatch),

        getScheduleListRequest: (result) => GETSCHEDULELIST_ACTIONS.request(result, dispatch),
        getScheduleListResult: (result) => GETSCHEDULELIST_ACTIONS.result(result, dispatch),
        getScheduleListError: (result) => GETSCHEDULELIST_ACTIONS.error(result, dispatch),

        getScheduleRequest: (result) => GETSCHEDULE_ACTIONS.request(result, dispatch),
        getScheduleResult: (result) => GETSCHEDULE_ACTIONS.result(result, dispatch),
        getScheduleError: (result) => GETSCHEDULE_ACTIONS.error(result, dispatch),

        deleteIntervalRequest: (result) => DELETEINTERVAL_ACTIONS.request(result, dispatch),
        deleteIntervalResult: (result) => DELETEINTERVAL_ACTIONS.result(result, dispatch),
        deleteIntervalError: (result) => DELETEINTERVAL_ACTIONS.error(result, dispatch),

        createIntervalRequest: (result) => CREATEINTERVAL_ACTIONS.request(result, dispatch),
        createIntervalResult: (result) => CREATEINTERVAL_ACTIONS.result(result, dispatch),
        createIntervalError: (result) => CREATEINTERVAL_ACTIONS.error(result, dispatch),

        getIntervalListRequest: (result) => GETINTERVALLIST_ACTIONS.request(result, dispatch),
        getIntervalListResult: (result) => GETINTERVALLIST_ACTIONS.result(result, dispatch),
        getIntervalListError: (result) => GETINTERVALLIST_ACTIONS.error(result, dispatch),

        getIntervalRequest: (result) => GETINTERVAL_ACTIONS.request(result, dispatch),
        getIntervalResult: (result) => GETINTERVAL_ACTIONS.result(result, dispatch),
        getIntervalError: (result) => GETINTERVAL_ACTIONS.error(result, dispatch),

        createArticleImportRequest: (result) => CREATEARTICLEIMPORT_ACTIONS.request(result, dispatch),
        createArticleImportResult: (result) => CREATEARTICLEIMPORT_ACTIONS.result(result, dispatch),
        createArticleImportError: (result) => CREATEARTICLEIMPORT_ACTIONS.error(result, dispatch),

        deleteMasterRequest: (result) => DELETEMASTER_ACTIONS.request(result, dispatch),
        deleteMasterResult: (result) => DELETEMASTER_ACTIONS.result(result, dispatch),
        deleteMasterError: (result) => DELETEMASTER_ACTIONS.error(result, dispatch),

        createMasterRequest: (result) => CREATEMASTER_ACTIONS.request(result, dispatch),
        createMasterResult: (result) => CREATEMASTER_ACTIONS.result(result, dispatch),
        createMasterError: (result) => CREATEMASTER_ACTIONS.error(result, dispatch),

        getMasterListRequest: (result) => GETMASTERLIST_ACTIONS.request(result, dispatch),
        getMasterListResult: (result) => GETMASTERLIST_ACTIONS.result(result, dispatch),
        getMasterListError: (result) => GETMASTERLIST_ACTIONS.error(result, dispatch),

        getMasterRequest: (result) => GETMASTER_ACTIONS.request(result, dispatch),
        getMasterResult: (result) => GETMASTER_ACTIONS.result(result, dispatch),
        getMasterError: (result) => GETMASTER_ACTIONS.error(result, dispatch),

        deleteKitRequest: (result) => DELETEKIT_ACTIONS.request(result, dispatch),
        deleteKitResult: (result) => DELETEKIT_ACTIONS.result(result, dispatch),
        deleteKitError: (result) => DELETEKIT_ACTIONS.error(result, dispatch),

        createKitRequest: (result) => CREATEKIT_ACTIONS.request(result, dispatch),
        createKitResult: (result) => CREATEKIT_ACTIONS.result(result, dispatch),
        createKitError: (result) => CREATEKIT_ACTIONS.error(result, dispatch),

        getKitListRequest: (result) => GETKITLIST_ACTIONS.request(result, dispatch),
        getKitListResult: (result) => GETKITLIST_ACTIONS.result(result, dispatch),
        getKitListError: (result) => GETKITLIST_ACTIONS.error(result, dispatch),

        getKitRequest: (result) => GETKIT_ACTIONS.request(result, dispatch),
        getKitResult: (result) => GETKIT_ACTIONS.result(result, dispatch),
        getKitError: (result) => GETKIT_ACTIONS.error(result, dispatch),

        deletePackageRequest: (result) => DELETEPACKAGE_ACTIONS.request(result, dispatch),
        deletePackageResult: (result) => DELETEPACKAGE_ACTIONS.result(result, dispatch),
        deletePackageError: (result) => DELETEPACKAGE_ACTIONS.error(result, dispatch),

        createPackageRequest: (result) => CREATEPACKAGE_ACTIONS.request(result, dispatch),
        createPackageResult: (result) => CREATEPACKAGE_ACTIONS.result(result, dispatch),
        createPackageError: (result) => CREATEPACKAGE_ACTIONS.error(result, dispatch),

        getPackageListRequest: (result) => GETPACKAGELIST_ACTIONS.request(result, dispatch),
        getPackageListResult: (result) => GETPACKAGELIST_ACTIONS.result(result, dispatch),
        getPackageListError: (result) => GETPACKAGELIST_ACTIONS.error(result, dispatch),

        getPackageRequest: (result) => GETPACKAGE_ACTIONS.request(result, dispatch),
        getPackageResult: (result) => GETPACKAGE_ACTIONS.result(result, dispatch),
        getPackageError: (result) => GETPACKAGE_ACTIONS.error(result, dispatch),

        deleteMaterialRequest: (result) => DELETEMATERIAL_ACTIONS.request(result, dispatch),
        deleteMaterialResult: (result) => DELETEMATERIAL_ACTIONS.result(result, dispatch),
        deleteMaterialError: (result) => DELETEMATERIAL_ACTIONS.error(result, dispatch),

        createMaterialRequest: (result) => CREATEMATERIAL_ACTIONS.request(result, dispatch),
        createMaterialResult: (result) => CREATEMATERIAL_ACTIONS.result(result, dispatch),
        createMaterialError: (result) => CREATEMATERIAL_ACTIONS.error(result, dispatch),

        getMaterialListRequest: (result) => GETMATERIALLIST_ACTIONS.request(result, dispatch),
        getMaterialListResult: (result) => GETMATERIALLIST_ACTIONS.result(result, dispatch),
        getMaterialListError: (result) => GETMATERIALLIST_ACTIONS.error(result, dispatch),

        getMaterialRequest: (result) => GETMATERIAL_ACTIONS.request(result, dispatch),
        getMaterialResult: (result) => GETMATERIAL_ACTIONS.result(result, dispatch),
        getMaterialError: (result) => GETMATERIAL_ACTIONS.error(result, dispatch),

        deleteCommandRequest: (result) => DELETECOMMAND_ACTIONS.request(result, dispatch),
        deleteCommandResult: (result) => DELETECOMMAND_ACTIONS.result(result, dispatch),
        deleteCommandError: (result) => DELETECOMMAND_ACTIONS.error(result, dispatch),

        createCommandRequest: (result) => CREATECOMMAND_ACTIONS.request(result, dispatch),
        createCommandResult: (result) => CREATECOMMAND_ACTIONS.result(result, dispatch),
        createCommandError: (result) => CREATECOMMAND_ACTIONS.error(result, dispatch),

        getCommandListRequest: (result) => GETCOMMANDLIST_ACTIONS.request(result, dispatch),
        getCommandListResult: (result) => GETCOMMANDLIST_ACTIONS.result(result, dispatch),
        getCommandListError: (result) => GETCOMMANDLIST_ACTIONS.error(result, dispatch),

        getCommandRequest: (result) => GETCOMMAND_ACTIONS.request(result, dispatch),
        getCommandResult: (result) => GETCOMMAND_ACTIONS.result(result, dispatch),
        getCommandError: (result) => GETCOMMAND_ACTIONS.error(result, dispatch),

        deleteWorkOrderRequest: (result) => DELETEWORKORDER_ACTIONS.request(result, dispatch),
        deleteWorkOrderResult: (result) => DELETEWORKORDER_ACTIONS.result(result, dispatch),
        deleteWorkOrderError: (result) => DELETEWORKORDER_ACTIONS.error(result, dispatch),

        createWorkOrderRequest: (result) => CREATEWORKORDER_ACTIONS.request(result, dispatch),
        createWorkOrderResult: (result) => CREATEWORKORDER_ACTIONS.result(result, dispatch),
        createWorkOrderError: (result) => CREATEWORKORDER_ACTIONS.error(result, dispatch),

        getWorkOrderListRequest: (result) => GETWORKORDERLIST_ACTIONS.request(result, dispatch),
        getWorkOrderListResult: (result) => GETWORKORDERLIST_ACTIONS.result(result, dispatch),
        getWorkOrderListError: (result) => GETWORKORDERLIST_ACTIONS.error(result, dispatch),

        getWorkOrderRequest: (result) => GETWORKORDER_ACTIONS.request(result, dispatch),
        getWorkOrderResult: (result) => GETWORKORDER_ACTIONS.result(result, dispatch),
        getWorkOrderError: (result) => GETWORKORDER_ACTIONS.error(result, dispatch),

        clientOrderListStateRequest: (result) => CLIENTORDERLISTSTATE_ACTIONS.request(result, dispatch),
        clientOrderListStateResult: (result) => CLIENTORDERLISTSTATE_ACTIONS.result(result, dispatch),
        clientOrderListStateError: (result) => CLIENTORDERLISTSTATE_ACTIONS.error(result, dispatch),

        deleteClientOrderRequest: (result) => DELETECLIENTORDER_ACTIONS.request(result, dispatch),
        deleteClientOrderResult: (result) => DELETECLIENTORDER_ACTIONS.result(result, dispatch),
        deleteClientOrderError: (result) => DELETECLIENTORDER_ACTIONS.error(result, dispatch),

        createClientOrderRequest: (result) => CREATECLIENTORDER_ACTIONS.request(result, dispatch),
        createClientOrderResult: (result) => CREATECLIENTORDER_ACTIONS.result(result, dispatch),
        createClientOrderError: (result) => CREATECLIENTORDER_ACTIONS.error(result, dispatch),

        getClientOrderRequest: (result) => GETCLIENTORDER_ACTIONS.request(result, dispatch),
        getClientOrderResult: (result) => GETCLIENTORDER_ACTIONS.result(result, dispatch),
        getClientOrderError: (result) => GETCLIENTORDER_ACTIONS.error(result, dispatch),

        // getPagedClientOrderListRequest: (result) => GETPAGEDCLIENTORDERLIST_ACTIONS.request(result, dispatch),
        // getPagedClientOrderListResult: (result) => GETPAGEDCLIENTORDERLIST_ACTIONS.result(result, dispatch),
        // getPagedClientOrderListError: (result) => GETPAGEDCLIENTORDERLIST_ACTIONS.error(result, dispatch),

        getClientOrderListRequest: (result) => GETCLIENTORDERLIST_ACTIONS.request(result, dispatch),
        getClientOrderListResult: (result) => GETCLIENTORDERLIST_ACTIONS.result(result, dispatch),
        getClientOrderListError: (result) => GETCLIENTORDERLIST_ACTIONS.error(result, dispatch),

        getDetailedClientOrderListRequest: (result) => GETDETAILEDCLIENTORDERLIST_ACTIONS.request(result, dispatch),
        getDetailedClientOrderListResult: (result) => GETDETAILEDCLIENTORDERLIST_ACTIONS.result(result, dispatch),
        getDetailedClientOrderListError: (result) => GETDETAILEDCLIENTORDERLIST_ACTIONS.error(result, dispatch),

        getClientOrderTypeListRequest: (result) => GETCLIENTORDERTYPELIST_ACTIONS.request(result, dispatch),
        getClientOrderTypeListResult: (result) => GETCLIENTORDERTYPELIST_ACTIONS.result(result, dispatch),
        getClientOrderTypeListError: (result) => GETCLIENTORDERTYPELIST_ACTIONS.error(result, dispatch),

        getRecentClientOrderListRequest: (result) => GETRECENTCLIENTORDERLIST_ACTIONS.request(result, dispatch),
        getRecentClientOrderListResult: (result) => GETRECENTCLIENTORDERLIST_ACTIONS.result(result, dispatch),
        getRecentClientOrderListError: (result) => GETRECENTCLIENTORDERLIST_ACTIONS.error(result, dispatch),

        // getClientOrderTaskListRequest: (result) => GETCLIENTORDERTASKLIST_ACTIONS.request(result, dispatch),
        // getClientOrderTaskListResult: (result) => GETCLIENTORDERTASKLIST_ACTIONS.result(result, dispatch),
        // getClientOrderTaskListError: (result) => GETCLIENTORDERTASKLIST_ACTIONS.error(result, dispatch),

        getUrgentClientOrderListRequest: (result) => GETURGENTCLIENTORDERLIST_ACTIONS.request(result, dispatch),
        getUrgentClientOrderListResult: (result) => GETURGENTCLIENTORDERLIST_ACTIONS.result(result, dispatch),
        getUrgentClientOrderListError: (result) => GETURGENTCLIENTORDERLIST_ACTIONS.error(result, dispatch),

        cloneTasksRequest: (result) => CLONETASKS_ACTIONS.request(result, dispatch),
        cloneTasksResult: (result) => CLONETASKS_ACTIONS.result(result, dispatch),
        cloneTasksError: (result) => CLONETASKS_ACTIONS.error(result, dispatch),

        updateTaskRequest: (result) => UPDATETASK_ACTIONS.request(result, dispatch),
        updateTaskResult: (result) => UPDATETASK_ACTIONS.result(result, dispatch),
        updateTaskError: (result) => UPDATETASK_ACTIONS.error(result, dispatch),

        getJobTaskListRequest: (result) => GETJOBTASKLIST_ACTIONS.request(result, dispatch),
        getJobTaskListResult: (result) => GETJOBTASKLIST_ACTIONS.result(result, dispatch),
        getJobTaskListError: (result) => GETJOBTASKLIST_ACTIONS.error(result, dispatch),

        deleteTaskRequest: (result) => DELETETASK_ACTIONS.request(result, dispatch),
        deleteTaskResult: (result) => DELETETASK_ACTIONS.result(result, dispatch),
        deleteTaskError: (result) => DELETETASK_ACTIONS.error(result, dispatch),

        createTaskRequest: (result) => CREATETASK_ACTIONS.request(result, dispatch),
        createTaskResult: (result) => CREATETASK_ACTIONS.result(result, dispatch),
        createTaskError: (result) => CREATETASK_ACTIONS.error(result, dispatch),

        getTaskListRequest: (result) => GETTASKLIST_ACTIONS.request(result, dispatch),
        getTaskListResult: (result) => GETTASKLIST_ACTIONS.result(result, dispatch),
        getTaskListError: (result) => GETTASKLIST_ACTIONS.error(result, dispatch),

        getTaskRequest: (result) => GETTASK_ACTIONS.request(result, dispatch),
        getTaskResult: (result) => GETTASK_ACTIONS.result(result, dispatch),
        getTaskError: (result) => GETTASK_ACTIONS.error(result, dispatch),

        filtersRequest: (result) => FILTERS_ACTIONS.request(result, dispatch),
        filtersResult: (result) => FILTERS_ACTIONS.result(result, dispatch),
        filtersError: (result) => FILTERS_ACTIONS.error(result, dispatch),

        runReverseGeocodingRequest: (result) => RUNREVERSEGEOCODING_ACTIONS.request(result, dispatch),
        runReverseGeocodingResult: (result) => RUNREVERSEGEOCODING_ACTIONS.result(result, dispatch),
        runReverseGeocodingError: (result) => RUNREVERSEGEOCODING_ACTIONS.error(result, dispatch),

        clearParentRequest: (result) => CLEARPARENT_ACTIONS.request(result, dispatch),
        clearParentResult: (result) => CLEARPARENT_ACTIONS.result(result, dispatch),
        clearParentError: (result) => CLEARPARENT_ACTIONS.error(result, dispatch),

        deleteArticleVariantRequest: (result) => DELETEARTICLEVARIANT_ACTIONS.request(result, dispatch),
        deleteArticleVariantResult: (result) => DELETEARTICLEVARIANT_ACTIONS.result(result, dispatch),
        deleteArticleVariantError: (result) => DELETEARTICLEVARIANT_ACTIONS.error(result, dispatch),

        getDataImportListRequest: (result) => GETDATAIMPORTLIST_ACTIONS.request(result, dispatch),
        getDataImportListResult: (result) => GETDATAIMPORTLIST_ACTIONS.result(result, dispatch),
        getDataImportListError: (result) => GETDATAIMPORTLIST_ACTIONS.error(result, dispatch),

        doAssignDuplicatesRequest: (result) => DOASSIGNDUPLICATES_ACTIONS.request(result, dispatch),
        doAssignDuplicatesResult: (result) => DOASSIGNDUPLICATES_ACTIONS.result(result, dispatch),
        doAssignDuplicatesError: (result) => DOASSIGNDUPLICATES_ACTIONS.error(result, dispatch),

        setArticleVariantOrderingRequest: (result) => SETARTICLEVARIANTORDERING_ACTIONS.request(result, dispatch),
        setArticleVariantOrderingResult: (result) => SETARTICLEVARIANTORDERING_ACTIONS.result(result, dispatch),
        setArticleVariantOrderingError: (result) => SETARTICLEVARIANTORDERING_ACTIONS.error(result, dispatch),
        deleteOrganizationRequest: (result) => DELETEORGANIZATION_ACTIONS.request(result, dispatch),
        deleteOrganizationResult: (result) => DELETEORGANIZATION_ACTIONS.result(result, dispatch),
        deleteOrganizationError: (result) => DELETEORGANIZATION_ACTIONS.error(result, dispatch),

        doAssignVariantsRequest: (result) => DOASSIGNVARIANTS_ACTIONS.request(result, dispatch),
        doAssignVariantsResult: (result) => DOASSIGNVARIANTS_ACTIONS.result(result, dispatch),
        doAssignVariantsError: (result) => DOASSIGNVARIANTS_ACTIONS.error(result, dispatch),

        createOrganizationRequest: (result) => CREATEORGANIZATION_ACTIONS.request(result, dispatch),
        createOrganizationResult: (result) => CREATEORGANIZATION_ACTIONS.result(result, dispatch),
        createOrganizationError: (result) => CREATEORGANIZATION_ACTIONS.error(result, dispatch),

        deleteOfficeRequest: (result) => DELETEOFFICE_ACTIONS.request(result, dispatch),
        deleteOfficeResult: (result) => DELETEOFFICE_ACTIONS.result(result, dispatch),
        deleteOfficeError: (result) => DELETEOFFICE_ACTIONS.error(result, dispatch),

        createOfficeRequest: (result) => CREATEOFFICE_ACTIONS.request(result, dispatch),
        createOfficeResult: (result) => CREATEOFFICE_ACTIONS.result(result, dispatch),
        createOfficeError: (result) => CREATEOFFICE_ACTIONS.error(result, dispatch),

        getOrganizationListRequest: (result) => GETORGANIZATIONLIST_ACTIONS.request(result, dispatch),
        getOrganizationListResult: (result) => GETORGANIZATIONLIST_ACTIONS.result(result, dispatch),
        getOrganizationListError: (result) => GETORGANIZATIONLIST_ACTIONS.error(result, dispatch),

        getOrganizationRequest: (result) => GETORGANIZATION_ACTIONS.request(result, dispatch),
        getOrganizationResult: (result) => GETORGANIZATION_ACTIONS.result(result, dispatch),
        getOrganizationError: (result) => GETORGANIZATION_ACTIONS.error(result, dispatch),

        getOfficeListRequest: (result) => GETOFFICELIST_ACTIONS.request(result, dispatch),
        getOfficeListResult: (result) => GETOFFICELIST_ACTIONS.result(result, dispatch),
        getOfficeListError: (result) => GETOFFICELIST_ACTIONS.error(result, dispatch),

        getOfficeRequest: (result) => GETOFFICE_ACTIONS.request(result, dispatch),
        getOfficeResult: (result) => GETOFFICE_ACTIONS.result(result, dispatch),
        getOfficeError: (result) => GETOFFICE_ACTIONS.error(result, dispatch),

        deleteContractRequest: (result) => DELETECONTRACT_ACTIONS.request(result, dispatch),
        deleteContractResult: (result) => DELETECONTRACT_ACTIONS.result(result, dispatch),
        deleteContractError: (result) => DELETECONTRACT_ACTIONS.error(result, dispatch),

        getArticleVariantImageListRequest: (result) => GETARTICLEVARIANTIMAGELIST_ACTIONS.request(result, dispatch),
        getArticleVariantImageListResult: (result) => GETARTICLEVARIANTIMAGELIST_ACTIONS.result(result, dispatch),
        getArticleVariantImageListError: (result) => GETARTICLEVARIANTIMAGELIST_ACTIONS.error(result, dispatch),

        createContractRequest: (result) => CREATECONTRACT_ACTIONS.request(result, dispatch),
        createContractResult: (result) => CREATECONTRACT_ACTIONS.result(result, dispatch),
        createContractError: (result) => CREATECONTRACT_ACTIONS.error(result, dispatch),

        getCollectionsFullRequest: (result) => GETCOLLECTIONSFULL_ACTIONS.request(result, dispatch),
        getCollectionsFullResult: (result) => GETCOLLECTIONSFULL_ACTIONS.result(result, dispatch),
        getCollectionsFullError: (result) => GETCOLLECTIONSFULL_ACTIONS.error(result, dispatch),

        getContractListRequest: (result) => GETCONTRACTLIST_ACTIONS.request(result, dispatch),
        getContractListResult: (result) => GETCONTRACTLIST_ACTIONS.result(result, dispatch),
        getContractListError: (result) => GETCONTRACTLIST_ACTIONS.error(result, dispatch),

        uploadMultipleFilesRequest: (result) => UPLOADMULTIPLEFILES_ACTIONS.request(result, dispatch),
        uploadMultipleFilesResult: (result) => UPLOADMULTIPLEFILES_ACTIONS.result(result, dispatch),
        uploadMultipleFilesError: (result) => UPLOADMULTIPLEFILES_ACTIONS.error(result, dispatch),

        getContractRequest: (result) => GETCONTRACT_ACTIONS.request(result, dispatch),
        getContractResult: (result) => GETCONTRACT_ACTIONS.result(result, dispatch),
        getContractError: (result) => GETCONTRACT_ACTIONS.error(result, dispatch),

        deletePictureRequest: (result) => DELETEPICTURE_ACTIONS.request(result, dispatch),
        deletePictureResult: (result) => DELETEPICTURE_ACTIONS.result(result, dispatch),
        deletePictureError: (result) => DELETEPICTURE_ACTIONS.error(result, dispatch),

        sendNotificationRequest: (result) => SENDNOTIFICATION_ACTIONS.request(result, dispatch),
        sendNotificationResult: (result) => SENDNOTIFICATION_ACTIONS.result(result, dispatch),
        sendNotificationError: (result) => SENDNOTIFICATION_ACTIONS.error(result, dispatch),

        createPictureRequest: (result) => CREATEPICTURE_ACTIONS.request(result, dispatch),
        createPictureResult: (result) => CREATEPICTURE_ACTIONS.result(result, dispatch),
        createPictureError: (result) => CREATEPICTURE_ACTIONS.error(result, dispatch),

        createProductRequest: (result) => CREATEPRODUCT_ACTIONS.request(result, dispatch),
        createProductResult: (result) => CREATEPRODUCT_ACTIONS.result(result, dispatch),
        createProductError: (result) => CREATEPRODUCT_ACTIONS.error(result, dispatch),

        deleteDepositRequest: (result) => DELETEDEPOSIT_ACTIONS.request(result, dispatch),
        deleteDepositResult: (result) => DELETEDEPOSIT_ACTIONS.result(result, dispatch),
        deleteDepositError: (result) => DELETEDEPOSIT_ACTIONS.error(result, dispatch),

        getArticleRecapRequest: (result) => GETARTICLERECAP_ACTIONS.request(result, dispatch),
        getArticleRecapResult: (result) => GETARTICLERECAP_ACTIONS.result(result, dispatch),
        getArticleRecapError: (result) => GETARTICLERECAP_ACTIONS.error(result, dispatch),

        getPictureListRequest: (result) => GETPICTURELIST_ACTIONS.request(result, dispatch),
        getPictureListResult: (result) => GETPICTURELIST_ACTIONS.result(result, dispatch),
        getPictureListError: (result) => GETPICTURELIST_ACTIONS.error(result, dispatch),

        getCorpListRequest: (result) => GETCORPLIST_ACTIONS.request(result, dispatch),
        getCorpListResult: (result) => GETCORPLIST_ACTIONS.result(result, dispatch),
        getCorpListError: (result) => GETCORPLIST_ACTIONS.error(result, dispatch),

        createDepositRequest: (result) => CREATEDEPOSIT_ACTIONS.request(result, dispatch),
        createDepositResult: (result) => CREATEDEPOSIT_ACTIONS.result(result, dispatch),
        createDepositError: (result) => CREATEDEPOSIT_ACTIONS.error(result, dispatch),

        getProductRecapRequest: (result) => GETPRODUCTRECAP_ACTIONS.request(result, dispatch),
        getProductRecapResult: (result) => GETPRODUCTRECAP_ACTIONS.result(result, dispatch),
        getProductRecapError: (result) => GETPRODUCTRECAP_ACTIONS.error(result, dispatch),

        getPictureRequest: (result) => GETPICTURE_ACTIONS.request(result, dispatch),
        getPictureResult: (result) => GETPICTURE_ACTIONS.result(result, dispatch),
        getPictureError: (result) => GETPICTURE_ACTIONS.error(result, dispatch),

        getCategoryListRequest: (result) => GETCATEGORYLIST_ACTIONS.request(result, dispatch),
        getCategoryListResult: (result) => GETCATEGORYLIST_ACTIONS.result(result, dispatch),
        getCategoryListError: (result) => GETCATEGORYLIST_ACTIONS.error(result, dispatch),

        getDepositRequest: (result) => GETDEPOSIT_ACTIONS.request(result, dispatch),
        getDepositResult: (result) => GETDEPOSIT_ACTIONS.result(result, dispatch),
        getDepositError: (result) => GETDEPOSIT_ACTIONS.error(result, dispatch),

        getDocumentRecapRequest: (result) => GETDOCUMENTRECAP_ACTIONS.request(result, dispatch),
        getDocumentRecapResult: (result) => GETDOCUMENTRECAP_ACTIONS.result(result, dispatch),
        getDocumentRecapError: (result) => GETDOCUMENTRECAP_ACTIONS.error(result, dispatch),

        deleteStdArticleRequest: (result) => DELETESTDARTICLE_ACTIONS.request(result, dispatch),
        deleteStdArticleResult: (result) => DELETESTDARTICLE_ACTIONS.result(result, dispatch),
        deleteStdArticleError: (result) => DELETESTDARTICLE_ACTIONS.error(result, dispatch),

        getCategoryRequest: (result) => GETCATEGORY_ACTIONS.request(result, dispatch),
        getCategoryResult: (result) => GETCATEGORY_ACTIONS.result(result, dispatch),
        getCategoryError: (result) => GETCATEGORY_ACTIONS.error(result, dispatch),

        createStdArticleRequest: (result) => CREATESTDARTICLE_ACTIONS.request(result, dispatch),
        createStdArticleResult: (result) => CREATESTDARTICLE_ACTIONS.result(result, dispatch),
        createStdArticleError: (result) => CREATESTDARTICLE_ACTIONS.error(result, dispatch),

        getCorpRequest: (result) => GETCORP_ACTIONS.request(result, dispatch),
        getCorpResult: (result) => GETCORP_ACTIONS.result(result, dispatch),
        getCorpError: (result) => GETCORP_ACTIONS.error(result, dispatch),

        getStdArticleListRequest: (result) => GETSTDARTICLELIST_ACTIONS.request(result, dispatch),
        getStdArticleListResult: (result) => GETSTDARTICLELIST_ACTIONS.result(result, dispatch),
        getStdArticleListError: (result) => GETSTDARTICLELIST_ACTIONS.error(result, dispatch),

        deleteCorpRequest: (result) => DELETECORP_ACTIONS.request(result, dispatch),
        deleteCorpResult: (result) => DELETECORP_ACTIONS.result(result, dispatch),
        deleteCorpError: (result) => DELETECORP_ACTIONS.error(result, dispatch),

        getStdArticleRequest: (result) => GETSTDARTICLE_ACTIONS.request(result, dispatch),
        getStdArticleResult: (result) => GETSTDARTICLE_ACTIONS.result(result, dispatch),
        getStdArticleError: (result) => GETSTDARTICLE_ACTIONS.error(result, dispatch),

        createCorpRequest: (result) => CREATECORP_ACTIONS.request(result, dispatch),
        createCorpResult: (result) => CREATECORP_ACTIONS.result(result, dispatch),
        createCorpError: (result) => CREATECORP_ACTIONS.error(result, dispatch),

        deleteVariantRequest: (result) => DELETEVARIANT_ACTIONS.request(result, dispatch),
        deleteVariantResult: (result) => DELETEVARIANT_ACTIONS.result(result, dispatch),
        deleteVariantError: (result) => DELETEVARIANT_ACTIONS.error(result, dispatch),

        deleteCategoryRequest: (result) => DELETECATEGORY_ACTIONS.request(result, dispatch),
        deleteCategoryResult: (result) => DELETECATEGORY_ACTIONS.result(result, dispatch),
        deleteCategoryError: (result) => DELETECATEGORY_ACTIONS.error(result, dispatch),

        createVariantRequest: (result) => CREATEVARIANT_ACTIONS.request(result, dispatch),
        createVariantResult: (result) => CREATEVARIANT_ACTIONS.result(result, dispatch),
        createVariantError: (result) => CREATEVARIANT_ACTIONS.error(result, dispatch),

        createCategoryRequest: (result) => CREATECATEGORY_ACTIONS.request(result, dispatch),
        createCategoryResult: (result) => CREATECATEGORY_ACTIONS.result(result, dispatch),
        createCategoryError: (result) => CREATECATEGORY_ACTIONS.error(result, dispatch),

        getVariantListRequest: (result) => GETVARIANTLIST_ACTIONS.request(result, dispatch),
        getVariantListResult: (result) => GETVARIANTLIST_ACTIONS.result(result, dispatch),
        getVariantListError: (result) => GETVARIANTLIST_ACTIONS.error(result, dispatch),

        deleteUserRequest: (result) => DELETEUSER_ACTIONS.request(result, dispatch),
        deleteUserResult: (result) => DELETEUSER_ACTIONS.result(result, dispatch),
        deleteUserError: (result) => DELETEUSER_ACTIONS.error(result, dispatch),

        getVariantRequest: (result) => GETVARIANT_ACTIONS.request(result, dispatch),
        getVariantResult: (result) => GETVARIANT_ACTIONS.result(result, dispatch),
        getVariantError: (result) => GETVARIANT_ACTIONS.error(result, dispatch),

        getProductRequest: (result) => GETPRODUCT_ACTIONS.request(result, dispatch),
        getProductResult: (result) => GETPRODUCT_ACTIONS.result(result, dispatch),
        getProductError: (result) => GETPRODUCT_ACTIONS.error(result, dispatch),

        deleteFamilyRequest: (result) => DELETEFAMILY_ACTIONS.request(result, dispatch),
        deleteFamilyResult: (result) => DELETEFAMILY_ACTIONS.result(result, dispatch),
        deleteFamilyError: (result) => DELETEFAMILY_ACTIONS.error(result, dispatch),

        deleteProductRequest: (result) => DELETEPRODUCT_ACTIONS.request(result, dispatch),
        deleteProductResult: (result) => DELETEPRODUCT_ACTIONS.result(result, dispatch),
        deleteProductError: (result) => DELETEPRODUCT_ACTIONS.error(result, dispatch),

        createFamilyRequest: (result) => CREATEFAMILY_ACTIONS.request(result, dispatch),
        createFamilyResult: (result) => CREATEFAMILY_ACTIONS.result(result, dispatch),
        createFamilyError: (result) => CREATEFAMILY_ACTIONS.error(result, dispatch),

        getProductListRequest: (result) => GETPRODUCTLIST_ACTIONS.request(result, dispatch),
        getProductListResult: (result) => GETPRODUCTLIST_ACTIONS.result(result, dispatch),
        getProductListError: (result) => GETPRODUCTLIST_ACTIONS.error(result, dispatch),

        getFamilyListRequest: (result) => GETFAMILYLIST_ACTIONS.request(result, dispatch),
        getFamilyListResult: (result) => GETFAMILYLIST_ACTIONS.result(result, dispatch),
        getFamilyListError: (result) => GETFAMILYLIST_ACTIONS.error(result, dispatch),

        getDepositListRequest: (result) => GETDEPOSITLIST_ACTIONS.request(result, dispatch),
        getDepositListResult: (result) => GETDEPOSITLIST_ACTIONS.result(result, dispatch),
        getDepositListError: (result) => GETDEPOSITLIST_ACTIONS.error(result, dispatch),

        getFamilyRequest: (result) => GETFAMILY_ACTIONS.request(result, dispatch),
        getFamilyResult: (result) => GETFAMILY_ACTIONS.result(result, dispatch),
        getFamilyError: (result) => GETFAMILY_ACTIONS.error(result, dispatch),

        getDocumentRequest: (result) => GETDOCUMENT_ACTIONS.request(result, dispatch),
        getDocumentResult: (result) => GETDOCUMENT_ACTIONS.result(result, dispatch),
        getDocumentError: (result) => GETDOCUMENT_ACTIONS.error(result, dispatch),

        deleteCollectionRequest: (result) => DELETECOLLECTION_ACTIONS.request(result, dispatch),
        deleteCollectionResult: (result) => DELETECOLLECTION_ACTIONS.result(result, dispatch),
        deleteCollectionError: (result) => DELETECOLLECTION_ACTIONS.error(result, dispatch),

        deleteDocumentRequest: (result) => DELETEDOCUMENT_ACTIONS.request(result, dispatch),
        deleteDocumentResult: (result) => DELETEDOCUMENT_ACTIONS.result(result, dispatch),
        deleteDocumentError: (result) => DELETEDOCUMENT_ACTIONS.error(result, dispatch),

        createCollectionRequest: (result) => CREATECOLLECTION_ACTIONS.request(result, dispatch),
        createCollectionResult: (result) => CREATECOLLECTION_ACTIONS.result(result, dispatch),
        createCollectionError: (result) => CREATECOLLECTION_ACTIONS.error(result, dispatch),

        createDocumentRequest: (result) => CREATEDOCUMENT_ACTIONS.request(result, dispatch),
        createDocumentResult: (result) => CREATEDOCUMENT_ACTIONS.result(result, dispatch),
        createDocumentError: (result) => CREATEDOCUMENT_ACTIONS.error(result, dispatch),

        getCollectionRequest: (result) => GETCOLLECTION_ACTIONS.request(result, dispatch),
        getCollectionResult: (result) => GETCOLLECTION_ACTIONS.result(result, dispatch),
        getCollectionError: (result) => GETCOLLECTION_ACTIONS.error(result, dispatch),

        getDocumentListRequest: (result) => GETDOCUMENTLIST_ACTIONS.request(result, dispatch),
        getDocumentListResult: (result) => GETDOCUMENTLIST_ACTIONS.result(result, dispatch),
        getDocumentListError: (result) => GETDOCUMENTLIST_ACTIONS.error(result, dispatch),

        getCollectionListRequest: (result) => GETCOLLECTIONLIST_ACTIONS.request(result, dispatch),
        getCollectionListResult: (result) => GETCOLLECTIONLIST_ACTIONS.result(result, dispatch),
        getCollectionListError: (result) => GETCOLLECTIONLIST_ACTIONS.error(result, dispatch),

        getArticleListRequest: (result) => GETARTICLELIST_ACTIONS.request(result, dispatch),
        getArticleListResult: (result) => GETARTICLELIST_ACTIONS.result(result, dispatch),
        getArticleListError: (result) => GETARTICLELIST_ACTIONS.error(result, dispatch),

        getUrgentJobListRequest: (result) => GETURGENTJOBLIST_ACTIONS.request(result, dispatch),
        getUrgentJobListResult: (result) => GETURGENTJOBLIST_ACTIONS.result(result, dispatch),
        getUrgentJobListError: (result) => GETURGENTJOBLIST_ACTIONS.error(result, dispatch),

        getArticleRequest: (result) => GETARTICLE_ACTIONS.request(result, dispatch),
        getArticleResult: (result) => GETARTICLE_ACTIONS.result(result, dispatch),
        getArticleError: (result) => GETARTICLE_ACTIONS.error(result, dispatch),

        deleteArticleRequest: (result) => DELETEARTICLE_ACTIONS.request(result, dispatch),
        deleteArticleResult: (result) => DELETEARTICLE_ACTIONS.result(result, dispatch),
        deleteArticleError: (result) => DELETEARTICLE_ACTIONS.error(result, dispatch),

        createArticleRequest: (result) => CREATEARTICLE_ACTIONS.request(result, dispatch),
        createArticleResult: (result) => CREATEARTICLE_ACTIONS.result(result, dispatch),
        createArticleError: (result) => CREATEARTICLE_ACTIONS.error(result, dispatch),

        getTurnRequest: (result) => GETTURN_ACTIONS.request(result, dispatch),
        getTurnResult: (result) => GETTURN_ACTIONS.result(result, dispatch),
        getTurnError: (result) => GETTURN_ACTIONS.error(result, dispatch),

        deleteTurnRequest: (result) => DELETETURN_ACTIONS.request(result, dispatch),
        deleteTurnResult: (result) => DELETETURN_ACTIONS.result(result, dispatch),
        deleteTurnError: (result) => DELETETURN_ACTIONS.error(result, dispatch),

        createTurnRequest: (result) => CREATETURN_ACTIONS.request(result, dispatch),
        createTurnResult: (result) => CREATETURN_ACTIONS.result(result, dispatch),
        createTurnError: (result) => CREATETURN_ACTIONS.error(result, dispatch),

        getTurnListRequest: (result) => GETTURNLIST_ACTIONS.request(result, dispatch),
        getTurnListResult: (result) => GETTURNLIST_ACTIONS.result(result, dispatch),
        getTurnListError: (result) => GETTURNLIST_ACTIONS.error(result, dispatch),

        getRoleListRequest: (result) => GETROLELIST_ACTIONS.request(result, dispatch),
        getRoleListResult: (result) => GETROLELIST_ACTIONS.result(result, dispatch),
        getRoleListError: (result) => GETROLELIST_ACTIONS.error(result, dispatch),

        getUserRequest: (result) => GETUSER_ACTIONS.request(result, dispatch),
        getUserResult: (result) => GETUSER_ACTIONS.result(result, dispatch),
        getUserError: (result) => GETUSER_ACTIONS.error(result, dispatch),

        changePasswordRequest: (result) => CHANGEPASSWORD_ACTIONS.request(result, dispatch),
        changePasswordResult: (result) => CHANGEPASSWORD_ACTIONS.result(result, dispatch),
        changePasswordError: (result) => CHANGEPASSWORD_ACTIONS.error(result, dispatch),

        createUserRequest: (result) => CREATEUSER_ACTIONS.request(result, dispatch),
        createUserResult: (result) => CREATEUSER_ACTIONS.result(result, dispatch),
        createUserError: (result) => CREATEUSER_ACTIONS.error(result, dispatch),

        getUserListRequest: (result) => GETUSERLIST_ACTIONS.request(result, dispatch),
        getUserListResult: (result) => GETUSERLIST_ACTIONS.result(result, dispatch),
        getUserListError: (result) => GETUSERLIST_ACTIONS.error(result, dispatch),

        organizationRequest: (result) => ORGANIZATION_ACTIONS.request(result, dispatch),
        organizationResult: (result) => ORGANIZATION_ACTIONS.result(result, dispatch),
        organizationError: (result) => ORGANIZATION_ACTIONS.error(result, dispatch),

        getFromIdSubidFilteringRequest: (result) => GETFROMIDSUBIDFILTERING_ACTIONS.request(result, dispatch),
        getFromIdSubidFilteringResult: (result) => GETFROMIDSUBIDFILTERING_ACTIONS.result(result, dispatch),
        getFromIdSubidFilteringError: (result) => GETFROMIDSUBIDFILTERING_ACTIONS.error(result, dispatch),

        getFromIdSubidRequest: (result) => GETFROMIDSUBID_ACTIONS.request(result, dispatch),
        getFromIdSubidResult: (result) => GETFROMIDSUBID_ACTIONS.result(result, dispatch),
        getFromIdSubidError: (result) => GETFROMIDSUBID_ACTIONS.error(result, dispatch),

        getFromIdFilteringRequest: (result) => GETFROMIDFILTERING_ACTIONS.request(result, dispatch),
        getFromIdFilteringResult: (result) => GETFROMIDFILTERING_ACTIONS.result(result, dispatch),
        getFromIdFilteringError: (result) => GETFROMIDFILTERING_ACTIONS.error(result, dispatch),

        getFromIdRequest: (result) => GETFROMID_ACTIONS.request(result, dispatch),
        getFromIdResult: (result) => GETFROMID_ACTIONS.result(result, dispatch),
        getFromIdError: (result) => GETFROMID_ACTIONS.error(result, dispatch),

        recapRequest: (result) => RECAP_ACTIONS.request(result, dispatch),
        recapResult: (result) => RECAP_ACTIONS.result(result, dispatch),
        recapError: (result) => RECAP_ACTIONS.error(result, dispatch),

        saveServiceRequest: (result) => SAVESERVICE_ACTIONS.request(result, dispatch),
        saveServiceResult: (result) => SAVESERVICE_ACTIONS.result(result, dispatch),
        saveServiceError: (result) => SAVESERVICE_ACTIONS.error(result, dispatch),

        getRecentJobListRequest: (result) => GETRECENTJOBLIST_ACTIONS.request(result, dispatch),
        getRecentJobListResult: (result) => GETRECENTJOBLIST_ACTIONS.result(result, dispatch),
        getRecentJobListError: (result) => GETRECENTJOBLIST_ACTIONS.error(result, dispatch),

        deleteBlackboxRequest: (result) => DELETEBLACKBOX_ACTIONS.request(result, dispatch),
        deleteBlackboxResult: (result) => DELETEBLACKBOX_ACTIONS.result(result, dispatch),
        deleteBlackboxError: (result) => DELETEBLACKBOX_ACTIONS.error(result, dispatch),

        jobListStateRequest: (result) => JOBLISTSTATE_ACTIONS.request(result, dispatch),
        jobListStateResult: (result) => JOBLISTSTATE_ACTIONS.result(result, dispatch),
        jobListStateError: (result) => JOBLISTSTATE_ACTIONS.error(result, dispatch),

        deleteWorkListRequest: (result) => DELETEWORKLIST_ACTIONS.request(result, dispatch),
        deleteWorkListResult: (result) => DELETEWORKLIST_ACTIONS.result(result, dispatch),
        deleteWorkListError: (result) => DELETEWORKLIST_ACTIONS.error(result, dispatch),

        getBlackboxListRequest: (result) => GETBLACKBOXLIST_ACTIONS.request(result, dispatch),
        getBlackboxListResult: (result) => GETBLACKBOXLIST_ACTIONS.result(result, dispatch),
        getBlackboxListError: (result) => GETBLACKBOXLIST_ACTIONS.error(result, dispatch),

        realtimeNewdata: (result) => REALTIME_ACTIONS.newdata(result, dispatch),
        realtimeConnect: (result) => REALTIME_ACTIONS.connect(result, dispatch),
        realtimeDisconnect: (result) => REALTIME_ACTIONS.disconnect(result, dispatch),

        socketHerculesNewdata: (result) => SOCKET_HERCULES_ACTIONS.newdata(result, dispatch),
        socketHerculesConnect: (result) => SOCKET_HERCULES_ACTIONS.connect(result, dispatch),
        socketHerculesDisconnect: (result) => SOCKET_HERCULES_ACTIONS.disconnect(result, dispatch),

        getCostRequest: (result) => GETCOST_ACTIONS.request(result, dispatch),
        getCostResult: (result) => GETCOST_ACTIONS.result(result, dispatch),
        getCostError: (result) => GETCOST_ACTIONS.error(result, dispatch),

        getMachineRequest: (result) => GETMACHINE_ACTIONS.request(result, dispatch),
        getMachineResult: (result) => GETMACHINE_ACTIONS.result(result, dispatch),
        getMachineError: (result) => GETMACHINE_ACTIONS.error(result, dispatch),

        getWorkListForDateRequest: (result) => GETWORKLISTFORDATE_ACTIONS.request(result, dispatch),
        getWorkListForDateResult: (result) => GETWORKLISTFORDATE_ACTIONS.result(result, dispatch),
        getWorkListForDateError: (result) => GETWORKLISTFORDATE_ACTIONS.error(result, dispatch),

        getWorkListForDaeRequest: (result) => GETWORKLISTFORDAE_ACTIONS.request(result, dispatch),
        getWorkListForDaeResult: (result) => GETWORKLISTFORDAE_ACTIONS.result(result, dispatch),
        getWorkListForDaeError: (result) => GETWORKLISTFORDAE_ACTIONS.error(result, dispatch),

        getWorkListForDayRequest: (result) => GETWORKLISTFORDAY_ACTIONS.request(result, dispatch),
        getWorkListForDayResult: (result) => GETWORKLISTFORDAY_ACTIONS.result(result, dispatch),
        getWorkListForDayError: (result) => GETWORKLISTFORDAY_ACTIONS.error(result, dispatch),

        workdayRequest: (result) => WORKDAY_ACTIONS.request(result, dispatch),
        workdayResult: (result) => WORKDAY_ACTIONS.result(result, dispatch),
        workdayError: (result) => WORKDAY_ACTIONS.error(result, dispatch),

        assignWorkRequest: (result) => ASSIGNWORK_ACTIONS.request(result, dispatch),
        assignWorkResult: (result) => ASSIGNWORK_ACTIONS.result(result, dispatch),
        assignWorkError: (result) => ASSIGNWORK_ACTIONS.error(result, dispatch),

        uploadFileRequest: (result) => UPLOADFILE_ACTIONS.request(result, dispatch),
        uploadFileResult: (result) => UPLOADFILE_ACTIONS.result(result, dispatch),
        uploadFileError: (result) => UPLOADFILE_ACTIONS.error(result, dispatch),

        getImageListRequest: (result) => GETIMAGELIST_ACTIONS.request(result, dispatch),
        getImageListResult: (result) => GETIMAGELIST_ACTIONS.result(result, dispatch),
        getImageListError: (result) => GETIMAGELIST_ACTIONS.error(result, dispatch),

        getJobTypeListRequest: (result) => GETJOBTYPELIST_ACTIONS.request(result, dispatch),
        getJobTypeListResult: (result) => GETJOBTYPELIST_ACTIONS.result(result, dispatch),
        getJobTypeListError: (result) => GETJOBTYPELIST_ACTIONS.error(result, dispatch),

        searchRequest: (result) => SEARCH_ACTIONS.request(result, dispatch),
        searchResult: (result) => SEARCH_ACTIONS.result(result, dispatch),
        searchError: (result) => SEARCH_ACTIONS.error(result, dispatch),

        toolbarRequest: (result) => TOOLBAR_ACTIONS.request(result, dispatch),
        toolbarResult: (result) => TOOLBAR_ACTIONS.result(result, dispatch),
        toolbarError: (result) => TOOLBAR_ACTIONS.error(result, dispatch),

        getDetailedJobListRequest: (result) => GETDETAILEDJOBLIST_ACTIONS.request(result, dispatch),
        getDetailedJobListResult: (result) => GETDETAILEDJOBLIST_ACTIONS.result(result, dispatch),
        getDetailedJobListError: (result) => GETDETAILEDJOBLIST_ACTIONS.error(result, dispatch),

        deleteMappingRequest: (result) => DELETEMAPPING_ACTIONS.request(result, dispatch),
        deleteMappingResult: (result) => DELETEMAPPING_ACTIONS.result(result, dispatch),
        deleteMappingError: (result) => DELETEMAPPING_ACTIONS.error(result, dispatch),

        createMappingRequest: (result) => CREATEMAPPING_ACTIONS.request(result, dispatch),
        createMappingResult: (result) => CREATEMAPPING_ACTIONS.result(result, dispatch),
        createMappingError: (result) => CREATEMAPPING_ACTIONS.error(result, dispatch),

        getMappingDataRequest: (result) => GETMAPPINGDATA_ACTIONS.request(result, dispatch),
        getMappingDataResult: (result) => GETMAPPINGDATA_ACTIONS.result(result, dispatch),
        getMappingDataError: (result) => GETMAPPINGDATA_ACTIONS.error(result, dispatch),

        getBabypressaDataFilterRequest: (result) => GETBABYPRESSADATAFILTER_ACTIONS.request(result, dispatch),
        getBabypressaDataFilterResult: (result) => GETBABYPRESSADATAFILTER_ACTIONS.result(result, dispatch),
        getBabypressaDataFilterError: (result) => GETBABYPRESSADATAFILTER_ACTIONS.error(result, dispatch),

        getGT2000DataFilterRequest: (result) => GETGT2000DATAFILTER_ACTIONS.request(result, dispatch),
        getGT2000DataFilterResult: (result) => GETGT2000DATAFILTER_ACTIONS.result(result, dispatch),
        getGT2000DataFilterError: (result) => GETGT2000DATAFILTER_ACTIONS.error(result, dispatch),

        getLastGT2000DataRequest: (result) => GETLASTGT2000DATA_ACTIONS.request(result, dispatch),
        getLastGT2000DataResult: (result) => GETLASTGT2000DATA_ACTIONS.result(result, dispatch),
        getLastGT2000DataError: (result) => GETLASTGT2000DATA_ACTIONS.error(result, dispatch),

        getGT2000DataRequest: (result) => GETGT2000DATA_ACTIONS.request(result, dispatch),
        getGT2000DataResult: (result) => GETGT2000DATA_ACTIONS.result(result, dispatch),
        getGT2000DataError: (result) => GETGT2000DATA_ACTIONS.error(result, dispatch),

        getLastSWA300DataRequest: (result) => GETLASTSWA300DATA_ACTIONS.request(result, dispatch),
        getLastSWA300DataResult: (result) => GETLASTSWA300DATA_ACTIONS.result(result, dispatch),
        getLastSWA300DataError: (result) => GETLASTSWA300DATA_ACTIONS.error(result, dispatch),

        getLastBabypressaDataRequest: (result) => GETLASTBABYPRESSADATA_ACTIONS.request(result, dispatch),
        getLastBabypressaDataResult: (result) => GETLASTBABYPRESSADATA_ACTIONS.result(result, dispatch),
        getLastBabypressaDataError: (result) => GETLASTBABYPRESSADATA_ACTIONS.error(result, dispatch),

        getBabypressaDataRequest: (result) => GETBABYPRESSADATA_ACTIONS.request(result, dispatch),
        getBabypressaDataResult: (result) => GETBABYPRESSADATA_ACTIONS.result(result, dispatch),
        getBabypressaDataError: (result) => GETBABYPRESSADATA_ACTIONS.error(result, dispatch),

        getSWA300DataRequest: (result) => GETSWA300DATA_ACTIONS.request(result, dispatch),
        getSWA300DataResult: (result) => GETSWA300DATA_ACTIONS.result(result, dispatch),
        getSWA300DataError: (result) => GETSWA300DATA_ACTIONS.error(result, dispatch),

        writeSWA300ConfigRequest: (result) => WRITESWA300CONFIG_ACTIONS.request(result, dispatch),
        writeSWA300ConfigResult: (result) => WRITESWA300CONFIG_ACTIONS.result(result, dispatch),
        writeSWA300ConfigError: (result) => WRITESWA300CONFIG_ACTIONS.error(result, dispatch),

        uploadImageRequest: (result) => UPLOADIMAGE_ACTIONS.request(result, dispatch),
        uploadImageResult: (result) => UPLOADIMAGE_ACTIONS.result(result, dispatch),
        uploadImageError: (result) => UPLOADIMAGE_ACTIONS.error(result, dispatch),

        createItemRequest: (result) => CREATEITEM_ACTIONS.request(result, dispatch),
        createItemResult: (result) => CREATEITEM_ACTIONS.result(result, dispatch),
        createItemError: (result) => CREATEITEM_ACTIONS.error(result, dispatch),

        deleteItemRequest: (result) => DELETEITEM_ACTIONS.request(result, dispatch),
        deleteItemResult: (result) => DELETEITEM_ACTIONS.result(result, dispatch),
        deleteItemError: (result) => DELETEITEM_ACTIONS.error(result, dispatch),

        getItemListRequest: (result) => GETITEMLIST_ACTIONS.request(result, dispatch),
        getItemListResult: (result) => GETITEMLIST_ACTIONS.result(result, dispatch),
        getItemListError: (result) => GETITEMLIST_ACTIONS.error(result, dispatch),

        mergeJobRequest: (result) => MERGEJOB_ACTIONS.request(result, dispatch),
        mergeJobResult: (result) => MERGEJOB_ACTIONS.result(result, dispatch),
        mergeJobError: (result) => MERGEJOB_ACTIONS.error(result, dispatch),

        getRecentMachineListRequest: (result) => GETRECENTMACHINELIST_ACTIONS.request(result, dispatch),
        getRecentMachineListResult: (result) => GETRECENTMACHINELIST_ACTIONS.result(result, dispatch),
        getRecentMachineListError: (result) => GETRECENTMACHINELIST_ACTIONS.error(result, dispatch),

        getWorkerRequest: (result) => GETWORKER_ACTIONS.request(result, dispatch),
        getWorkerResult: (result) => GETWORKER_ACTIONS.result(result, dispatch),
        getWorkerError: (result) => GETWORKER_ACTIONS.error(result, dispatch),

        getJobRequest: (result) => GETJOB_ACTIONS.request(result, dispatch),
        getJobResult: (result) => GETJOB_ACTIONS.result(result, dispatch),
        getJobError: (result) => GETJOB_ACTIONS.error(result, dispatch),

        getPagedJobListRequest: (result) => GETPAGEDJOBLIST_ACTIONS.request(result, dispatch),
        getPagedJobListResult: (result) => GETPAGEDJOBLIST_ACTIONS.result(result, dispatch),
        getPagedJobListError: (result) => GETPAGEDJOBLIST_ACTIONS.error(result, dispatch),

        getJobListRequest: (result) => GETJOBLIST_ACTIONS.request(result, dispatch),
        getJobListResult: (result) => GETJOBLIST_ACTIONS.result(result, dispatch),
        getJobListError: (result) => GETJOBLIST_ACTIONS.error(result, dispatch),

        getWorkerListRequest: (result) => GETWORKERLIST_ACTIONS.request(result, dispatch),
        getWorkerListResult: (result) => GETWORKERLIST_ACTIONS.result(result, dispatch),
        getWorkerListError: (result) => GETWORKERLIST_ACTIONS.error(result, dispatch),

        getItemCostsRequest: (result) => GETITEMCOSTS_ACTIONS.request(result, dispatch),
        getItemCostsResult: (result) => GETITEMCOSTS_ACTIONS.result(result, dispatch),
        getItemCostsError: (result) => GETITEMCOSTS_ACTIONS.error(result, dispatch),

        deleteWorkerRequest: (result) => DELETEWORKER_ACTIONS.request(result, dispatch),
        deleteWorkerResult: (result) => DELETEWORKER_ACTIONS.result(result, dispatch),
        deleteWorkerError: (result) => DELETEWORKER_ACTIONS.error(result, dispatch),

        updateWorkerRequest: (result) => UPDATEWORKER_ACTIONS.request(result, dispatch),
        updateWorkerResult: (result) => UPDATEWORKER_ACTIONS.result(result, dispatch),
        updateWorkerError: (result) => UPDATEWORKER_ACTIONS.error(result, dispatch),

        createWorkerRequest: (result) => CREATEWORKER_ACTIONS.request(result, dispatch),
        createWorkerResult: (result) => CREATEWORKER_ACTIONS.result(result, dispatch),
        createWorkerError: (result) => CREATEWORKER_ACTIONS.error(result, dispatch),

        getPagedCostListRequest: (result) => GETPAGEDCOSTLIST_ACTIONS.request(result, dispatch),
        getPagedCostListResult: (result) => GETPAGEDCOSTLIST_ACTIONS.result(result, dispatch),
        getPagedCostListError: (result) => GETPAGEDCOSTLIST_ACTIONS.error(result, dispatch),

        getCostListRequest: (result) => GETCOSTLIST_ACTIONS.request(result, dispatch),
        getCostListResult: (result) => GETCOSTLIST_ACTIONS.result(result, dispatch),
        getCostListError: (result) => GETCOSTLIST_ACTIONS.error(result, dispatch),

        deleteCostRequest: (result) => DELETECOST_ACTIONS.request(result, dispatch),
        deleteCostResult: (result) => DELETECOST_ACTIONS.result(result, dispatch),
        deleteCostError: (result) => DELETECOST_ACTIONS.error(result, dispatch),

        updateCostRequest: (result) => UPDATECOST_ACTIONS.request(result, dispatch),
        updateCostResult: (result) => UPDATECOST_ACTIONS.result(result, dispatch),
        updateCostError: (result) => UPDATECOST_ACTIONS.error(result, dispatch),

        createCostRequest: (result) => CREATECOST_ACTIONS.request(result, dispatch),
        createCostResult: (result) => CREATECOST_ACTIONS.result(result, dispatch),
        createCostError: (result) => CREATECOST_ACTIONS.error(result, dispatch),

        getMachineTypeListRequest: (result) => GETMACHINETYPELIST_ACTIONS.request(result, dispatch),
        getMachineTypeListResult: (result) => GETMACHINETYPELIST_ACTIONS.result(result, dispatch),
        getMachineTypeListError: (result) => GETMACHINETYPELIST_ACTIONS.error(result, dispatch),

        deleteMachineTypeRequest: (result) => DELETEMACHINETYPE_ACTIONS.request(result, dispatch),
        deleteMachineTypeResult: (result) => DELETEMACHINETYPE_ACTIONS.result(result, dispatch),
        deleteMachineTypeError: (result) => DELETEMACHINETYPE_ACTIONS.error(result, dispatch),

        updateMachineTypeRequest: (result) => UPDATEMACHINETYPE_ACTIONS.request(result, dispatch),
        updateMachineTypeResult: (result) => UPDATEMACHINETYPE_ACTIONS.result(result, dispatch),
        updateMachineTypeError: (result) => UPDATEMACHINETYPE_ACTIONS.error(result, dispatch),

        createMachineTypeRequest: (result) => CREATEMACHINETYPE_ACTIONS.request(result, dispatch),
        createMachineTypeResult: (result) => CREATEMACHINETYPE_ACTIONS.result(result, dispatch),
        createMachineTypeError: (result) => CREATEMACHINETYPE_ACTIONS.error(result, dispatch),

        getMachineListRequest: (result) => GETMACHINELIST_ACTIONS.request(result, dispatch),
        getMachineListResult: (result) => GETMACHINELIST_ACTIONS.result(result, dispatch),
        getMachineListError: (result) => GETMACHINELIST_ACTIONS.error(result, dispatch),

        deleteMachineRequest: (result) => DELETEMACHINE_ACTIONS.request(result, dispatch),
        deleteMachineResult: (result) => DELETEMACHINE_ACTIONS.result(result, dispatch),
        deleteMachineError: (result) => DELETEMACHINE_ACTIONS.error(result, dispatch),

        updateMachineRequest: (result) => UPDATEMACHINE_ACTIONS.request(result, dispatch),
        updateMachineResult: (result) => UPDATEMACHINE_ACTIONS.result(result, dispatch),
        updateMachineError: (result) => UPDATEMACHINE_ACTIONS.error(result, dispatch),

        createMachineRequest: (result) => CREATEMACHINE_ACTIONS.request(result, dispatch),
        createMachineResult: (result) => CREATEMACHINE_ACTIONS.result(result, dispatch),
        createMachineError: (result) => CREATEMACHINE_ACTIONS.error(result, dispatch),

        getWorkListFilterRequest: (result) => GETWORKLISTFILTER_ACTIONS.request(result, dispatch),
        getWorkListFilterResult: (result) => GETWORKLISTFILTER_ACTIONS.result(result, dispatch),
        getWorkListFilterError: (result) => GETWORKLISTFILTER_ACTIONS.error(result, dispatch),

        getWorkListSpanRequest: (result) => GETWORKLISTSPAN_ACTIONS.request(result, dispatch),
        getWorkListSpanResult: (result) => GETWORKLISTSPAN_ACTIONS.result(result, dispatch),
        getWorkListSpanError: (result) => GETWORKLISTSPAN_ACTIONS.error(result, dispatch),

        getWorkListRequest: (result) => GETWORKLIST_ACTIONS.request(result, dispatch),
        getWorkListResult: (result) => GETWORKLIST_ACTIONS.result(result, dispatch),
        getWorkListError: (result) => GETWORKLIST_ACTIONS.error(result, dispatch),

        getWorkRequest: (result) => GETWORK_ACTIONS.request(result, dispatch),
        getWorkResult: (result) => GETWORK_ACTIONS.result(result, dispatch),
        getWorkError: (result) => GETWORK_ACTIONS.error(result, dispatch),

        deleteWorkRequest: (result) => DELETEWORK_ACTIONS.request(result, dispatch),
        deleteWorkResult: (result) => DELETEWORK_ACTIONS.result(result, dispatch),
        deleteWorkError: (result) => DELETEWORK_ACTIONS.error(result, dispatch),

        updateWorkRequest: (result) => UPDATEWORK_ACTIONS.request(result, dispatch),
        updateWorkResult: (result) => UPDATEWORK_ACTIONS.result(result, dispatch),
        updateWorkError: (result) => UPDATEWORK_ACTIONS.error(result, dispatch),

        createWorkRequest: (result) => CREATEWORK_ACTIONS.request(result, dispatch),
        createWorkResult: (result) => CREATEWORK_ACTIONS.result(result, dispatch),
        createWorkError: (result) => CREATEWORK_ACTIONS.error(result, dispatch),

        deleteJobRequest: (result) => DELETEJOB_ACTIONS.request(result, dispatch),
        deleteJobResult: (result) => DELETEJOB_ACTIONS.result(result, dispatch),
        deleteJobError: (result) => DELETEJOB_ACTIONS.error(result, dispatch),

        switchJobRequest: (result) => SWITCHJOB_ACTIONS.request(result, dispatch),
        switchJobResult: (result) => SWITCHJOB_ACTIONS.result(result, dispatch),
        switchJobError: (result) => SWITCHJOB_ACTIONS.error(result, dispatch),

        updateJobRequest: (result) => UPDATEJOB_ACTIONS.request(result, dispatch),
        updateJobResult: (result) => UPDATEJOB_ACTIONS.result(result, dispatch),
        updateJobError: (result) => UPDATEJOB_ACTIONS.error(result, dispatch),

        createJobRequest: (result) => CREATEJOB_ACTIONS.request(result, dispatch),
        createJobResult: (result) => CREATEJOB_ACTIONS.result(result, dispatch),
        createJobError: (result) => CREATEJOB_ACTIONS.error(result, dispatch),

        loginEmployeeRequest: (result) => LOGINEMPLOYEE_ACTIONS.request(result, dispatch),
        loginEmployeeResult: (result) => LOGINEMPLOYEE_ACTIONS.result(result, dispatch),
        loginEmployeeError: (result) => LOGINEMPLOYEE_ACTIONS.error(result, dispatch),

        logoutEmployee: (result) => LOGINEMPLOYEE_ACTIONS.logout(result, dispatch),

        loginRequest: (result) => LOGIN_ACTIONS.request(result, dispatch),
        loginResult: (result) => LOGIN_ACTIONS.result(result, dispatch),
        loginError: (result) => LOGIN_ACTIONS.error(result, dispatch),
        logout: (result) => LOGIN_ACTIONS.logout(result, dispatch),
    }
}

export const connectNetwork = (ComponentToWrap) => {
    return class NetworkComponent extends Component {
        static contextTypes = {
            network: PropTypes.object.isRequired,
        }

        render() {
            const { network } = this.context

            return <ComponentToWrap {...this.props} network={network} />
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(NetworkProvider))
