import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import { Range } from 'rc-slider'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Icon } from 'semantic-ui-react'
import { Confirmation } from '../components'
import { floatTimeToPaddedString } from '../lib/util'

const { app, turns, turnEdit } = routes

class Turn extends Component {
    constructor(props) {
        super(props)
        let { turn } = props

        this.state = {
            pwModal: false,
            ...turn,
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, turn } = this.props

        network.deleteTurn(turn)

        this.setState({ deleting: false })
    }

    handleInput(event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        // console.log('Handling input', name, target, event)

        this.setState({
            [name]: value,
        })
    }

    render() {
        let { type } = this.props
        let { deleting, turnId, turnName, start, length } = this.state
        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        let end = start + length
        let startstring = floatTimeToPaddedString(start)
        let endstring = floatTimeToPaddedString(end)
        let range = [start, start + length]
        let marks = {}

        for (let i = 0; i <= 24; i += 1) {
            let hours = Math.floor(i)
            let minutes = i % 1
            if (minutes < 10) minutes = `0${minutes}`
            marks[i] = <span>{hours}</span>
        }

        switch (type) {
            default:
                return (
                    <View fullw column between>
                        <Box style={styles.jobCard} {...extraprops} borderWidth="1px" borderRadius="lg"
                    p="4" >
                            <Confirmation
                                message="Vuoi davvero eliminare questo turno?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.jobContent}>
                                <View fullw row>
                                    <View fullw column>
                                        <Box textStyle="header">
                                            <span style={styles.header}>{turnName}</span>
                                            <span
                                                style={{
                                                    marginLeft: '16px',
                                                    fontSize: '18pt',
                                                    color: 'grey',
                                                }}
                                            >
                                                Dalle {startstring.fullstring} alle {endstring.fullstring}
                                            </span>
                                        </Box>
                                        <Box textStyle="description">
                                            <Range
                                                style={{ height: 50, marginTop: 8 }}
                                                value={range}
                                                defaultValue={[start, start + length]}
                                                min={0}
                                                max={24}
                                                step={0.5}
                                                pushable={true}
                                                dots={true}
                                                marks={marks}
                                                activeTrackStyle={{
                                                    backgroundColor: 'green',
                                                }}
                                                handleStyle={{
                                                    backgroundColor: 'yellow',
                                                }}
                                            />
                                        </Box>
                                    </View>
                                </View>
                            </Box>
                            <Box textStyle="content" extra>
                                <Button
                                    color="blue"
                                    onClick={() => {
                                        this.props.history.push(route_from(app, turns, turnEdit, `${turnId}`))
                                    }}
                                >
                                    <Icon name="edit" />
                                    Modifica
                                </Button>
                                <Button
                                    color="red"
                                    onClick={() => {
                                        this.openRemoveDialog()
                                    }}
                                >
                                    <Icon name="delete" />
                                    Elimina
                                </Button>
                            </Box>
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    jobImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    jobContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    jobCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login } = state
    let rcode = null
    if (login && login.data && login.data.turn) rcode = login.data.turn.rcode

    return { role: rcode }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Turn)))
