import { Box } from '@chakra-ui/layout'
import { } from 'containers'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Button, Input, Table } from 'semantic-ui-react'
import { floatTimeToString, timeString } from '../lib/util'
import { Confirmation } from './'

const { app, tasks, taskEdit } = routes

class Task extends Component {
    constructor(props) {
        super(props)

        this.componentContainerRef = null

        this.setComponentContainerRef = (element) => {
            this.componentContainerRef = element
        }

        let { onCostChange, task } = props

        this.state = {
            deleting: false,
            ...task,
            taskMinutes: task ? parseFloat(task.taskMinutes) : 0,
        }

        if (onCostChange && task) {
            onCostChange(this.totalCost(), task.id)
        }
    }

    totalCost() {
        let { taskHourlyRate, taskHours, taskMinutes } = this.state
        let total = (taskHours + taskMinutes / 60) * parseFloat(taskHourlyRate)
        if (isNaN(total)) {
            total = 0
        }
        return total
    }

    componentDidMount() {
        let { task, id, network } = this.props

        if ((!task || !task.id) && id) {
            network.getTask(id)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getTask, login } = this.props
        let { id, taskHourlyRate } = this.state
        if (taskHourlyRate !== prevState.taskHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), id)
            }
        }

        // console.log('Task getTask fetching', getTask.fetching)
        if (getTask && getTask.fetching !== prevProps.getTask.fetching && !getTask.fetching) {
            // console.log('Done calling getTask')
            if (getTask.status.success) {
                // console.log(getTask.data)
                let task = getTask.data.data

                this.setState({ ...task })
                // console.log('Calling Get task', login, task)
                /*
				if (login && login.data.user && login.data.user.companies) {
					let company = login.data.user.companies[organization.data[0]]
					// console.log('Calling getFromIdFiltering', company, jobId)
					network.getFromIdFiltering(company, jobId, [
						{
							key: 'timestamp',
							symbol: '>',
							value: moment().subtract(4, 'hours').format('YYYY-MM-DD HH:mm:ss')
						}
					])
				}*/
                return
            } else {
                // console.log('calling No task found with this id')
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Macchina</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { name, taskHourlyRate, taskHours, taskMinutes } = this.state
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={taskHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    taskHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{taskHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(taskHours, taskMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { hideTask, hideTaskHours, isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        {!hideTask && <Table.HeaderCell>Nome Macchina</Table.HeaderCell>}
                        {!hideTaskHours && <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { hideTask, hideTaskHours, isBalance } = this.props
        let { name, taskHours, taskMinutes } = this.state

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    {!hideTask && <Table.Cell>{name}</Table.Cell>}
                    {!hideTaskHours && <Table.Cell>{timeString(taskHours, taskMinutes)}</Table.Cell>}
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, task } = this.props

        network.deleteTask(task)

        this.setState({ deleting: false })
    }

    render() {
        let { login, type, task, onClick, selected, noActions, traversable } = this.props
        let { deleting, taskId: id, taskId, taskDescription, taskStatus } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }

        if (onClick) {
            extraprops.onClick = () => {
                onClick(task)
            }
        }
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            case 'compact':
                return (
                    <>
                        <Confirmation
                            message="Vuoi davvero eliminare questo task?"
                            showModal={deleting}
                            onConfirm={() => {
                                this.remove()
                            }}
                            onCancel={() => {
                                this.setState({ deleting: false })
                            }}
                        />
                        <Box textStyle="content" style={styles.taskContent}>
                            <View fullw row>
                                <Button.Group style={{ marginRight: 8 }}>
                                    <Button
                                        active={taskStatus === 0}
                                        onClick={(e, data) => {
                                            const { network } = this.props
                                            const newValue = 0
                                            network.updateTask({ taskId, taskStatus: newValue })
                                            this.setState({ taskStatus: newValue })
                                        }}
                                        icon="pause"
                                    ></Button>
                                    {/* <Button.Or text="o" /> */}
                                    <Button
                                        active={taskStatus === 2}
                                        onClick={(e, data) => {
                                            const { network } = this.props
                                            const newValue = 2
                                            network.updateTask({ taskId, taskStatus: newValue })
                                            this.setState({ taskStatus: newValue })
                                        }}
                                        icon="play"
                                    ></Button>
                                    {/* <Button.Or text="o" /> */}
                                    <Button
                                        active={taskStatus === 1}
                                        onClick={(e, data) => {
                                            const { network } = this.props
                                            const newValue = 1
                                            network.updateTask({ taskId, taskStatus: newValue })
                                            this.setState({ taskStatus: newValue })
                                        }}
                                        icon="flag checkered"
                                    ></Button>
                                    <Button
                                        active={taskStatus === 3}
                                        onClick={(e, data) => {
                                            const { network } = this.props
                                            const newValue = 3
                                            network.updateTask({ taskId, taskStatus: newValue })
                                            this.setState({ taskStatus: newValue })
                                        }}
                                        icon="remove"
                                    ></Button>
                                </Button.Group>

                                <View fullw column>
                                    <span style={styles.smallheader}>{taskDescription}</span>
                                </View>
                            </View>
                        </Box>
                    </>
                )
            default:
                return (
                    <View fullw column between>
                        <Box borderWidth="1px" borderRadius="lg"
                    p="4" overflow="hidden" className={selected ? 'rowSelected' : ''} style={styles.taskCard} {...extraprops} >
                            <Confirmation
                                message="Vuoi davvero eliminare questo task?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.taskContent}>
                                <View fullw row>
                                    <Button.Group>
                                        <Button
                                            active={taskStatus === 0}
                                            onClick={(e, data) => {
                                                const { network } = this.props
                                                const newValue = 0
                                                network.updateTask({ taskId, taskStatus: newValue })
                                                this.setState({ taskStatus: newValue })
                                            }}
                                            icon="pause"
                                        ></Button>
                                        {/* <Button.Or text="o" /> */}
                                        <Button
                                            active={taskStatus === 2}
                                            onClick={(e, data) => {
                                                const { network } = this.props
                                                const newValue = 2
                                                network.updateTask({ taskId, taskStatus: newValue })
                                                this.setState({ taskStatus: newValue })
                                            }}
                                            icon="play"
                                        ></Button>
                                        {/* <Button.Or text="o" /> */}
                                        <Button
                                            active={taskStatus === 1}
                                            onClick={(e, data) => {
                                                const { network } = this.props
                                                const newValue = 1
                                                network.updateTask({ taskId, taskStatus: newValue })
                                                this.setState({ taskStatus: newValue })
                                            }}
                                            icon="flag checkered"
                                        ></Button>
                                        <Button
                                            active={taskStatus === 3}
                                            onClick={(e, data) => {
                                                const { network } = this.props
                                                const newValue = 3
                                                network.updateTask({ taskId, taskStatus: newValue })
                                                this.setState({ taskStatus: newValue })
                                            }}
                                            icon="remove"
                                        ></Button>
                                    </Button.Group>
                                    <View fullw column style={{ marginLeft: 8 }}>
                                        <span style={styles.header}>{taskDescription}</span>
                                    </View>
                                </View>
                            </Box>
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    smallheader: {
        fontSize: '18pt',
        lineHeight: 'normal',
    },
    header: {
        fontSize: '24pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    taskImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    taskContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    taskCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, getTask } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return { role: rcode, getTask, login }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Task)))
