export function mergeMaterial(data) {
    return new Promise((resolve, reject) => {
		let { mergeMaterialRequest, mergeMaterialError, mergeMaterialResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'merge_material',
			this.createBundle(
				'merge_material',
				{ data: data },
				this.POST,
				mergeMaterialRequest,
                (result) => {
                    mergeMaterialResult(result)
                    resolve(result)
                },
                (error) => {
                    mergeMaterialError(error)
                    reject(error)
                }
			)
		);
	})
}
