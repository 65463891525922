import { connectNetwork } from 'lib/NetworkProvider'
import View from 'lib/View'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Image, Item, Label, Menu, Rating, Sidebar } from 'semantic-ui-react'

const paragraph = <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />

const comp = [
    {
        img: 'https://www.paricop.com/media/wysiwyg/porto/homepage/slider/clienti/anc001.jpg',
        name: 'Paricop',
        address: 'Via Giuseppe di Vittorio, 1, 60024, Filottrano (AN), Italy',
        description:
            'La Paricop srl è un’azienda giovane e dinamica, che opera in un contesto territoriale dove la vocazione artigiana vanta una tradizione più che centenaria.',
        certified: true,
    },
    {
        img: 'https://www.esse80.it/wp-content/uploads/2016/07/unounouno.jpg',
        name: 'ESSE80',
        address: 'Via Monterustico, 5 60043 Cerreto d’Esi (AN)',
        description: `L’azienda “ESSE80 s.r.l.” creata nel febbraio 2016, con sede in Cerreto d’Esi, vede come legale rappresentante il sig. Sorci Giovanni, e nasce dal conferimento delle due ditte esistenti e collaboranti attivamente, ovvero la “ Sorci Giovanni Lamer” che sorgeva nel 1980 come azienda artigiana specializzata nella carpenteria metallica, la quale nel corso degli anni ha modificato l’attività in base alle esigenze dei clienti, rivolgendo il proprio operato al servizio delle industrie di cappe aspiranti, di elettrodomestici in genere e termosanitari (piani cottura, forni, lavatrici, macchine da caffè, riparazione vasche da bagno, frigoriferi etc), e l’azienda del figlio, “LA.M.IX. di Sorci Cristian” nata nel 2006 e specializzata nella lavorazione di piegatura di lamiere oltre che al trasporto conto terzi.`,
        certified: true,
    },
    {
        img: 'https://react.semantic-ui.com/images/wireframe/image.png',
        name: 'Reco',
        address: 'Non inserita',
        description: paragraph,
        certified: false,
    },
]
class IndexPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterEnabled: false,
        }
    }

    companies = () => {
        return comp.map(({ img, name, address, description, certified }) => (
            <Item key={name}>
                <Item.Image src={img} label={{ as: 'a', color: 'black', content: 'Dettagli', ribbon: true }} />

                <Item.Content>
                    <Item.Header as="a">
                        {name} <Rating icon="star" defaultRating={3} maxRating={5} />
                    </Item.Header>

                    <Item.Meta>
                        <span className="cinema">{address}</span>
                    </Item.Meta>
                    <Item.Description>{description}</Item.Description>
                    <Item.Extra>
                        <Label color={certified ? 'green' : 'red'} tag>
                            {certified ? 'CERTIFICATA' : 'NON CERTIFICATA'}
                        </Label>
                    </Item.Extra>
                </Item.Content>
            </Item>
        ))
    }

    render() {
        const { filterEnabled } = this.state
        return (
            <View fullh column style={{ padding: 16 }}>
                <View>
                    <Button
                        circular
                        icon="settings"
                        style={{
                            position: 'fixed',
                            top: 100,
                            right: 50,
                        }}
                        onClick={() => this.setState({ filterEnabled: !filterEnabled })}
                    />
                </View>
                <View fullh fullw column style={{ marginTop: 40 }}>
                    <Item.Group divided style={{ paddingLeft: 100 }}>
                        {this.companies()}
                    </Item.Group>
                </View>
                <Sidebar.Pushable
                    as="div"
                    style={{
                        position: 'absolute',
                        top: 62,
                        left: 100,
                        height: 'calc(100% - 62px)',
                        width: 260,
                        zIndex: 44,
                    }}
                >
                    <Sidebar as={Menu} animation="overlay" direction="left" vertical visible={filterEnabled}>
                        <Menu.Item header>Filtri</Menu.Item>
                        <Menu.Item>Tutte</Menu.Item>
                    </Sidebar>
                </Sidebar.Pushable>
            </View>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({})
export default connect(mapStateToProps)(connectNetwork(IndexPage))
