export function createKit(data) {
    return new Promise((resolve, reject) => {
        let { createKitRequest, createKitError, createKitResult } = this.props

        delete data.creationDate;
        delete data.updateDate;

        this.doAction(
            this.WEB_REQUEST_URL + 'create_kit',
            this.createBundle(
                'create_kit',
                { kit: data },
                this.POST,
                createKitRequest,
                (result) => {
                    createKitResult(result)
                    resolve(result)
                },
                (error) => {
                    createKitError(error)
                    reject(error)
                }
            )
        )
    })
}
