export function createWorker(data) {
    let { createWorkerRequest, createWorkerError, createWorkerResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_worker',
        this.createBundle('create_worker', { worker: data }, this.POST, createWorkerRequest, createWorkerResult, createWorkerError)
    )
}
