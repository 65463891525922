import moment from 'momentconfig'
import { WORKDAY_TYPES } from '../actions/workday'
import reducer from './requests'
moment.locale('it')
const Reducer = (
    state = {
        fetching: false,
        status: {
            success: false,
            error: undefined,
        },
        data: {
            date: moment(),
            color: moment().day() === 0 || moment().day() === 6 ? 'red' : 'grey',
            key: moment().format('YYYY-MM-DD'),
        },
    },
    action
) => {
    let requestReducer = reducer(WORKDAY_TYPES)(state, action)
    if (requestReducer !== null) return requestReducer
    switch (action.type) {
        default:
            return state
    }
}
export default Reducer
