export function getJobList(isEmployee, inverse, search, notasks) {
    return new Promise((resolve, reject) => {
        let { getJobListRequest, getJobListError, getJobListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_job_list',
            this.createBundle(
                'get_job_list',
                { inverse, search: search ? search : '', notasks },
                this.GET,
                getJobListRequest,
                (result) => {
                    getJobListResult(result)
                    resolve(result)
                },
                (error) => {
                    getJobListError(error)
                    reject(error)
                },
                isEmployee
            )
        )
    })
}
