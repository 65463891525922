import JsBarcode from 'jsbarcode'
import moment from 'momentconfig'
import React, { Component } from 'react'
import { DragSource } from 'react-dnd'

import '../App.css'
import { padToDigits } from '../lib/util'

class DraggableBarcode extends Component {
    replaceText = (string) => {
        const { arti, pack, customer, worker, currentSession } = this.props

        let artiOpSeq = ''
        if (!!arti && !!(!!(arti.codop || arti.codop === '') || !!(arti.op || arti.op === '')) && !!(arti.codice || arti.workCode)) {
            const codop = arti.op ? arti.op : arti.codop
            const codice = arti.workCode ? arti.workCode : arti.codice
            const seq = arti.seq ? arti.seq : 1
            if (currentSession && currentSession.sessionTargetPackageCount >= 0) {
                artiOpSeq = `${codice}${padToDigits(codop ? codop : '000000', 6)}${padToDigits(currentSession.sessionTargetPackageCount, 6)}${padToDigits(seq, 3)}`
            } else {
                artiOpSeq = `${codice}${padToDigits(codop ? codop : '000000', 6)}${padToDigits(seq, 9)}`
            }
        }

        return string
            .replace('{{pcsPerPackage}}', currentSession ? currentSession.sessionTargetPackageCount + ' PZ' : '')
            .replace(
                '{{articleQuantity}}',
                currentSession && currentSession.sessionTargetPackageCount >= 0 ? currentSession.sessionTargetPackageCount : ''
            )

            .replace('{{workerName}}', worker && worker.name ? worker.name : '')

            .replace('{{customerName}}', customer && customer.customerName ? customer.customerName : '')

            .replace('{{clientArticleCode}}', arti && arti.clientWorkCode ? arti.clientWorkCode.replace('*', '') : '')

            .replace('{{articleCode}}', arti && arti.workCode ? arti.workCode : '')

            .replace('{{articleName}}', arti && arti.description ? arti.description : '')

            .replace('{{customerAddress}}', arti && arti.customerDescription ? arti.customerDescription : '')
            .replace('{{customerAddress1}}', arti && arti.customerDescription ? arti.customerDescription : '')
            .replace('{{customerAddress2}}', arti && arti.customerDescription ? arti.customerDescription : '')

            .replace('{{lotto}}', padToDigits(arti ? (arti.lotto || arti.codop || arti.op || '') : '', 6))
            .replace('{{artiLotto}}', arti && arti.lotto && arti.workCode ? `${arti.workCode}${arti.lotto}` : '')
            .replace('{{artiOp}}', arti && (arti.op || arti.codop) && arti.workCode ? `${arti.workCode}${arti.op ? arti.op : arti.codop}` : '')

            .replace('{{warehouseSequential}}', arti && arti.warehouseSequential !== undefined ? arti.warehouseSequential : artiOpSeq)
            .replace(
                '{{warehouseQuantity}}',
                arti && arti.articleQuantity !== undefined
                    ? arti.articleQuantity
                    : currentSession && currentSession.sessionTargetPackageCount >= 0
                    ? currentSession.sessionTargetPackageCount
                    : ''
            )
            .replace(
                '{{warehousePaddedQuantity}}',
                arti && arti.articleQuantity !== undefined
                    ? arti.paddedArticleQuantity
                    : padToDigits(currentSession ? currentSession.sessionTargetPackageCount : 0, 6)
            )
            .replace(
                '{{warehousePcsPerPackage}}',
                (arti && arti.articleQuantity !== undefined ? arti.articleQuantity : currentSession ? currentSession.sessionTargetPackageCount : 0) +
                    ' PZ'
            )
            .replace(
                '{{warehouseKgs}}',
                `KG. ${
                    arti && arti.articleQuantity !== undefined ? arti.articleQuantity : currentSession ? currentSession.sessionTargetPackageCount : 0
                }`
            )

            .replace('{{seq}}', arti && arti.seq ? arti.seq : 1)

            .replace('{{op}}', arti && arti.op ? arti.op : '')
            .replace('{{artiOpSeq}}', `${artiOpSeq}`)

            .replace('{{deliveryDate}}', arti && arti.creationDate ? moment(arti.creationDate).format('DD/MM/YY') : '')
            .replace('{{currentDate}}', moment().format('DD/MM/YY'))
    }

    replacePlaceholderText = (string) => {
        if (!string) return 'UNDEFINED'

        return string
            .replace('{{deliveryDate}}', 'Data di consegna')
            .replace('{{currentDate}}', 'Data attuale')
            .replace('{{pcsPerPackage}}', 'Pezzi per pacchetto')
            .replace('{{customerAddress}}', 'Descrizione cliente')
            .replace('{{customerName}}', 'Cliente')
            .replace('{{clientArticleCode}}', 'Codice articolo cliente')
            .replace('{{articleCode}}', 'Codice articolo')
            .replace('{{articleName}}', 'Articolo')
            .replace('{{articleQuantity}}', 'Quantità articolo')
            .replace('{{workerName}}', 'Operatore')
            .replace('{{lotto}}', 'Lotto')
            .replace('{{warehouseSequential}}', 'Sequenziale magazzino')
    }

    render() {
        let { isPreview, index, connectDragSource, value, layoutElementStyle, padding } = this.props
        let paddingToApply = padding ? padding : 0
        const { width, ...style } = layoutElementStyle

        const replaceFunc = isPreview ? this.replacePlaceholderText : this.replaceText
        const { background, displayValue, font, fontOptions, fontSize, format, height, lineColor, width: w } = layoutElementStyle
        const barcodeStyle = {
            background,
            displayValue,
            font,
            fontOptions,
            fontSize,
            format,
            height,
            lineColor,
            width: w,
        }
        setImmediate(() => {
            const text = replaceFunc(value.layoutElementValue)
            if (text && text !== '' && document.getElementById(`barcode${index}`)) {
                JsBarcode(`#barcode${index}`, text, barcodeStyle)
            }
        })

        return connectDragSource(
            <div
                className="barcode-container"
                index={index}
                style={
                    !isPreview
                        ? {
                              transformOrigin: 'top left',
                              left: value.layoutElementX + paddingToApply,
                              top: value.layoutElementY + paddingToApply,
                              position: 'absolute',
                              ...style,
                            //   zIndex: -1,
                          }
                        : { ...style }
                }
            >
                <svg id={`barcode${index}`}></svg>
                {/* <Barcode value={replaceFunc(value.layoutElementValue)} {...layoutElementStyle} /> */}
            </div>
        )
    }
}

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
    }
}

const spec = {
    beginDrag(props, monitor, component) {
        const item = { component, data: props.value, style: props.layoutElementStyle }
        return item
    },
}

export default DragSource('any', spec, collect)(DraggableBarcode)
