/* eslint react/no-direct-mutation-state: "off" */
import { Box } from '@chakra-ui/layout'
import { connectNetwork } from 'lib/NetworkProvider'
import { routes, route_from } from 'lib/routes'
import View from 'lib/View'
import moment from 'momentconfig'
import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Area, AreaChart, CartesianGrid, Legend, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { Button, Form, Icon, Input, Select, Table, Transition } from 'semantic-ui-react'
import { Confirmation, CustomTable, DataPoint, ImageDefault } from '../components'
import { computeGaps, computeHadesData, Default, floatTimeToString, Mobile, timeString } from '../lib/util'

const { app, employees, workerEdit } = routes

const COLORS = { quantity: '#00B75B', qOptimal: '#EAFAF1', scrap: '#FFD930', sMax: '#FEF9E7' }

const REASON_LABELS = {
    0: 'Setup',
    1: 'Produzione',
    2: 'Blocco',
    99: 'Termine Lavori',
}

const REASON_COLORS = ['#FDF2E9', '#E9F7EF', '#F9EBEA']
let max = 0

class Worker extends Component {
    constructor(props) {
        super(props)
        let { onCostChange, worker } = props

        this.state = {
            deleting: false,
            chartDataReference: [],
            chartData: [],
            chartWorkOrder: '0',
            ...worker,
        }

        if (onCostChange && worker) {
            onCostChange(this.totalCost(), worker.workerId)
        }
    }

    totalCost() {
        let { workerHourlyRate, workerHours, workerMinutes } = this.state
        let total = (workerHours + workerMinutes / 60) * workerHourlyRate
        if (isNaN(total)) {
            total = 0
        }
        total = Math.round(total * 100) / 100
        return total
    }

    componentDidMount() {
        let { worker, workerId, network } = this.props

        if ((!worker || !worker.workerId) && workerId) {
            network.getWorker(workerId)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { onCostChange, getWorker, network, getFromId, organization, login, listingIndex } = this.props
        let { workerId, workerHourlyRate, chartWorkOrder, listings } = this.state

        if (listings && listingIndex !== prevProps.listingIndex) {
            let found = false
            for (const listing of listings) {
                if (listing.listingId === listingIndex) {
                    this.setState({ workerHourlyRate: parseFloat(listing.price) })
                    found = true
                }
            }

            if (!found) {
                this.setState({ workerHourlyRate: 0 })
            }
        }

        if (workerHourlyRate !== prevState.workerHourlyRate) {
            if (onCostChange) {
                onCostChange(this.totalCost(), workerId)
            }
        }

        if (getWorker && getWorker.fetching !== prevProps.getWorker.fetching && !getWorker.fetching) {
            if (getWorker.status.success) {
                // console.log(getWorker.data)
                let worker = getWorker.data.data

                this.setState({ ...worker })
                // console.log('Calling Get worker success', login)
                if (login && login.data.user && login.data.user.companies) {
                    let company = login.data.user.companies[organization.data[0]]
                    // console.log('Getting from id both filter and not')
                    network.getFromIdFiltering(company + '_worker', `${worker.workerIdentifier}`, [
                        {
                            key: 'timestamp',
                            symbol: '>',
                            value: moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                        },
                    ])
                    network.getFromId(company + '_worker', `${worker.workerIdentifier}`)
                }
                return
            }
        }

        if (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) {
            this.setState(computeHadesData(this.props))
        }

        if (
            (getFromId && getFromId.fetching !== prevProps.getFromId.fetching && !getFromId.fetching) ||
            chartWorkOrder !== prevState.chartWorkOrder
        ) {
            if (getFromId.status.success && getFromId.data) {
                // console.log('getFromId data is:', getFromId)

                let {
                    getFromId: {
                        request_data: { id: requestedWorker },
                        data: { data: hadesData },
                    },
                } = this.props

                if (!this.state.workerIdentifier || this.state.workerIdentifier !== requestedWorker) {
                    // console.log('Worker id:', this.state.workerId, 'requested:', requestedWorker)
                    return null
                }

                // console.log('Data from filtering is', hadesData)

                let fixedData = []
                if (hadesData) {
                    let condensed = []
                    for (let i = 0; i < hadesData.length; i++) {
                        if (i % 1 === 0) {
                            // TODO choose resolution
                            hadesData[i].millis = new Date(hadesData[i].timestamp).getTime() / 1000
                            condensed.push(hadesData[i])
                        }
                    }
                    fixedData = condensed
                }

                // console.log('fixedData is: ', fixedData)

                let _data = []
                if (this.state.buffer) {
                    for (let i in this.state.buffer) {
                        this.state.buffer[i].millis = new Date(this.state.buffer[i].timestamp).getTime() / 1000
                    }
                    _data = [...fixedData, ...this.state.buffer]
                } else {
                    _data = [...fixedData]
                }

                let { chartDataReference, chartData } = this.state

                if (_data.length === 0) return null

                chartDataReference = []
                chartData = []
                _data.sort((a, b) => {
                    return a.millis - b.millis
                })

                // console.log('fixedData is: ', _data)

                for (let d of _data) {
                    let { timestamp, millis, quantity, scrap, reason, id, sub_id, terminal } = d

                    if (sub_id !== chartWorkOrder) {
                        // console.log('Skipping data of different work order (', sub_id, ')')
                        // Skip data not from this work order
                        continue
                    }

                    if (chartData.some((x) => x.timestamp === timestamp)) {
                        //console.log('already in', timestamp);
                        // if alreasy has that timestamp
                        continue
                    }

                    let maxQty = Math.max(quantity, scrap)
                    if (maxQty > max) max = maxQty

                    if (chartDataReference.length !== 0) {
                        let last = chartDataReference.splice(-1)[0]
                        if (last.id === reason) {
                            last.x2 = millis
                        } else {
                            last.x2 = millis
                            chartDataReference.push({ ...last })
                            last.id = reason
                            last.x1 = millis
                            last.x2 = millis
                            last.label = REASON_LABELS[reason]
                            last.fillColor = REASON_COLORS[reason]
                        }
                        chartDataReference.push(last)
                    } else {
                        let current = {
                            id: reason,
                            label: REASON_LABELS[reason],
                            x1: millis,
                            x2: millis,
                            y1: 0,
                            fillColor: REASON_COLORS[reason],
                            opacity: '0.5',
                        }
                        chartDataReference.push(current)
                    }
                    for (let item of chartDataReference) {
                        item.y2 = max + 50
                    }
                    chartData.push({
                        timestamp,
                        millis,
                        corretti: quantity,
                        scarti: scrap,
                        commessa: id,
                        lavoro: sub_id,
                        macchina: terminal,
                    })
                }
                let { quantity, scrap } = _data.pop()
                let radialQuantity = [
                    {
                        name: 'Ottimale',
                        value: 1000,
                        fill: COLORS.qOptimal,
                    },
                    {
                        name: 'quatita',
                        value: quantity,
                        fill: COLORS.quantity,
                    },
                ]
                let radialScrap = [
                    {
                        name: 'Massimo',
                        value: 500,
                        fill: COLORS.sMax,
                    },
                    {
                        name: 'scarti',
                        value: scrap,
                        fill: COLORS.scrap,
                    },
                ]

                return this.setState({
                    chartData: [...chartData],
                    chartDataReference: [...chartDataReference],
                    buffer: [],
                    radialQuantity,
                    radialScrap,
                })
            }
        }
    }

    renderBalanceFooter() {
        let { hourtotal, total } = this.props

        let averageHourlyCost = Math.round((total / hourtotal) * 100) / 100
        if (isNaN(averageHourlyCost)) {
            averageHourlyCost = '--'
        }

        return (
            <Table.Row>
                <Table.Cell width="5">Prezzo orario medio: {averageHourlyCost}€/h</Table.Cell>
                <Table.Cell style={{ textAlign: 'right' }} width="4">
                    Totale Ore:
                </Table.Cell>
                <Table.Cell width="4">{floatTimeToString(hourtotal)}</Table.Cell>
                <Table.Cell width="1">Totale:</Table.Cell>
                <Table.Cell width="2">{total}€</Table.Cell>
            </Table.Row>
        )
    }

    renderBalanceHeader() {
        return (
            <Table.Header fullWidth>
                <Table.Row>
                    <Table.HeaderCell width="5">Nome Impiegato</Table.HeaderCell>
                    <Table.HeaderCell width="4">Prezzo €/h</Table.HeaderCell>
                    <Table.HeaderCell width="4">Ore di lavoro</Table.HeaderCell>
                    <Table.HeaderCell width="1" />
                    <Table.HeaderCell width="2">Totale</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )
    }

    renderForBalance() {
        let { workerName, workerHourlyRate, workerHours, workerMinutes } = this.state

        return (
            <Table.Row>
                <Table.Cell>{workerName}</Table.Cell>
                <Table.Cell>
                    <span className="no-print">
                        <Input
                            type="number"
                            step="0.01"
                            value={workerHourlyRate}
                            onChange={(e, data) => {
                                let value = data.value
                                if (value < 0) value = 0
                                this.setState({
                                    workerHourlyRate: value,
                                })
                            }}
                        />
                        €/h
                    </span>
                    <span className="only-print">{workerHourlyRate}€/h</span>
                </Table.Cell>
                <Table.Cell>{timeString(workerHours, workerMinutes)}</Table.Cell>
                <Table.Cell>=</Table.Cell>
                <Table.Cell>{this.totalCost()}€</Table.Cell>
            </Table.Row>
        )
    }

    renderTableFooter() {
        let { isBalance } = this.props
        if (isBalance) return this.renderBalanceFooter()
        else return null
    }

    renderTableHeader() {
        let { isBalance } = this.props
        if (isBalance) {
            return this.renderBalanceHeader()
        } else {
            return (
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>Nome Impiegato</Table.HeaderCell>
                        <Table.HeaderCell>Ore di lavoro</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )
        }
    }

    renderForTable() {
        let { isBalance } = this.props

        if (isBalance) {
            return this.renderForBalance()
        } else {
            return (
                <Table.Row>
                    <Table.Cell>{this.props.worker.workerName}</Table.Cell>
                    <Table.Cell>{timeString(this.props.worker.workerHours, this.props.worker.workerMinutes)}</Table.Cell>
                </Table.Row>
            )
        }
    }

    openRemoveDialog() {
        this.setState({ deleting: true })
    }

    remove() {
        let { network, worker } = this.props

        network.deleteWorker(worker)

        this.setState({ deleting: false })
    }

    renderControls() {
        let { grouped, chartWorkOrder } = this.state

        let options = []
        for (let i in grouped) {
            options.push({
                key: i,
                value: i,
                text: i,
            })
        }

        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Field
                        id="form-input-control-job-type"
                        control={Select}
                        options={options}
                        label={<span className="label">Ordine di lavoro</span>}
                        placeholder="Ordine di lavoro"
                        name="chartWorkOrder"
                        value={chartWorkOrder}
                        onChange={(e, data) => {
                            let id = data.value
                            this.setState({ chartWorkOrder: id })
                        }}
                    />
                </Form.Group>
            </Form>
        )
    }

    renderCharts() {
        let { chartData, chartDataReference } = this.state

        // console.log('Chart data is', chartData, chartDataReference)

        return (
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                    data={chartData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    onMouseOver={() => {
                        this.setState({ stopChart: true })
                    }}
                    onMouseLeave={() => {
                        this.setState({ stopChart: false })
                    }}
                >
                    <defs>
                        <linearGradient id="colorCorretti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.quantity} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.quantity} stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorScarti" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={COLORS.scrap} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={COLORS.scrap} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis
                        scale="time"
                        domain={['dataMin', 'dataMax']}
                        type="number"
                        dataKey="millis"
                        tickFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Legend verticalAlign="top" height={36} />
                    <Tooltip
                        labelFormatter={(value) => {
                            return moment.unix(value).format('DD/MM HH:mm:ss')
                        }}
                    />

                    {chartDataReference.map((value, index) => {
                        return (
                            <ReferenceArea
                                key={index}
                                ifOverflow="extendDomain"
                                // alwaysShow={true}
                                label={value.label}
                                x1={value.x1}
                                x2={value.x2}
                                y1={value.y1}
                                y2={value.y2}
                                fill={value.fillColor}
                                fillOpacity={value.opacity}
                            />
                        )
                    })}
                    <Area type="monotone" dataKey="corretti" stroke={COLORS.quantity} fillOpacity={1} fill="url(#colorCorretti)" />
                    <Area type="monotone" dataKey="scarti" stroke={COLORS.scrap} fillOpacity={1} fill="url(#colorScarti)" />
                    {/* <ReferenceLine y={350} label='Ottimalè stroke='red' strokeDasharray='3 3' /> */}
                </AreaChart>
            </ResponsiveContainer>
        )
    }

    renderDataTable() {
        let { grouped } = this.state
        let { isBalance } = this.props

        let extraProps = {}
        if (isBalance) {
            extraProps = {
                isBalance,
                onCostChange: (total, shorthand) => this.onCostChange(total, shorthand),
            }
        }

        const stats = computeGaps(grouped)

        let orderBy = (a, b) => {
            return a.millis - b.millis
        }

        return <CustomTable {...extraProps} orderBy={orderBy} name="Dati" shorthand="data" values={stats} child={DataPoint} subidName={`Commessa`} />
    }

    renderCardContent(direction) {
        let { type, role } = this.props
        let { workerName, pin, workerImage, workerHourlyRate } = this.state

        let extraViewProps = {
            row: (type === 'full' && !direction) || direction === 'row',
            column: (type !== 'full' && !direction) || direction === 'column',
        }

        return (
            <View fullw {...extraViewProps}>
                <div style={styles.imageContainer}>
                    <ImageDefault fromRoot src={workerImage} ui={false} style={styles.workerImage} />
                </div>
                <View fullw column>
                    <Default>
                        <span style={styles.header}>{workerName}</span>
                    </Default>
                    <Mobile>
                        <span style={styles.smallHeader}>{workerName}</span>
                    </Mobile>
                    {(!role || role === 'admin') && <Box textStyle="description">Prezzo orario: {workerHourlyRate}€/h</Box>}
                    <Box textStyle="meta">Pin di accesso al sinottico: {pin}</Box>
                </View>
            </View>
        )
    }

    render() {
        let { type, login, forcedOpen, onOpened } = this.props
        let { deleting, workerId, workerName, pin, workerImage, workerHourlyRate, workerHours, workerMinutes } = this.state

        let extraprops = {
            fluid: type === 'full' ? true : false,
        }
        let user = null
        if (login && login.authenticated) {
            user = login.data.user
        }

        switch (type) {
            case 'footer':
                return this.renderTableFooter()
            case 'header':
                return this.renderTableHeader()
            case 'table':
                return this.renderForTable()
            default:
                return (
                    <View noflex fullw column between>
                        <Box style={styles.workerCard} {...extraprops} borderWidth="1px" borderRadius="lg" p="4">
                            <Confirmation
                                message="Vuoi davvero eliminare questo impiegato?"
                                showModal={deleting}
                                onConfirm={() => {
                                    this.remove()
                                }}
                                onCancel={() => {
                                    this.setState({ deleting: false })
                                }}
                            />
                            <Box textStyle="content" style={styles.workerContent}>
                                <Default>{this.renderCardContent()}</Default>
                                <Mobile>{this.renderCardContent('column')}</Mobile>
                            </Box>
                            <Box textStyle="content" extra>
                                {!forcedOpen && (
                                    <Button
                                        onClick={() => {
                                            if (onOpened) {
                                                onOpened(
                                                    {
                                                        workerId,
                                                        workerName,
                                                        pin,
                                                        workerImage,
                                                        workerHourlyRate,
                                                        workerHours,
                                                        workerMinutes,
                                                    },
                                                    true,
                                                    () => {
                                                        this.props.history.push(route_from(app, employees, `${workerId}`))
                                                    }
                                                )
                                            } else {
                                                this.props.history.push(route_from(app, employees, `${workerId}`))
                                            }
                                        }}
                                    >
                                        <Icon name="folder open" />
                                        <Default>Apri</Default>
                                    </Button>
                                )}
                                {user && !!user.manage_worker && (
                                    <Button
                                        color="blue"
                                        onClick={() => {
                                            this.props.history.push(route_from(app, employees, workerEdit, `${workerId}`))
                                        }}
                                    >
                                        <Icon name="edit" />
                                        <Default>Modifica</Default>
                                    </Button>
                                )}
                                {user && !!user.manage_worker && (
                                    <Button
                                        color="red"
                                        onClick={() => {
                                            this.openRemoveDialog()
                                        }}
                                    >
                                        <Icon name="delete" />
                                        <Default>Elimina</Default>
                                    </Button>
                                )}
                            </Box>
                            {type === 'full' && forcedOpen && (
                                <Transition visible={forcedOpen ? true : this.state.showDetails} animation="slide down" duration={200}>
                                    <Box textStyle="content">
                                        <View fullw column>
                                            {this.renderControls()}
                                            {this.renderCharts()}
                                            {this.renderDataTable()}
                                        </View>
                                    </Box>
                                </Transition>
                            )}
                        </Box>
                    </View>
                )
        }
    }
}

const styles = {
    header: {
        fontSize: '40pt',
        lineHeight: 'normal',
    },
    smallHeader: {
        fontSize: '32pt',
        lineHeight: 'normal',
    },
    imageContainer: {
        minWidth: 200,
        minHeight: 150,
        marginRight: 28,
    },
    workerImage: {
        width: 200,
        height: 150,
        objectFit: 'contain',
    },
    workerContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    workerCard: {
        marginTop: 8,
        marginBottom: 8,
    },
}

const mapStateToProps = (state) => {
    let { login, organization, getWorker, getFromId, getFromIdFiltering } = state
    let rcode = null
    if (login && login.data && login.data.user) rcode = login.data.user.rcode

    return {
        role: rcode,
        getWorker,
        organization,
        getFromId,
        getFromIdFiltering,
        login,
    }
}

export default withRouter(connect(mapStateToProps)(connectNetwork(Worker)))
