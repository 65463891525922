export function createTask(job, tasks) {
    return new Promise((resolve, reject) => {
        let { createTaskRequest, createTaskError, createTaskResult } = this.props

        // delete data.creationDate;
        // delete data.updateDate;

        this.doAction(
            this.WEB_REQUEST_URL + 'create_task',
            this.createBundle(
                'create_task',
                { tasks, job },
                this.POST,
                createTaskRequest,
                (result) => {
                    createTaskResult(result)
                    resolve(result)
                },
                (error) => {
                    createTaskError(error)
                    reject(error)
                }
            )
        )
    })
}
