export function getSimpleArticleList(search) {
    return new Promise((resolve, reject) => {
        let { getSimpleArticleListRequest, getSimpleArticleListError, getSimpleArticleListResult } = this.props

        this.doAction(
            this.WEB_REQUEST_URL + 'get_simple_article_list',
            this.createBundle(
                'get_simple_article_list',
                { search: search ? search : '' },
                this.GET,
                getSimpleArticleListRequest,
                (result) => {
                    getSimpleArticleListResult(result)
                    resolve(result)
                },
                (error) => {
                    getSimpleArticleListError(error)
                    reject(error)
                }
            )
        )
    })
}
