export function getTimeCalendar(data) {
    return new Promise((resolve, reject) => {
		let { getTimeCalendarRequest, getTimeCalendarError, getTimeCalendarResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'get_timecalendar',
			this.createBundle(
				'get_timecalendar',
				{ timecalendar: data },
				this.GET,
				getTimeCalendarRequest,
                (result) => {
                    getTimeCalendarResult(result)
                    resolve(result)
                },
                (error) => {
                    getTimeCalendarError(error)
                    reject(error)
                }
			)
		);
	})
}
