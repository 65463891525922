export function createArticleImport(data) {
    let { createArticleImportRequest, createArticleImportError, createArticleImportResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    return new Promise((resolve, reject) => {
        this.doAction(
            this.WEB_REQUEST_URL + 'create_articleimport',
            this.createBundle(
                'create_articleimport',
                { articleimport: data },
                this.POST,
                createArticleImportRequest,
                (result) => {
                    createArticleImportResult(result)
                    resolve(result)
                },
                (error) => {
                    createArticleImportError(error)
                    reject(error)
                }
            )
        )
    })
}
