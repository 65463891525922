export function createItem(data) {
    let { createItemRequest, createItemError, createItemResult } = this.props

    delete data.creationDate;
    delete data.updateDate;

    this.doAction(
        this.WEB_REQUEST_URL + 'create_item',
        this.createBundle('create_item', { item: data }, this.POST, createItemRequest, createItemResult, createItemError)
    )
}
