export function deleteListing(data) {
    return new Promise((resolve, reject) => {
		let { deleteListingRequest, deleteListingError, deleteListingResult } = this.props;

		this.doAction(
			this.WEB_REQUEST_URL + 'delete_listing',
			this.createBundle(
				'delete_listing',
				{ listing: data },
				this.POST,
				deleteListingRequest,
                (result) => {
                    deleteListingResult(result)
                    resolve(result)
                },
                (error) => {
                    deleteListingError(error)
                    reject(error)
                }
			)
		);
	})
}
